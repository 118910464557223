import React, {MouseEventHandler, useState} from 'react';
import './styles.scss';
import {Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {FaPen, FaBan, FaRegWindowClose, FaRegSave} from 'react-icons/fa';
import {toast} from 'react-toastify';

import {editarCategoria} from '../../../api/categoria-api';
import {ICategoria} from '../../../models/categoria-model';
import BtnDefault from '../../Buttons/BtnDefault';
import CharacterCount from '../../CharacterCount';
import Errors from '../../Errors';
import InputDefault from '../../Inputs/InputDefault';
import noEmptyMask from '../../../utils/masks/noEmptyMask';
interface IModalEditarCategoriaProps {
  categoria: ICategoria;
  handleCloseModal?: Function;
  handleUpdateTable?: Function;
  action?: MouseEventHandler;
  showEditOrView?: boolean;
}

const FormEditCategoria: React.FC<IModalEditarCategoriaProps> = ({
  categoria,
  handleCloseModal,
  handleUpdateTable,
  showEditOrView,
}: IModalEditarCategoriaProps) => {
  const defaultValues = {descricao: categoria.descricao};

  const {register, handleSubmit, errors} = useForm({defaultValues});

  const countCharacters = 100 - defaultValues.descricao.length;

  const [editing, setEditing] = useState(showEditOrView);
  const [contadorDescricao, setContadorDescricao] = useState(countCharacters);

  const handleEditar = async data => {
    try {
      await editarCategoria(categoria.id, data.nome, data.descricao);
      toast.success('Categoria editada com sucesso!', {autoClose: 2500});
      closeModal();
      handleCloseModal();
    } catch (e) {
      console.error(e);
    }
  };

  const characterCounter = e => {
    e.target.value = e.target.value.replace(/^\s+/, '');
    setContadorDescricao(100 - e.target.value.length);
  };

  const cancelForm = () => {
    handleCloseModal(false);
  };

  const closeModal = () => {
    handleUpdateTable(true);
  };

  const toggleEditing = () => {
    setEditing(true);
  };
  return (
    <>
      <form onSubmit={handleSubmit(handleEditar)} className="form-modal">
        <h6 className="titulo-sessao">Informações da categoria</h6>
        <div className="form-row">
          <div className="col-sm-12" key="nome">
            <label className="label-item-tabela" htmlFor="input-nome">
              Nome da categoria/setor
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="nome"
              type="text"
              disabledInput={!editing}
              readOnly={false}
              id="input-nome"
              inputRef={register({
                required: true,
                maxLength: 100,
                minLength: 1,
              })}
              defaultValue={categoria.nome}
              erros={errors}
              inputMaxLength="100"
              mask={noEmptyMask}
            ></InputDefault>
          </div>

          <div className="col-sm-12  mt-3" key="descricao">
            <label className="label-item-tabela" htmlFor="categoria-id">
              Descrição
            </label>
            <span className="asterisk ">*</span>
            <textarea
              name="descricao"
              maxLength={100}
              id="categoria-id"
              className="text-area-default"
              aria-label="With textarea"
              ref={register({required: true})}
              onChange={characterCounter}
              defaultValue={categoria.descricao}
              disabled={!editing}
            ></textarea>

            <div className="d-flex justify-content-between">
              <Errors name="descricao" erros={errors} />
              <CharacterCount count={contadorDescricao}></CharacterCount>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-4">
          <Button
            className="btn-custom btn-cancel"
            variant="custom"
            onClick={() => cancelForm()}
          >
            {showEditOrView ? (
              <span>
                <FaBan size={13} className="mr-1" /> Cancelar
              </span>
            ) : (
              <span>
                <FaRegWindowClose size={13} className="mr-1" /> Fechar
              </span>
            )}
          </Button>
          {!editing && !showEditOrView ? (
            <Button
              type="button"
              className="btn-custom btn-save"
              variant="custom"
              onClick={toggleEditing}
            >
              <FaPen size={13} className="mr-1" />
              Editar
            </Button>
          ) : (
            <BtnDefault
              type="submit"
              variantClass="custom"
              className="btn-custom btn-save"
            >
              {showEditOrView ? (
                <span>
                  <FaPen size={13} className="mr-1" />
                  Editar
                </span>
              ) : (
                <span>
                  {' '}
                  <FaRegSave size={13} className="mr-1" />
                  Salvar
                </span>
              )}
            </BtnDefault>
          )}
        </div>
      </form>
    </>
  );
};

export default FormEditCategoria;
