import React, {useEffect, useState} from 'react';

import {faturamentoByCategoriaByDate} from '../../../../api/dashboard-api';
import UILoad from '../../../../components/UI/Load';
import {
  IDashboardFilters,
  IFaturamento,
} from '../../../../models/dashboard-model';
import {formataDinheiro} from '../../../../utils/formatMonetary';
interface Filtros {
  filter: IDashboardFilters;
}

const FaturamentoCategoria: React.FC<Filtros> = ({filter}) => {
  const [faturamento, setFaturamento] = useState<IFaturamento>();
  const [loading, setLoading] = useState(false);

  async function getFaturamentoByCategoriaByDate(filter) {
    setLoading(true);
    const listaFaturamento: IFaturamento = await faturamentoByCategoriaByDate(
      filter
    );
    setFaturamento(listaFaturamento);
    setLoading(false);
  }
  useEffect(() => {
    if (filter) {
      getFaturamentoByCategoriaByDate(filter);
    }
  }, [filter]);
  return (
    <div
      className={
        loading ? 'dashboard-categoria__border' : 'dashboard-categoria__border'
      }
    >
      <h6 className="dashboard-categoria__title">Faturamento por categoria</h6>
      {loading ? (
        <UILoad />
      ) : (
        <>
          {faturamento && faturamento.total > 0 ? (
            <>
              <div className="dashboard-categoria__content">
                <table className="categoria-table">
                  <thead className="dashboard-categoria__table">
                    <tr>
                      <th>Categoria / setor</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {faturamento &&
                      faturamento.categorias.map((val, index) => {
                        return (
                          <tr key={index}>
                            <td>{val.nome}</td>
                            <td>{formataDinheiro(val.total)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div
                className={
                  loading
                    ? 'dashboard-categoria__show '
                    : 'dashboard-categoria__tot'
                }
              >
                <span className="dashboard-categoria__total">
                  Total - {faturamento && formataDinheiro(faturamento.total)}
                </span>
              </div>
            </>
          ) : (
            <span className="dashboard-boleto__span dashboard-boleto__zero   ">
              Não há dados disponíveis para o período selecionado.{' '}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default FaturamentoCategoria;
