export default function cpfcnpjMask(rawNum: string): string {
  if (rawNum === undefined) {
    return rawNum;
  }
  rawNum = rawNum.replace(/\D/g, '');
  if (rawNum.length > 11) {
    rawNum = rawNum.replace(/(\d{2})(\d)/, '$1.$2');
    rawNum = rawNum.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    rawNum = rawNum.replace(/^(\d{2})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3/$4');
    rawNum = rawNum.replace(
      /^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d)/,
      '$1.$2.$3/$4-$5'
    );
  } else {
    rawNum = rawNum.replace(/^(\d{3})(\d)/g, '$1.$2');
    rawNum = rawNum.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3');
    rawNum = rawNum.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3-$4');
    rawNum = rawNum.replace(
      /^(\d{3})\.(\d{3})\.(\d{3})\/(\d{2})(\d)/,
      '$1.$2.$3-$4'
    );
  }

  return rawNum;
}

export function cnpjMask(rawNum: string): string {
  if (!rawNum) {
    return '';
  }

  rawNum = rawNum.replace(/\D/g, '');
  rawNum = rawNum.replace(/^(\d{2})(\d)/g, '$1.$2');
  rawNum = rawNum.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  rawNum = rawNum.replace(/^(\d{2})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3/$4');
  rawNum = rawNum.replace(
    /^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d)/,
    '$1.$2.$3/$4-$5'
  );
  rawNum = rawNum.replace(
    /^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d)\/(\d{2})(\d)/,
    '$1.$2.$3/$4-$5'
  );

  return rawNum;
}
