import cpfCnpjValidator, {
  cnpjValidator,
  cpfValidator,
} from './cpfCnpjValidator';

export function validaCpfCnpj(documento: string): boolean {
  documento = documento.replace(/[^\d]+/g, '');
  if (documento.length >= 11) {
    return cpfCnpjValidator(documento);
  }
  return false;
}

export function validaApenasCpf(documento: string): boolean {
  documento = documento.replace(/[^\d]+/g, '');
  if (documento.length <= 14) {
    return cpfValidator(documento);
  }
  return false;
}

export function validaApenasCnpj(documento: string): boolean {
  documento = documento.replace(/[^\d]+/g, '');
  if (documento.length <= 18) {
    return cnpjValidator(documento);
  }
  return false;
}
