import React from 'react';
import {IoIosArrowForward} from 'react-icons/io';

import './styles.scss';

export type CardTicketProps = {
  month: string;
  day: string;
  ticketStatus: 'Pagos' | 'Inadimplentes' | 'A vencer';
  qtdTickets: string;
  totalValue: string;
};

const CardTicket = ({
  month,
  day,
  ticketStatus,
  qtdTickets,
  totalValue,
}: CardTicketProps) => (
  <div className="card-cobranca__body col-md-12 row ml-0 align-items-center">
    <div className="col-2 flex-column">
      <div className="d-flex justify-content-center date__box date__box__title pb-2">
        {month}
      </div>
      <div className="d-flex justify-content-center date__box date__box__data">
        {day}
      </div>
    </div>

    <div className="col-3 flex-column">
      <div className="d-flex justify-content-center pb-2 box__label">
        Estado do boleto
      </div>
      <div className="d-flex justify-content-center box__data">
        {ticketStatus}
      </div>
    </div>

    <div className="col-3 flex-column">
      <div className="d-flex justify-content-center pb-2 box__label">
        Qnt. de boletos
      </div>
      <div className="d-flex justify-content-center box__data">
        {qtdTickets}
      </div>
    </div>

    <div className="col-3 flex-column">
      <div className="d-flex justify-content-center pb-2 box__label">
        Valor Total
      </div>
      <div className="d-flex justify-content-center box__data">
        {totalValue}
      </div>
    </div>

    <div className="col-1 pr-2 d-flex justify-content-end align-items-center arrow__box">
      <IoIosArrowForward size={40} />
    </div>
  </div>
);

export default CardTicket;
