import {FieldErrors} from 'react-hook-form';

import _ from 'lodash/fp';

interface IErrors {
  name: string;
  erros?: FieldErrors;
  inputMaxLength?: String;
  inputMinLengthNumber?: String;
  inputMinLength?: string;
}

const Errors: React.FC<IErrors> = ({
  name,
  erros,
  inputMaxLength,
  inputMinLengthNumber,
  inputMinLength,
}) => {
  return (
    <>
      {_.get(`${name}['type']`, erros) === 'required' && (
        <p className="input-error">Este campo é obrigatório.</p>
      )}

      {_.get(`${name}['type']`, erros) === 'maxLength' && (
        <p className="input-error">
          O campo não pode ter mais de {inputMaxLength} caracteres.
        </p>
      )}

      {/* Usado apenas em atributos do tipo number */}
      {_.get(`${name}['type']`, erros) === 'min' && (
        <p className="input-error">
          O campo não pode ter menos de {inputMinLengthNumber} caracteres.
        </p>
      )}
      {_.get(`${name}['type']`, erros) === 'minLength' && (
        <p className="input-error">
          Quantidade caracteres {inputMinLength} inválida.
        </p>
      )}
      {_.get(`${name}['type']`, erros) === 'validate' && (
        <p className="input-error">Valor inserido inválido</p>
      )}
      {_.get(`${name}['type']`, erros) === 'cpfCnpj' && (
        <p className="input-error">Valor do CPF/CNPJ inválido</p>
      )}
      {_.get(`${name}['type']`, erros) === 'cpf' && (
        <p className="input-error">Valor do CPF inválido</p>
      )}
      {_.get(`${name}['type']`, erros) === 'cnpj' && (
        <p className="input-error">Valor do CNPJ inválido</p>
      )}
      {_.get(`${name}['type']`, erros) === 'confirmacaoEmail' && (
        <p className="input-error">Os emails não correspondem</p>
      )}
      {_.get(`${name}['type']`, erros) === 'pattern' && (
          <p className="input-error">Caractere inválido.</p>
        ) &&
        !erros.email}
      {_.get(`${name}['type']`, erros) === 'pattern' && erros.email && (
        <p className="input-error">{erros.email.message}</p>
      )}

      {_.get(`${name}['type']`, erros) === 'pattern' &&
        erros.confirmacaoEmail && (
          <p className="input-error">{erros.confirmacaoEmail.message}</p>
        )}

      {_.get(`${name}['type']`, erros) === 'password' && (
        <p className="input-error">
          A senha deverá ter no mínimo 8 caracteres incluindo número, letra
          maiúscula, letra minúscula e símbolo.
        </p>
      )}

      {_.get(`${name}['type']`, erros) === 'categoria' &&
        erros?.categoria &&
        erros?.categoria?.type === 'required' && (
          <p className="input-error">{erros.confirmacaoEmail.message}</p>
        )}
    </>
  );
};

export default Errors;
