import React from 'react';
import './styles.scss';

interface LogoProps {
  src?: string;
}

import defaultLogo from '../../assets/logosU4crypto/company_logo.png';

const Logo: React.FC<LogoProps> = ({src}) => {
  return (
    <img
      className="text-center mb-5 img-fluid"
      width="100%"
      src={src ? src : defaultLogo}
      alt="Logo"
    />
  );
};

export default Logo;
