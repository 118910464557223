import React, {ButtonHTMLAttributes} from 'react';
import {Button} from 'react-bootstrap';
import {FaTimesCircle} from 'react-icons/fa';
import './styles.scss';

interface FormTicketProps extends ButtonHTMLAttributes<Button> {
  onClickClose?: Function;
}
const UIModalHeader = ({children, onClickClose}) => {
  return (
    <div className="ui-modal__header">
      <h4 className="modal-title"> {children} </h4>
      <button className="ui-modal__close-button">
        <FaTimesCircle
          className="input-content__icone-close-ticket"
          size={35}
          onClick={() => onClickClose()}
        />
      </button>
    </div>
  );
};

export default UIModalHeader;
