import {ICategoria} from 'models/categoria-model';

import {PaginationModel} from '../models/pagination-model';
import api from './api';

export const cadastrarCategoria = async (nome: string, descricao?: string) => {
  const params = {nome, descricao};
  return (await api.post<ICategoria>('/categorias', params))?.data;
};

export const listarCategorias = async (
  page = 0,
  limit = 100,
  nome?: string
) => {
  return (
    await api.get<PaginationModel>(
      `/categorias?page=${page}&limit=${limit}&nome=${nome}`
    )
  )?.data;
};

export const exibirCategoria = async (id: string) => {
  return (await api.get<ICategoria>(`/categorias/${id}`))?.data;
};

export const deletarCategoria = async (id: string) => {
  return await api.delete<ICategoria>(`/categorias/${id}`);
};

export const editarCategoria = async (
  id: string,
  nome: string,
  descricao?: string
) => {
  const params = {nome, descricao};
  return (await api.put<ICategoria>(`/categorias/${id}`, params))?.data;
};
