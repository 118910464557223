import React, {useEffect, useState} from 'react';
import './styles.scss';
import {Table} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {FaBroom, FaGripVertical, FaSearch} from 'react-icons/fa';
import {toast} from 'react-toastify';

import Swal from 'sweetalert2';

import {deletarCategoria, listarCategorias} from '../../api';
import {PaginationModel} from '../../models/pagination-model';
import noEmptyMask from '../../utils/masks/noEmptyMask';
import BtnDefault from '../Buttons/BtnDefault';
import InputDefault from '../Inputs/InputDefault';
import ModalDefault from '../ModalDefault';
import PaginationDefault from '../PaginationDefault';
import SpinnerLoader from '../Spinner';
import TableRowCategoria from '../TableRows/TableRowsCategoria';
import Titulo from '../Titulo';
import UIContent from '../UI/Content';
import ModalEditarCategoria from './ModalCategoriaEditar';

const MainCategoria: React.FC = () => {
  const {handleSubmit} = useForm();
  const [categorias, setCategorias] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(10);

  const [updateTable, setUpdateTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categoriaNome, setCategoriaNome] = useState<string>('');
  const [idCategoria, setIdCategoria] = useState<string | undefined>();
  const [showModalCategoria, setShowModalCategoria] = useState(false);
  const [showModalViewOrEdit, setShowModalViewOrEdit] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  if (updateTable) {
    listarTodasCategorias(currentPage);
    setUpdateTable(!updateTable);
  }

  async function listarTodasCategorias(pageNumber: number) {
    setIsLoading(true);
    let paginaAtual = 0;
    if (pageNumber > 0) {
      paginaAtual = pageNumber - 1;
    }

    const todasCategorias: PaginationModel = await listarCategorias(
      paginaAtual,
      limit,
      categoriaNome
    );

    setCategorias(todasCategorias.data);
    setTotalCount(todasCategorias.totalCount);
    setCurrentPage(pageNumber);
    setIsLoading(false);
  }

  const filtrar = async () => {
    listarTodasCategorias(1);
  };

  const clear = () => {
    setCategoriaNome('');
    setTriggerUpdate(!triggerUpdate);
  };

  const exibirModal = (id: string, showMenu: boolean) => {
    setIdCategoria(id);
    setShowModalCategoria(true);
    setShowModalViewOrEdit(showMenu);
  };
  const handleCloseCategoria = () => {
    setShowModalCategoria(false);
  };
  function excluirCategoria(id: string) {
    try {
      Swal.fire({
        title: 'Tem certeza que deseja excluir?',
        text: 'Ao confirmar, a categoria será excluída!',
        showCancelButton: true,
        confirmButtonText:
          '<i class="fas fa-trash fa-sm" aria-hidden="true"></i> Excluir',
        cancelButtonText:
          '<i class="fas fa-ban" aria-hidden="true"></i> Cancelar',
        customClass: {
          confirmButton: 'confirmButtonModalClass',
          cancelButton: 'cancelButtonModalClass',
        },
      }).then(async result => {
        if (result.value) {
          try {
            setIsLoading(true);
            await deletarCategoria(id);
            toast.success('Categoria excluída com sucesso!', {autoClose: 2500});
            setIsLoading(false);
            if (categorias.length - 1 > 0) {
              listarTodasCategorias(currentPage);
            } else {
              listarTodasCategorias(currentPage - 1);
              setCurrentPage(currentPage - 1);
            }
          } catch (e) {
            setIsLoading(false);
          }
        }
      });
    } catch (error) {
      toast.error(error, {autoClose: 2500});
    }
  }

  useEffect(() => {
    listarTodasCategorias(currentPage);
  }, []);

  useEffect(() => {
    listarTodasCategorias(currentPage);
  }, [triggerUpdate]);

  return (
    <UIContent>
      <div className="d-flex bd-highlight titleAndButton">
        <div className="p-2 flex-grow-1 bd-highlight">
          <Titulo> Categorias </Titulo>
        </div>
      </div>
      <form onSubmit={handleSubmit(filtrar)}>
        <div className="form-row mb-5">
          <div className="form-group col-md-4">
            <InputDefault
              name="categoria"
              type="text"
              placeholder="Nome da categoria"
              id="input-nome-categoria"
              onChange={e => setCategoriaNome(e.target.value)}
              value={categoriaNome}
              inputMaxLength="100"
              mask={noEmptyMask}
            />
          </div>
          <div className="d-flex justify-content-start">
            <BtnDefault
              name="btn-buscar"
              className="btn-custom btn-save"
              id="btn-buscar"
              type="submit"
              variantClass="custom"
            >
              <FaSearch size={12} className="mr-1" />
              Buscar
            </BtnDefault>
            <BtnDefault
              name="btn-reset"
              className="btn-custom btn-save"
              id="btn-reset"
              type="reset"
              variantClass="custom"
              action={clear}
            >
              <FaBroom size={12} className="mr-1" />
              Limpar Filtros
            </BtnDefault>
          </div>
        </div>
      </form>

      <ModalDefault
        nome="categorias"
        icon={FaGripVertical}
        variantClass="btn-purple btn-purple--round"
        setUpdateTable={setUpdateTable}
      >
        Nova categoria
      </ModalDefault>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <Table responsive hover>
          <thead className="table-header">
            <tr>
              <th>
                <div className="thContainer">Categoria/Setor</div>
              </th>
              <th>
                <div className="thContainer">Descrição</div>
              </th>
              <th>
                <div className="thContainer">Ações</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {categorias &&
              categorias.map((categoria, index) => {
                return (
                  <TableRowCategoria
                    categoria={categoria}
                    exibirModal={exibirModal}
                    excluirCategoria={excluirCategoria}
                    key={index}
                  />
                );
              })}
          </tbody>
        </Table>
      )}
      {!isLoading && categorias !== undefined && categorias.length === 0 && (
        <div className="msg-table ">
          <p>Nenhum categoria cadastrada.</p>
        </div>
      )}
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <PaginationDefault
          currentPage={currentPage}
          totalCount={totalCount}
          limit={limit}
          action={(value: number) => listarTodasCategorias(value)}
          totalNaPagina={categorias.length}
        />
      )}
      {showModalCategoria ? (
        <ModalEditarCategoria
          icon={FaGripVertical}
          variantClass="btn-purple btn-purple--round"
          setUpdateTable={setUpdateTable}
          id={idCategoria}
          showModalCategoria={showModalCategoria}
          handleCloseCategoria={handleCloseCategoria}
          showMenuModal={showModalViewOrEdit}
        />
      ) : (
        ''
      )}
    </UIContent>
  );
};

export default MainCategoria;
