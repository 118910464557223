import {IBatchParams, IBillingBatch} from '../models/billing-batch.model';
import api from './api';

const PATH_API = '/billing-batch';

export const createBillingBatch = async (batch: IBillingBatch) => {
  return api.post<IBillingBatch>(`${PATH_API}`, batch);
};

export const listBatchesApi = async (params: IBatchParams) => {
  return (
    await api.get(
      `${PATH_API}?limit=${params.limit}&page=${params.page}&batch=${params.batch}&dueDateStart=${params.dueDateStart}&dueDateEnd=${params.dueDateEnd}&clientGroupName=${params.clientGroupName}&order=${params.order}&orderBy=${params.orderBy}`
    )
  ).data;
};

export const getBatchDetailsApi = async (id: string) => {
  return (await api.get(`${PATH_API}/${id}`)).data;
};

export const duplicateBatchApi = async (id: string) => {
  return (await api.get(`${PATH_API}/${id}/duplicate`)).data;
};

export const resendDefaulters = async (id: string) => {
  return (await api.get(`${PATH_API}/${id}/resend`)).data;
};

export const abortBatchApi = async (id: string) => {
  return api.patch(`${PATH_API}/${id}/cancel`);
};

export const sendNowAPI = async (id: string) => {
  return (await api.get(`${PATH_API}/${id}/send-now`)).data;
};
