export enum BilletOrderByEnum {
  CLIENT = 'client',
  ISSUE_DATE = 'issue_date',
  DUE_DATE = 'due_date',
  VALUE = 'value',
  PAYED_VALUE = 'payed_value',
  PAYED_AT = 'payed_at',
}
export enum BilletBatchOrderByEnum {
  GROUP = 'group',
  DESCRIPTION = 'description',
  DUE_DATE = 'due_date',
  NUMBER = 'number',
  PAYED_VALUE = 'payed_value',
  VALUE = 'value',
}
