import React, {useState} from 'react';
import {useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import {IconBaseProps} from 'react-icons';
import {toast} from 'react-toastify';

import {getGroupDetailsApi} from '../../../api';
import {IClientGroup} from '../../../models/client-group.model';
import {IFormContent} from '../../../models/forms-model';
import FormEditClientGroup from '../FormEditClientGroup';

import './styles.scss';

interface ModalEditarProps {
  id?: string;
  icon?: React.ComponentType<IconBaseProps>;
  variantClass?: string;
  formContent?: IFormContent;
  handleCloseCliente?: Function;
  showModalCliente?: boolean;
  setUpdateTable?: Function;
  showModalViewOrEdit?: boolean;
  secondStageParam?: boolean;
}

const ModalEditClientGroup: React.FC<ModalEditarProps> = ({
  id,
  icon: Icon,
  setUpdateTable,
  handleCloseCliente,
  showModalCliente,
  showModalViewOrEdit,
  secondStageParam = false,
}) => {
  const [group, setGroup] = useState<IClientGroup | undefined>();

  const loadGroup = async (id: string) => {
    try {
      const groupReceived = await getGroupDetailsApi(id);
      setGroup(groupReceived);
    } catch (e) {
      toast.error(e, {autoClose: 2500});
    }
  };

  const updateTable = () => {
    setGroup(undefined);
    setUpdateTable && setUpdateTable(true);
    handleCloseCliente;
  };
  const showPopoverClient = () => {
    const showPopover = document.querySelectorAll<HTMLElement>('.popover ')[0];

    if (showPopover) {
      if (showModalCliente) {
        showPopover.style.display = 'none';
      }
    }
  };
  useEffect(() => {
    loadGroup(id);

    showPopoverClient();
  }, []);
  return (
    <>
      {group && (
        <Modal
          animation={false}
          show={showModalCliente}
          onHide={handleCloseCliente}
          backdrop="static"
          keyboard={true}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {Icon && <Icon size={20} className="modal-icone" />}
              {showModalViewOrEdit ? 'Editar grupo' : 'Ver grupo'}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <FormEditClientGroup
              group={group}
              handleUpdateTable={updateTable}
              showEditOrView={showModalViewOrEdit}
              secondStageParam={secondStageParam}
              avoidRedirect={true}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default ModalEditClientGroup;
