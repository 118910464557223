import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {updateStatusEnvioCobrancaRecebido} from '../../api/cobranca-api';
import LinkPDFNotFound from '../LinkPDFNotFound';

interface ParamTypes {
  id: string;
}

const EnviarBoletoPDF = () => {
  const [linkPdf, setLinkPdf] = useState<string | undefined>('initial');
  const [exibirNotFound, setExibirNotFound] = useState<Boolean>(false);

  const {id} = useParams<ParamTypes>();

  const setarLinkDoBoleto = async (id: string): Promise<void> => {
    setLinkPdf(await updateStatusEnvioCobrancaRecebido(id));
  };

  useEffect(() => {
    setarLinkDoBoleto(id);
  }, []);

  useEffect(() => {
    if (linkPdf && linkPdf !== 'initial') {
      setExibirNotFound(false);
      window.open(linkPdf);
    }

    if (!linkPdf) {
      setExibirNotFound(false);
    }
  }, [linkPdf]);

  if (!exibirNotFound && linkPdf && linkPdf === 'initial') {
    return <p style={{margin: '10px'}}>Carregando ..</p>;
  }

  if (!exibirNotFound && linkPdf && linkPdf !== 'initial') {
    return <p style={{margin: '10px'}}>Arquivo em uma nova aba. ..</p>;
  }

  if (exibirNotFound || !linkPdf) {
    return <LinkPDFNotFound />;
  }
};

export default EnviarBoletoPDF;
