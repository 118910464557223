import React from 'react';
import {useHistory} from 'react-router-dom';

import './styles.scss';
import BtnDefault from '../../components/Buttons/BtnDefault';
import UIContainer from '../../components/UI/Container';
import {useAuth} from '../../context/usuario-context';
import {isMaster} from '../../utils/validations/userIsAdminstrator';

const Pagina403: React.FC = () => {
  const {usuarioLogado} = useAuth();

  const history = useHistory();
  const redirectHome = () => {
    if (!isMaster(usuarioLogado)) {
      history.push('/dashboard');
    } else {
      history.push('/termos-uso');
    }
  };

  return (
    <UIContainer>
      <h1 className="container__error">Erro 403 - Acesso negado</h1>
      <div className="container_p">
        <p className="container__content mt-5">
          Você não tem permissão para acessar esse recurso. Clique em voltar
          para a Home.
        </p>
        <p className="container__content">
          Quaisquer dúvidas entre em contato com o suporte
        </p>
      </div>
      <div className="container__btn">
        <BtnDefault
          action={redirectHome}
          className="btn-custom btn-save"
          variantClass="custom"
        >
          {!isMaster(usuarioLogado) ? '  Home' : '  Termos de uso'}
        </BtnDefault>
      </div>
    </UIContainer>
  );
};

export default Pagina403;
