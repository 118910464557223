import React, {useEffect, useState} from 'react';

import {
  volumeEnvioTotal,
  volumeEnvioTotalByDate,
} from '../../../../api/dashboard-api';
import UILoad from '../../../../components/UI/Load';
import {IDashboardFilters} from '../../../../models/dashboard-model';

interface Filtros {
  filter: IDashboardFilters;
}

const VolumeEnvio: React.FC<Filtros> = ({filter}) => {
  const [totalBoletosEnviados, setTotalBoletosEnviados] = useState(0);
  const [loading, setLoading] = useState(false);

  async function getBoletosEnviados() {
    const volumeTotalEnviados: number = await volumeEnvioTotal();
    setTotalBoletosEnviados(volumeTotalEnviados);
  }

  async function getBoletosEnviadosByDate(filter) {
    setLoading(true);
    const volumeTotalEnviados: number = await volumeEnvioTotalByDate(filter);
    setTotalBoletosEnviados(volumeTotalEnviados);
    setLoading(false);
  }
  useEffect(() => {
    if (filter) {
      getBoletosEnviadosByDate(filter);
    } else {
      getBoletosEnviados();
    }
  }, [filter]);

  return (
    <div className="dashboard-boleto__content dashboard-boleto__border">
      {loading ? (
        <UILoad />
      ) : (
        <>
          <label className="dashboard-boleto__label" htmlFor="envio">
            Volume de envio
          </label>
          {totalBoletosEnviados > 0 ? (
            <span className="dashboard-boleto__span dashboard-boleto__purple  ">
              {totalBoletosEnviados}
            </span>
          ) : (
            <span className="dashboard-boleto__span dashboard-boleto__zero   ">
              Não há dados disponíveis para o período selecionado .{' '}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default VolumeEnvio;
