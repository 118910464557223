import React, {useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

import {signUpValidarToken} from '../../api/auth-api';
import Logo from '../../components/Logo';
import {IAuthPrimeiroAcesso} from '../../models/auth-model';
import cpfcnpjMask, {cnpjMask} from '../../utils/masks/cpfcnpjMask';

import './styles.scss';

const CodigoVerificacaoConta: React.FC = () => {
  const {handleSubmit, errors, register} = useForm();
  const [isLoading, setLoading] = useState(false);
  const [statusErrorToken, setStatusErrorToken] = useState<boolean>(false);
  const [msgToken, setMsgToken] = useState<string>('');

  const history = useHistory();
  const documentsLocation = history.location.state as IAuthPrimeiroAcesso;

  const backToFirstAccess = () => {
    history.push('/primeiro-acesso');
  };

  const onSubmit = data => {
    setLoading(true);
    const sendToken = Object.values(data).join('').concat();

    tokenValidation(
      documentsLocation.cpf,
      documentsLocation.cnpj,
      documentsLocation.twoFactorAuthId,
      sendToken
    );
  };

  const tokenValidation = async (
    cpf: string,
    cnpj: string,
    twoFactorAuthId: string,
    token: string
  ) => {
    try {
      const response = await signUpValidarToken(
        cpf,
        cnpj,
        twoFactorAuthId,
        token
      );

      history.push({
        pathname: '/cadastro-senha',
        state: response,
      });
    } catch (error) {
      setLoading(false);
      const {data} = error.response;
      setMsgToken(data.message);
      setStatusErrorToken(true);
    }
  };

  const onKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\D/g.test(keyValue)) {
      event.preventDefault();
    } else {
      return;
    }
  };

  const handlePaste = e => {
    const inputs = document.querySelectorAll('input[type=text]');

    let teclas = e.clipboardData.getData('Text');

    if (e.target.type === 'text') {
      if (teclas.length === 6) {
        teclas = teclas.split('');

        [].forEach.call(inputs, (node, index) => {
          node.value = teclas[index];
        });
      } else {
        toast.warning('Atenção! Aceita-se colar seis dígitos. ', {
          autoClose: 2500,
        });
      }
    }
  };

  const handleFocus = e => {
    const tecla = e.target.value.replace(/\D/g, '');

    if (tecla) {
      const digito = Number(e.target.dataset.digito) + 1;
      const nextItem = document.querySelector(
        `[data-digito="${digito}"]`
      ) as HTMLInputElement;
      if (digito <= 6) {
        nextItem.focus();
      } else {
        const btn = document.querySelector('.btn-purple') as HTMLInputElement;
        btn.focus();
      }
    }
    return tecla;
  };

  const RenderError = () => {
    return (
      <div className="mt-3">
        <span className="style-msg ml-5 mr-5">{msgToken}</span>
        <div className="row mb-4">
          <div className="col-sm-12 center-content ml-3">
            <label className="form-label" htmlFor="Cnpj">
              CNPJ:{' '}
            </label>
            <span className="text-span">
              {cnpjMask(documentsLocation.cnpj)}
            </span>
          </div>
          <div className="col-sm-12 center-content">
            <label className="form-label" htmlFor="Cpf">
              CPF:{' '}
            </label>
            <span className="text-span">
              {cpfcnpjMask(documentsLocation.cpf)}
            </span>
          </div>
        </div>
        <div className="col-md-12 mb-2">
          <Button
            className="btn-purple col-md-12"
            type="submit"
            disabled={isLoading}
            variant="custom"
            onClick={handleSubmit(onSubmit)}
          >
            {isLoading ? 'Loading…' : 'Enviar código novamente'}
          </Button>
        </div>
        <div className="col-md-12">
          <Button
            className="btn-purple col-md-12"
            type="submit"
            variant="custom"
            onClick={backToFirstAccess}
          >
            {'Voltar ao início do cadastro'}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="all-content">
      <span className="container page-title">
        Sistema de Gestão de Cobranças com Pix
      </span>
      <div className="container">
        <div className="container__form col-md-12">
          <main className="content__main-codigo row">
            <div className="content__main-codigo__img">
              <Logo></Logo>
            </div>

            <div className="p-3 ml-1 content__style">
              <p>
                Código de segurança enviado por SMS e e-mail cadastrado na sua
                conta digital {process.env.REACT_APP_NOME_PARCEIRO}.
              </p>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-4">
                <div className="col-sm-2 ">
                  <input
                    className="item"
                    type="text"
                    name="digitoum"
                    id="digitoum"
                    maxLength={1}
                    data-digito="1"
                    onPaste={handlePaste}
                    onKeyPress={onKeyPress}
                    onChange={handleFocus}
                    ref={register({
                      required: true,
                      maxLength: 1,
                    })}
                    autoFocus
                  />
                  {errors.digitoum && (
                    <h6 className="check-error">Campo obrigatório.</h6>
                  )}
                </div>
                <div className="col-sm-2 ">
                  <input
                    className="item"
                    type="text"
                    name="digitodois"
                    maxLength={1}
                    id="digitodois"
                    data-digito="2"
                    onKeyPress={onKeyPress}
                    onChange={handleFocus}
                    ref={register({
                      required: true,
                      maxLength: 1,
                    })}
                  />
                  {errors.digitodois && (
                    <h6 className="check-error">Campo obrigatório.</h6>
                  )}
                </div>
                <div className="col-sm-2 ">
                  <input
                    className="item"
                    type="text"
                    name="digitotres"
                    maxLength={1}
                    id="digitotres"
                    data-digito="3"
                    onKeyPress={onKeyPress}
                    onChange={handleFocus}
                    ref={register({
                      required: true,
                      maxLength: 1,
                    })}
                  />
                  {errors.digitotres && (
                    <h6 className="check-error">Campo obrigatório.</h6>
                  )}
                </div>
                <div className="col-sm-2 ">
                  <input
                    className="item"
                    type="text"
                    name="digitoquatro"
                    id="digitoquatro"
                    maxLength={1}
                    data-digito="4"
                    onKeyPress={onKeyPress}
                    onChange={handleFocus}
                    ref={register({
                      required: true,
                      maxLength: 1,
                    })}
                  />
                  {errors.digitoquatro && (
                    <h6 className="check-error">Campo obrigatório.</h6>
                  )}
                </div>
                <div className="col-sm-2 ">
                  <input
                    className="item"
                    type="text"
                    name="digitocinco"
                    id="digitocinco"
                    maxLength={1}
                    data-digito="5"
                    onKeyPress={onKeyPress}
                    onChange={handleFocus}
                    ref={register({
                      required: true,
                      maxLength: 1,
                    })}
                  />
                  {errors.digitocinco && (
                    <h6 className="check-error">Campo obrigatório.</h6>
                  )}
                </div>
                <div className="col-sm-2 ">
                  <input
                    className="item"
                    type="text"
                    name="digitoseis"
                    maxLength={1}
                    id="digitoseis"
                    data-digito="6"
                    onKeyPress={onKeyPress}
                    onChange={handleFocus}
                    ref={register({
                      required: true,
                      maxLength: 1,
                    })}
                  />
                  {errors.digitoseis && (
                    <h6 className="check-error">Campo obrigatório.</h6>
                  )}
                </div>
              </div>
              {statusErrorToken ? (
                RenderError()
              ) : (
                <div className="col-md-12">
                  <Button
                    className="btn-purple col-md-12 btn-reset-password"
                    type="submit"
                    disabled={isLoading}
                    variant="custom"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {isLoading ? 'Loading…' : 'Enviar código'}
                  </Button>
                </div>
              )}
            </Form>
          </main>
        </div>
      </div>
    </div>
  );
};
export default CodigoVerificacaoConta;
