import React from 'react';
import {Card} from 'react-bootstrap';
import {Color} from 'react-bootstrap/esm/types';
import './styles.scss';

interface CardSectionProps {
  title: string;
  bgColor?: string;
  className?: string;
  bgText?: Color;
  borderColor?: string;
  divClassName?: string;
}
const CardSection: React.FC<CardSectionProps> = ({
  title,
  children,
  bgColor,
  borderColor,
  bgText,
  divClassName = 'card-default',
}) => {
  return (
    <div className={divClassName}>
      <Card
        bg={bgColor}
        text={bgText}
        border={borderColor}
        //className={className}
      >
        <Card.Header>
          <span className="card__title">{title}</span>
        </Card.Header>
        <Card.Body>{children}</Card.Body>
      </Card>
    </div>
  );
};

export default CardSection;
