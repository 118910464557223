import React from 'react';
import {Button} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';

import {IRespostaReset} from '../../../models/resetar-senha-model';
import Logo from '../../Logo';

import './styles.scss';

interface ITelaRespostaResetarSenhaProps {
  params: IRespostaReset;
}

const SuccessResetarSenha: React.FC<ITelaRespostaResetarSenhaProps> = ({
  params,
}) => {
  const history = useHistory();
  const redirectTo = () => {
    history.push('/');
  };

  return (
    <main className="content-success-password row">
      <div className="content-success-password__img">
        <Logo></Logo>
      </div>
      <h1 className="reset-password-title mb-4">{params.title}</h1>
      <h3 className="reset-password-subtitle mb-4 text-center">
        {params.description}
      </h3>

      <div className="mb-4">
        <Button
          className="btn-purple btn-reset-password col-md-12"
          type="submit"
          variant="custom"
          onClick={redirectTo}
        >
          {params.botao}
        </Button>
      </div>
    </main>
  );
};
export default SuccessResetarSenha;
