import React from 'react';
import {Form, Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {FaRegEye} from 'react-icons/fa';
import {toast} from 'react-toastify';

import './styles.scss';
import {alterarSenha} from '../../../api';
import {IAuthModel} from '../../../models/auth-model';
import {IUsuario} from '../../../models/usuario-model';
import cnpjMask from '../../../utils/masks/cpfcnpjMask';
import cpfMask from '../../../utils/masks/cpfMask';
import {
  senhaValidator,
  validarSenhas,
} from '../../../utils/validations/senhaValidator';
import InputPassword from '../../Inputs/InputPassword';
import Logo from '../../Logo';

interface FormResetProps {
  setAlterado?: Function;
  usuario?: IUsuario;
}

const FormResetarSenha: React.FC<FormResetProps> = ({setAlterado, usuario}) => {
  const {register, handleSubmit, errors} = useForm<IAuthModel>();

  const onSubmit = data => {
    if (validarSenhas(data.password, data.confirmarSenha)) {
      resetarSenha(usuario.id, data.password);
    } else {
      toast.error('As senhas não se coincidem!', {autoClose: 2500});
    }
  };

  async function resetarSenha(id: string, password: string) {
    try {
      await alterarSenha(id, password);
      setAlterado(true);
    } catch (err) {
      setAlterado(false);
    }
  }

  return (
    <main className="content-reset-password row">
      <div className="content-reset-password__img">
        <Logo></Logo>
      </div>
      <h1 className="reset-password-title ml-5 mr-5 mb-4 text-center">
        Defina sua senha de acesso exclusiva para o sistema de recorrência
      </h1>

      <div className="row text-center mb-4">
        <div className="col-sm-12">
          <label className="form-label ml-5" htmlFor="Cnpj">
            CNPJ:{' '}
          </label>
          <span className="text__content">
            {usuario.contaFinanceira?.cpfCnpj &&
              cnpjMask(usuario.contaFinanceira?.cpfCnpj)}
          </span>
        </div>
        <div className="col-sm-12 ">
          <label className="form-label ml-3" htmlFor="Cpf">
            CPF:
          </label>
          <span className="text__content">
            {usuario.cpf && cpfMask(usuario?.cpf)}
          </span>
        </div>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} className="ml-5 mr-5">
        <div className="form-group">
          <InputPassword
            className="form-control input-form"
            name="password"
            type="password"
            erros={errors}
            icon={FaRegEye}
            autocomplete="off"
            placeholder="Senha"
            inputRef={register({
              validate: {
                password: value => {
                  return senhaValidator(value);
                },
              },
            })}
          />
        </div>
        <div className="form-group">
          <InputPassword
            className="form-control input-form"
            name="confirmarSenha"
            id="input-confirmarSenha"
            icon={FaRegEye}
            type="password"
            placeholder="Confirmar senha"
            erros={errors}
            autocomplete="off"
            inputRef={register({
              required: true,
              validate: {
                password: value => {
                  return senhaValidator(value);
                },
              },
            })}
          />
        </div>
        <div className="form-group">
          <Button
            className="btn-purple btn-reset-password col-md-12"
            type="submit"
            variant="custom"
          >
            Cadastrar senha
          </Button>
        </div>
      </Form>
    </main>
  );
};
export default FormResetarSenha;
