import {toast} from 'react-toastify';

import {ICobranca} from 'models/cobranca';

export function validarFormEmitirBoleto(data: ICobranca) {
  if (data.dataLimitePagamento && data.dataLimitePagamento < data.vencimento) {
    toast.error(
      'A data limite de vencimento não pode ser inferior a data de vencimento.',
      {autoClose: 2500}
    );
    return false;
  }

  const today = new Date();
  today.setHours(0, 0, 0);
  data.vencimento.setHours(0, 0, 1);

  if (data.vencimento < today) {
    toast.error('A data de vencimento não pode ser o dia atual.', {
      autoClose: 2500,
    });

    return false;
  }

  return true;
}
