import {Redirect, Route, RouteProps} from 'react-router-dom';

import {useAuth} from '../../src/context';
import {isMaster} from '../../src/utils/validations/userIsAdminstrator';

export default function PublicRoute(props: RouteProps) {
  const {isLogged, usuarioLogado} = useAuth();

  if (isLogged()) {
    if (isMaster(usuarioLogado)) return <Redirect to="/termos-uso" />;
    return <Redirect to="/dashboard" />;
  }

  return <Route {...props} />;
}
