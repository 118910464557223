import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {FaBan} from 'react-icons/fa';
import {toast} from 'react-toastify';

import {
  getExampleFileDir,
  getExceptionFile,
  sendImportedClients,
} from '../../../api';
import {IModalEventProps} from '../../../models/modal-events-model';
import SpinnerLoader from '../../Spinner';

import './styles.scss';

const FormImportClients: React.FC<IModalEventProps> = ({
  handleCloseModal,
  handleUpdateTable,
  afterSave,
  booleanUtil,
  objectUtil,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [processingError, setProcessingError] = useState(false);

  const onFileChange = async event => {
    await uploadSheet(event.target.files[0]);
  };

  const uploadSheet = async file => {
    const formData = new FormData();

    formData.append('file', file);

    try {
      setIsLoading(true);
      const response = await sendImportedClients(formData);
      if (response.status === 200) {
        toast.success('Cliente cadastrado com sucesso!', {autoClose: 2500});
      }

      if (response.status === 207) {
        setProcessingError(true);
      }

      setIsLoading(false);
    } catch (e) {
      toast.error(e, {autoClose: 2500});
      setIsLoading(false);
    }
  };

  const downloadExceptionFile = async () => {
    const resp = await getExceptionFile();

    const link = document.createElement('a');
    link.setAttribute('download', 'exceções.xlsx');
    // eslint-disable-next-line node/no-unsupported-features/node-builtins
    link.href = URL.createObjectURL(new Blob([resp.data]));
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const downloadExampleFile = () => {
    const link = document.createElement('a');
    link.setAttribute('target', '__blank');
    link.href = objectUtil.valor;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <div>
      {isLoading ? (
        <SpinnerLoader />
      ) : processingError ? (
        <div>
          <div className="d-flex justify-content-center mt-4">
            <label className="form-label" htmlFor="input-confirmacao-email">
              Clientes importados com sucesso! Exceções:{' '}
              <a onClick={() => downloadExceptionFile()} href="#">
                download
              </a>
            </label>
          </div>
          <br />

          <div className="d-flex justify-content-center mt-4">
            <Button
              className="btn-custom btn-cancel"
              variant="custom"
              onClick={() => handleCloseModal()}
            >
              Fechar
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-center mt-4">
            <label className="form-label" htmlFor="input-confirmacao-email">
              Qual ação você deseja realizar?
            </label>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <Button
              className="btn-custom btn-cancel"
              variant="custom"
              onClick={() => downloadExampleFile()}
            >
              Baixar Template
            </Button>

            <div>
              <label htmlFor="file-upload" className="custom-file-upload">
                Importar Planilha
              </label>

              <input
                id="file-upload"
                type="file"
                onChange={onFileChange}
                className="btn-custom btn-save"
              />
            </div>
          </div>
          <br />

          <div className="d-flex justify-content-center mt-4">
            <Button
              className="btn-custom btn-cancel"
              variant="custom"
              onClick={() => handleCloseModal()}
            >
              <FaBan size={13} className="mr-1" />
              Cancelar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormImportClients;
