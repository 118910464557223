import React, {useRef, useState} from 'react';
import {Overlay, Popover} from 'react-bootstrap';
import {
  FaBan,
  FaEllipsisV,
  FaRegEye,
  FaRegFileAlt,
  FaTelegramPlane,
} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

import Swal from 'sweetalert2';

import {
  deleteBilletApi,
  verBoletoPDF,
  cancelAllBillets,
} from '../../api/cobranca-api';
import {IListaCobranca} from '../../models/cobranca';
import {
  statusCobranca,
  statusEnvioMsgCobranca,
  tipoCobrancaEnums,
} from '../../shared/enumMap';
import {formataData} from '../../utils/formatDate';
import {formataDinheiro} from '../../utils/formatMonetary';

import './styles.scss';
import {showModalWarning} from '../../utils/modalWarning';
import {isMaster} from '../../utils/validations/userIsAdminstrator';

interface TableRowProps {
  cobranca: IListaCobranca;
  exibirModal: Function;
  exibirModalEnviar: Function;
  cancelarBoleto: Function;
  enviarBoleto: Function;
  exibirModalEditar?: Function;
  updateTableFunction?: Function;
}

const TableRow: React.FC<TableRowProps> = ({
  cobranca,
  exibirModal,
  exibirModalEnviar,
  updateTableFunction,
}) => {
  const history = useHistory();
  const ref = useRef(null);

  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const deleteBilletClick = async () => {
    if (cobranca.statuscobranca === 'PAGA') {
      toast.warning(
        `Cobrança com status ${statusCobranca(
          cobranca.statuscobranca
        )} não pode ser cancelada!`,
        {autoClose: 2500}
      );
      return;
    }

    const inputExcluirParcelas =
      cobranca.tipocobranca === 'PARCELADA'
        ? {
            input: 'checkbox',
            inputPlaceholder:
              'Excluir todas as parcelas referentes a esta cobrança?',
          }
        : {};

    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      text: 'Ao confirmar, a cobrança será excluída!',
      ...inputExcluirParcelas,
      showCancelButton: true,
      confirmButtonText:
        '<i class="fa fa-check fa-sm" aria-hidden="true"></i> Excluir',
      cancelButtonText:
        '<i class="fas fa-ban fa-sm" aria-hidden="true"></i> Cancelar',
      customClass: {
        confirmButton: 'confirmButtonModalClass',
        cancelButton: 'cancelButtonModalClass',
      },
    }).then(async result => {
      if (result.isConfirmed) {
        if (cobranca.tipocobranca === 'PARCELADA' && result.value === 1) {
          await cancelAllBillets(cobranca.idcontrolecobranca);
          toast.success('Parcelas canceladas com sucesso!', {
            autoClose: 2500,
          });
          updateTableFunction();
        } else await deleteBilletAction(cobranca);
      }
    });
  };

  const enviar = () => {
    enviarBoletoCliente(cobranca);
  };

  const handleClosepop = () => setShow(false);

  const deleteBilletAction = async (boleto: IListaCobranca) => {
    try {
      await deleteBilletApi(boleto.idcobranca);
      toast.success('Cobrança excluida com sucesso!', {autoClose: 2500});
      updateTableFunction();
    } catch (error) {
      toast.error(`Erro ao excluir cobrança!: Detalhes: ${error}`, {
        autoClose: 2500,
      });
    }
  };

  const verBoleto = () => {
    handleStatusBoleto(cobranca);
  };

  const enviarBoletoCliente = async (cobranca: IListaCobranca) => {
    if (
      cobranca.statuscobranca === 'AGUARDANDO' ||
      cobranca.statuscobranca === 'EM_PROCESSAMENTO' ||
      cobranca.statuscobranca === 'ERRO' ||
      cobranca.statuscobranca === 'CANCELADA'
    ) {
      toast.warning(
        `Não é possível enviar uma cobrança com o status  ${statusCobranca(
          cobranca.statuscobranca
        )}`,
        {autoClose: 2500}
      );
      return;
    }
    exibirModalEnviar(cobranca);
  };

  const handleStatusBoleto = async (boleto: IListaCobranca) => {
    if (
      boleto.statuscobranca === 'AGUARDANDO' ||
      boleto.statuscobranca === 'EM_PROCESSAMENTO' ||
      boleto.statuscobranca === 'ERRO'
    ) {
      toast.warning(
        `Não é possível gerar PDF com o status  ${statusCobranca(
          boleto.statuscobranca
        )}`,
        {autoClose: 2500}
      );
      return;
    }
    const link = await verBoletoPDF(boleto.idcobranca);
    window.open(link, '_blank');
  };

  return (
    <>
      <tr key={cobranca.idcobranca}>
        <td className="center">
          {cobranca.numerocobranca}/{cobranca.totalCobrancas}
        </td>
        <td className="left">{tipoCobrancaEnums(cobranca.tipocobranca)}</td>
        <td className="left">{cobranca.nomecliente}</td>
        {<td className="center">{formataData(cobranca.dataemissao)}</td>}
        {<td className="center">{formataData(cobranca.vencimento)}</td>}
        <td className="left">
          {cobranca.nomecategoria ? cobranca.nomecategoria : 'Sem categoria '}
        </td>
        <td className="left">
          {statusEnvioMsgCobranca(cobranca.statusenviomsg)}
        </td>
        <td className="right">{formataDinheiro(cobranca.valor)}</td>
        <td className="left">{statusCobranca(cobranca.statuscobranca)}</td>
        <td className="right">
          {!cobranca.valorpago ? '--' : formataDinheiro(cobranca.valorpago)}
        </td>
        <td className="left">
          {!cobranca.pagoem ? '--' : formataData(cobranca.pagoem)}
        </td>
        <td>
          <FaEllipsisV style={{cursor: 'pointer'}} onClick={handleClick} />
          <Overlay
            show={show}
            target={target}
            placement="bottom"
            container={ref.current}
            containerPadding={20}
            onHide={handleClosepop}
            rootClose={true}
            transition={false}
          >
            <Popover id="popover-contained">
              <Popover.Title as="h3">{'Ações'}</Popover.Title>
              <Popover.Content>
                <div className="icones-popover">
                  <div
                    onClick={() =>
                      history.push(`/ver-cobranca/${cobranca.idcobranca}`)
                    }
                  >
                    <FaRegFileAlt className="icones-popover__icone" size="18" />
                    <span className="icones-popover__text">Detalhe</span>
                  </div>
                  {cobranca.statuscobranca === 'EM_ABERTO' ? (
                    <>
                      <div onClick={verBoleto}>
                        <FaRegEye className="icones-popover__icone" size="18" />
                        <span className="icones-popover__text">
                          Ver Cobrança
                        </span>
                      </div>
                      <div
                        className="icones-popover__text"
                        onClick={() => enviar()}
                      >
                        <FaTelegramPlane
                          className="icones-popover__icone"
                          size="18"
                        ></FaTelegramPlane>

                        <span className="icones-popover__text">Enviar</span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div
                    className="icones-popover__text"
                    onClick={deleteBilletClick}
                  >
                    <FaBan className="icones-popover__icone" size="18"></FaBan>

                    <span className="icones-popover__text">Excluir</span>
                  </div>
                </div>
              </Popover.Content>
            </Popover>
          </Overlay>
        </td>
      </tr>
    </>
  );
};

export default TableRow;
