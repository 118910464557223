import 'bootstrap/dist/css/bootstrap.min.css';

import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import {ThemeProvider} from 'styled-components';

import {OnRefreshRedirect} from './components/OnRefreshRedirect';
import Ticket from './components/Ticket';
import {AuthProvider} from './context/usuario-context';
import './App.scss';
import Routes from './routes';
import {GlobalStyles} from './themes/GlobalStyles';
import {useTheme} from './themes/useTheme';
import {saveLocalStorage} from './utils/storage';

const themes = {
  data: {
    u4crypto: {
      primaryColor: `${process.env.REACT_APP_COLOR_PRIMARY}`,
      secondColor: `${process.env.REACT_APP_COLOR_SECONDARY}`,
      approves: `${process.env.REACT_APP_APPROVES}`,
      danger: `${process.env.REACT_APP_DANGER}`,
      alerts: `${process.env.REACT_APP_ALERTS}`,
    },
    purple: {
      primaryColor: '#8063d5',
      secondColor: '#bbadf5',
      approves: '#62d15c',
      danger: '#a32607',
      alerts: '#8063d5',
    },
  },
};

const App: React.FC = () => {
  saveLocalStorage('all-themes', themes);
  const {theme, themeLoaded} = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);

  useEffect(() => {
    if (!themeLoaded) {
      setSelectedTheme(theme);
    }
  }, []);

  return (
    <>
      <AuthProvider>
        <ToastContainer autoClose={2500} />
        <ThemeProvider theme={{selectedTheme}}>
          <Router>
            <GlobalStyles />
            <OnRefreshRedirect />
            <Routes />
            <Ticket></Ticket>
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </>
  );
};

export default App;
