import React, {useState, useRef} from 'react';
import {Overlay, Popover} from 'react-bootstrap';
import {
  FaEllipsisV,
  FaRegEye,
  FaRegFileAlt,
  FaRegFileExcel,
} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

import Swal from 'sweetalert2';

import {abortBatchApi} from '../../../api/billing-batch';
import {IBillingBatch} from '../../../models/billing-batch.model';
import {formataData} from '../../../utils/formatDate';
import {newMoneyMask} from '../../../utils/masks/moneyMask';

import './styles.scss';

interface TableRowProps {
  batch: IBillingBatch;
  setUpdateTable: Function;
}
const TableRowBillingRule: React.FC<TableRowProps> = ({
  batch,
  setUpdateTable,
}) => {
  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatString = string => {
    const newString = string.toLowerCase().replace(/([_])+/g, ' ');
    return capitalizeFirstLetter(newString);
  };

  const history = useHistory();

  const ref = useRef(null);

  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleClosepop = () => setShow(false);

  const abortClick = async (id: string, numero: string) => {
    try {
      await Swal.fire({
        title: `Tem certeza que deseja Cancelar o envio do Lote ${numero} ?`,
        text:
          'Ao confirmar, você estará cancelando o envio para todos os clientes deste lote!',
        showCancelButton: true,
        customClass: {
          confirmButton: 'confirmButtonModalClass',
          cancelButton: 'cancelButtonModalClass',
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText:
          '<i class="fas fa-trash fa-sm" aria-hidden="true"></i> Confirmar',
        cancelButtonText:
          '<i class="fas fa-ban" aria-hidden="true"></i> Voltar',
      }).then(async result => {
        if (result.isConfirmed) {
          await abortAction(id);
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const abortAction = async (id: string) => {
    try {
      await abortBatchApi(id);
      toast.success('Lote cancelado com sucesso', {autoClose: 2500});
      setUpdateTable();
    } catch (e) {
      toast.error('Erro ao cancelar lote', {autoClose: 2500});
    }
  };

  return (
    <>
      <tr key={batch.id}>
        <td className="center">
          {batch.number}
          <br />
          {new Date(`${batch.createdAt}`).toLocaleDateString('pt-br')}
        </td>
        <td className="center">{batch.billingsCount}</td>
        <td className="left">{batch.clientGroupName}</td>
        <td
          className="right"
          style={{pageBreakInside: 'avoid', whiteSpace: 'nowrap'}}
        >
          {batch.totalValue
            ? `R$ ${newMoneyMask(batch.totalValue.toString())}`
            : '-'}
        </td>
        <td
          className="right"
          style={{pageBreakInside: 'avoid', whiteSpace: 'nowrap'}}
        >
          {batch.payedValue
            ? `R$ ${newMoneyMask(batch.payedValue.toString())}`
            : '-'}
        </td>
        <td
          className="right"
          style={{pageBreakInside: 'avoid', whiteSpace: 'nowrap'}}
        >
          {batch.toBeReceivableValue
            ? `R$ ${newMoneyMask(batch.toBeReceivableValue.toString())}`
            : '-'}
        </td>
        <td className="center">{formataData(batch.expirationDay)}</td>
        <td className="left">{formatString(batch.status.toString())}</td>
        <td>
          <FaEllipsisV style={{cursor: 'pointer'}} onClick={handleClick} />
          <Overlay
            show={show}
            target={target}
            placement="bottom"
            container={ref.current}
            containerPadding={20}
            onHide={handleClosepop}
            rootClose={true}
            transition={false}
          >
            <Popover id="popover-contained">
              <Popover.Title as="h3">{'Ações'}</Popover.Title>
              <Popover.Content>
                <div className="icones-popover">
                  <div
                    onClick={() => history.push(`/detalhe-lote/${batch.id}`)}
                  >
                    <FaRegFileAlt className="icones-popover__icone" size="18" />
                    <span className="icones-popover__text">Detalhe</span>
                  </div>

                  <div
                    onClick={() =>
                      history.push(`/consultar-cobranca/${batch.id}`)
                    }
                  >
                    <FaRegEye className="icones-popover__icone" size="18" />
                    <span className="icones-popover__text">Ver Cobranças</span>
                  </div>

                  {batch.status === 'AGUARDANDO_PROCESSAMENTO' && (
                    <div
                      className="icones-popover__text"
                      onClick={() => abortClick(batch.id, batch.number)}
                    >
                      <FaRegFileExcel
                        className="icones-popover__icone"
                        size="18"
                      ></FaRegFileExcel>

                      <span className="icones-popover__text">Cancelar</span>
                    </div>
                  )}
                </div>
              </Popover.Content>
            </Popover>
          </Overlay>
        </td>
      </tr>
    </>
  );
};

export default TableRowBillingRule;
