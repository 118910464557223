import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {getDadosByHash} from '../../../api';
import FormResetarSenha from '../../../components/ResetarSenha/FormResetarSenha';
import SuccessResetarSenha from '../../../components/ResetarSenha/RetornoResetarSenha';
import {IRespostaReset} from '../../../models/resetar-senha-model';
import {IUsuario} from '../../../models/usuario-model';
import './styles.scss';
interface ParamTypes {
  hash: string;
}

const ResetarSenha: React.FC = () => {
  const [isAlterado, setAlterado] = useState(false);
  const {hash} = useParams<ParamTypes>();
  const [usuario, setUsuario] = useState<IUsuario>({});
  const [info, setInfo] = useState<IRespostaReset>({});

  async function loadDadosPorHash() {
    try {
      const usuario = await getDadosByHash(hash);
      setUsuario(usuario);
      setInfo({
        title: 'Sucesso!',
        description: 'Sua senha foi alterada!',
        botao: 'Acessar minha conta',
      });
    } catch {
      setInfo({
        title: 'Erro!',
        description: 'O link que você seguiu pode ter expirado!',
        botao: 'Voltar para tela de login',
      });
      setAlterado(true);
    }
  }

  useEffect(() => {
    loadDadosPorHash();
  }, []);

  return (
    <div className="all-content">
      <span className="container page-title">
        Sistema de Gestão de Cobranças com Pix
      </span>

      <div className="container">
        <div className="div-form col-md-12">
          {!isAlterado ? (
            <FormResetarSenha setAlterado={setAlterado} usuario={usuario} />
          ) : (
            <SuccessResetarSenha params={info} />
          )}
        </div>
      </div>
    </div>
  );
};
export default ResetarSenha;
