import React, {useState} from 'react';
import {FaRegQuestionCircle} from 'react-icons/fa';

import Tooltip from '../../Tooltip';
import './styles.scss';

type ReceberCardProps = {
  value: string;
};

const ReceberCard = ({value = '0'}: ReceberCardProps) => {
  const [quantidadeDias, setQuantidadeDias] = useState<number>(5);

  // TODO setValorReceber, precisa de nova rota
  return (
    <>
      <div className="col-md-12 card-receber__body">
        <div className="card-receber__header">
          <label className="card-receber__label">Projeção de recebimento</label>
          <Tooltip data="Projeção do possível valor a receber dentro dos dias estipulados no filtro abaixo.">
            <FaRegQuestionCircle size={20} className="card-receber__help" />
          </Tooltip>
        </div>
        <div className="card-receber__bottom">
          <label className="card-receber__label">Valor a receber</label>
          <label className="card-receber__valor">R$ {value}</label>
          <div className="card-receber__dropdown">
            <select
              className="card-receber__dropdown"
              onChange={e => setQuantidadeDias(+e.target.value)}
            >
              <option value={5}>Próximos 5 dias</option>
              <option value={10}>Próximos 10 dias</option>
              <option value={15}>Próximos 15 dias</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceberCard;
