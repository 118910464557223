import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';

import Logo from '../../components/Logo';

import './styles.scss';

const ConfirmacaoContaCriada: React.FC = () => {
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const redirectHome = () => {
    setLoading(!isLoading);
    history.push('/dashboard');
  };

  return (
    <div className="all-content">
      <span className="container page-title">
        Sistema de Gestão de Cobranças com Pix
      </span>

      <div className="container">
        <div className="container__form-conta-criada col-md-12">
          <main className="content__main-conta-criada row">
            <div className="content__main-conta-criada__logo">
              <Logo></Logo>
            </div>

            <div className="p-2 ml-3 content__style">
              <p className="p-2 ml-3 text-center content__style">
                Conta criada com sucesso!
              </p>

              <p className="text-center">
                Para acessar sua conta, use o CPF cadastrado no sistema.
              </p>
            </div>

            <div className="content__style ">
              <Button
                className="btn-purple col-12 btn-reset-password"
                type="submit"
                disabled={isLoading}
                variant="custom"
                onClick={redirectHome}
              >
                {isLoading ? 'Loading…' : 'Acessar minha conta'}
              </Button>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
export default ConfirmacaoContaCriada;
