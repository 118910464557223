import React, {LegacyRef, useRef} from 'react';
import {useForm} from 'react-hook-form';
import {FieldErrors} from 'react-hook-form';
import './styles.scss';
import Select from 'react-select';

import Errors from '../../Errors';

interface InputSelectProps {
  className?: string;
  name?: string;
  id?: string;
  placeholder?: React.ReactNode;
  options?: Array<Object>;
  value?: Object;
  defaultValue?: Object;
  inputRef?: LegacyRef<HTMLSelectElement>;
  erros?: FieldErrors;
  onChange?;
  classNamePrefix?;
  isDisabled?: boolean;
  isMulti?: boolean;
}

const InputSelect: React.FC<InputSelectProps> = ({
  className,
  isMulti = false,
  name,
  value,
  defaultValue,
  placeholder,
  inputRef,
  options,
  onChange,
  classNamePrefix,
  id,
  erros,
  isDisabled,
}) => {
  const inputSelect = useRef(undefined);
  const {getValues} = useForm();

  const cat = getValues(name);

  if (cat === undefined && erros && erros?.tipo) {
    inputSelect.current?.focus();
  }

  return (
    <>
      <Select
        isMulti={isMulti}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        ref={inputSelect}
        inputRef={inputRef}
        classNamePrefix={`${classNamePrefix} react-select`}
        options={options}
        value={value}
        onChange={onChange}
        className={
          className ? className + ' custom-react-select' : 'custom-react-select'
        }
        id={id}
        isDisabled={isDisabled}
        noOptionsMessage={() => 'Nenhuma opção'}
        error={erros}
      />

      <Errors erros={erros} name={name} />
    </>
  );
};

export default InputSelect;
