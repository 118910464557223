import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import {AiOutlineEye} from 'react-icons/ai';
import {FaArrowRight} from 'react-icons/fa';
import {FiSave} from 'react-icons/fi';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';

import {createBillingBatch} from '../../api';
import ModalEditClientGroup from '../../components/MainClientGroup/ModalClientGroupEdit';
import Titulo from '../../components/Titulo';
import {IBillingBatchSecondStage} from '../../models/billing-batch.model';
import {formataDinheiro} from '../../utils/formatMonetary';

const BatchSummary: React.FC<IBillingBatchSecondStage> = (
  props: IBillingBatchSecondStage
) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const history = useHistory();

  const calcDayToBeSend = () => {
    const IN_MILLISECONDS_1_DAY = 86400000;
    const daysToRemove = props.batch.daysBeforeSend * IN_MILLISECONDS_1_DAY;
    const sd = props.batch.expirationDay.split('/');
    const dateToSend =
      new Date(`${sd[2]}/${sd[1]}/${sd[0]}`).getTime() - daysToRemove;
    return new Date(dateToSend).toLocaleDateString('pt-br');
  };

  const createBatch = async () => {
    try {
      setDisableButton(true);
      const sd = props.batch.expirationDay.split('/');
      props.batch.expirationDay = `${sd[2]}-${sd[1]}-${sd[0]}`;
      const idNovoLote = (await createBillingBatch(props.batch)).data.id;
      Swal.fire({
        title: 'Lote criado com sucesso!',
        text:
          'Sua cobrança em lote já foi criada e está disponível para visualização',
        showCancelButton: true,
        confirmButtonColor: ' #d33',
        cancelButtonColor: `${process.env.REACT_APP_COLOR_PRIMARY}`,
        confirmButtonText:
          '<i class="fas fa-times" aria-hidden="true"></i> Fechar',
        cancelButtonText:
          '<i class="fas fa-eye" aria-hidden="true"></i> Ver lote',
        customClass: {
          confirmButton: 'confirmButtonModalClass',
          cancelButton: 'cancelButtonModalClass',
        },
      }).then(async result => {
        if (result.isDismissed) history.push(`/detalhe-lote/${idNovoLote}`);
      });
      history.push('/consulta-lotes');
    } catch (e) {
      toast.error(`Erro ao gerar lote. Detalhes: ${e}`, {autoClose: 2500});
      history.push('/consulta-lotes');
    }
  };

  return (
    <>
      <div className="d-flex bd-highlight">
        <div className="p-2 flex-grow-1 bd-highlight">
          <Titulo>Cobrança</Titulo>
        </div>
      </div>

      <div className="row mx-auto">
        <div className="col-sm-4">
          <label htmlFor="Categoria" className="form-label">
            Regra de Cobrança:
          </label>
          <span className="text__content">{props.billingRuleName}</span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Descrição" className="form-label">
            Descrição:
          </label>
          <span className="text__content">{props.batch.description}</span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Tipo" className="form-label">
            Vencimento:
          </label>
          <span className="text__content">{props.batch.expirationDay}</span>
        </div>
      </div>
      <div className="row mx-auto">
        <div className="col-sm-4">
          <label htmlFor="Multa" className="form-label">
            Multa:
          </label>
          <span className="text__content">
            {String(props.batch.fine) !== '0'
              ? formataDinheiro(props.batch.fine / 100)
              : '-'}
          </span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Juros ao mês" className="form-label">
            Juros ao mês:
          </label>
          <span className="text__content">
            {String(props.batch.interest) !== '0'
              ? formataDinheiro(props.batch.interest / 100)
              : '-'}
          </span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Instruções" className="form-label">
            Desconto:
          </label>
          <span className="text__content">
            {String(props.batch.discount) !== '0'
              ? formataDinheiro(props.batch.discount / 100)
              : '-'}
          </span>
        </div>
      </div>
      <div className="row mx-auto">
        <div className="col-sm-4">
          <label htmlFor="Juros ao mês" className="form-label">
            Valor da Cobrança:
          </label>
          <span className="text__content">
            {String(props.batch.value) !== '0'
              ? formataDinheiro(props.batch.value / 100)
              : '-'}
          </span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Juros ao mês" className="form-label">
            Nª Lote:
          </label>
          <span className="text__content">{'-'}</span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Juros ao mês" className="form-label">
            Valor do Lote:
          </label>
          <span className="text__content">
            {String(props.batch.value) !== '0'
              ? formataDinheiro(
                  (props.batch.value / 100) * props.groupInfo.amountClient
                )
              : '-'}
          </span>
        </div>
      </div>
      <br />
      <br />
      <div className="row mx-auto">
        <div className="col-sm-4">
          <div className="row">
            <span className="group__title">{`${props.groupInfo.groupName} (${props.groupInfo.amountClient} clientes)`}</span>
          </div>
          <div className="row">
            <label htmlFor="Categoria" className="group__description">
              {props.groupInfo.groupDescription}
            </label>
          </div>
        </div>
        <Button
          name="btnSalvar"
          className="btn-custom btn-save"
          type="button"
          onClick={() => setShowModal(true)}
          variant="custom"
        >
          <AiOutlineEye size={20} className="mr-1" />
          Visualizar Grupo
        </Button>
      </div>
      <div className="row mx-auto">
        <div className="col-sm-4">
          <label htmlFor="Juros ao mês" className="form-label">
            Envio:
          </label>
          <span className="text__content">
            {props.batch.sendImmediately
              ? 'Imediatamente'
              : `Agendado - ${calcDayToBeSend()}`}
          </span>
        </div>
      </div>

      <div className="container-bottoes col-md-14">
        <div className="button-salvar-cobranca">
          {
            <Button
              name="btnSalvar"
              className="btn-custom btn-save"
              type="submit"
              onClick={createBatch}
              variant="custom"
              disabled={disableButton}
            >
              {props.batch.sendImmediately ? (
                <>
                  <FaArrowRight size={16} className="mr-1" />
                  Confirmar e Enviar
                </>
              ) : (
                <>
                  <FiSave size={16} className="mr-1" />
                  Salvar Cobrança
                </>
              )}
            </Button>
          }
        </div>
      </div>

      {showModal ? (
        <ModalEditClientGroup
          icon={AiOutlineEye}
          variantClass="btn-purple btn-purple--round"
          showModalCliente={true}
          id={props.batch.clientGroupID}
          setUpdateTable={() => setShowModal(false)}
          showModalViewOrEdit={true}
          secondStageParam={true}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default BatchSummary;
