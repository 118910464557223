import React, {useRef, useState} from 'react';
import {Overlay, Popover} from 'react-bootstrap';
import {FaEllipsisV, FaTrashAlt} from 'react-icons/fa';
import InputCheckboxDefault from '../../../../components/Inputs/InputCheckboxDefault';
import {ITermosUso} from '../../../../models/u4Cripto-model';
import {formataData} from '../../../../utils/formatDate';
import './styles.scss';

interface TablesRowProps {
  termosUso: ITermosUso;
  excluirTermoUso: Function;
}
const TableRowAtividadesTermosDeUso: React.FC<TablesRowProps> = ({
  termosUso,
  excluirTermoUso,
}) => {
  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);

  const ref = useRef(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleClosepop = () => setShow(false);
  return (
    <>
      <tr key={termosUso.id}>
        <td>{termosUso.versao}</td>
        <td>
          <a
            href={termosUso.descricao}
            target="__blank"
            rel="noopener noreferrer"
            className="link-termo"
          >
            {termosUso.descricao}
          </a>
        </td>
        <td>{formataData(termosUso.dataCriacao)}</td>
        <td>
          <InputCheckboxDefault
            name="ativo"
            id="ativo"
            defaulChecked={termosUso.ativo}
            className="check-uso"
            disabled={true}
          />
        </td>
        <td>
          <FaEllipsisV style={{cursor: 'pointer'}} onClick={handleClick} />
          <Overlay
            show={show}
            target={target}
            placement="bottom"
            container={ref.current}
            containerPadding={20}
            onHide={handleClosepop}
            rootClose={true}
            transition={false}
          >
            <Popover id="popover-contained">
              <Popover.Title as="h3">{'Ações'}</Popover.Title>
              <Popover.Content>
                <span className="icones-popover">
                  <span
                    className="icones-popover__text"
                    onClick={() => excluirTermoUso(termosUso.id)}
                  >
                    <FaTrashAlt
                      className="icones-popover__icone"
                      size="18"
                    ></FaTrashAlt>

                    <span className="icones-popover__text">Excluir</span>
                  </span>
                </span>
              </Popover.Content>
            </Popover>
          </Overlay>
        </td>
      </tr>
    </>
  );
};

export default TableRowAtividadesTermosDeUso;
