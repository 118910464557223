import React, {useEffect, useState} from 'react';

import {boletosPagosByDate} from '../../../../api/dashboard-api';
import UILoad from '../../../../components/UI/Load';
import {IDashboardFilters} from '../../../../models/dashboard-model';
import {formataDinheiro} from '../../../../utils/formatMonetary';
interface Filtros {
  filter: IDashboardFilters;
}
const BoletosPagos: React.FC<Filtros> = ({filter}) => {
  const [totalBoletosPagos, setTotalBoletosPagos] = useState(0);
  const [loading, setLoading] = useState(false);

  async function getBoletosPagosByDate(filter) {
    setLoading(true);
    const totalPagos: number = await boletosPagosByDate(filter);
    setTotalBoletosPagos(totalPagos);
    setLoading(false);
  }

  useEffect(() => {
    if (filter) {
      getBoletosPagosByDate(filter);
    }
  }, [filter]);
  return (
    <div className="dashboard-boleto__content dashboard-boleto__border">
      {loading ? (
        <UILoad />
      ) : (
        <>
          <label className="dashboard-boleto__label" htmlFor="pagos">
            Cobranças Pagas
          </label>
          {totalBoletosPagos > 0 ? (
            <span className="dashboard-boleto__span dashboard-boleto__green ">
              {formataDinheiro(totalBoletosPagos)}
            </span>
          ) : (
            <span className="dashboard-boleto__span dashboard-boleto__zero   ">
              Não há dados disponíveis para o período selecionado.{' '}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default BoletosPagos;
