import React, {
  InputHTMLAttributes,
  useState,
  LegacyRef,
  ChangeEventHandler,
  FocusEventHandler,
} from 'react';
import './styles.scss';
import {FieldErrors} from 'react-hook-form';
import {IconBaseProps} from 'react-icons';
import {FaEyeSlash} from 'react-icons/fa';

import Errors from '../../Errors';

interface inputLoginProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  name?: string;
  id?: string;
  icon?: React.ComponentType<IconBaseProps>;
  type: string;
  inputRef?: LegacyRef<HTMLInputElement>;
  erros?: FieldErrors;
  inputMaxLength?: string;
  mask?: (value: string) => string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  autocomplete?: string;
  className?: string;
}

const InputPassword: React.FC<inputLoginProps> = ({
  name,
  type,
  placeholder,
  id,
  inputRef,
  erros,
  icon: Icon,
  mask = (value: string) => value,
  onChange,
  onBlur,

  autocomplete,
  className = '',
}) => {
  const [visiblePassword, setVisiblePassword] = useState(true);
  const [eyeIconVisible, setIconVisible] = useState(false);
  const aplicarMask = e => (e.target.value = `${mask(e.target.value)}`);

  const onChangeTrigger = e => {
    if (onChange) {
      onChange(e);
    }
    aplicarMask(e);
  };
  const onBlurTrigger = e => {
    if (onBlur) {
      onBlur(e);
    }
    aplicarMask(e);
  };

  const alterarTipoField = () => {
    setVisiblePassword(!visiblePassword);
    setIconVisible(!eyeIconVisible);
    return 'text';
  };

  return (
    <>
      <div className="input-content">
        <input
          maxLength={14}
          name={name}
          className={className}
          type={type === 'password' && visiblePassword ? 'password' : 'text'}
          placeholder={placeholder}
          id={id}
          ref={inputRef}
          onChange={e => {
            onChangeTrigger(e);
          }}
          onBlur={e => {
            onBlurTrigger(e);
          }}
          autoComplete={autocomplete}
        />
        <span>
          {Icon &&
            (eyeIconVisible ? (
              <FaEyeSlash
                className="input-content__icone"
                onClick={alterarTipoField}
                size={20}
              />
            ) : (
              <Icon
                className="input-content__icone"
                onClick={alterarTipoField}
                size={20}
              />
            ))}
        </span>
      </div>

      <Errors erros={erros} name={name} inputMaxLength="14" />
    </>
  );
};

export default InputPassword;
