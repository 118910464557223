import React, {useEffect, useState} from 'react';

import {volumeInadimplenciaByDate} from '../../../../api/dashboard-api';
import UILoad from '../../../../components/UI/Load';
import {IDashboardFilters} from '../../../../models/dashboard-model';
interface Filtros {
  filter: IDashboardFilters;
}
const VolumeInadimplencia: React.FC<Filtros> = ({filter}) => {
  const [volume, setVolumeInadimplencia] = useState(0);
  const [loading, setLoading] = useState(false);

  async function getVolumeInadimplenciaByDate(filter) {
    setLoading(true);
    const totalAbertos: number = await volumeInadimplenciaByDate(filter);
    setVolumeInadimplencia(totalAbertos);
    setLoading(false);
  }
  useEffect(() => {
    if (filter) {
      getVolumeInadimplenciaByDate(filter);
    }
  }, [filter]);

  return (
    <div className="dashboard-boleto__content dashboard-boleto__border">
      {loading ? (
        <UILoad />
      ) : (
        <>
          <label className="dashboard-boleto__label" htmlFor="inadimplência">
            Volume inadimplência
          </label>
          {volume > 0 ? (
            <span className="dashboard-boleto__span dashboard-boleto__red ">
              {volume}
            </span>
          ) : (
            <span className="dashboard-boleto__span dashboard-boleto__zero   ">
              Não há dados disponíveis para o período selecionado.{' '}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default VolumeInadimplencia;
