export default function cpfMask(rawNum: string): string {
  rawNum = rawNum.replace(/\D/g, '');
  rawNum = rawNum.replace(/^(\d{3})(\d)/g, '$1.$2');
  rawNum = rawNum.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3');
  rawNum = rawNum.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3-$4');
  rawNum = rawNum.replace(
    /^(\d{3})\.(\d{3})\.(\d{3})\/(\d{2})(\d)/,
    '$1.$2.$3-$4'
  );

  return rawNum;
}
