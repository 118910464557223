import React, {
  ChangeEventHandler,
  LegacyRef,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import {FieldErrors} from 'react-hook-form';

import './styles.scss';
import Errors from '../../Errors';

interface InputRadioDefaultProps {
  name: string;
  value?: string;
  id?: string;
  className?: string;
  inputRef?: LegacyRef<HTMLInputElement>;
  onClick?: MouseEventHandler;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  erros?: FieldErrors;
  defaultChecked?: boolean;
  classLabel?: string;
}

const InputRadioDefault: React.FC<InputRadioDefaultProps> = ({
  onClick,
  name,
  id,
  className,
  value,
  inputRef,
  defaultChecked,
  erros,
  classLabel,
  children,
}) => {
  const [check, setCheck] = useState(false);
  useEffect(() => {
    if (defaultChecked !== undefined) setCheck(defaultChecked);
  }, [defaultChecked, check]);
  return (
    <>
      <label className={`radiobutton-container ${classLabel}`} htmlFor={id}>
        {children}
        <input
          type="radio"
          name={name}
          value={value}
          id={id}
          onClick={onClick}
          className={className + ' custom-radio-button'}
          ref={inputRef}
          defaultChecked={check || false}
          onChange={e => {
            e.target.checked;
          }}
          checked={check || false}
        />
        <span className="custom-radio-button-checkmark" />
      </label>
      <Errors name={name} erros={erros} />
    </>
  );
};

export default InputRadioDefault;
