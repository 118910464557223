import React from 'react';
import './styles.scss';

type TooltipProps = {
  data: string;
  children: React.ReactNode;
};

const Tooltip = ({data, children}: TooltipProps) => {
  return (
    <div data-tooltip={data} className="tooltipclass">
      {children}
    </div>
  );
};

export default Tooltip;
