import React, {useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {FaRegEye} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

import Swal from 'sweetalert2';

import {atualizarTermo, efetuarLogin, ultimoTermo} from '../../api';
import DownloadButtons from '../../components/DownloadButtons';
import InputLogin from '../../components/Inputs/InputLogin';
import Logo from '../../components/Logo';
import {useAuth} from '../../context/usuario-context';
import {IAuthModel} from '../../models/auth-model';
import {ITermo} from '../../models/termo-model';
import {cnpjMask} from '../../utils/masks/cpfcnpjMask';
import cpfMask from '../../utils/masks/cpfMask';
import {limpaCpf} from '../../utils/validations/cpfCnpjValidator';
import {limpaCnpj} from '../../utils/validations/cpfCnpjValidator';
import {isMaster} from '../../utils/validations/userIsAdminstrator';
import {validaApenasCpf} from '../../utils/validations/validaCpfCnpj';
import {validaApenasCnpj} from '../../utils/validations/validaCpfCnpj';
import './styles.scss';

const Login: React.FC = () => {
  const {register, handleSubmit, errors, setValue} = useForm<IAuthModel>();

  const [isLoading, setLoading] = useState(false);
  const {Login, Logout, isLogged, usuarioLogado} = useAuth();
  const history = useHistory();

  const getUltimotermo = async () => {
    const termoUso = await ultimoTermo();
    return termoUso;
  };

  useEffect(() => {
    if (isLogged()) {
      if (!isMaster(usuarioLogado)) {
        history.push('/dashboard');
      } else {
        history.push('/termos-uso');
      }
    }
  }, []);

  const onSubmit = (data: IAuthModel) => {
    setLoading(true);

    const cpf = limpaCpf(data.cpf);
    const cnpj = limpaCnpj(data.cnpj);
    Logar(cpf, cnpj, data.password);
  };

  async function aceitarTermo(cpf, cnpj, password) {
    const termo: ITermo = await getUltimotermo();
    try {
      Swal.fire({
        title: 'Os termos de uso foram atualizados!',
        html: `<p>Para continuar o acesso ao sistema é necessário aceitar os novos termos</p>
          <p>
            <a
              href="${termo ? termo.descricao : '#'}"
              className="content__login"
              target="_blank"
              rel="noopener noreferrer"
            >
              Acesse o termo aqui
            </a>
          </p>`,
        showCancelButton: true,
        confirmButtonColor: process.env.REACT_APP_COLOR_PRIMARY,
        cancelButtonColor: '#d33',
        confirmButtonText:
          '<i class="fa fa-check fa-sm" aria-hidden="true"></i> Confirmar',
        cancelButtonText:
          '<i class="fas fa-ban fa-sm" aria-hidden="true"></i> Cancelar',
      }).then(async result => {
        if (result.isConfirmed) {
          Logar(cpf, cnpj, password, true);
        } else {
          Logout();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  }

  async function login(
    usuario: object,
    token: string,
    pixAvailable: boolean,
    documentAvailable: boolean,
    infoPix: boolean
  ) {
    await Login(usuario, token);
    history.push({
      pathname: '/dashboard',
      state: {
        pixAvailable: pixAvailable,
        documentAvailable: documentAvailable,
        infoPix: infoPix,
      },
    });
  }

  async function errorLogin(cpf: string, cnpj: string, password: string) {
    aceitarTermo(cpf, cnpj, password);
    setLoading(false);
  }

  async function Logar(
    cpf: string,
    cnpj: string,
    password: string,
    updateTermo?: boolean
  ) {
    try {
      const {
        usuario,
        accept,
        token,
        pixAvailable,
        documentAvailable,
        infoPix,
      } = await efetuarLogin(cpf, cnpj, password);
      if (updateTermo) {
        try {
          await atualizarTermo(usuario.id);
          login(usuario, token, pixAvailable, documentAvailable, infoPix);
        } catch (error) {
          toast.error(error);
          errorLogin(cpf, cnpj, password);
        }
      } else {
        if (accept) {
          login(usuario, token, pixAvailable, documentAvailable, infoPix);
        } else {
          errorLogin(cpf, cnpj, password);
        }
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <div className="all-content">
      <div className="container">
        <div className="div-form col-md-12">
          <main className="content-login row">
            <div className="container__img">
              <Logo
                src={
                  process.env.REACT_APP_LOGIN_LOGO_ADDRESS
                    ? process.env.REACT_APP_LOGIN_LOGO_ADDRESS
                    : process.env.REACT_APP_LOGO_ADDRESS
                }
              />
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <InputLogin
                placeholder="CPF"
                id="input-cpf"
                type="text"
                name="cpf"
                inputRef={register({
                  required: true,
                  maxLength: 14,
                  validate: {
                    cpf: value => {
                      return validaApenasCpf(value);
                    },
                  },
                })}
                erros={errors}
                inputMaxLength="14"
                mask={cpfMask}
                onBlur={e =>
                  setValue('cpf', cpfMask(e.target.value.slice(0, 14)))
                }
              />

              <InputLogin
                placeholder="CNPJ"
                id="input-cnpj"
                type="text"
                name="cnpj"
                inputRef={register({
                  required: true,
                  maxLength: 18,
                  validate: {
                    cnpj: value => {
                      return validaApenasCnpj(value);
                    },
                  },
                })}
                erros={errors}
                inputMaxLength="18"
                mask={cnpjMask}
                onBlur={e =>
                  setValue('cnpj', cnpjMask(e.target.value.slice(0, 18)))
                }
              />

              <InputLogin
                name="password"
                placeholder="SENHA"
                id="input-password"
                icon={FaRegEye}
                type="password"
                inputRef={register({
                  required: true,
                })}
                erros={errors}
                autocomplete="on"
              />
              <div className="col-md-12">
                <Button
                  className="btn-purple btn-reset-password col-md-12"
                  type="submit"
                  disabled={isLoading}
                  variant="custom"
                >
                  {isLoading ? 'Loading…' : 'Avançar'}
                </Button>
              </div>
              <div className="links-login">
                <a
                  href={'/primeiro-acesso'}
                  rel="noopener noreferrer"
                  className="links-login__link"
                >
                  Primeiro Acesso
                </a>
                <a
                  href={'/recuperar-senha'}
                  className="links-login__link"
                  rel="noopener noreferrer"
                >
                  Esqueci minha senha
                </a>
              </div>
            </Form>
          </main>
        </div>
        <DownloadButtons />
      </div>
    </div>
  );
};
export default Login;
