/* eslint-disable import-helpers/order-imports */
import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify';

import CobrancaCard from '../../../components/Cards/CobrancaCard';
import ReceberCard from '../../../components/Cards/ReceberCard';
import LabelLastUpdated from '../../../components/Labels/LabelLastUpdated';

import './styles.scss';
import {getDadosPlanoContaFinanceira} from '../../../api';
import SaldoCard from '../../../components/Cards/SaldoCard';
import InputSelect from '../../../components/Inputs/InputSelect';
import SpinnerLoader from '../../../components/Spinner';
import Subtitulo from '../../../components/Subtitulo';
import UIContainer from '../../../components/UI/Container';
import {useAuth} from '../../../context/usuario-context';
import {
  IPixAvailable,
  IPlanoContaFinanceira,
} from '../../../models/conta-financeiro';
import {IDashboardFilters} from '../../../models/dashboard-model';
import {formataDinheiro} from '../../../utils/formatMonetary';
import {showModalWarning} from '../../../utils/modalWarning';

import {batchType} from '../../../shared/constantes/index';

import {useHistory} from 'react-router-dom';
import BarChart from '../../../components/bar/BarChart';
import LineChart from '../../../components/line/LineChart';

const Dashboard: React.FC = () => {
  const {usuarioLogado} = useAuth();
  const history = useHistory();
  const date = new Date();
  const [startDate, setStartDate] = useState<Date | undefined>(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );
  const [tipoData, setTipoData] = useState<string | undefined>('Envio');
  const [filter, setFilter] = useState<IDashboardFilters | undefined>();

  const redirect = () => {
    history.push('/dashboard');
  };

  const [items] = useState([
    {
      label: 'Envio',
      value: 'Envio',
    },
    {
      label: 'Vencimento',
      value: 'Vencimento',
    },
    {
      label: 'Pagamento',
      value: 'Pagamento',
    },
  ]);

  const validateDates = () => {
    setFilter(undefined);
    if (new Date(endDate) < new Date(startDate)) {
      toast.warning('A data fim deve ser igual ou posterior a data atual.', {
        autoClose: 2500,
      });
      return false;
    }

    if (endDate && startDate) {
      setFilter({
        tipoData: tipoData,
        startDate: startDate,
        finishDate: endDate,
      });
    }
    return true;
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateDates();
  };

  useEffect(() => {
    if (endDate && startDate) {
      setFilter({
        tipoData: tipoData ? tipoData : 'Envio',
        startDate: startDate,
        finishDate: endDate,
      });
    }
  }, []);

  const getPossuiPix = async () => {
    const documentsLocation = history.location.state as IPixAvailable;
    let html = '';
    if (documentsLocation && !documentsLocation.pixAvailable) {
      html += `<li> Para emissão de cobranças você deve ter uma chave PIX cadastrada no seu aplicativo <strong>${process.env.REACT_APP_NOME_PARCEIRO}</strong>
      </li>`;
    }
    if (documentsLocation && !documentsLocation.documentAvailable) {
      html += `<li> Para emissão de cobranças você deve ter a documentação aprovada no seu aplicativo ${process.env.REACT_APP_NOME_PARCEIRO}
      </li>`;
    }

    if (documentsLocation && !documentsLocation.infoPix) {
      html += `<li> Você atingiu o limite de operações da sua conta ${process.env.REACT_APP_NOME_PARCEIRO}. Para solicitar o aumento do limite acesse o aplicativo e clique no menu opções - meus limites.
      </li>`;
    }
    if (html) {
      showModalWarning(
        'Atenção! Você possui algumas pendências',
        html,
        redirect
      );
    }
  };

  useEffect(() => {
    getPossuiPix();
    return () => {};
  }, []);

  const [
    planoFinanceira,
    setPlanoFinanceira,
  ] = useState<IPlanoContaFinanceira>();

  const [isLoading, setIsLoading] = useState(false);

  const limitePlanoConta = async () => {
    setIsLoading(true);
    const planos = await getDadosPlanoContaFinanceira();
    setPlanoFinanceira(planos);

    setIsLoading(false);
  };

  useEffect(() => {
    limitePlanoConta();
  }, []);

  const options = ['Avulsa', 'Lote'];

  return (
    <UIContainer>
      <br />
      <Subtitulo>Seja bem vindo(a) {usuarioLogado?.nome}!</Subtitulo>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <div className="col-md-12 subtitulo-tela">
          {planoFinanceira ? (
            <p>
              {' '}
              Você poderá emitir{' '}
              {planoFinanceira ? (
                planoFinanceira.dailyTransactionLimit -
                  planoFinanceira.dailyTransactionLimitRequested >
                9999999 ? (
                  <span className="text-home">{' ilimitadas'}</span>
                ) : (
                  <span className="text-home">
                    {' '}
                    {planoFinanceira.dailyTransactionLimit -
                      planoFinanceira.dailyTransactionLimitRequested}
                  </span>
                )
              ) : (
                <span className="text-home">0</span>
              )}{' '}
              cobranças, no valor máximo de{' '}
              {planoFinanceira ? (
                planoFinanceira.dailyMaximumValue > 9999999 ? (
                  <span className="text-home">{' ilimitado'}</span>
                ) : (
                  <span className="text-home">
                    {' '}
                    {formataDinheiro(
                      planoFinanceira.dailyMaximumValue -
                        planoFinanceira.dailyMaximumValueRequested
                    )}
                  </span>
                )
              ) : (
                <span className="text-home">{formataDinheiro(0)}</span>
              )}
              {''} por cobrança.
            </p>
          ) : (
            `Para emissão de cobranças você deve ter uma chave PIX cadastrada no seu aplicativo ${process.env.REACT_APP_NOME_PARCEIRO}`
          )}
        </div>
      )}
      <div className="grid-container">
        <div className="cobrancaSelect">
          <div className="col-3 p-0">
            <InputSelect placeholder="Tipos de cobranças" options={batchType} />
          </div>
        </div>
        <div className="cobrancaLabel">
          <LabelLastUpdated tempo="12 horas" />
        </div>
        <div className="cobrancaCard1">
          <CobrancaCard
            day="12"
            month="JUN"
            qtdTickets="1200"
            ticketStatus="A vencer"
            totalValue="121.765,90"
          />
        </div>
        <div className="cobrancaCard2">
          <CobrancaCard
            day="12"
            month="JUN"
            qtdTickets="1200"
            ticketStatus="A vencer"
            totalValue="121.765,90"
          />
        </div>
        <div className="cobrancaCard3">
          <CobrancaCard
            day="12"
            month="JUN"
            qtdTickets="1200"
            ticketStatus="A vencer"
            totalValue="121.765,90"
          />
        </div>
        <div className="saldoCard">
          <SaldoCard value="121.987, 51" />
        </div>
        <div className="receberCard">
          <ReceberCard value={'11.000, 00'} />
        </div>
      </div>
      <div className="bar">
        <div className="bar-title">
          <h4>Emissão Mensal</h4>
        </div>
        <div className="bar-chart">
          <BarChart />
        </div>
      </div>

      <div className="bar">
        <div className="bar-title">
          <h4>Provisionamento Mensal</h4>
        </div>
        <div className="bar-chart">
          <LineChart />
        </div>
      </div>
    </UIContainer>
  );
};

export default Dashboard;
