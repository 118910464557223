import React, {useState, useEffect} from 'react';
import {Table} from 'react-bootstrap';
import './styles.scss';

import {listarVerCobrancas} from '../../../api/cobranca-api';
import PaginationDefault from '../../../components/PaginationDefault';
import {IListVerCobranca} from '../../../models/cobranca';
import {statusCobranca, statusEnvioMsgCobranca} from '../../../shared/enumMap';
import {formataData} from '../../../utils/formatDate';
import {formataDinheiro} from '../../../utils/formatMonetary';
import UIContent from '../../UI/Content';
interface VerCobranca {
  id: string;
}
const VerBoletosPix: React.FC<VerCobranca> = ({id}) => {
  const [cobrancas, setCobrancas] = useState<IListVerCobranca>();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(10);

  const listarCobrancas = async (id, pageNumber: number) => {
    const todasCobrancas = await listarVerCobrancas(id, pageNumber - 1, limit);

    setCobrancas(todasCobrancas);
    setTotalCount(todasCobrancas.totalCount);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    listarCobrancas(id, 1);
  }, []);
  return (
    <UIContent>
      <Table responsive bordered hover>
        <thead>
          <tr>
            <th>Nº cobrança</th>
            <th>Vencimento</th>
            <th>Status Pgto.</th>
            <th>Valor</th>
            <th>Status Envio</th>
            {/* <th>Mais</th> */}
          </tr>
        </thead>
        <tbody>
          {cobrancas?.data?.map(cobranca => {
            return RenderDatas(cobranca);
          })}
        </tbody>
      </Table>
      <PaginationDefault
        totalNaPagina={totalCount}
        currentPage={currentPage}
        totalCount={totalCount}
        limit={limit}
        action={(value: number) => listarCobrancas(id, value)}
      />
    </UIContent>
  );
};

const RenderDatas = cobranca => {
  return (
    <tr key={cobranca.id}>
      <td>{cobranca.numero}</td>
      <td>{formataData(cobranca.vencimentoEm)}</td>
      <td>{statusCobranca(cobranca.status)}</td>
      <td>{formataDinheiro(cobranca.valor)}</td>
      <td>{statusEnvioMsgCobranca(cobranca.statusEnvioMensagemCobranca)}</td>
      {/* <td>...</td> */}
    </tr>
  );
};
export default VerBoletosPix;
