import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {FaRegEye} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import {efetuarLoginMaster} from '../../../api';
import InputLogin from '../../../components/Inputs/InputLogin';
import Logo from '../../../components/Logo';
import {useAuth} from '../../../context/usuario-context';
import {IAuthU4criptoModel} from '../../../models/u4Cripto-model';
import noEmptyMask from '../../../utils/masks/noEmptyMask';
import './styles.scss';

const LoginU4Crypto: React.FC = () => {
  const {register, handleSubmit, errors} = useForm<IAuthU4criptoModel>();

  const [isLoading, setLoading] = useState(false);

  const {Login, isLogged} = useAuth();

  const history = useHistory();

  const onSubmit = (data: IAuthU4criptoModel) => {
    setLoading(true);
    Logar(data.email, data.password);
  };

  async function Logar(email: string, password: string) {
    try {
      const {usuarioLogado, token} = await efetuarLoginMaster(email, password);
      Login(usuarioLogado, token);
      history.replace('/termos-uso');
    } catch {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (isLogged()) {
      history.push('/termos-uso');
    }
  }, []);

  return (
    <div className="all-content">
      <span className="container page-title">
        Sistema de Gestão de Cobranças com Pix
      </span>
      <span className="container title-super-user ">
        Administrador {process.env.REACT_APP_NOME_PARCEIRO}
      </span>
      <div className="container">
        <div className="div-form col-md-12">
          <main className="content-login-uf4  row">
            <div className="content-login-uf4__img">
              <Logo></Logo>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <InputLogin
                name="email"
                type="text"
                placeholder="EMAIL"
                id="email-u4cripto"
                inputMaxLength={'100'}
                inputRef={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Entre com um e-mail válido.',
                  },
                })}
                erros={errors}
                mask={noEmptyMask}
              />

              <InputLogin
                name="password"
                placeholder="SENHA"
                id="input-password"
                icon={FaRegEye}
                type="password"
                inputRef={register({
                  required: true,
                })}
                erros={errors}
                autocomplete="on"
              />
              <div className="col-md-12">
                <Button
                  className="btn-purple w-100 btn-reset-password"
                  type="submit"
                  disabled={isLoading}
                  variant="custom"
                >
                  {isLoading ? 'Loading…' : 'Acessar'}
                </Button>
              </div>
            </Form>
          </main>
        </div>
      </div>
    </div>
  );
};
export default LoginU4Crypto;
