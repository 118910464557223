import React, {useState} from 'react';
import {Control, Controller, DeepMap, FieldError} from 'react-hook-form';
import {FaCalendar} from 'react-icons/fa';
import './styles.scss';

import {getDadosPlanoContaFinanceira} from '../../../api';
import CardSection from '../../../components/CardSection';
import CharacterCount from '../../../components/CharacterCount';
import DatePickerBr from '../../../components/DatePickerBr';
import InputDefault from '../../../components/Inputs/InputDefault';
import {ICobranca} from '../../../models/cobranca';
import {IPlanoContaFinanceira} from '../../../models/conta-financeiro';
import moneyMask from '../../../utils/masks/moneyMask';
import {showModalWarning} from '../../../utils/modalWarning';
import InputCheckboxDefault from '../../../components/Inputs/InputCheckboxDefault';

interface CardClienteAvulsaProps {
  register;
  setValue;
  control: Control;
  errors: DeepMap<ICobranca, FieldError>;
  setValorCobranca: React.Dispatch<React.SetStateAction<number>>;
  planoFinanceira: IPlanoContaFinanceira;
  setHasLimit: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingLimit: React.Dispatch<React.SetStateAction<boolean>>;
  receivedValue?: string;
  information?: string;
  setInformation?: React.Dispatch<React.SetStateAction<string>>;
  expirationDate?: string;
  paymentLimitDate?: string;
  isDisabled?: boolean;
  clientsFromGroup?: number;
  setCobrarPorEmail: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardClienteAvulsa: React.FC<CardClienteAvulsaProps> = ({
  register,
  control,
  errors,
  setValorCobranca,
  setValue,
  setHasLimit,
  setIsLoadingLimit,
  receivedValue,
  information,
  setInformation,
  expirationDate,
  paymentLimitDate,
  isDisabled = false,
  clientsFromGroup,
  setCobrarPorEmail,
}) => {
  const obterDataUmAnoDepois = (hoje): Date => {
    return new Date(hoje.setFullYear(hoje.getFullYear() + 1));
  };

  const [characterCount, setCharacterCount] = useState(150);
  const [dataMinima, setDataMinima] = useState<Date>(new Date());
  const [dataPagamento, setDataPagamento] = useState<Date>();
  const [dataEnvio, setDataEnvio] = useState<Date>(new Date());
  const [vencimento, setVencimento] = useState<Date>();
  const [cobrancaEmail, setCobrancaEmail] = useState<boolean>(false);

  const characterCounter = e => {
    e.target.value = e.target.value.replace(/^\s+/, '');
    setCharacterCount(150 - e.target.value.length);
  };

  const verificarLimite = async value => {
    setIsLoadingLimit(true);
    const data: IPlanoContaFinanceira = await getDadosPlanoContaFinanceira();
    let parsedValue = Number(value.replace(/\./g, '').replace(',', '.'));

    parsedValue = clientsFromGroup
      ? parsedValue * clientsFromGroup
      : parsedValue;

    if (
      !(
        data.maxValueMonthlyValueRequested + parsedValue >
        data.maxValueMonthly
      ) &&
      !(data.dailyMaximumValueRequested + parsedValue > data.dailyMaximumValue)
    ) {
      setIsLoadingLimit(false);
      setHasLimit(true);
      return;
    }

    setHasLimit(false);
    showModalWarning(
      'Limite do plano ultrapassado!',
      '<p>O limite do seu plano diário ou mensal não comporta a emissão desta cobrança, aumente seu limite através do atendimento!</p>'
    );
  };

  return (
    <CardSection title="Informações Valor / Data / Envio">
      <div className="form-row">
        <div className="form-group col-md-3 input-valor-cobranca">
          <label className="label-item-tabela" htmlFor="input-valor">
            Valor
          </label>
          <span className="asterisk ">*</span>
          <InputDefault
            name="valor"
            type="text"
            placeholder=""
            id="input-valor"
            inputRef={register({
              required: true,
              min: 1,
              maxLength: 14,
            })}
            erros={errors}
            onChange={() => {
              setIsLoadingLimit(true);
            }}
            onBlur={event => {
              setValorCobranca(
                Number(
                  event.target.value
                    .replace('R$ ', '')
                    .replace('.', '')
                    .replace(',', '.')
                )
              );
              verificarLimite(event.target.value);
            }}
            mask={moneyMask}
            inputMaxLength="14"
            inputGroupText="R$"
            defaultValue={receivedValue}
            disabledInput={isDisabled}
          />
          {/* <span className="info-limit-day">
            {`Você pode emitir mais ${
              planoFinanceira.dailyTransactionLimit
            } cobranças, com limite total de ${formataDinheiro(
              planoFinanceira.dailyTransactionLimit
            )}.`}
          </span> */}
        </div>

        <div className="form-group col-md-3 cliente-datapicker ">
          <label className="label-item-tabela" htmlFor="input-vencimento">
            Vencimento
            <span className="asterisk ">*</span>
          </label>
          <Controller
            className="input-default"
            name="vencimento"
            defaultValue={null}
            control={control}
            rules={expirationDate ? {required: false} : {required: true}}
            render={({value, onChange, ref}) => (
              <DatePickerBr
                id="input-vencimento"
                icon={FaCalendar}
                classIcon="icon-calendar"
                selected={value}
                name="vencimento"
                inputRef={ref}
                erros={errors}
                onChange={value => {
                  onChange(value);
                  setVencimento(() => value);
                  const data = new Date(value);
                  setDataMinima(data);
                  setDataPagamento(null);
                  setValue('dataLimitePagamento', null);
                }}
                dataMinima={new Date()}
                dataMaxima={obterDataUmAnoDepois(new Date())}
                isDisabled={isDisabled}
              />
            )}
          />
        </div>

        <div className="form-group col-md-3 cliente-datapicker ">
          <label
            className="label-item-tabela"
            htmlFor="input-vencimento-limite"
          >
            Limite de pagamento
            <span className="asterisk ">*</span>
          </label>
          <Controller
            className="input-default"
            name="dataLimitePagamento"
            defaultValue={null}
            control={control}
            rules={paymentLimitDate ? {required: false} : {required: true}}
            render={({onChange, value, ref}) => (
              <DatePickerBr
                icon={FaCalendar}
                name="dataLimitePagamento"
                id="input-vencimento-limite"
                selected={dataPagamento}
                erros={errors}
                inputRef={ref}
                onChange={value => {
                  onChange(value);
                  setDataPagamento(value);
                }}
                dataMaxima={obterDataUmAnoDepois(new Date())}
                classIcon="icon-calendar"
                dataMinima={dataMinima}
                dataInicial={dataMinima}
                isDisabled={isDisabled}
                //receivedDate={paymentLimitDate}
              />
            )}
          />
        </div>

        <div className="form-group col-md-3 cliente-datapicker ">
          <label className="label-item-tabela" htmlFor="input-data-envio">
            Agendar envio para
            <span className="asterisk ">*</span>
          </label>
          <Controller
            className="input-default"
            name="dataEnvio"
            defaultValue={new Date()}
            control={control}
            rules={paymentLimitDate ? {required: false} : {required: true}}
            render={({onChange, value, ref}) => (
              <DatePickerBr
                icon={FaCalendar}
                name="dataEnvio"
                id="input-data-envio"
                selected={dataEnvio}
                erros={errors}
                inputRef={ref}
                onChange={value => {
                  onChange(value);
                  setDataEnvio(value);
                }}
                dataMaxima={vencimento}
                classIcon="icon-calendar"
                dataMinima={new Date()}
                dataInicial={new Date()}
                isDisabled={isDisabled}
                //receivedDate={paymentLimitDate}
              />
            )}
          />
        </div>
        <div className="col-md-9">
          <label className="d-flex mt-3 mb-4 checkbox-cobranca">
            <InputCheckboxDefault
              name="cobrancaEmail"
              checked={cobrancaEmail}
              onChange={e => {
                setCobrancaEmail(e.target.checked);
                setCobrarPorEmail(e.target.checked);
              }}
            />
            Desejo que seja cobrada inadimplência diariamente por e-mail
          </label>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-12">
          <label className="label-item-tabela" htmlFor="input-instrucoes">
            Informações
          </label>
          <span className="asterisk ">*</span>
          <textarea
            className="col-md-12 text-area-default"
            name="instrucoesCliente"
            id="input-instrucoes"
            placeholder="Insira aqui as informações que irão ser exibidas na cobrança"
            onChange={event => {
              characterCounter(event);
              if (setInformation) setInformation(event.target.value);
            }}
            maxLength={150}
            ref={register({
              required: true,
            })}
            value={information}
            disabled={isDisabled}
          />
          <CharacterCount count={characterCount} />
          {errors.instrucoesCliente && (
            <span className="input-error error-textarea ">
              Este campo é obrigatório.
            </span>
          )}
        </div>
      </div>
    </CardSection>
  );
};

export default CardClienteAvulsa;
