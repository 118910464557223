import React, {useState, useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import {IconBaseProps} from 'react-icons';

import {exibirUsuario} from '../../../api/usuario-api';
import {IFormContent} from '../../../models/forms-model';
import {IUsuarios} from '../../../models/usuario-model';
import FormEditUsuario from '../FormEditUsuario';

import './styles.scss';
interface ModalEditarProps {
  id?: string;
  icon?: React.ComponentType<IconBaseProps>;
  variantClass?: string;
  formContent?: IFormContent;
  setUpdateTable?: Function;
  showModalUsuario?: boolean;
  handleCloseUsuario?: Function;
  showMenuModal?: boolean;
}

const ModalEditarUsuario: React.FC<ModalEditarProps> = ({
  id,
  icon: Icon,
  setUpdateTable,
  showModalUsuario,
  handleCloseUsuario,
  showMenuModal,
}) => {
  const [usuario, setUsuario] = useState<IUsuarios>();

  async function carregarUsuario(id: string) {
    setUsuario(await exibirUsuario(id));
  }

  const updateTable = () => {
    setUsuario(undefined);
    setUpdateTable(true);
  };

  const showPopoverClient = () => {
    const showPopover = document.querySelectorAll<HTMLElement>('.popover ')[0];

    if (showPopover) {
      if (showModalUsuario) {
        showPopover.style.display = 'none';
      }
    }
  };

  useEffect(() => {
    carregarUsuario(id);
    showPopoverClient();
  }, []);
  return (
    <>
      {usuario && (
        <Modal
          animation={false}
          show={showModalUsuario}
          onHide={handleCloseUsuario}
          backdrop="static"
          keyboard={true}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {Icon && <Icon size={20} className="modal-icone" />}
              {showMenuModal ? 'Editar usuário' : 'Ver usuário'}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <FormEditUsuario
              usuario={usuario}
              handleCloseModal={handleCloseUsuario}
              handleUpdateTable={updateTable}
              showEditOrView={showMenuModal}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default ModalEditarUsuario;
