import React, {ButtonHTMLAttributes, MouseEventHandler} from 'react';
import './styles.scss';
import {Button} from 'react-bootstrap';
import {IconBaseProps} from 'react-icons';

interface BtnDefaultProps extends ButtonHTMLAttributes<Button> {
  action?: MouseEventHandler;
  type?: 'submit' | 'reset' | 'button';
  icon?: React.ComponentType<IconBaseProps>;
  name?: string;
  id?: string;
  variantClass?: string;
  className?: string;
  autoFocus?: boolean;
  disabled?: boolean;
}

const BtnDefault: React.FC<BtnDefaultProps> = ({
  children,
  action,
  icon: Icon,
  name,
  id,
  variantClass,
  className,
  type = 'submit',
  autoFocus,
  disabled,
}) => {
  return (
    <span>
      <Button
        name={name}
        type={type}
        onClick={action}
        id={id}
        variant={variantClass}
        className={className}
        autoFocus={autoFocus}
        disabled={disabled}
      >
        {children}
      </Button>
      {Icon && <Icon size={15} />}
    </span>
  );
};
export default BtnDefault;
