import React, {MouseEventHandler, useState} from 'react';
import './styles.scss';
import {Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {FaPen, FaBan, FaRegWindowClose, FaRegSave} from 'react-icons/fa';
import {toast} from 'react-toastify';

import {getEndereco} from '../../../api';
import {atualizarCliente} from '../../../api/cliente-api';
import {ICliente} from '../../../models/cliente-model';
import {IViaCep} from '../../../models/viaCep-model';
import cepMask from '../../../utils/masks/cepMask';
import cpfcnpjMask from '../../../utils/masks/cpfcnpjMask';
import phoneMask from '../../../utils/masks/phoneMask';
import cepValidator from '../../../utils/validations/cepValidator';
import cpfCnpjValidator from '../../../utils/validations/cpfCnpjValidator';
import BtnDefault from '../../Buttons/BtnDefault';
import InputDefault from '../../Inputs/InputDefault';
import InputEstado from '../../Inputs/InputEstado';
import noEmptyMask from '../../../utils/masks/noEmptyMask';

interface IModalEditarClienteProps {
  cliente: ICliente;
  handleCloseModal?: Function;
  handleUpdateTable?: Function;
  action?: MouseEventHandler;
  showEditOrView?: boolean;
}

const FormEditCliente: React.FC<IModalEditarClienteProps> = ({
  cliente,
  handleCloseModal,
  handleUpdateTable,
  showEditOrView,
}: IModalEditarClienteProps) => {
  const {register, handleSubmit, errors, control, setValue} = useForm();
  const [editing, setEditing] = useState(showEditOrView);

  const handleEditar = async data => {
    const editCliente = {
      nome: data.nome,
      cpfCnpj: data.CPF?.replace(/[^\d]+/g, ''),
      telefone: data.telefone?.replace(/[^\d]+/g, ''),
      email: data.email,
      endereco: {
        cep: data.cep?.replace(/[^\d]+/g, ''),
        logradouro: data.endereco,
        numero: data.numero,
        complemento: data.complemento,
        bairro: data.bairro,
        cidade: data.cidade,
        siglaEstado: data.estado['value'],
      },
    };
    if (data.confirmacaoEmail !== data.email) {
      toast.error('Os emails digitados não correspondem', {autoClose: 4000});
      return;
    }
    try {
      await atualizarCliente(cliente.id, editCliente);
      setEditing(true);
      toast.success('Cliente editado com sucesso! ', {autoClose: 2500});
      closeModal();
      handleCloseModal();
    } catch (e) {
      console.error(e);
    }
  };

  const cancelForm = () => {
    handleCloseModal(false);
  };

  const closeModal = () => {
    handleUpdateTable(true);
  };

  const onChangeState = val => {
    setValue('estado', {
      label: val.uf,
      value: val.uf,
    });
  };

  async function getAddress(event) {
    if (cepValidator(event.target.value)) {
      const endereco: IViaCep = await getEndereco(event.target.value);
      if (!endereco.erro) {
        setValue('endereco', endereco.logradouro);
        setValue('bairro', endereco.bairro);
        setValue('cidade', endereco.localidade);
        setValue('complemento', endereco.complemento);
        setValue('estado', {
          label: endereco.uf,
          value: endereco.uf,
        });
      }
    }
  }

  const toggleEditing = () => {
    setEditing(true);
  };
  return (
    <>
      <form onSubmit={handleSubmit(handleEditar)} className="form-modal">
        <h6 className="titulo-sessao">Informações do cliente</h6>
        <div className="row">
          <div className="col-sm-4" key={'CPF'}>
            <label className="label-item-tabela" htmlFor="input-cpf">
              CPF/CNPJ
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="CPF"
              type="text"
              id="input-cpf"
              disabledInput={!editing}
              inputRef={register({
                required: true,
                minLength: 14,
                maxLength: 18,
                validate: {
                  cpfCnpj: value => {
                    return cpfCnpjValidator(value);
                  },
                },
              })}
              mask={cpfcnpjMask}
              erros={errors}
              inputMaxLength="18"
              defaultValue={cpfcnpjMask(cliente.cpfCnpj)}
            ></InputDefault>
          </div>
          <div className="col-sm-4" key={'nome'}>
            <label className="label-item-tabela" htmlFor="input-nome">
              Nome ou Razão Social
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="nome"
              type="text"
              disabledInput={!editing}
              readOnly={false}
              id="input-nome"
              inputRef={register({
                required: true,
                maxLength: 100,
              })}
              erros={errors}
              inputMaxLength="100"
              defaultValue={cliente.nome}
              mask={noEmptyMask}
            ></InputDefault>
          </div>

          <div className="col-sm-4" key="telefone">
            <label className="label-item-tabela" htmlFor="input-telefone">
              Telefone
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="telefone"
              type="text"
              id="input-telefone"
              disabledInput={!editing}
              inputRef={register({
                required: true,
                maxLength: 14,
                minLength: 13,
              })}
              erros={errors}
              inputMaxLength="14"
              mask={phoneMask}
              defaultValue={phoneMask(cliente.telefone)}
            ></InputDefault>
          </div>

          <div className="col-sm-6 mt-3" key="email">
            <label className="label-item-tabela" htmlFor="input-email">
              Email
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="email"
              type="text"
              id="input-email"
              disabledInput={!editing}
              inputRef={register({
                required: true,
                maxLength: 100,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Entre com um e-mail válido.',
                },
              })}
              erros={errors}
              inputMaxLength="100"
              defaultValue={cliente.email}
              mask={noEmptyMask}
            ></InputDefault>
          </div>
          <div className="col-sm-6 mt-3" key="confirmacaoEmail">
            <label
              className="label-item-tabela"
              htmlFor="input-confirmacaoEmail"
            >
              Confirme seu Email
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="confirmacaoEmail"
              type="text"
              id="input-confirmacaoEmail"
              disabledInput={!editing}
              inputRef={register({
                required: true,
                maxLength: 100,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Entre com um e-mail válido.',
                },
              })}
              erros={errors}
              inputMaxLength="100"
              defaultValue={cliente.email}
              mask={noEmptyMask}
            ></InputDefault>
          </div>
        </div>

        <div className="titulo-sessao">Dados cobrança </div>

        <div className="row">
          <div className="col-sm-4 mt-3" key={'cep'}>
            <label className="label-item-tabela" htmlFor="input-cep">
              CEP
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="cep"
              type="text"
              id="input-cep"
              disabledInput={!editing}
              inputRef={register({
                required: true,
                minLength: 9,
              })}
              mask={cepMask}
              erros={errors}
              inputMaxLength="9"
              defaultValue={cepMask(cliente.endereco.cep)}
              onBlur={e => getAddress(e)}
            ></InputDefault>
          </div>
          <div className="col-sm-4 mt-3" key={'endereco'}>
            <label className="label-item-tabela" htmlFor="input-endereco">
              Endereço completo
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="endereco"
              type="text"
              disabledInput={!editing}
              readOnly={false}
              id="input-endereco"
              inputRef={register({
                required: true,
                maxLength: 100,
              })}
              erros={errors}
              inputMaxLength="100"
              defaultValue={cliente.endereco.logradouro}
              mask={noEmptyMask}
            ></InputDefault>
          </div>
          <div className="col-sm-4 mt-3" key={'numero'}>
            <label className="label-item-tabela" htmlFor="input-numero">
              Nº
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="numero"
              type="text"
              disabledInput={!editing}
              readOnly={false}
              id="input-numero"
              inputRef={register({
                required: true,
                maxLength: 5,
                minLength: 1,
              })}
              erros={errors}
              inputMaxLength="5"
              defaultValue={cliente.endereco.numero}
              mask={noEmptyMask}
            ></InputDefault>
          </div>
          <div className="col-sm-3 mt-3" key={'complemento'}>
            <label className="label-item-tabela" htmlFor="input-complemento">
              Complemento
            </label>
            <InputDefault
              name="complemento"
              type="text"
              disabledInput={!editing}
              readOnly={false}
              id="input-complemento"
              inputRef={register({
                maxLength: 100,
              })}
              erros={errors}
              inputMaxLength="100"
              defaultValue={cliente.endereco.complemento}
              mask={noEmptyMask}
            ></InputDefault>
          </div>
          <div className="col-sm-3 mt-3" key={'bairro'}>
            <label className="label-item-tabela" htmlFor="t">
              Bairro
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="bairro"
              type="text"
              disabledInput={!editing}
              readOnly={false}
              id="t"
              inputRef={register({
                required: true,
                maxLength: 100,
                minLength: 1,
              })}
              erros={errors}
              inputMaxLength="100"
              defaultValue={cliente.endereco.bairro}
              mask={noEmptyMask}
            ></InputDefault>
          </div>

          <div className="col-sm-3 mt-3" key="cidade">
            <label className="label-item-tabela" htmlFor="input-cidade">
              Cidade
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="cidade"
              type="text"
              disabledInput={!editing}
              readOnly={false}
              id="input-cidade"
              inputRef={register({
                required: true,
                maxLength: 60,
                minLength: 1,
              })}
              erros={errors}
              inputMaxLength="60"
              defaultValue={cliente.endereco.cidade}
            ></InputDefault>
          </div>
          <div className="col-sm-2 mt-3" key="estado">
            <InputEstado
              control={control}
              name="estado"
              inputClassName="form-select"
              isRequired={true}
              erros={errors}
              defaultValue={{
                value: cliente.endereco.siglaEstado,
                label: cliente.endereco.siglaEstado,
              }}
              inputRef={register({
                required: true,
              })}
              onChange={onChangeState}
              isDisabledSelect={!editing}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            className="btn-custom btn-cancel"
            variant="custom"
            onClick={() => cancelForm()}
          >
            {showEditOrView ? (
              <span>
                <FaBan size={13} className="mr-1" /> Cancelar
              </span>
            ) : (
              <span>
                <FaRegWindowClose size={13} className="mr-1" /> Fechar
              </span>
            )}
          </Button>

          {!editing && !showEditOrView ? (
            <Button
              type="button"
              className="btn-custom btn-save"
              variant="custom"
              onClick={toggleEditing}
            >
              <FaPen size={13} className="mr-1" />
              Editar
            </Button>
          ) : (
            <BtnDefault
              type="submit"
              variantClass="custom"
              className="btn-custom btn-save"
            >
              {showEditOrView ? (
                <span>
                  <FaPen size={13} className="mr-1" />
                  Editar
                </span>
              ) : (
                <span>
                  {' '}
                  <FaRegSave size={13} className="mr-1" />
                  Salvar
                </span>
              )}
            </BtnDefault>
          )}
        </div>
      </form>
    </>
  );
};

export default FormEditCliente;
