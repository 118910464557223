import React from 'react';

import './styles.scss';

const SpinnerLoader: React.FC = () => {
  return (
    <div className="loader-container">
      <div className="loader-container-animation" />
    </div>
  );
};
export default SpinnerLoader;
