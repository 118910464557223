import React from 'react';

import './styles.scss';

interface CharacterCountProps {
  count: Number;
}

const CharacterCount: React.FC<CharacterCountProps> = ({count}) => {
  return (
    <span className="limite-textarea d-flex justify-content-end mt-2 ml-auto">
      Limite de caracteres: {count}
    </span>
  );
};

export default CharacterCount;
