import React, {useRef, useState} from 'react';
import {Overlay, Popover} from 'react-bootstrap';
import {FaEllipsisV, FaRegEye, FaTrashAlt, FaPen} from 'react-icons/fa';

import {ICategoria} from '../../../models/cobranca';

interface TableRowProps {
  categoria: ICategoria;
  exibirModal: Function;
  excluirCategoria: Function;
}
const TableRowCategoria: React.FC<TableRowProps> = ({
  categoria,
  exibirModal,
  excluirCategoria,
}) => {
  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);

  const ref = useRef(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleClosepop = () => setShow(false);
  return (
    <>
      <tr key={categoria.id}>
        <td className="left">{categoria.nome}</td>
        <td className="left">{categoria.descricao}</td>
        <td>
          <FaEllipsisV style={{cursor: 'pointer'}} onClick={handleClick} />
          <Overlay
            show={show}
            target={target}
            placement="bottom"
            container={ref.current}
            containerPadding={20}
            onHide={handleClosepop}
            rootClose={true}
            transition={false}
          >
            <Popover id="popover-contained">
              <Popover.Title as="h3">{'Ações'}</Popover.Title>
              <Popover.Content>
                <span className="icones-popover">
                  <span
                    className="icones-popover__text"
                    onClick={() => excluirCategoria(categoria.id)}
                  >
                    <FaTrashAlt
                      className="icones-popover__icone"
                      size="18"
                    ></FaTrashAlt>

                    <span className="icones-popover__text">Excluir</span>
                  </span>

                  <span onClick={() => exibirModal(categoria.id, true)}>
                    <FaPen className="icones-popover__icone" size="18" />
                    <span className="icones-popover__text">Editar</span>
                  </span>

                  <span onClick={() => exibirModal(categoria.id, false)}>
                    <FaRegEye className="icones-popover__icone" size="18" />
                    <span className="icones-popover__text">Ver</span>
                  </span>
                </span>
              </Popover.Content>
            </Popover>
          </Overlay>
        </td>
      </tr>
    </>
  );
};

export default TableRowCategoria;
