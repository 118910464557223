import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {
  FaFastBackward,
  FaQuestionCircle,
  FaRegSave,
  FaSearch,
} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

import {listarCategorias} from '../../api';
import {exibirCliente} from '../../api';
import {cadastrarCobranca} from '../../api';
import {
  getDadosPlanoContaFinanceira,
  getVerificacaoDocumento,
  verificarChavePix,
} from '../../api';
import CardSection from '../../components/CardSection';
import InputRadioDefault from '../../components/Inputs/InputRadioDefault';
import ModalSearchName from '../../components/ModalSearchName';
import SpinnerLoader from '../../components/Spinner';
import Titulo from '../../components/Titulo';
import UIContainer from '../../components/UI/Container';
import {ICliente} from '../../models';
import {ICategoria} from '../../models/categoria-model';
import {ICobranca} from '../../models/cobranca';
import {IPlanoContaFinanceira} from '../../models/conta-financeiro';
import {TipoCobranca} from '../../shared/enumMap';
import subtrDate from '../../utils/dates/formulas';
import {formataDinheiro} from '../../utils/formatMonetary';
import cpfcnpjMask from '../../utils/masks/cpfcnpjMask';
import {showModalWarning} from '../../utils/modalWarning';
import {validarFormEmitirBoleto} from '../../utils/validations/formEmitirBoleto';
import CardClienteAvulsa from './CardClienteAvulsa';
import CardClienteParcelada from './CardClienteParcelada';
import CardInterno from './CardInterno';
import CardJurosMulta from './CardJurosMulta';

import './styles.scss';

// eslint-disable-next-line import-helpers/order-imports
import Swal from 'sweetalert2';

interface DadosBoletoProps {
  ClienteId: string;
}

const DadosBoleto: React.FC<DadosBoletoProps> = () => {
  const radioBilletNameOption = 'type-billet-selection';
  const radioSingleBilletSendOption = 'type-billet-single-selection';
  const radioInstallmentBilletSendOption = 'type-billet-installment-selection';
  const [clienteInfos, setClienteInfos] = useState<ICliente>();
  const [clienteInfosLoaded, setClienteInfosLoaded] = useState<boolean>(false);
  const [cobrarPorEmail, setCobrarPorEmail] = useState<boolean>(false);

  const history = useHistory();
  const CobrancasCategorias = [];

  const {
    register,
    handleSubmit,
    control,
    errors,
    clearErrors,
    getValues,
    setValue,
  } = useForm<ICobranca>();

  const [categorias, setCategorias] = useState([]);
  const [numParcelas, setNumParcelas] = useState<number>(null);
  const [vlParcela, setVlParcelas] = useState<number>(1);
  const [vlTotalCalculado, setVlTotalCalculado] = useState<number>(1);

  const [tipoBoleto, setTipoBoleto] = useState<TipoCobranca>();
  const [emailIschecked, setEmailIschecked] = useState<boolean>(false);
  const [whatsappIschecked, setWhatsappChecked] = useState<boolean>(false);

  const [valorCobranca, setValorCobranca] = useState<number>(0);
  const [valorDesconto, setValorDesconto] = useState<number>(0);
  const [porcDescontoCalculado, setPorcDescontoCalculado] = useState<number>(
    null
  );
  const [diaExatoDeEnvio, setDiaExatoDeEnvio] = useState<number>(0);
  const [url, setStateUrl] = useState<boolean>(true);
  const [diasAmaisParaDesconto, setDiasAmaisParaDesconto] = useState<number>(0);
  const [isPorcentagemDesconto, setIsPorcentagemDesconto] = useState<boolean>(
    false
  );

  const [isPorcentagemJuros, setIsPorcentagemJuros] = useState<boolean>(false);
  const [maxDias, setMaxDias] = useState<number>(10);

  const [isLoading, setIsLoading] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const [hasLimit, setHasLimit] = useState(true);
  const [isLoadingLimit, setIsLoadingLimit] = useState(false);
  const [clientId, setClientId] = useState('');
  const [modalIsVisible, setModalIsVisible] = useState(true);
  const [isAvailable, setAvailable] = useState<boolean>(false);
  const [dataEnvio, setDataEnvio] = useState<Date>(new Date());

  const [isEnvioImediato, setIsEnvioImediato] = useState(true);
  const [qtdDiasAntesDoVencimento, setQtdDiasAntesDoVencimento] = useState(0);

  const [dataVencimento, setDataVencimento] = useState<Date>();

  const [
    planoFinanceira,
    setPlanoFinanceira,
  ] = useState<IPlanoContaFinanceira>();

  const updatePlanoConta = async () => {
    const planos = await getDadosPlanoContaFinanceira();
    setPlanoFinanceira(planos);
  };

  useEffect(() => {
    setVlTotalCalculado(numParcelas * vlParcela);
  }, [numParcelas, vlParcela]);

  useEffect(() => {
    valorDesconto < valorCobranca && porcDescontoCalculado
      ? setPorcDescontoCalculado((valorDesconto * 100) / valorCobranca)
      : 0;
  }, [valorCobranca, valorDesconto]);

  useEffect(() => {
    setVlTotalCalculado(numParcelas * vlParcela);
  }, [numParcelas, vlParcela]);

  const listarTodasCategorias = async () => {
    const todasCategorias = await listarCategorias();

    if (todasCategorias) {
      todasCategorias.data.map((categoria: ICategoria) =>
        CobrancasCategorias.push({value: categoria.id, label: categoria.nome})
      );
      setCategorias(CobrancasCategorias);
    }
  };

  async function getClienteInfos() {
    await exibirCliente(clientId).then(cliente => {
      if (cliente) {
        setClienteInfos(cliente);
      }
    });
  }

  useEffect(() => {
    setIsLoading(true);
    listarTodasCategorias();
    setIsPorcentagemDesconto(false);
    updatePlanoConta();
    setIsLoading(false);
    getClienteInfos();
  }, []);

  const loadClient = async () => {
    setIsLoading(true);
    await getClienteInfos();
    setIsLoading(false);
  };

  const setarTipoBoleto = (opc: TipoCobranca) => {
    setTipoBoleto(opc);

    if (opc === 'AVULSA') {
      handleResetFormCat();
    }

    if (opc === 'PARCELADA') {
      handleResetFormCat();
    }

    if (errors) {
      clearErrors(undefined);
    }
  };

  const handleResetFormCat = () => {
    setVlTotalCalculado(null);
    setNumParcelas(null);
    setVlParcelas(null);
  };

  const enviarCobranca = async (data: ICobranca) => {
    /// se tiver dia exato para envio, eu não mando dias antes do vencimento
    const categoriaEscolhida = categorias.find(
      categoria => categoria.value === data.categoria
    );

    const valorParcela = data.valor.replace(/\./g, '').replace(',', '.');
    let multaPorcentagem = '0';

    let multa = data.multa
      ? data.multa.replace(/\./g, '').replace(',', '.')
      : '0';
    let juros = data.juros
      ? data.juros.replace(/\./g, '').replace(',', '.')
      : '0';

    if (isPorcentagemJuros) {
      multaPorcentagem = multa;
      juros = String((parseFloat(juros) / 100) * parseFloat(valorParcela));
      multa = String((parseFloat(multa) / 100) * parseFloat(valorParcela));
    }

    let diasAntesVencimento = 0;
    let localEnvioImediato = true;

    if (tipoBoleto === TipoCobranca.AVULSA) {
      if (
        data.dataEnvio?.toLocaleDateString() ===
        data.vencimento.toLocaleDateString()
      ) {
        localEnvioImediato = true;
        diasAntesVencimento = 0;
      } else {
        localEnvioImediato = false;
        diasAntesVencimento = subtrDate(data.vencimento, data.dataEnvio);
      }
    } else {
      localEnvioImediato = isEnvioImediato;
      diasAntesVencimento = Number(qtdDiasAntesDoVencimento);
    }

    let descontoPorcentagem = '0';
    let desconto = data.desconto
      ? data.desconto.replace(/\./g, '').replace(',', '.')
      : '0';

    if (isPorcentagemDesconto) {
      descontoPorcentagem = desconto;
      desconto = String(
        (parseFloat(descontoPorcentagem) / 100) * parseFloat(valorParcela)
      );
    }
    if (validarFormEmitirBoleto(data)) {
      const novaCobranca: ICobranca = {
        descricao: data.descricao,
        valor: data.valor.replace(/\./g, '').replace(',', '.'),
        numeroParcelas: data.numeroParcelas,
        diaMesVencimento: data.diaMesVencimento,
        qtdeDiasGeracaoRecorrencia: data.qtdeDiasGeracaoRecorrencia,
        instrucoesCliente: data.instrucoesCliente,
        multa: multa,
        multaPorcentagem: multaPorcentagem.replace(',', '.'),
        juros: juros,
        desconto: desconto,
        descontoPorcentagem: descontoPorcentagem.replace(',', '.'),
        diasAmaisParaPagamento: data.diasAmaisParaPagamento
          ? data.diasAmaisParaPagamento
          : 0,
        dataLimitePagamento: data.dataLimitePagamento,
        vencimento: data.vencimento,
        dataEnvio: data.dataEnvio,
        idCliente: clientId,
        tipoCobranca: tipoBoleto,
        categoria: categoriaEscolhida,
        porSms: false,
        porEmail: emailIschecked,
        porWhatsapp: whatsappIschecked,
        isEnvioImediato: localEnvioImediato,
        qtdDiasEnvioAntesVencimento: diasAntesVencimento,
        diaExatoDeEnvio: diaExatoDeEnvio,
        diasAmaisParaDesconto: diasAmaisParaDesconto,
        cobrarPorEmail: cobrarPorEmail,
      };
      if (hasLimit) {
        criarCobranca(novaCobranca);
      } else {
        showModalWarning(
          `Você atingiu o limite de operações da sua conta ${process.env.REACT_APP_NOME_PARCEIRO}.`,
          '<p>Para solicitar o aumento do limite acesse o aplicativo e clique no menu opções - meus limites.</p>'
        );
      }
    } else {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data: ICobranca) => {
    const showWarning = areValuesHigherThanAllowed(data);
    if (showWarning) showValueWarning(data, enviarCobranca);
    if (!showWarning) await enviarCobranca(data);
  };

  const showValueWarning = (billet: ICobranca, callback: Function) => {
    try {
      Swal.fire({
        title:
          'O valor do juros e/ou da multa ultrapassaram o limite permitido por lei!',
        text:
          'Pode-se apenas cobrar 0,033% de juros ao dia e 2% de multa ao mês conforme art. 52 do Código de Defesa do Consumidor!',
        showCancelButton: true,
        confirmButtonColor: ' #d33',
        cancelButtonColor: `${process.env.REACT_APP_COLOR_PRIMARY}`,
        confirmButtonText:
          '<i class="fas fa-check fa-sm" aria-hidden="true"></i> Estou ciente, continuar',
        cancelButtonText:
          '<i class="fas fa-ban" aria-hidden="true"></i> Voltar e editar',
        customClass: {
          confirmButton: 'confirmButtonModalClass',
          cancelButton: 'cancelButtonModalClass',
        },
      }).then(async result => {
        if (result.isConfirmed) {
          await callback(billet);
        }
      });
    } catch (error) {
      toast.error(error, {autoClose: 2500});
    }
  };

  const areValuesHigherThanAllowed = (billet: ICobranca) => {
    let {multa, juros, valor} = billet;

    multa = multa.replace(/\./g, '').replace(',', '.');
    juros = juros.replace(/\./g, '').replace(',', '.');
    valor = valor.replace(/\./g, '').replace(',', '.');

    if (multa === '' || multa === '0') return false;

    if (isPorcentagemJuros && parseFloat(juros) > 0.033) return true;

    if (isPorcentagemJuros && parseFloat(multa) > 2) return true;

    if (!isPorcentagemJuros) {
      const interestValueAllowed = parseFloat(valor) * 0.0033;
      if (parseFloat(juros) > interestValueAllowed) return true;
    }

    if (!isPorcentagemJuros) {
      const fineValueAllowed = parseFloat(valor) * 0.02;
      if (parseFloat(valor) > fineValueAllowed) return true;
    }

    return false;
  };

  const criarCobranca = async (novaCobranca: ICobranca) => {
    try {
      setIsLoading(true);
      const resultadoCadastro = await cadastrarCobranca(novaCobranca);
      Swal.fire({
        title: 'Cobrança criada com sucesso!',
        text:
          'Sua cobrança já foi emitida e está disponível para visualização.',
        showCancelButton: true,
        confirmButtonColor: ' #d33',
        cancelButtonColor: `${process.env.REACT_APP_COLOR_PRIMARY}`,
        confirmButtonText:
          '<i class="fas fa-times" aria-hidden="true"></i> Fechar',
        cancelButtonText:
          '<i class="fas fa-eye" aria-hidden="true"></i> Ver cobrança',
        customClass: {
          confirmButton: 'confirmButtonModalClass',
          cancelButton: 'cancelButtonModalClass',
        },
      }).then(alertResponse => {
        if (alertResponse.isDismissed)
          history.push(`/ver-cobranca/${resultadoCadastro['cobrancas'][0].id}`);
      });
      if (url) {
        history.push('/busca-cobrancas');
      } else {
        handleResetFormCat();
        setTipoBoleto(tipoBoleto);
        setIsLoading(false);
        setQtdDiasAntesDoVencimento(0);
      }
    } catch (error) {
      setIsLoading(false);
      handleResetFormCat();
      setTipoBoleto(tipoBoleto);
      setQtdDiasAntesDoVencimento(0);
      toast.error(error, {autoClose: 2500});
    }
  };

  useEffect(() => {
    if (clienteInfos) {
      setClienteInfosLoaded(true);
    }
  }, [clienteInfos]);

  if (updateTable) {
    setIsLoading(true);
    loadClient();
    listarTodasCategorias().then(() => setIsLoading(false));
    setUpdateTable(!updateTable);
  }

  const redirect = () => {
    history.push('/dashboard');
  };

  const getPossuiPix = async () => {
    Promise.all([
      verificarChavePix(),
      getVerificacaoDocumento(),
      getDadosPlanoContaFinanceira(),
    ])
      .then(results => {
        const dadosPlanoContaFinanceira = results[2];
        const possuiPix = results[0];
        const isDocumentacaoValida = results[1];

        if (
          (dadosPlanoContaFinanceira &&
            dadosPlanoContaFinanceira.maxTransactionsMonthlyRequested >=
              dadosPlanoContaFinanceira.maxTransactionsMonthly) ||
          (dadosPlanoContaFinanceira &&
            dadosPlanoContaFinanceira.dailyTransactionLimitRequested >=
              dadosPlanoContaFinanceira.dailyTransactionLimit)
        ) {
          setAvailable(false);
          showModalWarning(
            'Limite do plano ultrapassado',
            '<p>O limite do seu plano diário ou mensal não comporta a emissão deste lote, aumente seu limite através do atendimento!</p>',
            redirect
          );
        }

        let html = '';
        if (!possuiPix) {
          html += `<li> Para emissão de cobranças você deve ter uma chave PIX cadastrada no seu aplicativo <strong>${process.env.REACT_APP_NOME_PARCEIRO}</strong>
        </li>`;
        }
        if (!isDocumentacaoValida) {
          html += `<li> Para emissão de cobranças você deve ter a documentação aprovada no seu aplicativo ${process.env.REACT_APP_NOME_PARCEIRO}
        </li>`;
        }
        if (html) {
          setAvailable(false);
          showModalWarning(
            'Atenção! Você possui algumas pendências',
            html,
            redirect
          );
        } else {
          setAvailable(true);
        }
      })
      .catch(() => {
        setAvailable(false);
      });
  };

  useEffect(() => {
    getPossuiPix();
    return () => {};
  }, []);

  return (
    <UIContainer>
      <div className="d-flex bd-highlight titleAndButton">
        <div className="p-2 flex-grow-1 bd-highlight">
          <Titulo>Emitir Cobrança</Titulo>
        </div>
      </div>

      {modalIsVisible ? (
        <ModalSearchName
          onClose={() => setModalIsVisible(false)}
          sizeColMdInput={8}
          getClientId={(value: string) => setClientId(value)}
          setUpdateTable={setUpdateTable}
        />
      ) : null}

      <CardSection title="Informações do cliente">
        {clienteInfosLoaded ? (
          <span className="form-nova-cobranca__label">
            {clienteInfos.nome} | {cpfcnpjMask(clienteInfos.cpfCnpj)}
            <br />
            <br />
            <Button
              className="btn-custom btn-save p-2 flex-grow-2 bd-highlight "
              variant="custom"
              onClick={() => setModalIsVisible(true)}
            >
              <FaSearch size={13} className="mr-1" />
              Alterar cliente
            </Button>
          </span>
        ) : (
          <Button
            className="btn-custom btn-save p-2 flex-grow-2 bd-highlight "
            variant="custom"
            onClick={() => setModalIsVisible(true)}
          >
            <FaSearch size={13} className="mr-1" />
            Buscar cliente
          </Button>
        )}
      </CardSection>

      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <form name="form-nova-cobranca" onSubmit={handleSubmit(onSubmit)}>
          <div className="sessao-inf-gerais">
            <CardSection title="Tipo de cobrança">
              <div className="sessao-inf-gerais__opcoes-cobranca form-row ">
                <div className="sessao-inf-gerais__opcoes-cobranca__tipo ">
                  <InputRadioDefault
                    name={radioBilletNameOption}
                    id={radioBilletNameOption + 1}
                    onClick={() => {
                      setarTipoBoleto(TipoCobranca.AVULSA);
                    }}
                    defaultChecked={tipoBoleto === TipoCobranca.AVULSA}
                    inputRef={register()}
                    className="sessao-inf-gerais__opcoes-cobranca-tipo-item"
                    classLabel="form-nova-cobranca__label"
                  >
                    Avulso
                  </InputRadioDefault>
                  <div
                    data-tooltip="Cobrança somente para um mês."
                    className="--tooltip"
                  >
                    <FaQuestionCircle className="form-nova-cobranca__icon" />
                  </div>
                </div>
                <div className="sessao-inf-gerais__opcoes-cobranca__tipo">
                  <InputRadioDefault
                    name={radioBilletNameOption}
                    id={radioBilletNameOption + 2}
                    onClick={() => {
                      setarTipoBoleto(TipoCobranca.PARCELADA);
                    }}
                    inputRef={register()}
                    className="sessao-inf-gerais__opcoes-cobranca-tipo-item"
                    defaultChecked={tipoBoleto === TipoCobranca.PARCELADA}
                    classLabel="form-nova-cobranca__label"
                  >
                    Parcelado
                  </InputRadioDefault>
                  <div
                    data-tooltip="Cobrança que poderá ser dividida em parcelas."
                    className="--tooltip"
                  >
                    <FaQuestionCircle className="form-nova-cobranca__icon" />
                  </div>
                </div>
              </div>
            </CardSection>
          </div>

          {tipoBoleto && (
            <>
              <CardInterno
                categorias={categorias}
                CobrancasCategorias={CobrancasCategorias}
                errors={errors}
                register={register}
                control={control}
                setUpdateTable={setUpdateTable}
                key={tipoBoleto}
              />

              {tipoBoleto === TipoCobranca.AVULSA && (
                <CardClienteAvulsa
                  register={register}
                  setValorCobranca={setValorCobranca}
                  setDataEnvio={setDataEnvio}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  planoFinanceira={planoFinanceira}
                  setHasLimit={setHasLimit}
                  setIsLoadingLimit={setIsLoadingLimit}
                  setCobrarPorEmail={setCobrarPorEmail}
                />
              )}

              {tipoBoleto === TipoCobranca.PARCELADA && (
                <CardClienteParcelada
                  setValorCobranca={setValorCobranca}
                  register={register}
                  control={control}
                  errors={errors}
                  vlTotalCalculado={vlTotalCalculado}
                  setVlParcelas={setVlParcelas}
                  setNumParcelas={setNumParcelas}
                  isEnvioImediato={isEnvioImediato}
                  setIsEnvioImediato={setIsEnvioImediato}
                  setQtdDiasAntesDoVencimento={setQtdDiasAntesDoVencimento}
                  qtdDiasAntesDoVencimento={qtdDiasAntesDoVencimento}
                  planoFinanceira={planoFinanceira}
                  setHasLimit={setHasLimit}
                  getValues={getValues}
                  setIsLoadingLimit={setIsLoadingLimit}
                  setCobrarPorEmail={setCobrarPorEmail}
                />
              )}

              <CardSection title="Informações Juros e Desconto">
                <CardJurosMulta
                  register={register}
                  errors={errors}
                  setValorDesconto={setValorDesconto}
                  key={tipoBoleto}
                  setDiasAmaisParaDesconto={setDiasAmaisParaDesconto}
                  setIsPorcentagemDesconto={setIsPorcentagemDesconto}
                  setIsPorcentagemJuros={setIsPorcentagemJuros}
                />
              </CardSection>

              <div className="box-tax py-3">
                <label>
                  {planoFinanceira &&
                    'Para esta operação será cobrada uma tarifa de ' +
                      formataDinheiro(planoFinanceira.taxValue)}
                </label>
              </div>

              <div className="container-bottoes col-md-12">
                <div className="button-salvar-cobranca">
                  {
                    <Button
                      name="btnSalvar"
                      className="btn-custom btn-save"
                      type="submit"
                      onClick={() => setStateUrl(true)}
                      variant="custom"
                      disabled={isLoadingLimit || !clientId}
                    >
                      <FaRegSave size={13} className="mr-1" />
                      Salvar
                    </Button>
                  }
                </div>
              </div>
            </>
          )}
        </form>
      )}
    </UIContainer>
  );
};
export default DadosBoleto;
