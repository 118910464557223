import React, {useEffect, useState} from 'react';
import './styles.scss';
import {Table} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {
  FaUserPlus,
  FaSearch,
  FaBroom,
  FaSortAlphaDown,
  FaSortAlphaUpAlt,
} from 'react-icons/fa';
import {toast} from 'react-toastify';

import Swal from 'sweetalert2';

import {
  checkIfThereIsClientsRegistered,
  deleteGroupApi,
  listGroups,
} from '../../api/cliente-api';
import {IClientGroup} from '../../models/client-group.model';
import {PaginationModel} from '../../models/pagination-model';
import noEmptyMask from '../../utils/masks/noEmptyMask';
import BtnDefault from '../Buttons/BtnDefault';
import InputDefault from '../Inputs/InputDefault';
import ModalDefault from '../ModalDefault';
import PaginationDefault from '../PaginationDefault';
import SpinnerLoader from '../Spinner';
import TableRowsClientGroup from '../TableRows/TableRowsClientGroup';
import Titulo from '../Titulo';
import UIContent from '../UI/Content';
import ModalEditClientAddClients from './ModalClientGroupAddClients';
import ModalEditClientGroup from './ModalClientGroupEdit';

export enum ModalEnumClientGroup {
  'ADD_OR_REMOVE_CLIENTS',
  'EDIT_GROUP',
}

const MainClientGroup: React.FC = () => {
  const [groups, setGroups] = useState<IClientGroup[]>([]);
  const [updateTable, setUpdateTable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [groupName, setGroupName] = useState<string>('');
  const [cpfCnpj, setCpfCnpj] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [showEditGroupModal, setShowEditGroupModal] = useState(false);
  const [showAddClientsGroupModal, setShowAddClientsGroupModal] = useState(
    false
  );
  const [groupId, setGroupId] = useState<string | undefined>();
  const [showModalViewOrEdit, setShowModalViewOrEdit] = useState(false);
  const [thereIsClient, setThereIsClient] = useState<boolean>(true);
  const [orderSort, setOrderSort] = useState(true);

  const {handleSubmit, reset, register} = useForm();

  if (updateTable) {
    setShowEditGroupModal(false);
    setShowAddClientsGroupModal(false);
    listAllGroups(currentPage);
    setUpdateTable(!updateTable);
  }

  const handleCloseModal = () => {
    setShowEditGroupModal(false);
  };

  async function listAllGroups(pageNumber: number, order: string) {
    let paginaAtual = 0;

    if (pageNumber > 0) {
      paginaAtual = pageNumber - 1;
    }

    setIsLoading(true);
    const allGroups: PaginationModel = await listGroups(
      paginaAtual,
      limit,
      groupName,
      order
    );
    setGroups(allGroups.data);
    setTotalCount(allGroups.totalCount);
    setCurrentPage(pageNumber);
    setIsLoading(false);
  }

  useEffect(async () => {
    const order = orderSort ? 'ASC' : 'DESC';
    setIsLoading(true);
    listAllGroups(currentPage, order);

    setThereIsClient(await checkIfThereIsClientsRegistered());
    setIsLoading(false);
  }, [orderSort]);

  async function deleteGroup(id: string) {
    try {
      Swal.fire({
        title: 'Tem certeza que deseja excluir?',
        text: 'Ao confirmar, o grupo será excluído!',
        showCancelButton: true,
        confirmButtonText:
          '<i class="fas fa-trash fa-sm" aria-hidden="true"></i> Excluir',
        cancelButtonText:
          '<i class="fas fa-ban" aria-hidden="true"></i> Cancelar',
        customClass: {
          confirmButton: 'confirmButtonModalClass',
          cancelButton: 'cancelButtonModalClass',
        },
      }).then(async result => {
        if (result.value) {
          try {
            setIsLoading(true);
            await deleteGroupApi(id);
            toast.success('Grupo excluído com sucesso!', {autoClose: 2500});
            setIsLoading(false);
            if (groups.length - 1 > 0) {
              const order = orderSort ? 'ASC' : 'DESC';
              listAllGroups(currentPage, order);
            } else {
              const order = orderSort ? 'ASC' : 'DESC';
              listAllGroups(currentPage - 1, order);
              setCurrentPage(currentPage - 1);
            }
          } catch (e) {
            setIsLoading(false);
          }
        }
      });
    } catch (error) {
      toast.error(error, {autoClose: 2500});
    }
  }

  const filtrar = () => {
    const order = orderSort ? 'ASC' : 'DESC';
    listAllGroups(currentPage, order);
  };

  const clearFilter = () => {
    if (groupName || cpfCnpj || email) {
      reset();
      setGroupName('');
      setCpfCnpj('');
      setEmail('');
      return;
    }
    const order = orderSort ? 'ASC' : 'DESC';
    listAllGroups(1, order);
  };

  const showModal = (
    id: string,
    showMenu: boolean,
    type: ModalEnumClientGroup
  ) => {
    setGroupId(id);

    switch (type) {
      case ModalEnumClientGroup.EDIT_GROUP:
        setShowEditGroupModal(true);
        setShowModalViewOrEdit(showMenu);
        return;

      case ModalEnumClientGroup.ADD_OR_REMOVE_CLIENTS:
        setShowAddClientsGroupModal(true);
        setShowModalViewOrEdit(showMenu);
        return;
    }
  };

  return (
    <UIContent>
      <div className="d-flex bd-highlight titleAndButton">
        <div className="p-2 flex-grow-1 bd-highlight">
          <Titulo> Grupos de Clientes </Titulo>
        </div>
      </div>
      <form onSubmit={handleSubmit(filtrar)}>
        <div className="form-row mb-5">
          <div className="form-group col-md-4">
            <InputDefault
              name="nome"
              type="text"
              placeholder="Nome do grupo"
              id="input-nome-cliente"
              onChange={e => {
                setGroupName(e.target.value);
              }}
              value={groupName}
              inputMaxLength="300"
              inputRef={register({})}
              mask={noEmptyMask}
            />
          </div>
        </div>
        <div className="row">
          <div className="d-flex justify-content-start">
            <BtnDefault
              name="btn-buscar"
              className="btn-custom btn-save ml-1"
              id="btn-buscar"
              type="submit"
              variantClass="custom"
            >
              <FaSearch size={12} className="mr-1" />
              Buscar
            </BtnDefault>
            <BtnDefault
              action={clearFilter}
              name="btn-limpar"
              id="btn-limpar"
              className="btn-custom btn-save p-2 flex-grow-1 bd-highlight"
              variantClass="custom"
            >
              <FaBroom size={12} className="mr-1" />
              Limpar filtros
            </BtnDefault>
          </div>
        </div>
      </form>

      <ModalDefault
        nome="client-group"
        icon={FaUserPlus}
        variantClass="btn-purple btn-purple--round"
        setUpdateTable={setUpdateTable}
        booleanUtil={thereIsClient}
      >
        Novo Grupo
      </ModalDefault>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <Table responsive hover>
          <thead className="table-header">
            <tr>
              <th>
                <div
                  className="thContainerActive"
                  onClick={() => setOrderSort(!orderSort)}
                >
                  {orderSort ? (
                    <FaSortAlphaDown size={20} />
                  ) : (
                    <FaSortAlphaUpAlt size={20} />
                  )}
                  Nome do Grupo
                </div>
              </th>
              <th>
                <div className="th">Descrição</div>
              </th>
              <th>
                <div className="th">Clientes</div>
              </th>
              <th>
                <div className="th">Ações</div>
              </th>
            </tr>
          </thead>

          <tbody>
            {groups &&
              groups.map((group: IClientGroup, index) => {
                return (
                  <TableRowsClientGroup
                    clientGroup={group}
                    showModal={showModal}
                    key={index}
                    deleteCall={deleteGroup}
                  />
                );
              })}
          </tbody>
        </Table>
      )}
      {!isLoading && groups !== undefined && groups.length === 0 && (
        <div className="msg-table ">
          <p>Nenhum grupo cadastrado.</p>
        </div>
      )}
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <PaginationDefault
          currentPage={currentPage}
          totalCount={totalCount}
          limit={limit}
          action={(value: number) =>
            listAllGroups(value, orderSort ? 'ASC' : 'DESC')
          }
          totalNaPagina={groups.length}
        />
      )}
      {showEditGroupModal ? (
        <ModalEditClientGroup
          icon={FaUserPlus}
          variantClass="btn-purple btn-purple--round"
          setUpdateTable={setUpdateTable}
          id={groupId}
          showModalCliente={showEditGroupModal}
          handleCloseCliente={handleCloseModal}
          showModalViewOrEdit={showModalViewOrEdit}
        />
      ) : (
        ''
      )}
      {showAddClientsGroupModal ? (
        <ModalEditClientAddClients
          icon={FaUserPlus}
          variantClass="btn-purple btn-purple--round"
          setUpdateTable={setUpdateTable}
          id={groupId}
          showModalCliente={showAddClientsGroupModal}
          handleCloseCliente={handleCloseModal}
        />
      ) : (
        ''
      )}
    </UIContent>
  );
};

export default MainClientGroup;
