export const shippingOptions = [
  {value: 'RECEBIDO', label: 'Recebido'},
  {value: 'ENVIADO', label: 'Enviado'},
  {value: 'PENDENTE', label: 'Pendente'},
];

export const statusOptions = [
  {value: 'ATIVO', label: 'Ativo'},
  {
    value: 'INATIVO PELO ADMINISTRADOR',
    label: 'Inativo',
  },
];

export const batchType = [
  {
    value: 'AVULSA',
    label: 'Avulsa',
  },
  {
    value: 'EM_LOTE',
    label: 'Em lote',
  },
];

export const statusCobrancaEnum = [
  {value: 'AGUARDANDO', label: 'Aguardando'},
  {value: 'EM_ABERTO', label: 'Em aberto'},
  {value: 'PAGA', label: 'Paga'},
  {value: 'EM_PROCESSAMENTO', label: 'Em processamento'},
  {value: 'VENCIDA', label: 'Vencida'},
  {value: 'CANCELADA', label: 'Cancelada'},

  {value: 'ERRO', label: 'Erro'},
  {value: 'FALHA_GERAR_COBRANCA', label: 'Falha ao tentar gerar a cobrança.'},
  {
    value: 'FALHA_ENVIO_PARA_CLIENTE',
    label: 'Falha ao tentar enviar para o cliente.',
  },
  {
    value: 'FALHA_CANCELAR_COBRANCA_WALLET',
    label: 'Falha ao tentar cancelar a cobrança na wallet.',
  },
];

export const coresPorStatus = {
  PENDENTE: 'text-danger',
  ENVIADO: 'text-warning',
  RECEBIDO: 'text-success',
  PAGO: 'text-success',
  EM_ABERTO: 'text-warning',
  CANCELADO: 'text-danger',
  VENCIDO: 'text-danger',
};

export const StatusEnvioMensagemEnum = [
  {value: 'PENDENTE', label: 'Pendente'},
  {value: 'ENVIADO', label: 'Enviado'},
  {value: 'RECEBIDO', label: 'Recebido'},
  {value: 'CANCELADO', label: 'Cancelado'},
];

export const TipoCobrancaEnum = [
  {value: 'AVULSA', label: 'Avulsa'},
  {value: 'PARCELADA', label: 'Parcelada'},
  {value: 'RECORRENTE', label: 'Recorrente'},
];

export const TipoJurosDesconto = [
  {value: 'PORCENTAGEM', label: 'Porcentagem'},
  {value: 'VALOR', label: 'Valor'},
];

export const TipoOptionsLogado = [
  {value: 'AUMENTO_DE_LIMITE', label: 'Aumento de Limite'},
  {value: 'PRIMEIRO_ACESSO', label: 'Primeiro Acesso'},
  {value: 'LOGIN', label: 'Login'},
  {value: 'BOLETO', label: 'Emissão de Boleto'},
  {value: 'CLIENTE', label: 'Cadastro de cliente'},
  {value: 'USUARIO', label: 'Cadastro de usuário'},
  {value: 'DADOS', label: 'Meus dados'},
  {value: 'ALTERAR_SENHA', label: 'Alterar senha'},
  {value: 'DASHBOARD', label: 'Dashboard'},
  {value: 'DUVIDA', label: 'Dúvida'},
  {value: 'SUGESTAO', label: 'Sugestão'},
];

export const TipoOptionsDeslogado = [
  {value: 'PRIMEIRO_ACESSO', label: 'Primeiro Acesso'},
  {value: 'LOGIN', label: 'Login'},
  {value: 'DUVIDA', label: 'Dúvida'},
  {value: 'SUGESTAO', label: 'Sugestão'},
];
