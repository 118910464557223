import React, {useEffect, useState} from 'react';
import {Table} from 'react-bootstrap';
import {Controller, useForm} from 'react-hook-form';
import {
  FaBroom,
  FaCalendar,
  FaSearch,
  FaSortAlphaDown,
  FaSortAlphaUpAlt,
  FaSortAmountDown,
  FaSortAmountUpAlt,
  FaSortNumericDown,
  FaSortNumericUpAlt,
} from 'react-icons/fa';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import {listarCobrancasDoLote} from '../../api';
import {autocompleteClientes} from '../../api/cliente-api';
import Accordion from '../../components/Accordion/Accordion';
import BtnDefault from '../../components/Buttons/BtnDefault';
import DatePickerBr from '../../components/DatePickerBr';
import InputNameSearch from '../../components/Inputs/InputNameSearch';
import InputSelect from '../../components/Inputs/InputSelect';
import PaginationDefault from '../../components/PaginationDefault';
import SpinnerLoader from '../../components/Spinner';
import TableRowsBatchCharges from '../../components/TableRows/TableRowsBatchCharges';
import Titulo from '../../components/Titulo';
import UIContainer from '../../components/UI/Container';
import UIContent from '../../components/UI/Content';
import {RouteParamModel} from '../../models/batch-charges-model';
import {IInputValue} from '../../models/forms-model';
import {shippingOptions, statusCobrancaEnum} from '../../shared/constantes';
import {statusCobranca} from '../../shared/enumMap';
import {BatchChargesOrderByEnum} from './enum/index';
import './styles.scss';

const BatchCharges: React.FC = () => {
  const {reset} = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nomeCliente, setNomeCliente] = useState<string>('');
  const [statusEnvio, setStatusEnvio] = useState<string | undefined>(undefined);
  const [statusEnvioIsNull, setStatusEnvioAsNull] = useState<boolean>(false);
  const [statusPagamento, setStatusPagamento] = useState<string | undefined>(
    undefined
  );
  const [statusPagamentoIsNull, setStatusPagamentoAsNull] = useState(false);
  const [nameSearchValue, setNameSearchValue] = useState<IInputValue>(null);
  const [clienteId, setClienteId] = useState('');

  const [cobrancas, setCobrancas] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [dtEmissao, setDtEmissao] = useState<Date | undefined>();
  const [dtVencimento, setDtVencimento] = useState<Date | undefined>();

  const [expand, setExpand] = useState(false);

  const [idCobranca, setIdCobranca] = useState<string | undefined>();

  const [orderBySort, setOrderBySort] = useState(
    BatchChargesOrderByEnum.CLIENT
  );
  const [orderSort, setOrderSort] = useState(true);

  const [updateTable, setUpdateTable] = useState(false);

  const {control} = useForm();

  const params = useParams<RouteParamModel>();

  const handlerExpandAccordion = () => {
    setExpand(!expand);
  };
  function loadClienteData(clienteSelected: IInputValue) {
    clienteSelected && setClienteId(clienteSelected.value);
    clienteSelected && setNomeCliente(clienteSelected.label.split('| ')[1]);
  }

  const resetForm = () => {
    const order = orderSort ? 'ASC' : 'DESC';
    reset();

    setDtVencimento(undefined);
    setDtEmissao(undefined);
    setNomeCliente('');
    advancedFilterClean();
    listar(currentPage, limit, order, orderBySort);
    setNameSearchValue(null);
  };

  const advancedFilterClean = () => {
    setStatusEnvioAsNull(true);
    setStatusPagamentoAsNull(true);

    setStatusEnvio(undefined);
    setStatusPagamento(undefined);
    setDtEmissao(undefined);
    setDtVencimento(undefined);
  };

  async function listar(
    pageNumber: number,
    limit: number,
    order: string,
    orderBy: BatchChargesOrderByEnum,
    nomeCliente?: string,
    statusEnvio?: string,
    statusCobranca?: string,
    dtEmissao?: string,
    dtVencimento?: string
  ) {
    setIsLoading(true);
    const todasCobrancasDoLote = await listarCobrancasDoLote(
      pageNumber - 1 > 0 ? pageNumber - 1 : 0,
      limit,
      params.id,
      order,
      orderBy,
      nomeCliente,
      statusEnvio,
      statusCobranca,
      dtEmissao,
      dtVencimento
    );

    setCobrancas(todasCobrancasDoLote.data.data);
    setTotalCount(todasCobrancasDoLote.data.totalCount);
    setCurrentPage(pageNumber);
    setIsLoading(false);
  }

  useEffect(() => {
    const order = orderSort ? 'ASC' : 'DESC';
    listar(currentPage, limit, order, orderBySort);
  }, [currentPage, limit, orderSort, orderBySort]);

  if (updateTable) {
    const order = orderSort ? 'ASC' : 'DESC';
    listar(currentPage, limit, order, orderBySort);
    setUpdateTable(!updateTable);
  }
  const validateDates = () => {
    if (new Date(dtVencimento) < new Date(dtEmissao)) {
      toast.warning(
        'Por favor, Insira uma data de vencimento superior ou igual a data de emissão.',
        {
          autoClose: 2500,
        }
      );
      return false;
    }
    return true;
  };

  const filtrar = async () => {
    const order = orderSort ? 'ASC' : 'DESC';
    validateDates();
    await listar(
      currentPage,
      limit,
      order,
      orderBySort,
      nomeCliente,
      statusEnvio,
      statusPagamento,
      dtEmissao,
      dtVencimento
    );
  };

  return (
    <UIContainer>
      <UIContent>
        <div className="d-flex bd-highlight titleAndButton">
          <div className="p-2 flex-grow-1 bd-highlight">
            <Titulo> Cobranças do Lote </Titulo>
          </div>
        </div>

        <form>
          <div className="form-row ml-2">
            <div className="form-group col-md-4">
              <InputNameSearch
                find="clientes"
                name="cliente-boleto"
                id="input-buscar-cliente"
                placeholder="Nome do Cliente"
                inputMaxLength="100"
                promiseOptions={autocompleteClientes}
                setFieldValue={inputValue =>
                  setNameSearchValue(loadClienteData(inputValue))
                }
                sizeColMdInput={16}
                value={nameSearchValue}
              />
            </div>
            <div className="form-group col-md-4">
              <Controller
                control={control}
                name="status-envio"
                defaultValue={''}
                render={({onChange, value, ref}) => (
                  <InputSelect
                    placeholder="Status Envio"
                    inputRef={ref}
                    classNamePrefix="addl-class"
                    options={shippingOptions}
                    value={
                      !statusEnvioIsNull
                        ? shippingOptions.find(c => c.value === value)
                        : statusEnvioIsNull
                    }
                    onChange={val => {
                      onChange(val.value);
                      setStatusEnvio(val.value);
                      setStatusEnvioAsNull(false);
                    }}
                  />
                )}
              />
            </div>

            <div className="form-group col-md-4">
              <Controller
                control={control}
                name="status-pagamento"
                defaultValue={null}
                render={({onChange, value, ref}) => (
                  <InputSelect
                    placeholder="Status Cobrança"
                    inputRef={ref}
                    classNamePrefix="addl-class"
                    options={statusCobrancaEnum}
                    value={
                      statusPagamentoIsNull
                        ? statusPagamentoIsNull
                        : statusCobrancaEnum.find(c => c.value === value)
                    }
                    onChange={val => {
                      onChange(val.value);
                      setStatusPagamento(val.value);
                      setStatusPagamentoAsNull(false);
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="form-group col-md-12">
            <Accordion
              isExpand={expand}
              title={!expand ? 'Mais filtros' : 'Menos filtros'}
              actionAccordion={handlerExpandAccordion}
            >
              <div className="form-row">
                <div className="form-group col-md-3 boleto-datapicker">
                  <DatePickerBr
                    placeholder="Emissão"
                    selected={dtEmissao}
                    onChange={(date: Date) => setDtEmissao(date)}
                    icon={FaCalendar}
                    classIcon="calendar-position "
                  />
                </div>

                <div className="form-group col-md-3 boleto-datapicker">
                  <DatePickerBr
                    placeholder="Vencimento"
                    selected={dtVencimento}
                    onChange={date => setDtVencimento(date)}
                    icon={FaCalendar}
                    classIcon="calendar-position "
                  />
                </div>
              </div>
            </Accordion>
          </div>

          <div className="d-flex justify-content-start ml-2">
            <BtnDefault
              name="btn-buscar"
              className="btn-custom btn-save"
              id="btn-buscar"
              type="button"
              action={filtrar}
              variantClass="custom"
            >
              <FaSearch size={12} className="mr-1" />
              Buscar
            </BtnDefault>

            <BtnDefault
              action={resetForm}
              type="reset"
              className="btn-custom btn-save"
              variantClass="custom"
            >
              <FaBroom size={12} className="mr-1" />
              Limpar Filtros
            </BtnDefault>
          </div>
        </form>
        {isLoading && !cobrancas ? (
          <SpinnerLoader />
        ) : (
          <Table responsive hover>
            <thead className="table-header">
              <tr>
                <th>
                  <div
                    className={
                      orderBySort === BatchChargesOrderByEnum.CLIENT
                        ? 'thContainerActive'
                        : 'thContainer'
                    }
                    onClick={() => {
                      setOrderSort(!orderSort);
                      setOrderBySort(BatchChargesOrderByEnum.CLIENT);
                    }}
                  >
                    {orderSort &&
                    orderBySort === BatchChargesOrderByEnum.CLIENT ? (
                      <FaSortAlphaDown size={20} />
                    ) : orderBySort !== BatchChargesOrderByEnum.CLIENT ? (
                      <FaSortAlphaDown size={20} />
                    ) : (
                      <FaSortAlphaUpAlt size={20} />
                    )}
                    Cliente
                  </div>
                </th>

                <th>
                  <div
                    className={
                      orderBySort === BatchChargesOrderByEnum.ISSUE_DATE
                        ? 'thContainerActive'
                        : 'thContainer'
                    }
                    onClick={() => {
                      setOrderSort(!orderSort);
                      setOrderBySort(BatchChargesOrderByEnum.ISSUE_DATE);
                    }}
                  >
                    {orderSort &&
                    orderBySort === BatchChargesOrderByEnum.ISSUE_DATE ? (
                      <FaSortAmountDown size={20} />
                    ) : orderBySort !== BatchChargesOrderByEnum.ISSUE_DATE ? (
                      <FaSortAmountDown size={20} />
                    ) : (
                      <FaSortAmountUpAlt size={20} />
                    )}
                    Emissão
                  </div>
                </th>

                <th>
                  <div
                    className={
                      orderBySort === BatchChargesOrderByEnum.DUE_DATE
                        ? 'thContainerActive'
                        : 'thContainer'
                    }
                    onClick={() => {
                      setOrderSort(!orderSort);
                      setOrderBySort(BatchChargesOrderByEnum.DUE_DATE);
                    }}
                  >
                    {orderSort &&
                    orderBySort === BatchChargesOrderByEnum.DUE_DATE ? (
                      <FaSortAmountDown size={20} />
                    ) : orderBySort !== BatchChargesOrderByEnum.DUE_DATE ? (
                      <FaSortAmountDown size={20} />
                    ) : (
                      <FaSortAmountUpAlt size={20} />
                    )}
                    Vencimento
                  </div>
                </th>

                <th>
                  <div>Status Envio</div>
                </th>

                <th>
                  <div
                    className={
                      orderBySort === BatchChargesOrderByEnum.VALUE
                        ? 'thContainerActive'
                        : 'thContainer'
                    }
                    onClick={() => {
                      setOrderSort(!orderSort);
                      setOrderBySort(BatchChargesOrderByEnum.VALUE);
                    }}
                  >
                    {orderSort &&
                    orderBySort === BatchChargesOrderByEnum.VALUE ? (
                      <FaSortNumericDown size={20} />
                    ) : orderBySort !== BatchChargesOrderByEnum.VALUE ? (
                      <FaSortNumericDown size={20} />
                    ) : (
                      <FaSortNumericUpAlt size={20} />
                    )}
                    Valor
                  </div>
                </th>

                <th>
                  <div>Status Cobrança</div>
                </th>

                <th>
                  <div
                    className={
                      orderBySort === BatchChargesOrderByEnum.PAYED_VALUE
                        ? 'thContainerActive'
                        : 'thContainer'
                    }
                    onClick={() => {
                      setOrderSort(!orderSort);
                      setOrderBySort(BatchChargesOrderByEnum.PAYED_VALUE);
                    }}
                  >
                    {orderSort &&
                    orderBySort === BatchChargesOrderByEnum.PAYED_VALUE ? (
                      <FaSortNumericDown size={20} />
                    ) : orderBySort !== BatchChargesOrderByEnum.PAYED_VALUE ? (
                      <FaSortNumericDown size={20} />
                    ) : (
                      <FaSortNumericUpAlt size={20} />
                    )}
                    Valor Pago
                  </div>
                </th>

                <th>
                  <div
                    className={
                      orderBySort === BatchChargesOrderByEnum.PAYED_AT
                        ? 'thContainerActive'
                        : 'thContainer'
                    }
                    onClick={() => {
                      setOrderSort(!orderSort);
                      setOrderBySort(BatchChargesOrderByEnum.PAYED_AT);
                    }}
                  >
                    {orderSort &&
                    orderBySort === BatchChargesOrderByEnum.PAYED_AT ? (
                      <FaSortAmountDown size={20} />
                    ) : orderBySort !== BatchChargesOrderByEnum.PAYED_AT ? (
                      <FaSortAmountDown size={20} />
                    ) : (
                      <FaSortAmountUpAlt size={20} />
                    )}
                    Pago Em
                  </div>
                </th>

                <th>
                  <div>Ações</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {cobrancas &&
                cobrancas.map((cobranca, index) => {
                  return (
                    <TableRowsBatchCharges
                      cobranca={cobranca}
                      updateTableFunction={() => setUpdateTable(true)}
                      key={index}
                    />
                  );
                })}
            </tbody>
          </Table>
        )}
        {!isLoading && cobrancas !== undefined && cobrancas.length === 0 && (
          <div className="msg-table ">
            <p>Nenhum dado para ser exibido com esses parâmetros.</p>
          </div>
        )}
        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <div className="pagination justify-content-center">
            <PaginationDefault
              totalNaPagina={cobrancas.length}
              currentPage={currentPage}
              totalCount={totalCount}
              limit={limit}
              action={(value: number) => setCurrentPage(value)}
            />
            <select
              name="amount"
              id="amount"
              className="pagination-box"
              onChange={event => {
                setLimit(parseInt(event.target.value));
              }}
              value={limit}
            >
              <option value="30">30</option>
              <option value="45">45</option>
              <option value="60">60</option>
              <option value="75">75</option>
              <option value="90">90</option>
            </select>
          </div>
        )}
      </UIContent>
    </UIContainer>
  );
};

export default BatchCharges;
