import React, {LegacyRef, useRef} from 'react';
import './styles.scss';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {registerLocale} from 'react-datepicker';
import {FieldErrors, useForm} from 'react-hook-form';
import {IconBaseProps} from 'react-icons';

import pt from 'date-fns/locale/pt-BR';
import _ from 'lodash/fp';

import Errors from '../Errors';

registerLocale('pt', pt);
interface DatePickerBrProps {
  name?: string;
  className?: string;
  id?: string;
  placeholder?: string;
  dataMaxima?: Date;
  dataMinima?: Date;
  selected?: Object;
  onChange?: Function;
  onBlur?: Function;
  icon?: React.ComponentType<IconBaseProps>;
  classIcon?: string;
  required?: boolean;
  dataInicial?: Date;
  inputRef?: LegacyRef<HTMLSelectElement>;
  erros?: FieldErrors;
  receivedDate?: string;
  isDisabled?: boolean;
}

const DatePickerBr: React.FC<DatePickerBrProps> = ({
  className = 'input-default ',
  name,
  id,
  placeholder,
  dataMaxima = new Date(2100, 0, 1),
  dataMinima,
  selected,
  onChange,
  onBlur,
  icon: Icon,
  classIcon,
  required = false,
  dataInicial,
  inputRef,
  erros,
  receivedDate,
  isDisabled = false,
}) => {
  const inputSelect = useRef(undefined);
  const {getValues} = useForm();
  const value = getValues(name);
  if (
    !value &&
    erros &&
    ((erros?.vencimento && name === 'vencimento') ||
      (name === 'dataLimitePagamento' && erros.dataLimitePagamento)) &&
    !erros.descricao &&
    !erros.valor
  ) {
    if (inputSelect && inputSelect.current) {
      inputSelect.current.input.scrollIntoView({block: 'center'});
    }
  }

  return (
    <>
      <span className={classIcon}>
        {!selected ? Icon && <Icon size={15} /> : ''}
      </span>
      <DatePicker
        className={
          (name === 'vencimento' && erros?.vencimento) ||
          (name === 'dataLimitePagamento' && erros.dataLimitePagamento)
            ? 'error-focus-datepicker ' + className
            : className
        }
        locale="pt"
        dateFormat="dd/MM/yyyy"
        maxDate={dataMaxima}
        minDate={dataMinima}
        name={name}
        id={id}
        placeholderText={placeholder}
        showYearDropdown
        selected={selected}
        onChange={onChange}
        onBlur={onBlur}
        inputRef={inputRef}
        ref={inputSelect}
        autoComplete="off"
        disabled={isDisabled}
      />
      <Errors erros={erros} name={name} />
    </>
  );
};
export default DatePickerBr;
