import {LegacyRef} from 'react';
import {Control, Controller, FieldErrors, FieldValues} from 'react-hook-form';
import Select from 'react-select';

import {INovoCliente} from '../../../models/cliente-model';
import Errors from '../../Errors';

interface IInputEstado {
  name: string;
  inputClassName: string;
  id?: string;
  isRequired: boolean;
  defaultValue?: Object;
  inputRef?: LegacyRef<HTMLInputElement>;
  erros?: FieldErrors;
  setEstado?: Function;
  onChange?: Function;
  control?: Control<INovoCliente | FieldValues>;
  isDisabledSelect?: boolean;
}

const InputEstado: React.FC<IInputEstado> = (props: IInputEstado) => {
  const estadosOptions = [
    {label: 'AC', value: 'AC'},
    {label: 'AL', value: 'AL'},
    {label: 'AP', value: 'AP'},
    {label: 'AM', value: 'AM'},
    {label: 'BA', value: 'BA'},
    {label: 'CE', value: 'CE'},
    {label: 'DF', value: 'DF'},
    {label: 'ES', value: 'ES'},
    {label: 'GO', value: 'GO'},
    {label: 'MA', value: 'MA'},
    {label: 'MT', value: 'MT'},
    {label: 'MS', value: 'MS'},
    {label: 'MG', value: 'MG'},
    {label: 'PA', value: 'PA'},
    {label: 'PB', value: 'PB'},
    {label: 'PR', value: 'PR'},
    {label: 'PE', value: 'PE'},
    {label: 'PI', value: 'PI'},
    {label: 'RJ', value: 'RJ'},
    {label: 'RN', value: 'RN'},
    {label: 'RS', value: 'RS'},
    {label: 'RO', value: 'RO'},
    {label: 'RR', value: 'RR'},
    {label: 'SC', value: 'SC'},
    {label: 'SP', value: 'SP'},
    {label: 'SE', value: 'SE'},
    {label: 'TO', value: 'TO'},
  ];

  return (
    <>
      <label className="form-label" htmlFor="enderecoEstado">
        Estado
      </label>
      {props.isRequired && <span className="asterisk ">*</span>}
      <Controller
        control={props.control}
        defaultValue={props.defaultValue ? props.defaultValue : null}
        rules={{required: props.isRequired}}
        name={props.name}
        render={({onChange, ref, value}) => (
          <Select
            id="enderecoEstado"
            value={value}
            inputRef={ref}
            classNamePrefix="react-select"
            onChange={val => {
              onChange(val);
            }}
            className={props.inputClassName + ' custom-react-select'}
            placeholder=""
            options={estadosOptions}
            isDisabled={props.isDisabledSelect}
          />
        )}
      />
      <Errors name={props.name} erros={props.erros}></Errors>
    </>
  );
};

export default InputEstado;
