import React from 'react';

import './styles.scss';
const UILoad: React.FC = () => {
  return (
    <div className="spinner">
      <span className="spinner__text">Carregando...</span>
      <div className="spinner__loading"></div>
    </div>
  );
};

export default UILoad;
