import React, {useState} from 'react';
import {Control, Controller, DeepMap, FieldError} from 'react-hook-form';
import {FaPlus} from 'react-icons/fa';

import _ from 'lodash/fp';

import CardSection from '../../../components/CardSection';
import CharacterCount from '../../../components/CharacterCount';
import InputSelect from '../../../components/Inputs/InputSelect';
import ModalDefault from '../../../components/ModalDefault';
import {ICategoria} from '../../../models/categoria-model';
import {ICobranca} from '../../../models/cobranca';
import {ItemSelectDto} from '../../../models/item-select';

import './styles.scss';
interface CardInternoProps {
  register;
  errors: DeepMap<ICobranca, FieldError>;
  categorias: ICategoria[];
  CobrancasCategorias: ItemSelectDto[];
  control: Control;
  setUpdateTable: Function;
}

const CardInterno: React.FC<CardInternoProps> = ({
  register,
  errors,
  categorias,
  CobrancasCategorias,
  control,
  setUpdateTable,
}) => {
  const [characterCount, setCharacterCount] = useState(300);
  const characterCounter = e => {
    e.target.value = e.target.value.replace(/^\s+/, '');
    setCharacterCount(300 - e.target.value.length);
  };

  return (
    <CardSection title="Informações Gerais">
      <div className="form-interno">
        <div className="form-row">
          <div className="form-group col-md-4">
            <label className="label-item-tabela" htmlFor="categoria-select">
              Categoria
            </label>

            <Controller
              control={control}
              defaultValue={null}
              rules={{required: false}}
              id="categoria-select"
              name="categoria"
              render={({onChange, value, ref}) => (
                <InputSelect
                  placeholder=""
                  inputRef={ref}
                  id="categoria-select"
                  classNamePrefix="addl-class"
                  name="categoria"
                  options={categorias}
                  value={CobrancasCategorias.find(c => c.value === value)}
                  onChange={(val: {value: any}) => {
                    onChange(val.value);
                  }}
                />
              )}
            />
          </div>
          <div className="form-group col-md-2 form-interno__modal">
            <ModalDefault
              nome="categorias"
              icon={FaPlus}
              variantClass="btn-purple btn-purple--round"
              setUpdateTable={setUpdateTable}
            >
              Nova categoria
            </ModalDefault>
          </div>
          <div className="form-group col-12">
            <label className="label-item-tabela" htmlFor="input-descricao">
              Descrição
            </label>
            <textarea
              className="col-md-12 text-area-default"
              name="descricao"
              id="input-descricao"
              placeholder="Informe os detalhes desta cobrança."
              onChange={characterCounter}
              maxLength={300}
            />
            <CharacterCount count={characterCount}></CharacterCount>
          </div>
        </div>
      </div>
    </CardSection>
  );
};

export default CardInterno;
