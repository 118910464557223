import React, {useState} from 'react';
import {useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import {IconBaseProps} from 'react-icons';

import {exibirCliente} from '../../../api/cliente-api';
import {ICliente} from '../../../models';
import {IFormContent} from '../../../models/forms-model';
import FormEditCliente from '../FormEditCliente';

import './styles.scss';

interface ModalEditarProps {
  id?: string;
  icon?: React.ComponentType<IconBaseProps>;
  variantClass?: string;
  formContent?: IFormContent;
  handleCloseCliente?: Function;
  showModalCliente?: boolean;
  setUpdateTable?: Function;
  showMenuModal?: boolean;
}

const ModalEditarCliente: React.FC<ModalEditarProps> = ({
  id,
  icon: Icon,
  setUpdateTable,
  handleCloseCliente,
  showModalCliente,
  showMenuModal,
}) => {
  const [cliente, setCliente] = useState<ICliente>();

  async function carregarCliente(id: string) {
    setCliente(await exibirCliente(id));
  }

  const updateTable = () => {
    setCliente(undefined);
    setUpdateTable(true);
  };
  const showPopoverClient = () => {
    const showPopover = document.querySelectorAll<HTMLElement>('.popover ')[0];

    if (showPopover) {
      if (showModalCliente) {
        showPopover.style.display = 'none';
      }
    }
  };
  useEffect(() => {
    carregarCliente(id);

    showPopoverClient();
  }, []);
  return (
    <>
      {cliente && (
        <Modal
          animation={false}
          show={showModalCliente}
          onHide={handleCloseCliente}
          backdrop="static"
          keyboard={true}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {Icon && <Icon size={20} className="modal-icone" />}
              {showMenuModal ? 'Editar cliente' : 'Ver cliente'}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <FormEditCliente
              cliente={cliente}
              handleCloseModal={handleCloseCliente}
              handleUpdateTable={updateTable}
              showEditOrView={showMenuModal}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default ModalEditarCliente;
