import React, {MouseEventHandler} from 'react';

import './styles.scss';

interface AccordionProps {
  title?: string;
  actionAccordion?: MouseEventHandler;
  isExpand?: boolean;
}
const Accordion: React.FC<AccordionProps> = ({
  children,
  title,
  isExpand,
  actionAccordion,
}) => {
  return (
    <div className="accordion-wrapper">
      <div
        className={`accordion__title ${isExpand ? 'open' : ''}`}
        onClick={actionAccordion}
      >
        {title}
      </div>
      <div className={`accordion__item ${!isExpand ? 'collapsed' : 'visible'}`}>
        <div className="accordion__content">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
