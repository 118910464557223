import {toast} from 'react-toastify';

import Swal from 'sweetalert2';
import '../../pages/DadosBoleto/styles.scss';

export async function showModalWarning(
  title: string,
  html: string,
  action?: Function
) {
  try {
    await Swal.fire({
      title: title,
      html: `<div class="sweet-alert-warnings">${html}</div>`,
      showCancelButton: false,
      confirmButtonColor: ' #d33',
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText:
        '<i class="fas fa-check fa-sm" aria-hidden="true"></i> OK',
      customClass: {
        confirmButton: 'confirmButtonModalClass',
        cancelButton: 'cancelButtonModalClass',
      },
    }).then(async result => {
      if (result.isConfirmed) {
        if (action) {
          action();
        }
      }
    });
  } catch (error: any) {
    toast.error(error);
  }
}
