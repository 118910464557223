import React from 'react';

import './styles.scss';
import MainBilletRule from '../../components/MainBillingRule';
import UIContainer from '../../components/UI/Container';

const BilletRule: React.FC = () => (
  <UIContainer>
    <MainBilletRule />
  </UIContainer>
);
export default BilletRule;
