import React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';

import Cookies from 'js-cookie';

import {useAuth} from '../../../src/context/usuario-context';

interface IRouteProps extends RouteProps {
  hasRole?: string;
}

const PrivateRoute: React.FC<IRouteProps> = (props: IRouteProps) => {
  const {isLogged, hasRole} = useAuth();

  if (Cookies.get('userActive')) {
    if (props.hasRole && !hasRole(props.hasRole)) {
      return (
        <Route {...props} component={() => <Redirect to="/acesso-negado" />} />
      );
    }
    if (!isLogged())
      return <Route {...props} component={() => <Redirect to="/" />} />;
    return <Route {...props} />;
  } else {
    return <Route {...props} component={() => <Redirect to="/" />} />;
  }
};

export default PrivateRoute;
