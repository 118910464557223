import React, {useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {IconBaseProps} from 'react-icons';

import FormBilletRule from '../MainBillingRule/FormBillingRule';
import FormCategoria from '../MainCategoria/FormCategoria';
import FormCadastroCliente from '../MainCliente/FormCadastroCliente';
import FormImportClients from '../MainCliente/FormImportClients';
import FormRegisterGroup from '../MainClientGroup/FormRegisterGroup';
import FormCadastroUsuario from '../MainUsuarios/FormCadastroUsuario';

import './styles.scss';

interface ModalDefaultProps {
  nome: string;
  icon?: React.ComponentType<IconBaseProps>;
  variantClass?: string;
  setUpdateTable?: Function;
  afterSave?: Function;
  booleanUtil?: boolean;
  className?: string;
  objectUtil?: any;
}

const ModalDefault: React.FC<ModalDefaultProps> = ({
  nome,
  children,
  icon: Icon,
  variantClass,
  setUpdateTable,
  afterSave,
  booleanUtil,
  className = 'button-new-group',
  objectUtil,
}) => {
  const [showCliente, setShowCliente] = useState(false);
  const [showClientGroup, setShowClientGroup] = useState(false);
  const [showImportClients, setShowImportClients] = useState(false);
  const [showCategoria, setShowCategoria] = useState(false);
  const [showUsuario, setShowUsuario] = useState(false);
  const [showUsuarioEditar, setShowUsuarioEditar] = useState(false);
  const [showCreateBilletRule, setShowCreateBilletRule] = useState(false);

  const handleShowCliente = () => setShowCliente(true);
  const handleCloseCliente = () => setShowCliente(false);

  const handleShowClientGroup = () => setShowClientGroup(true);
  const handleCloseClientGroup = () => setShowClientGroup(false);

  const handleShowImportClients = () => setShowImportClients(true);
  const handleCloseImportClients = () => setShowImportClients(false);

  const handleShowCategoria = () => setShowCategoria(true);
  const handleCloseCategoria = () => setShowCategoria(false);

  const handleShowUsuario = () => setShowUsuario(true);
  const handleCloseUsuario = () => setShowUsuario(false);

  const handleShowUsuarioEditar = () => setShowUsuarioEditar(true);
  const handleCloseUsuarioEditar = () => setShowUsuarioEditar(false);

  const handleShowCreateBilletRule = () => setShowCreateBilletRule(true);
  const handleCloseCreateBilletRule = () => setShowCreateBilletRule(false);

  function exibe() {
    if (nome === 'clientes') {
      handleShowCliente();
    }

    if (nome === 'client-group') {
      handleShowClientGroup();
    }

    if (nome === 'import-clients') {
      handleShowImportClients();
    }

    if (nome === 'categorias') {
      handleShowCategoria();
    }

    if (nome === 'usuarios') {
      handleShowUsuario();
    }

    if (nome === 'usuarios-editar') {
      handleShowUsuarioEditar();
    }

    if (nome === 'create-billet-rules') {
      handleShowCreateBilletRule();
    }
  }

  return (
    <>
      <div className={className}>
        <Button
          onClick={exibe}
          className={`btn-custom btn-wicon ${variantClass}`}
          variant="custom"
        >
          {Icon && <Icon size={18} />}
          {children}
        </Button>
      </div>

      <Modal
        animation={false}
        show={showCliente}
        onHide={handleCloseCliente}
        backdrop="static"
        keyboard={true}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {Icon && <Icon size={20} className="modal-icone" />}
            Cadastro Cliente
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormCadastroCliente
            handleCloseModal={setShowCliente}
            handleUpdateTable={setUpdateTable}
            afterSave={afterSave}
          />
        </Modal.Body>
      </Modal>

      <Modal
        animation={false}
        show={showClientGroup}
        onHide={handleCloseClientGroup}
        backdrop="static"
        keyboard={true}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {Icon && <Icon size={20} className="modal-icone" />}
            Adicionar Grupo de Clientes
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormRegisterGroup
            handleCloseModal={setShowClientGroup}
            handleUpdateTable={setUpdateTable}
            booleanUtil={booleanUtil}
          />
        </Modal.Body>
      </Modal>

      <Modal
        animation={false}
        show={showImportClients}
        onHide={handleCloseImportClients}
        backdrop="static"
        keyboard={true}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {Icon && <Icon size={20} className="modal-icone" />}
            Importar Planilha de Clientes
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormImportClients
            handleCloseModal={handleCloseImportClients}
            handleUpdateTable={setUpdateTable}
            booleanUtil={booleanUtil}
            objectUtil={objectUtil}
          />
        </Modal.Body>
      </Modal>

      <Modal
        animation={false}
        show={showCategoria}
        onHide={handleCloseCategoria}
        backdrop="static"
        keyboard={true}
        size="lg"
      >
        <Modal.Header closeButton className="modal__header">
          <Modal.Title>
            {Icon && <Icon size={20} className="modal-icone" />}
            Cadastro Categoria
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormCategoria
            handleCloseModal={setShowCategoria}
            handleUpdateTable={setUpdateTable}
          />
        </Modal.Body>
      </Modal>

      <Modal
        animation={false}
        show={showUsuario}
        onHide={handleCloseUsuario}
        backdrop="static"
        keyboard={true}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {Icon && <Icon size={20} className="modal-icone" />}
            Cadastro Usuário
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormCadastroUsuario
            handleCloseModal={setShowUsuario}
            handleUpdateTable={setUpdateTable}
          />
        </Modal.Body>
      </Modal>

      <Modal
        animation={false}
        show={showUsuarioEditar}
        onHide={handleCloseUsuarioEditar}
        backdrop="static"
        keyboard={true}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {Icon && <Icon size={20} className="modal-icone" />}
            Editar Usuário
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormCadastroUsuario
            handleCloseModal={setShowUsuarioEditar}
            handleUpdateTable={setUpdateTable}
          />
        </Modal.Body>
      </Modal>

      <Modal
        animation={false}
        show={showCreateBilletRule}
        onHide={handleCloseCreateBilletRule}
        backdrop="static"
        keyboard={true}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {Icon && <Icon size={20} className="modal-icone" />}
            Cadastro Regra de Lote
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormBilletRule
            handleCloseModal={setShowCreateBilletRule}
            handleUpdateTable={setUpdateTable}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalDefault;
