import axios from 'axios';
import Cookies from 'js-cookie';

import {Alert} from '../components/Alert';
import locationHome from '../utils/redirectLocation/redirectExpiresToken';
import locationPage403 from '../utils/redirectLocation/redirectPage403';
import {getRefreshToken} from './auth-api';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/recurrence/v1beta1/api`,
  headers: {
    apiKey: process.env.REACT_APP_API_KEY,
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

api.interceptors.request.use(async config => {
  const cookiesAuthentication = Cookies.get('access_token');
  if (cookiesAuthentication) {
    config.headers.Authorization = `Bearer ${cookiesAuthentication}`;
  }

  return config;
});

api.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalConfig = error.config;

    if (error.message !== undefined && error.message === 'Network Error') {
      Alert.warning(
        'Sistema indisponível, tente novamente mais tarde!',
        undefined
      );
    } else if (error.response !== undefined && error.response.status === 403) {
      Alert.warning(
        'Você não tem permissão para acessar este recurso.',
        undefined,
        () => locationPage403()
      );
    } else if (
      error.message !== undefined &&
      error.response.data.message === 'Error Term'
    ) {
      return Promise.reject(error);
    } else if (error.response.status === 401 && !originalConfig._retry) {
      const originalConfig = error.config;

      if (
        originalConfig.url !== '/' &&
        originalConfig.url !== 'auth/login-master' &&
        error.config.url !== 'auth/refresh-token' &&
        error.response &&
        error.response.data.message !== 'Por favor, efetue login novamente.'
      ) {
        //  Access Token expirou.
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          const refreshToken = Cookies.get('refresh_token');

          if (refreshToken) {
            Cookies.set('access_token', refreshToken);
          }

          try {
            const objRefreshToken = await getRefreshToken();
            Cookies.set('access_token', objRefreshToken.access_token);
            Cookies.set('refresh_token', objRefreshToken.refresh_token);
            return api(originalConfig);
          } catch (_error: any) {
            Alert.warning(
              'Por favor, efetue login novamente.',
              undefined,
              () => {
                if (originalConfig?.url.includes('/termos-de-uso')) {
                  locationHome('/acesso-u4crypto');
                } else {
                  locationHome('/');
                }
              }
            );
            removeCookiesUser();
            return Promise.reject(_error);
          }
        }

        return Promise.reject(error);
      }
    } else {
      Alert.error(error.response.data.message);
    }
    return Promise.reject(error);
  }
);

export default api;

export function removeCookiesUser() {
  Cookies.remove('access_token');
  Cookies.remove('refresh_token');
  Cookies.remove('userActive');
  Cookies.remove('user');
  return;
}
