import React from 'react';
import './styles.scss';

const UIModal = ({children, isOpen, onClickClose}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="ui-modal__overlay">
      <div className="ui-modal">{children}</div>
    </div>
  );
};

export default UIModal;
