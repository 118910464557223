import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {Controller, useForm} from 'react-hook-form';
import {FaArrowLeft, FaArrowRight, FaPlus} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

import {
  checkIfThereIsClientsRegistered,
  getGroupDetailsApi,
  listGroups,
} from '../../api';
import {
  getDadosPlanoContaFinanceira,
  getVerificacaoDocumento,
  verificarChavePix,
} from '../../api';
import {getDetailsRuleApi, listRulesApi} from '../../api';
import BtnDefault from '../../components/Buttons/BtnDefault';
import CardSection from '../../components/CardSection';
import CharacterCount from '../../components/CharacterCount';
import InputSelect from '../../components/Inputs/InputSelect';
import ModalDefault from '../../components/ModalDefault';
import SpinnerLoader from '../../components/Spinner';
import Titulo from '../../components/Titulo';
import UIContainer from '../../components/UI/Container';
import {ICliente} from '../../models';
import {
  IBillingBatch,
  IBillingBatchSecondStage,
} from '../../models/billing-batch.model';
import {
  BillingRuleType,
  InterestFineDiscountType,
} from '../../models/billing-rule-model';
import {IClientGroupSelectModel} from '../../models/client-group-select.model';
import {ICobranca} from '../../models/cobranca';
import {IPlanoContaFinanceira} from '../../models/conta-financeiro';
import {TipoCobranca} from '../../shared/enumMap';
import subtrDate from '../../utils/dates/formulas';
import {formataDinheiro} from '../../utils/formatMonetary';
import moneyMask, {newMoneyMask} from '../../utils/masks/moneyMask';
import {showModalWarning} from '../../utils/modalWarning';
import BatchSummary from '../BatchSummary';
import CardClienteAvulsa from '../DadosBoleto/CardClienteAvulsa';
import CardJurosMulta from '../DadosBoleto/CardJurosMulta';

import './styles.scss';
// eslint-disable-next-line import-helpers/order-imports
import Swal from 'sweetalert2';

interface DadosBoletoProps {
  ClienteId: string;
}

const BillingBatch: React.FC<DadosBoletoProps> = () => {
  const [clienteInfos, setClienteInfos] = useState<ICliente>();
  const [clienteInfosLoaded, setClienteInfosLoaded] = useState<boolean>(false);

  const history = useHistory();

  const {
    register,
    handleSubmit,
    control,
    errors,
    clearErrors,
    getValues,
    setValue,
  } = useForm<ICobranca>();

  const [numParcelas, setNumParcelas] = useState<number>(null);
  const [vlParcela, setVlParcelas] = useState<number>(1);
  const [vlTotalCalculado, setVlTotalCalculado] = useState<number>(1);

  const [tipoBoleto, setTipoBoleto] = useState<TipoCobranca>();
  const [smsIschecked, setSmsChecked] = useState<boolean>(false);
  const [emailIschecked, setEmailIschecked] = useState<boolean>(true);
  const [whatsappIschecked, setWhatsappChecked] = useState<boolean>(false);

  const [valorCobranca, setValorCobranca] = useState<number>(0);
  const [valorDesconto, setValorDesconto] = useState<number>(0);
  const [porcDescontoCalculado, setPorcDescontoCalculado] = useState<number>(
    null
  );

  const [isPorcentagemJuros, setIsPorcentagemJuros] = useState<boolean>(false);

  const [envioImediato, setEnvioImediato] = useState<boolean>(true);
  const [diaExatoDeEnvio, setDiaExatoDeEnvio] = useState<number>(0);
  const [url, setStateUrl] = useState<boolean>(true);
  const [
    qtdDiasEnvioAntesVencimento,
    setQtdDiasEnvioAntesVencimento,
  ] = useState<number>(0);
  const [isPorcentagemDesconto, setIsPorcentagemDesconto] = useState<boolean>(
    false
  );
  const [isPercentInterest, setIsPercentInterest] = useState<boolean>(false);
  const [isPercentDiscount, setIsPercentDiscount] = useState<boolean>(false);
  const [maxDias, setMaxDias] = useState<number>(10);

  const [isLoading, setIsLoading] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const [hasLimit, setHasLimit] = useState(true);
  const [isLoadingLimit, setIsLoadingLimit] = useState(false);
  const [isAvailable, setAvailable] = useState<boolean>(false);
  const [groups, setGroups] = useState([]);
  const [rules, setRules] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [ruleName, setRuleName] = useState('');
  const [ruleID, setRuleID] = useState('');
  const [groupMembers, setGroupMembers] = useState(0);
  const [groupDescription, setGroupDescription] = useState('');
  const [groupId, setGroupId] = useState('');
  const [thereIsClient, setThereIsClient] = useState<boolean>(true);
  const [characterCount, setCharacterCount] = useState(300);
  const [ruleDescription, setRuleDescription] = useState('');
  const [billingInformation, setBillingInformation] = useState('');
  const [billingValue, setBillingValue] = useState('');
  const [isRuleFixed, setIsRuleFixed] = useState(false);
  const [interest, setInterest] = useState('');
  const [fine, setFine] = useState('');
  const [discount, setDiscount] = useState('');
  const [daysToPaymentWithDiscount, setDaysToPaymentWithDiscount] = useState(
    ''
  );
  const [expirationDateString, setExpirationDateString] = useState('');
  const [paymentLimitDateString, setPaymentLimitDateString] = useState('');
  const [secondStage, setSecondStage] = useState(false);
  const [summary, setSummary] = useState<IBillingBatchSecondStage>();

  const [
    planoFinanceira,
    setPlanoFinanceira,
  ] = useState<IPlanoContaFinanceira>();

  const updatePlanoConta = async () => {
    const planos = await getDadosPlanoContaFinanceira();
    setPlanoFinanceira(planos);
  };

  const updateDiasEnvio = () => {
    const dataVencimento = getValues('vencimento');
    const diaAtual = new Date();
    const time_difference = subtrDate(dataVencimento, diaAtual) - 1;
    setMaxDias(time_difference);
  };

  const areValuesHigherThanAllowed = (billet: ICobranca) => {
    let {multa, juros, valor} = billet;

    multa = multa.replace(/\./g, '').replace(',', '.');
    juros = juros.replace(/\./g, '').replace(',', '.');
    valor = valor.replace(/\./g, '').replace(',', '.');

    if (multa === '' || multa === '0') return false;

    if (isPercentInterest && parseFloat(juros) > 0.033) {
      return true;
    }

    if (isPercentInterest && parseFloat(multa) > 2) {
      return true;
    }

    if (!isPercentInterest) {
      const interestValueAllowed = parseFloat(valor) * 0.02;
      if (parseFloat(multa) > interestValueAllowed) {
        return true;
      }
    }

    if (!isPercentInterest) {
      const fineValueAllowed = parseFloat(valor) * 0.0033;
      if (parseFloat(juros) > fineValueAllowed) {
        return true;
      }
    }

    return false;
  };

  const enviarCobranca = async (data: never) => {
    const assembledBatch = assembleBatch(data);
    const summaryInfo: IBillingBatchSecondStage = {
      batch: assembledBatch,
      billingRuleName: ruleName,
      groupInfo: {
        groupName: groupName,
        groupDescription: groupDescription,
        amountClient: groupMembers,
      },
    };
    setSummary(summaryInfo);
    setSecondStage(true);
    setIsLoading(false);
  };

  const onSubmit = async (data: never) => {
    if (isRuleFixed) {
      enviarCobranca(data);
    } else {
      const showWarning = areValuesHigherThanAllowed(data);
      if (showWarning) showValueWarning(data, enviarCobranca);
      if (!showWarning) await enviarCobranca(data);
    }
  };

  const showValueWarning = (billet: ICobranca, callback: Function) => {
    try {
      Swal.fire({
        title:
          'O valor do juros e/ou da multa ultrapassaram o limite permitido por lei!',
        text:
          'Pode-se apenas cobrar 0,033% de juros ao dia e 2% de multa ao mês conforme art. 52 do Código de Defesa do Consumidor!',
        showCancelButton: true,
        confirmButtonColor: ' #d33',
        cancelButtonColor: `${process.env.REACT_APP_COLOR_PRIMARY}`,
        confirmButtonText:
          '<i class="fas fa-check fa-sm" aria-hidden="true"></i> Estou ciente, continuar',
        cancelButtonText:
          '<i class="fas fa-ban" aria-hidden="true"></i> Voltar e editar',
        customClass: {
          confirmButton: 'confirmButtonModalClass',
          cancelButton: 'cancelButtonModalClass',
        },
      }).then(async result => {
        if (result.isConfirmed) {
          await callback(billet);
        }
      });
    } catch (error) {
      toast.error(error, {autoClose: 2500});
    }
  };

  const assembleBatch = (batchInfo: never) => {
    return isRuleFixed
      ? assembleBatchForFixedRules(batchInfo)
      : assembleBatchForDynamicRules(batchInfo);
  };

  const transformValues = (totalValue: number, percentValue: number) => {
    return parseInt(
      (((totalValue / 100) * percentValue) / 10000).toFixed(2).replace('.', '')
    );
  };

  const assembleBatchForFixedRules = batchInfo => {
    const discountValue: number = parseInt(discount.replace('.', ''));
    const fineValue: number = parseInt(fine.replace('.', ''));
    const interestValue: number = parseInt(interest.replace('.', ''));
    const billingValueParsed: number = parseInt(billingValue.replace(',', ''));

    let diasAntesVencimento = 0;
    let localEnvioImediato = true;

    if (batchInfo.dataEnvio === batchInfo.vencimento) {
      localEnvioImediato = true;
      diasAntesVencimento = 0;
    } else {
      localEnvioImediato = false;
      diasAntesVencimento = subtrDate(
        batchInfo.vencimento,
        batchInfo.dataEnvio
      );
    }

    const batch: IBillingBatch = {
      daysBeforeSend: diasAntesVencimento,
      numberOfDaysToPaymentAfterExpiration: dateDifference(
        expirationDateString,
        paymentLimitDateString
      ),
      numberOfDaysToPaymentWithDiscount: parseInt(daysToPaymentWithDiscount),
      sendByEmail: emailIschecked,
      sendImmediately: envioImediato,
      clientGroupID: groupId,
      billingRuleID: ruleID,
      description: ruleDescription,
      information: billingInformation,
      value: billingValueParsed,
      fine: isPercentInterest
        ? transformValues(billingValueParsed, fineValue)
        : fineValue,
      interest: isPercentInterest
        ? transformValues(billingValueParsed, interestValue)
        : interestValue,
      discount: isPercentDiscount
        ? transformValues(billingValueParsed, discountValue)
        : discountValue,
      expirationDay: expirationDateString,
    };

    return batch;
  };

  const assembleBatchForDynamicRules = batchInfo => {
    let diasAntesVencimento = 0;
    let localEnvioImediato = true;

    if (batchInfo.dataEnvio === batchInfo.vencimento) {
      localEnvioImediato = true;
      diasAntesVencimento = 0;
    } else {
      localEnvioImediato = false;
      diasAntesVencimento = subtrDate(
        batchInfo.vencimento,
        batchInfo.dataEnvio
      );
    }

    const discountValue: number = parseInt(batchInfo.desconto.replace(',', ''));
    const fineValue: number = parseInt(batchInfo.multa.replace(',', ''));
    const interestValue: number = parseInt(batchInfo.juros.replace(',', ''));
    const billingValueParsed: number = parseInt(
      batchInfo.valor.replace(',', '')
    );
    const expirationDateValue = batchInfo.vencimento
      ? batchInfo.vencimento.toLocaleDateString('pt-br')
      : expirationDateString;
    const paymentLimitValue = batchInfo.dataLimitePagamento
      ? batchInfo.dataLimitePagamento.toLocaleDateString('pt-br')
      : paymentLimitDateString;

    const batch: IBillingBatch = {
      daysBeforeSend: localEnvioImediato ? 0 : diasAntesVencimento,
      numberOfDaysToPaymentAfterExpiration: dateDifference(
        expirationDateValue,
        paymentLimitValue
      ),
      expirationDay: expirationDateValue,
      numberOfDaysToPaymentWithDiscount: parseInt(daysToPaymentWithDiscount),
      sendByEmail: emailIschecked,
      sendImmediately: envioImediato,
      clientGroupID: groupId,
      billingRuleID: ruleID,
      description: ruleDescription,
      information: batchInfo.instrucoesCliente,
      value: billingValueParsed,
      fine: isPercentInterest
        ? transformValues(billingValueParsed, fineValue)
        : fineValue,
      interest: isPercentInterest
        ? transformValues(billingValueParsed, interestValue)
        : interestValue,
      discount: isPercentDiscount
        ? transformValues(billingValueParsed, discountValue)
        : discountValue,
    };
    return batch;
  };

  const dateDifference = (expiration: string, limit: string) => {
    const IN_MILLISECONDS_1_DAY = 86400000;

    const sd1 = expiration.split('/');
    const sd2 = limit.split('/');

    for (let iterator = 0; iterator < 3; iterator++) {
      sd1[iterator] = parseInt(sd1[iterator]);
      sd2[iterator] = parseInt(sd2[iterator]);
    }
    const expirationUTC = Date.UTC(sd1[2], sd1[1], sd1[0]);
    const limitUTC = Date.UTC(sd2[2], sd2[1], sd2[0]);

    return (limitUTC - expirationUTC) / IN_MILLISECONDS_1_DAY;
  };

  const redirect = () => {
    history.push('/dashboard');
  };

  const getPossuiPix = async () => {
    Promise.all([
      verificarChavePix(),
      getVerificacaoDocumento(),
      getDadosPlanoContaFinanceira(),
    ])
      .then(results => {
        const dadosPlanoContaFinanceira = results[2];
        const possuiPix = results[0];
        const isDocumentacaoValida = results[1];

        if (
          (dadosPlanoContaFinanceira &&
            dadosPlanoContaFinanceira.maxTransactionsMonthlyRequested >=
              dadosPlanoContaFinanceira.maxTransactionsMonthly) ||
          (dadosPlanoContaFinanceira &&
            dadosPlanoContaFinanceira.dailyTransactionLimitRequested >=
              dadosPlanoContaFinanceira.dailyTransactionLimit)
        ) {
          setAvailable(false);
          showModalWarning(
            'Limite do plano ultrapassado',
            '<p>O limite do seu plano diário ou mensal não comporta a emissão deste lote, aumente seu limite através do atendimento!</p>',
            redirect
          );
        }

        let html = '';
        if (!possuiPix) {
          html += `<li> Para emissão de cobranças você deve ter uma chave PIX cadastrada no seu aplicativo <strong>${process.env.REACT_APP_NOME_PARCEIRO}</strong>
        </li>`;
        }
        if (!isDocumentacaoValida) {
          html += `<li> Para emissão de cobranças você deve ter a documentação aprovada no seu aplicativo ${process.env.REACT_APP_NOME_PARCEIRO}
        </li>`;
        }
        if (html) {
          setAvailable(false);
          showModalWarning(
            'Atenção! Você possui algumas pendências',
            html,
            redirect
          );
        } else {
          setAvailable(true);
        }
      })
      .catch(() => {
        setAvailable(false);
      });
  };

  const loadClientGroups = async () => {
    const loadedGroups = await listGroups(0, 0);
    const clientGroups = [];
    loadedGroups.data.map(receivedGroup =>
      clientGroups.push({
        value: receivedGroup.id,
        label: receivedGroup.name,
      })
    );
    setGroups(clientGroups);
  };

  const loadBillingRules = async () => {
    setIsLoading(true);
    const parsedRules = [];
    const loadedRules = await listRulesApi(0, 0, '');
    loadedRules.data.map(receivedRule =>
      parsedRules.push({
        value: receivedRule.id,
        label: receivedRule.name,
      })
    );
    setRules(parsedRules);
    setIsLoading(false);
  };

  const loadClientGroupInfo = async (groupId: string) => {
    const loadedGroup = await getGroupDetailsApi(groupId);
    setGroupName(loadedGroup.name);
    setGroupMembers(loadedGroup.clients.length);
    setGroupDescription(loadedGroup.description);
    setGroupId(groupId);
  };

  const loadBillingRuleInfo = async (ruleId: string) => {
    const loadedRule = await getDetailsRuleApi(ruleId);
    setRuleDescription(loadedRule.description);
    setBillingInformation(loadedRule.information);
    setBillingValue(
      newMoneyMask((loadedRule.value / 100).toFixed(2).toString())
    );
    loadedRule.type === BillingRuleType.FIXED
      ? setIsRuleFixed(true)
      : setIsRuleFixed(false);
    setInterest(loadedRule.interest.toFixed(2).toString());
    setFine(loadedRule.fine.toFixed(2).toString());
    loadedRule.interestType === InterestFineDiscountType.PERCENT
      ? setIsPercentInterest(true)
      : setIsPercentInterest(false);
    setDiscount(loadedRule.discount.toFixed(2).toString());
    loadedRule.discountType === InterestFineDiscountType.PERCENT
      ? setIsPercentDiscount(true)
      : setIsPercentDiscount(false);
    setDaysToPaymentWithDiscount(loadedRule.discountAdvanceDays.toString());
    const [expirationDate, limitDate] = await defineExpirationAndLimitDates(
      loadedRule.expirationDay,
      loadedRule.daysAfterExpiration
    );
    setRuleName(loadedRule.name);
    setRuleID(loadedRule.id);

    const expiration = document.querySelector(
      '#input-vencimento'
    ) as HTMLInputElement;
    expiration.value = expirationDate;

    const limit = document.querySelector(
      '#input-vencimento-limite'
    ) as HTMLInputElement;
    limit.value = limitDate;
  };

  const defineExpirationAndLimitDates = async (
    expirationDay: number,
    limitDay: number
  ) => {
    const expirationDate = setNextExpirationDate(expirationDay);
    setExpirationDateString(expirationDate);
    const limitDate = setNextLimitDate(limitDay, expirationDate);
    setPaymentLimitDateString(limitDate);
    return [expirationDate, limitDate];
  };

  const setNextExpirationDate = (expirationDay: number) => {
    const IN_MILLISECONDS_1_DAY = 86400000;
    let expirationDate;
    const today = new Date();
    if (today.getDate() > expirationDay) {
      expirationDate = new Date(today.getTime() + 30 * IN_MILLISECONDS_1_DAY);
      expirationDate = new Date(
        expirationDate.setDate(expirationDay)
      ).toLocaleDateString('pt-br');
      return expirationDate;
    }
    expirationDate = new Date(today.setDate(expirationDay)).toLocaleDateString(
      'pt-br'
    );
    return expirationDate;
  };

  const setNextLimitDate = (limitDay: number, expirationDateString: string) => {
    const IN_MILLISECONDS_1_DAY = 86400000;
    const sd = expirationDateString.split('/');
    const expirationDate = new Date(`${sd[2]}-${sd[1]}-${sd[0]}`);
    return new Date(
      expirationDate.getTime() + (limitDay + 1) * IN_MILLISECONDS_1_DAY
    ).toLocaleDateString('pt-br');
  };

  const checkClients = async () => {
    setThereIsClient(await checkIfThereIsClientsRegistered());
  };

  if (updateTable) {
    loadClientGroups();
    setUpdateTable(!updateTable);
  }

  const characterCounter = e => {
    e.target.value = e.target.value.replace(/^\s+/, '');
    setCharacterCount(300 - e.target.value.length);
  };

  useEffect(() => {
    getPossuiPix();
    return () => {};
  }, []);

  useEffect(() => {
    if (clienteInfos) {
      setClienteInfosLoaded(true);
    }
  }, [clienteInfos]);

  useEffect(() => {
    setIsLoading(true);
    loadClientGroups();
    loadBillingRules();
    checkClients();
    setIsPorcentagemDesconto(false);
    updatePlanoConta();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    updateDiasEnvio;
    if (envioImediato) {
      setValue('qtdDiasEnvioAntesVencimento', '');
    } else {
      setValue('qtdDiasEnvioAntesVencimentoImediata', '');
    }
    setQtdDiasEnvioAntesVencimento(0);
  }, [envioImediato]);

  useEffect(() => {
    setVlTotalCalculado(numParcelas * vlParcela);
  }, [numParcelas, vlParcela]);

  useEffect(() => {
    valorDesconto < valorCobranca && porcDescontoCalculado
      ? setPorcDescontoCalculado((valorDesconto * 100) / valorCobranca)
      : 0;
  }, [valorCobranca, valorDesconto]);

  useEffect(() => {
    setVlTotalCalculado(numParcelas * vlParcela);
  }, [numParcelas, vlParcela]);

  return (
    <>
      <UIContainer>
        <div className="d-flex bd-highlight titleAndButton">
          <div className="p-2 flex-grow-1 bd-highlight">
            <Titulo>Emitir Lote de Cobrança</Titulo>
          </div>
          {secondStage ? (
            <div className="align-content-end">
              <BtnDefault
                action={() => {
                  setSecondStage(false);
                }}
                className="btn-custom btn-save p-2 flex-grow-1 bd-highlight"
                variantClass="custom"
              >
                <FaArrowLeft size={13} className="mr-1" />
                Voltar
              </BtnDefault>
            </div>
          ) : (
            ''
          )}
        </div>

        {!secondStage ? (
          <>
            <CardSection title="Informações do Grupo de Clientes">
              {groups ? (
                <div className="form-interno">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <Controller
                        control={control}
                        defaultValue={null}
                        rules={{required: true}}
                        id="group-select"
                        name="group"
                        render={({onChange, value}) => (
                          <>
                            <InputSelect
                              placeholder={
                                <>
                                  Digite o Nome do Grupo de Clientes
                                  <span className="asterisk ">*</span>
                                </>
                              }
                              inputRef={register({
                                required: true,
                              })}
                              id="group-select"
                              classNamePrefix="addl-class"
                              name="groups"
                              options={groups}
                              erros={errors}
                              value={groups.find(c => c.label === value)}
                              onChange={(val: IClientGroupSelectModel) => {
                                onChange(val.label);
                                loadClientGroupInfo(val.value);
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                    <div>
                      <ModalDefault
                        nome="client-group"
                        className="btn-custom btn-save align-top"
                        icon={FaPlus}
                        variantClass="btn-purple btn-purple--round"
                        setUpdateTable={setUpdateTable}
                        booleanUtil={thereIsClient}
                      >
                        Adicionar Grupo
                      </ModalDefault>
                    </div>
                  </div>

                  {groupName !== '' ? (
                    <>
                      <div className="row">
                        <span className="group__title">{`${groupName} (${groupMembers} clientes)`}</span>
                      </div>
                      <div className="row">
                        <label
                          htmlFor="Categoria"
                          className="group__description"
                        >
                          {groupDescription}
                        </label>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                ''
              )}
            </CardSection>

            <CardSection title="Informações Gerais">
              {rules ? (
                <div className="form-interno">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <Controller
                        control={control}
                        defaultValue={null}
                        rules={{required: true}}
                        id="rule-select"
                        name="rules"
                        render={({onChange, value, ref}) => (
                          <>
                            <InputSelect
                              placeholder={
                                <>
                                  Regra de Lote
                                  <span className="asterisk ">*</span>
                                </>
                              }
                              inputRef={register({
                                required: true,
                              })}
                              id="rule-select"
                              classNamePrefix="addl-class"
                              name="rules"
                              options={rules}
                              erros={errors}
                              value={rules.find(c => c.label === value)}
                              onChange={(val: IClientGroupSelectModel) => {
                                onChange(val.label);
                                loadBillingRuleInfo(val.value);
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                    <div>
                      <ModalDefault
                        nome="create-billet-rules"
                        className="btn-custom btn-save"
                        icon={FaPlus}
                        variantClass="btn-purple btn-purple--round"
                        setUpdateTable={setUpdateTable}
                      >
                        Adicionar Regra
                      </ModalDefault>
                    </div>
                    <div className="form-group col-12">
                      <label
                        className="label-item-tabela"
                        htmlFor="input-descricao"
                      >
                        Descrição
                      </label>
                      <textarea
                        className="col-md-12 text-area-default"
                        name="descricao"
                        id="input-descricao"
                        placeholder="Informe os detalhes deste lote."
                        value={ruleDescription}
                        disabled={isRuleFixed}
                        onChange={event => {
                          characterCounter(event);
                          setRuleDescription(event.target.value);
                        }}
                        maxLength={300}
                      />
                      <CharacterCount count={characterCount} />
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </CardSection>

            {isLoading ? (
              <SpinnerLoader />
            ) : (
              <form name="form-nova-cobranca" onSubmit={handleSubmit(onSubmit)}>
                {
                  <>
                    <CardClienteAvulsa
                      register={register}
                      setValorCobranca={setValorCobranca}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      planoFinanceira={planoFinanceira}
                      setHasLimit={setHasLimit}
                      setIsLoadingLimit={setIsLoadingLimit}
                      information={billingInformation}
                      setInformation={setBillingInformation}
                      receivedValue={billingValue}
                      isDisabled={isRuleFixed}
                      expirationDate={expirationDateString}
                      paymentLimitDate={paymentLimitDateString}
                      clientsFromGroup={groupMembers}
                    />

                    <CardSection title="Informações de Juros e Desconto">
                      <CardJurosMulta
                        register={register}
                        errors={errors}
                        setValorDesconto={setValorDesconto}
                        key={tipoBoleto}
                        setDiasAmaisParaDesconto={setDaysToPaymentWithDiscount}
                        setIsPorcentagemDesconto={setIsPorcentagemDesconto}
                        setIsPorcentagemJuros={setIsPercentInterest}
                        juros={newMoneyMask(interest.replace(',', '.'))}
                        multa={moneyMask(fine.replace(',', '.'))}
                        desconto={moneyMask(discount.replace(',', '.'))}
                        isFinePercent={isPercentInterest}
                        isDiscountPercent={isPercentDiscount}
                        isDisabled={isRuleFixed}
                        diasAMaisParaDesconto={daysToPaymentWithDiscount}
                        radioFineNameOption={'modal-radio-fine-option'}
                        radioDiscountNameOption={'modal-radio-discount-option'}
                      />
                    </CardSection>

                    <div className="box-tax py-3">
                      <label>
                        {planoFinanceira &&
                          'Para esta operação será cobrada uma tarifa de ' +
                            formataDinheiro(planoFinanceira.taxValue)}
                      </label>
                    </div>

                    <div className="container-bottoes col-md-12">
                      <div className="button-salvar-cobranca">
                        {
                          <Button
                            name="btnSalvar"
                            className="btn-custom btn-save"
                            type="submit"
                            onClick={() => setStateUrl(false)}
                            variant="custom"
                            disabled={isLoadingLimit}
                          >
                            <FaArrowRight size={13} className="mr-1" />
                            Continuar
                          </Button>
                        }
                      </div>
                    </div>
                  </>
                }
              </form>
            )}
          </>
        ) : (
          <CardSection title={''}>
            <BatchSummary
              batch={summary.batch}
              billingRuleName={summary.billingRuleName}
              groupInfo={summary.groupInfo}
            />
          </CardSection>
        )}
      </UIContainer>
    </>
  );
};
export default BillingBatch;
