import axios from 'axios';
import {IViaCep} from 'models/viaCep-model';

const apiViaCep = axios.create({
  baseURL: 'https://viacep.com.br/ws',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

export const getEndereco = async (cep: string) => {
  return (await apiViaCep.get<IViaCep>(`/${cep}/json`))?.data;
};
