import React from 'react';
import {Redirect, withRouter} from 'react-router-dom';

import {useAuth} from '../../context';

export const OnRefreshRedirect = withRouter(({location}) => {
  const lastRouteKey = 'last-route';
  const pathLoginRoute = '/';
  const pathLogoutRoute = '/logout';
  const {usuarioLogado} = useAuth();

  if (![pathLoginRoute, pathLogoutRoute].includes(location.pathname)) {
    sessionStorage.setItem(lastRouteKey, location.pathname);
  }

  const lastRoute = sessionStorage.getItem(lastRouteKey);
  const isRedirect = usuarioLogado && lastRoute;

  return isRedirect ? <Redirect to={lastRoute} /> : null;
});
