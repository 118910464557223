import React, {useEffect, useState} from 'react';
import './styles.scss';
import {Table} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {
  FaUserPlus,
  FaSearch,
  FaBroom,
  FaSortAlphaDown,
  FaSortAlphaUpAlt,
} from 'react-icons/fa';
import {toast} from 'react-toastify';

import _ from 'lodash/fp';
import Swal from 'sweetalert2';

import cpfcnpjMask from '../../../src/utils/masks/cpfcnpjMask';
import {
  deletarCliente,
  getExampleFileDir,
  listarClientes,
} from '../../api/cliente-api';
import {ICliente} from '../../models/cliente-model';
import {PaginationModel} from '../../models/pagination-model';
import noEmptyMask from '../../utils/masks/noEmptyMask';
import BtnDefault from '../Buttons/BtnDefault';
import InputDefault from '../Inputs/InputDefault';
import ModalDefault from '../ModalDefault';
import PaginationDefault from '../PaginationDefault';
import SpinnerLoader from '../Spinner';
import TableRowCliente from '../TableRows/TableRowsCliente';
import Titulo from '../Titulo';
import UIContent from '../UI/Content';
import ModalEditarCliente from './ModalClienteEditar';

const MainCliente: React.FC = () => {
  const [clientes, setClientes] = useState<ICliente[]>([]);
  const [updateTable, setUpdateTable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(30);
  const [isLoading, setIsLoading] = useState(false);
  const [nome, setNome] = useState<string>('');
  const [cpfCnpj, setCpfCnpj] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [showModalCliente, setShowModalCliente] = useState(false);
  const [idCliente, setIdCliente] = useState<string | undefined>();
  const [showModalViewOrEdit, setShowModalViewOrEdit] = useState(false);
  const [linkExample, setLinkExample] = useState<any>({});
  const [orderSort, setOrderSort] = useState(true);

  const {handleSubmit, reset, errors, register, setValue} = useForm();

  if (updateTable) {
    const order = orderSort ? 'ASC' : 'DESC';
    listarTodosClientes(currentPage, order);
    setUpdateTable(!updateTable);
  }

  const handleCloseCliente = () => {
    setShowModalCliente(false);
  };

  async function listarTodosClientes(pageNumber: number, order: string) {
    let paginaAtual = 0;

    if (pageNumber > 0) {
      paginaAtual = pageNumber - 1;
    }

    setIsLoading(true);
    let cpfCliente = undefined;
    cpfCliente = cpfCnpj?.replace(/[^\d]+/g, '');

    const todosclientes: PaginationModel = await listarClientes(
      paginaAtual,
      limit,
      nome,
      cpfCliente,
      email,
      order,
      true
    );
    setClientes(todosclientes.data);
    setTotalCount(todosclientes.totalCount);
    setCurrentPage(pageNumber);
    setIsLoading(false);
  }

  useEffect(() => {
    const order = orderSort ? 'ASC' : 'DESC';
    listarTodosClientes(currentPage, order);
    defineExampleLink();
  }, [orderSort]);

  async function excluirCliente(id: string) {
    try {
      Swal.fire({
        title: 'Tem certeza que deseja excluir?',
        text: 'Ao confirmar, o cliente será excluído!',
        showCancelButton: true,
        confirmButtonText:
          '<i class="fas fa-trash fa-sm" aria-hidden="true"></i> Excluir',
        cancelButtonText:
          '<i class="fas fa-ban" aria-hidden="true"></i> Cancelar',
        customClass: {
          confirmButton: 'confirmButtonModalClass',
          cancelButton: 'cancelButtonModalClass',
        },
      }).then(async result => {
        if (result.value) {
          try {
            setIsLoading(true);
            await deletarCliente(id);
            toast.success('Cliente excluído com sucesso!', {autoClose: 2500});
            setIsLoading(false);
            if (clientes.length - 1 > 0) {
              const order = orderSort ? 'ASC' : 'DESC';
              listarTodosClientes(currentPage, order);
            } else {
              const order = orderSort ? 'ASC' : 'DESC';
              listarTodosClientes(currentPage - 1, order);
              setCurrentPage(currentPage - 1);
            }
          } catch (e) {
            setIsLoading(false);
          }
        }
      });
    } catch (error) {
      toast.error(error, {autoClose: 2500});
    }
  }

  const filtrar = () => {
    const order = orderSort ? 'ASC' : 'DESC';
    listarTodosClientes(currentPage, order);
  };

  const clearFilter = () => {
    if (nome || cpfCnpj || email) {
      reset();
      setNome('');
      setCpfCnpj('');
      setEmail('');
      return;
    }
    const order = orderSort ? 'ASC' : 'DESC';
    listarTodosClientes(1, order);
  };

  const exibirModal = (id: string, showMenu: boolean) => {
    setIdCliente(id);
    setShowModalCliente(true);
    setShowModalViewOrEdit(showMenu);
  };

  const defineExampleLink = async () => {
    const resp = await getExampleFileDir();
    setLinkExample(resp);
  };

  return (
    <UIContent>
      <div className="d-flex bd-highlight titleAndButton">
        <div className="p-2 flex-grow-1 bd-highlight">
          <Titulo> Cadastro de Clientes </Titulo>
        </div>
      </div>

      <form onSubmit={handleSubmit(filtrar)}>
        <div className="form-row mb-5">
          <div className="form-group col-md-4">
            <InputDefault
              name="nome"
              type="text"
              placeholder="Nome cliente"
              id="input-nome-cliente"
              onChange={e => setNome(e.target.value)}
              value={nome}
              inputMaxLength="300"
              inputRef={register({})}
              mask={noEmptyMask}
            />
          </div>
          <div className="form-group col-md-4">
            <InputDefault
              name="cpfCnpj"
              type="text"
              placeholder="Cpf ou Cnpj"
              id="inpu-cpfcnpj-cliente"
              onChange={e => setCpfCnpj(e.target.value)}
              value={cpfCnpj}
              inputMaxLength="18"
              mask={cpfcnpjMask}
              inputRef={register({})}
              onBlur={e =>
                setValue('cpfCnpj', cpfcnpjMask(e.target.value.slice(0, 18)))
              }
            />
          </div>
          <div className="form-group col-md-4">
            <InputDefault
              name="email"
              type="text"
              placeholder="Email cliente"
              id="input-email-cliente"
              onChange={e => setEmail(e.target.value)}
              value={email}
              inputMaxLength="255"
              inputRef={register({})}
              mask={noEmptyMask}
            />
            {_.get('email.type', errors) === 'maxLength' && (
              <p className="input-error">
                O email não pode ter mais de 255 caracteres
              </p>
            )}
          </div>
          <div className="d-flex justify-content-start">
            <BtnDefault
              name="btn-buscar"
              className="btn-custom btn-save ml-1"
              id="btn-buscar"
              type="submit"
              variantClass="custom"
            >
              <FaSearch size={12} className="mr-1" />
              Buscar
            </BtnDefault>
            <BtnDefault
              action={clearFilter}
              name="btn-limpar"
              id="btn-limpar"
              className="btn-custom btn-save p-2 flex-grow-1 bd-highlight"
              variantClass="custom"
            >
              <FaBroom size={12} className="mr-1" />
              Limpar filtros
            </BtnDefault>
          </div>
        </div>
      </form>

      <div className="d-flex flex-row-reverse">
        <ModalDefault
          nome="clientes"
          icon={FaUserPlus}
          variantClass="btn-purple btn-purple--round"
          setUpdateTable={setUpdateTable}
          className="d-flex flex-row-reverse"
        >
          Novo cliente
        </ModalDefault>

        <ModalDefault
          nome="import-clients"
          icon={FaUserPlus}
          variantClass="btn-purple btn-purple--round"
          setUpdateTable={setUpdateTable}
          className="d-flex flex-row-reverse"
          objectUtil={linkExample}
        >
          Importar
        </ModalDefault>
      </div>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <Table responsive hover>
          <thead className="table-header">
            <tr>
              <th>
                <div
                  className="thContainerActive"
                  onClick={() => setOrderSort(!orderSort)}
                >
                  {orderSort ? (
                    <FaSortAlphaDown size={20} />
                  ) : (
                    <FaSortAlphaUpAlt size={20} />
                  )}
                  Nome
                </div>
              </th>
              <th>
                <div className="th">Email</div>
              </th>
              <th>
                <div className="th">Telefone</div>
              </th>
              <th>
                <div className="th">CPF/CNPJ</div>
              </th>
              <th>
                <div className="th">Grupos de Clientes</div>
              </th>
              <th>
                <div className="th">Ativar/Inativar</div>
              </th>
              <th>
                <div className="th">Ações</div>
              </th>
            </tr>
          </thead>

          <tbody>
            {clientes &&
              clientes.map((cliente: ICliente, index) => {
                return (
                  <TableRowCliente
                    cliente={cliente}
                    exibirModal={exibirModal}
                    key={index}
                    excluirCliente={excluirCliente}
                  />
                );
              })}
          </tbody>
        </Table>
      )}
      {!isLoading && clientes !== undefined && clientes.length === 0 && (
        <div className="msg-table ">
          <p>Nenhum cliente cadastrado.</p>
        </div>
      )}
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <PaginationDefault
          currentPage={currentPage}
          totalCount={totalCount}
          limit={limit}
          action={(value: number) =>
            listarTodosClientes(value, orderSort ? 'ASC' : 'DESC')
          }
          totalNaPagina={clientes.length}
        />
      )}
      {showModalCliente ? (
        <ModalEditarCliente
          icon={FaUserPlus}
          variantClass="btn-purple btn-purple--round"
          setUpdateTable={setUpdateTable}
          id={idCliente}
          showModalCliente={showModalCliente}
          handleCloseCliente={handleCloseCliente}
          showMenuModal={showModalViewOrEdit}
        />
      ) : (
        ''
      )}
    </UIContent>
  );
};

export default MainCliente;
