import {toast} from 'react-toastify';

export function formataData(data: string) {
  /// entrada:
  // Tue Mar 02 2021 00:00:00 GMT-0300 (Horário Padrão de Brasília)
  // ou 2021/03/02
  /// saida: 02/03/2021
  try {
    if (data === undefined) {
      return data;
    }
    return new Date(data).toLocaleDateString('pt-BR', {timeZone: 'UTC'});

    /*  let newDate;

    // se:: 2021/03/02 é necessario hack de horas para nao acrescentar dia
    if (data.length === 10) {
      newDate = new Date(`${data}`);
    } else {
      newDate = new Date(data);
    }

    const ano = newDate.getFullYear();
    const mes = newDate.getMonth() + 1;
    const dia = newDate.getDate();

    const diaFormatado =
      dia.toString().length !== 1 ? dia.toString() : '0' + dia.toString();
    const mesFormatado =
      mes.toString().length !== 1 ? mes.toString() : '0' + mes.toString();

    const newFormat = diaFormatado + '/' + mesFormatado + '/' + ano;
    return newFormat;*/
  } catch {
    return toast.error('Erro na formatação da data.', {autoClose: 2500});
  }
}
