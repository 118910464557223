import React, {useEffect, useState} from 'react';
import {DeepMap, FieldError} from 'react-hook-form';

import CardSection from '../../../components/CardSection';
import InputDefault from '../../../components/Inputs/InputDefault';
import InputRadioDefault from '../../../components/Inputs/InputRadioDefault';
import SelectQtdDias from '../../../components/SelectQtdDias';
import {ICobranca} from '../../../models/cobranca';
import moneyMask from '../../../utils/masks/moneyMask';
import './styles.scss';

interface CardDescontoProps {
  register;
  errors: DeepMap<ICobranca, FieldError>;
  setValorDesconto: React.Dispatch<React.SetStateAction<number>>;
  setDiasAmaisParaDesconto: React.Dispatch<React.SetStateAction<number>>;
  setIsPorcentagemDesconto: React.Dispatch<React.SetStateAction<boolean>>;
  desconto?: string;
  diasAMaisParaDesconto?: string;
  isPercent?: boolean;
  isDisabled?: boolean;
  radioNameOption?: string;
}

const CardDesconto: React.FC<CardDescontoProps> = ({
  register,
  errors,
  setDiasAmaisParaDesconto,
  setIsPorcentagemDesconto,
  desconto,
  diasAMaisParaDesconto,
  isPercent = false,
  isDisabled = false,
  radioNameOption = 'isPorcentagemDesconto',
}) => {
  const [isPorcentagem, setIsPorcentagem] = useState<boolean>(false);
  const [valor, setValor] = useState<string>('0');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [
    daysToPaymentWithDiscount,
    setDaysToPaymentWithDiscount,
  ] = useState<string>('');

  const trocarTipo = (tipo: boolean) => {
    if (!isDisabled) {
      setIsPorcentagem(tipo);
      setIsPorcentagemDesconto(tipo);
    }
    if (isPercent === undefined) {
      setValor('0');
      setDisabled(false);
      const desconto = document.querySelector(
        '#input-desconto'
      ) as HTMLInputElement;
      if (desconto) {
        desconto.value = '';
      }
    }
  };

  useEffect(() => {
    setDisabled(isDisabled);
    setDaysToPaymentWithDiscount(diasAMaisParaDesconto);
    setIsPorcentagem(isPercent);
  }, [isDisabled, disabled, diasAMaisParaDesconto, daysToPaymentWithDiscount]);

  return (
    <CardSection title="Desconto">
      <div className="form-row justify-content-center">
        <div className="form-group col-md-2 mt-3">
          <div className="cards-valores__card-desconto__container-radio">
            <InputRadioDefault
              className="form-check-input-porc"
              name={radioNameOption}
              id={radioNameOption + 1}
              onClick={() => {
                trocarTipo(false);
              }}
              classLabel="label-item-tabela ml-2"
              defaultChecked={!isPorcentagem}
            >
              $
            </InputRadioDefault>
          </div>
          <div className="cards-valores__card-desconto__container-radio">
            <InputRadioDefault
              className="form-check-input-porc"
              name={radioNameOption}
              id={radioNameOption + 2}
              onClick={() => {
                trocarTipo(true);
              }}
              classLabel="label-item-tabela ml-2 mt-3"
              defaultChecked={isPorcentagem}
            >
              %
            </InputRadioDefault>
          </div>
        </div>

        <div className="form-group col-md-5">
          <label className="label-item-tabela" htmlFor="input-desconto">
            Valor
          </label>
          <div className="cards-valores__container-card__multa">
            <InputDefault
              name="desconto"
              type="text"
              placeholder=""
              id="input-desconto"
              inputMinLengthNumber={'0'}
              inputMaxLength={isPorcentagem ? '6' : '10'}
              mask={moneyMask}
              erros={errors}
              inputRef={register({
                maxLength: isPorcentagem ? 6 : 10,
              })}
              onChange={event => {
                setValor(event.target.value);
              }}
              value={valor}
              disabledInput={disabled}
              defaultValue={desconto}
            />
            {<span>{isPorcentagem ? '%' : '$'}</span>}
          </div>
        </div>

        <div className="form-group col-md-5 field-days">
          <label className="label-item-tabela" htmlFor="input-dias">
            Dias com Antecedência
          </label>
          <SelectQtdDias
            id="input-dias"
            inputRef={register}
            qtde={10}
            name="qtdDiasAntecedenciaDesconto"
            className="input-default "
            disabled={disabled}
            onChange={event => {
              setDiasAmaisParaDesconto(Number(event.target.value));
            }}
            defaultValue={daysToPaymentWithDiscount}
          />
        </div>
      </div>
    </CardSection>
  );
};

export default CardDesconto;
