import React, {useEffect, useState} from 'react';
import {Modal, Table, Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {IconBaseProps} from 'react-icons';
import {FaBan, FaArrowLeft} from 'react-icons/fa';
import {toast} from 'react-toastify';

import {
  cancelarCobranca,
  exibirUltimasCobrancas,
} from '../../../api/cobranca-api';
import {ICobrancaAtual, IVerCobrancaBoleto} from '../../../models/cobranca';
import {statusCobranca, statusEnvioMsgCobranca} from '../../../shared/enumMap';
import {formataData} from '../../../utils/formatDate';
import {formataDinheiro} from '../../../utils/formatMonetary';
import CharacterCount from '../../CharacterCount';

import './styles.scss';

interface ModalDefaultProps {
  nome: string;
  icon?: React.ComponentType<IconBaseProps>;
  variantClass?: string;
  idRecorrencia?: string;
  idCobranca?: string;
  handleCloseCancelar?: Function;
  showModalCancelar?: boolean;
  //closeRoot?: Function;
}

const ModalDefaultCancelar: React.FC<ModalDefaultProps> = ({
  icon: Icon,
  idRecorrencia,
  idCobranca,
  handleCloseCancelar,
  showModalCancelar,
  //closeRoot,
}) => {
  const [recorrencia, setRecorrencia] = useState<
    ICobrancaAtual | IVerCobrancaBoleto
  >();
  const [contadorJustificar, setContadorJustificar] = useState<Number>(100);

  async function carregarRecorrencia(idRecorrencia: string) {
    const ultimasCobrancas = await exibirUltimasCobrancas(idRecorrencia, 6);
    setRecorrencia(ultimasCobrancas);
    return ultimasCobrancas;
  }

  const showPopoverBoletos = () => {
    const showPopover = document.querySelectorAll<HTMLElement>('.popover ')[0];
    if (showModalCancelar) {
      showPopover.style.display = 'none';
    } else {
      showPopover.style.display = 'block';
    }
  };

  useEffect(() => {
    carregarRecorrencia(idRecorrencia);
    showPopoverBoletos();
  }, []);

  const closeModal = () => {
    handleCloseCancelar();
    //closeRoot(true);
  };

  const {handleSubmit, register, errors} = useForm();

  const cancelarBoleto = async (data: {justificar: string | undefined}) => {
    try {
      await cancelarCobranca(idCobranca, data.justificar);
      toast.success('Cobrança cancelada com sucesso!', {autoClose: 2500});
      closeModal();
      handleCloseCancelar();
    } catch (e) {
      console.error(e);
    }
  };
  const characterCounter = e => {
    e.target.value = e.target.value.replace(/^\s+/, '');
    setContadorJustificar(100 - e.target.value.length);
  };

  return (
    <Modal
      animation={false}
      show={showModalCancelar}
      onHide={handleCloseCancelar}
      backdrop="static"
      keyboard={true}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {Icon && <Icon size={20} className="modal-icone" />}
          Cancelar Cobrança
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="form-modal">
          {recorrencia !== undefined ? (
            <Table responsive borderless hover>
              <thead>
                <tr>
                  <th>N° Cobrança</th>
                  <th>Vencimento</th>
                  <th>Status envio</th>
                  <th>Valor</th>
                  <th>Status Pgto.</th>
                </tr>
              </thead>

              <tbody>
                {recorrencia.cobrancas.map(cobranca => {
                  return (
                    <tr key={cobranca.id}>
                      <td>{cobranca.numero}</td>
                      <td>{formataData(cobranca.vencimentoEm)}</td>
                      <td>
                        {statusEnvioMsgCobranca(
                          cobranca.statusEnvioMensagemCobranca
                        )}
                      </td>

                      <td>{formataDinheiro(cobranca.valor)}</td>
                      <td>{statusCobranca(cobranca.status)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            ''
          )}

          <form onSubmit={handleSubmit(cancelarBoleto)} className="">
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="label-item-tabela" htmlFor="justificar">
                  Justificar
                </label>
                <span className="asterisk ">*</span>
                <textarea
                  maxLength={100}
                  className="col-md-12 text-area-default"
                  name="justificar"
                  id="justificar"
                  ref={register({
                    required: true,
                  })}
                  onChange={characterCounter}
                />
                <CharacterCount count={contadorJustificar}></CharacterCount>
                {errors.justificar && (
                  <span className="input-error error-textarea">
                    Este campo é obrigatório.
                  </span>
                )}
              </div>
            </div>

            <div className="buttons-group">
              <Button
                className="btn-custom btn-cancel"
                variant="custom"
                onClick={() => handleCloseCancelar()}
              >
                <FaArrowLeft size={13} className="mr-1" />
                Voltar
              </Button>

              <Button
                type="submit"
                variant="custom"
                className="btn-custom btn-save"
              >
                <FaBan className="mr-1" size="13"></FaBan>
                Cancelar cobrança
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDefaultCancelar;
