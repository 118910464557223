import React from 'react';

import {IVerCobrancaBoleto} from '../../../models/cobranca';
import {formataDinheiro} from '../../../utils/formatMonetary';

const VerFinanceiro: React.FC<IVerCobrancaBoleto> = (
  props: IVerCobrancaBoleto
) => {
  return (
    <div className="row ml-2">
      <div className="col-sm-4">
        <label htmlFor="Total" className="form-label">
          Total:
        </label>
        <span className="text__content">
          {' '}
          {props.valor ? formataDinheiro(props.valor) : '-'}
        </span>
      </div>

      <div className="col-sm-4">
        <label htmlFor="Pago" className="form-label">
          Cobrança paga na data:
        </label>
        <span className="text__content">
          {' '}
          {props.valorTotalPago ? formataDinheiro(props.valorTotalPago) : '-'}
        </span>
      </div>
    </div>
  );
};
export default VerFinanceiro;
