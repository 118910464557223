import React, {useState} from 'react';
import {Form, Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

import {enviarEmailEsqueciMinhaSenha} from '../../api';
import InputLogin from '../../components/Inputs/InputLogin';
import Logo from '../../components/Logo';
import {IAuthModel} from '../../models/auth-model';
import {cnpjMask} from '../../utils/masks/cpfcnpjMask';
import cpfMask from '../../utils/masks/cpfMask';
import {limpaCnpj, limpaCpf} from '../../utils/validations/cpfCnpjValidator';
import {
  validaApenasCpf,
  validaApenasCnpj,
} from '../../utils/validations/validaCpfCnpj';

import './styles.scss';
const RecuperarSenha: React.FC = () => {
  const {register, handleSubmit, errors} = useForm<IAuthModel>();
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);

  const onSubmit = data => {
    setLoading(true);
    esqueciMinhaSenha(limpaCpf(data.cpf), limpaCnpj(data.cnpj));
  };

  async function esqueciMinhaSenha(cpf: string, cnpj: string) {
    try {
      await enviarEmailEsqueciMinhaSenha(cpf, cnpj);
      setLoading(false);
      toast.success(
        'O link de redefinição de senha foi enviado para email cadastrado.',
        {autoClose: 2500}
      );

      history.push('/');
    } catch (error) {
      toast.error(
        'Não foi possível realizar sua solicitação, por favor tente mais tarde',
        {autoClose: 2500}
      );
    }
  }

  return (
    <div className="all-content">
      <span className="container page-title">
        Sistema de Gestão de Cobranças com Pix
      </span>

      <div className="container">
        <div className="div-form col-md-12">
          <main className="content-esqueci-senha row">
            <div className="content-esqueci-senha__logo">
              <Logo></Logo>
            </div>
            <h1 className="reset-password-title">Esqueci minha Senha</h1>
            <div className="content__text content__style  ml-5 mr-5 mb-4 text-center">
              <p>Favor informar os dados abaixo para receber uma nova senha</p>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <InputLogin
                placeholder="CPF"
                id="input-cpf"
                type="text"
                name="cpf"
                inputRef={register({
                  required: true,
                  maxLength: 14,
                  validate: {
                    cpf: value => {
                      return validaApenasCpf(value);
                    },
                  },
                })}
                erros={errors}
                inputMaxLength="14"
                mask={cpfMask}
              />

              <InputLogin
                placeholder="CNPJ"
                id="input-cnpj"
                type="text"
                name="cnpj"
                inputRef={register({
                  required: true,
                  maxLength: 18,
                  validate: {
                    cnpj: value => {
                      return validaApenasCnpj(value);
                    },
                  },
                })}
                erros={errors}
                inputMaxLength="18"
                mask={cnpjMask}
              />

              <div className="col-md-12">
                <Button
                  className="btn-purple btn-reset-password col-md-12"
                  type="submit"
                  disabled={isLoading}
                  variant="custom"
                >
                  {isLoading ? 'Loading…' : 'Recuperar senha'}
                </Button>
              </div>
            </Form>
          </main>
        </div>
      </div>
    </div>
  );
};
export default RecuperarSenha;
