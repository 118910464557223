import React, {useEffect, useState} from 'react';

import {statusEnvioByDate} from '../../../../api/dashboard-api';
import UILoad from '../../../../components/UI/Load';
import {
  IDashboardFilters,
  IStatusEnvio,
} from '../../../../models/dashboard-model';
interface Filtros {
  filter: IDashboardFilters;
}
const StatusEnvio: React.FC<Filtros> = ({filter}) => {
  const [status, setStatus] = useState<IStatusEnvio>({
    enviados: 0,
    recebidos: 0,
  });
  const [loading, setLoading] = useState(false);

  async function getStatusEnvioByDate(filter) {
    setLoading(true);
    const result: IStatusEnvio = await statusEnvioByDate(filter);
    setStatus(result);
    setLoading(false);
  }

  useEffect(() => {
    if (filter) {
      getStatusEnvioByDate(filter);
    }
  }, [filter]);
  return (
    <div className={'dashboard-boleto__content dashboard-boleto__border'}>
      {loading ? (
        <UILoad />
      ) : (
        <table className="boleto-table">
          {status.enviados > 0 && status.recebidos > 0 ? (
            <>
              <thead className="dashboard-boleto__table">
                <tr>
                  <th>Status Envio</th>
                  <th>Quantidade</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="dashboard-categoria__yellow">Enviados</td>
                  <td>{status.enviados}</td>
                </tr>
                <tr>
                  <td className="dashboard-categoria__green">Recebidos</td>
                  <td>{status.recebidos}</td>
                </tr>
              </tbody>
            </>
          ) : (
            <>
              <thead>
                <tr>
                  <th className="dashboard-boleto__label">Status Envio</th>
                </tr>
              </thead>
              <tbody>
                <tr className="dashboard-boleto__no-info">
                  <td>
                    <span className="dashboard-boleto__span dashboard-boleto__zero">
                      Não há dados disponíveis para o período selecionado.
                    </span>
                  </td>
                </tr>
              </tbody>
            </>
          )}
        </table>
      )}
    </div>
  );
};

export default StatusEnvio;
