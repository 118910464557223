import React, {useEffect, useState} from 'react';
import './styles.scss';
import {Button, Table} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {
  FaUserPlus,
  FaSearch,
  FaBroom,
  FaBan,
  FaRegSave,
  FaSortAlphaDown,
  FaSortAlphaUpAlt,
} from 'react-icons/fa';

import cpfcnpjMask from '../../../../../src/utils/masks/cpfcnpjMask';
import {getClientsInGroup, getClientsOutOfGroup} from '../../../../api';
import {ICliente} from '../../../../models';
import noEmptyMask from '../../../../utils/masks/noEmptyMask';
import BtnDefault from '../../../Buttons/BtnDefault';
import InputCheckboxDefault from '../../../Inputs/InputCheckboxDefault';
import InputDefault from '../../../Inputs/InputDefault';
import InputRadioDefault from '../../../Inputs/InputRadioDefault';
import PaginationDefault from '../../../PaginationDefault';
import SpinnerLoader from '../../../Spinner';
import TableRowClientGroupModal from '../../../TableRows/TableRowClientGroupModal';
import Titulo from '../../../Titulo';
import UIContent from '../../../UI/Content';
import ModalEditClientGroup from '../../ModalClientGroupEdit';

interface ISecondStageClientGroup {
  handleCloseModal: Function;
  handleUpdateGroup: Function;
  clientsIdsAlreadyInserted: Set<string>;
  groupId: string;
}

const FormSecondStepGroupRegister: React.FC<ISecondStageClientGroup> = ({
  handleCloseModal,
  handleUpdateGroup,
  clientsIdsAlreadyInserted,
  groupId,
}) => {
  const [clientsInGroup, setClientsInGroup] = useState<ICliente[]>([]);
  const [clientsOutGroup, setClientsOutGroup] = useState<ICliente[]>([]);
  const [clientsIds] = useState<Set<string>>(clientsIdsAlreadyInserted);
  const [clientUpdated, setClientUpdated] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState<string>('');
  const [cpfCnpj, setCpfCnpj] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [showClientModal, setShowClientModal] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [orderSort, setOrderSort] = useState(true);
  const [isSelectAllChecked, setSelectAllChecked] = useState(false);
  const [isClientsInGroupSelected, setIsClientsInGroupSelected] = useState(
    true
  );
  const [limit] = useState(7);

  const {handleSubmit, reset, register, setValue} = useForm();

  const CLIENTS_SELECT_RADIO_INPUT = 'CLIENTS_SELECT_RADIO_INPUT';

  const handleCloseCliente = () => {
    setShowClientModal(false);
  };

  const handleSelectAllClick = () => {
    if (!isSelectAllChecked)
      clientsInGroup.map(item => {
        if (!clientsIds.has(item.id)) clientsIds.add(item.id);
      });
    else
      clientsInGroup.map(item => {
        if (clientsIds.has(item.id)) clientsIds.delete(item.id);
      });
    setSelectAllChecked(!isSelectAllChecked);
    setUpdateTable(!updateTable);
  };

  const checkSelectedClients = () => {
    const areAllClientsSelected = clientsInGroup.map((item: ICliente) => {
      return clientsIds.has(item.id);
    });
    if (!areAllClientsSelected.includes(false)) setSelectAllChecked(true);
    else setSelectAllChecked(false);
  };

  const listAllClients = async (pageNumber: number, order: string) => {
    setIsLoading(true);

    const receivedClients = await getClientsInGroup(
      groupId,
      pageNumber,
      limit,
      order
    );
    setClientsInGroup(receivedClients.data);
    setTotalCount(receivedClients.totalCount);
    setClientUpdated(!clientUpdated);
    setCurrentPage(pageNumber);
    setIsLoading(false);
  };

  if (updateTable) {
    const order = orderSort ? 'ASC' : 'DESC';
    listAllClients(currentPage, order);
    setUpdateTable(!updateTable);
  }

  useEffect(() => {
    checkSelectedClients();
  }, [clientUpdated]);

  useEffect(() => {
    const order = orderSort ? 'ASC' : 'DESC';
    listAllClients(currentPage, order);
  }, [orderSort]);

  const filtrar = () => {
    const order = orderSort ? 'ASC' : 'DESC';
    listAllClients(currentPage, order);
  };

  const clearFilter = () => {
    if (name || cpfCnpj || email) {
      reset();
      setName('');
      setCpfCnpj('');
      setEmail('');
      return;
    }
    listAllClients(1, orderSort ? 'ASC' : 'DESC');
  };

  const loadClientsOutGroup = async () => {
    setIsLoading(true);
    setIsClientsInGroupSelected(false);
    const response = await getClientsOutOfGroup(groupId, currentPage, limit);
    setClientsOutGroup(response.data);
    setTotalCount(response.totalCount);
    setIsLoading(false);
  };

  const loadClientsInGroup = async () => {
    setIsLoading(true);
    setIsClientsInGroupSelected(true);
    const response = await getClientsInGroup(groupId, currentPage, limit);
    setClientsInGroup(response.data);
    setTotalCount(response.totalCount);
    setIsLoading(false);
  };

  const returnCollectionToBeRendered = () => {
    if (isClientsInGroupSelected) return clientsInGroup;
    return clientsOutGroup;
  };

  const returnMethodToRunInPagination = () => {
    if (isClientsInGroupSelected) {
      return listAllClients(currentPage, orderSort ? 'ASC' : 'DESC');
    }
    return loadClientsOutGroup();
  };

  useEffect(() => {
    returnMethodToRunInPagination();
  }, [currentPage]);

  return (
    <UIContent>
      <Titulo> Passo 2/2 - Adicione clientes ao seu novo grupo: </Titulo>

      <form className="form-modal" onSubmit={handleSubmit(filtrar)}>
        <div className="row">
          <div className="form-group col-md-4">
            <InputDefault
              name="nome"
              type="text"
              placeholder="Nome cliente"
              id="input-nome-cliente"
              onChange={e => setName(e.target.value)}
              value={name}
              inputMaxLength="300"
              inputRef={register({})}
              mask={noEmptyMask}
            />
          </div>
          <div className="form-group col-md-4">
            <InputDefault
              name="cpfCnpj"
              type="text"
              placeholder="Cpf ou Cnpj"
              id="inpu-cpfcnpj-cliente"
              onChange={e => setCpfCnpj(e.target.value)}
              value={cpfCnpj}
              inputMaxLength="18"
              mask={cpfcnpjMask}
              inputRef={register({})}
              onBlur={e =>
                setValue('cpfCnpj', cpfcnpjMask(e.target.value.slice(0, 18)))
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="d-flex justify-content-start">
            <BtnDefault
              name="btn-buscar"
              className="btn-custom btn-save ml-1"
              id="btn-buscar"
              type="submit"
              variantClass="custom"
            >
              <FaSearch size={12} className="mr-1" />
              Buscar
            </BtnDefault>
            <BtnDefault
              action={clearFilter}
              name="btn-limpar"
              id="btn-limpar"
              className="btn-custom btn-save p-2 flex-grow-1 bd-highlight"
              variantClass="custom"
            >
              <FaBroom size={12} className="mr-1" />
              Limpar filtros
            </BtnDefault>
          </div>
        </div>
      </form>

      <div className="row pl-4">
        <div className="col-3">
          <InputRadioDefault
            className="form-check-input"
            name={CLIENTS_SELECT_RADIO_INPUT}
            id={CLIENTS_SELECT_RADIO_INPUT + 1}
            onClick={loadClientsInGroup}
            inputRef={register({
              required: true,
            })}
            classLabel="label-item-quando"
            defaultChecked={isClientsInGroupSelected}
          >
            Clientes adicionados
          </InputRadioDefault>
        </div>

        <div>
          <InputRadioDefault
            className="form-check-input"
            name={CLIENTS_SELECT_RADIO_INPUT}
            id={CLIENTS_SELECT_RADIO_INPUT + 2}
            onClick={loadClientsOutGroup}
            inputRef={register({
              required: true,
            })}
            classLabel="label-item-quando"
            defaultChecked={!isClientsInGroupSelected}
          >
            Clientes não adicionados
          </InputRadioDefault>
        </div>
      </div>

      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <Table responsive hover>
          <thead className="table-header">
            <tr>
              <th className="align-middle check">
                <InputCheckboxDefault
                  name={''}
                  onClick={handleSelectAllClick}
                  checked={isSelectAllChecked}
                />
              </th>
              <th>
                <div
                  className="thContainerActive"
                  onClick={() => setOrderSort(!orderSort)}
                >
                  {orderSort ? (
                    <FaSortAlphaDown size={20} />
                  ) : (
                    <FaSortAlphaUpAlt size={20} />
                  )}
                  Nome
                </div>
              </th>
              <th>
                <div className="th">Email</div>
              </th>
              <th>
                <div className="th">CPF/CNPJ</div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {returnCollectionToBeRendered().map((cliente: ICliente, index) => {
              return (
                <TableRowClientGroupModal
                  checked={clientsIds.has(cliente.id)}
                  client={cliente}
                  key={index}
                  clientsIds={clientsIds}
                  setClientUpdated={() => {
                    setClientUpdated(!clientUpdated);
                  }}
                />
              );
            })}
          </tbody>
        </Table>
      )}
      {!isLoading &&
        clientsInGroup !== undefined &&
        clientsInGroup.length === 0 && (
          <div className="msg-table ">
            <p>Nenhum cliente cadastrado.</p>
          </div>
        )}
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <PaginationDefault
          currentPage={currentPage}
          totalCount={totalCount}
          limit={limit}
          action={(value: number) => {
            setCurrentPage(value);
            setSelectAllChecked(false);
          }}
          totalNaPagina={returnCollectionToBeRendered().length}
        />
      )}
      {showClientModal ? (
        <ModalEditClientGroup
          icon={FaUserPlus}
          variantClass="btn-purple btn-purple--round"
          setUpdateTable={setUpdateTable}
          showModalCliente={showClientModal}
          handleCloseCliente={handleCloseCliente}
        />
      ) : (
        ''
      )}

      <div className="d-flex justify-content-center mt-4">
        <Button
          className="btn-custom btn-cancel"
          variant="custom"
          onClick={() => handleCloseModal()}
        >
          <FaBan size={13} className="mr-1" />
          Cancelar
        </Button>

        <Button
          type="submit"
          variant="custom"
          className="btn-custom btn-save"
          disabled={disableSaveButton}
          onClick={() => {
            setDisableSaveButton(true);
            handleUpdateGroup(clientsIds);
          }}
        >
          <FaRegSave size={13} className="mr-1" />
          Salvar
        </Button>
      </div>
    </UIContent>
  );
};

export default FormSecondStepGroupRegister;
