import React, {useRef, useState} from 'react';
import {Overlay, Popover} from 'react-bootstrap';
import {FaEllipsisV, FaPen, FaRegEye, FaTrashAlt} from 'react-icons/fa';

import {
  BillingRuleModel,
  BillingRuleType,
  InterestFineDiscountType,
} from '../../../models/billing-rule-model';
import {newMoneyMask} from '../../../utils/masks/moneyMask';

interface TableRowProps {
  rule: BillingRuleModel;
  showModalMethod: Function;
  deleteRuleMethod: Function;
}
const TableRowBillingRule: React.FC<TableRowProps> = ({
  rule,
  showModalMethod,
  deleteRuleMethod,
}) => {
  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);

  const ref = useRef(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleClosepop = () => setShow(false);
  return (
    <>
      <tr key={rule.id}>
        <td className="left">
          {rule.type === BillingRuleType.FIXED ? 'Fixa' : 'Dinâmica'}
        </td>
        <td className="left">{rule.name}</td>
        <td className="right">{`R$ ${newMoneyMask(rule.value.toString())}`}</td>
        <td className="left">{rule.description}</td>
        <td>{rule.expirationDay}</td>
        <td>{rule.daysAfterExpiration}</td>
        <td className="right">
          {rule.fineType === InterestFineDiscountType.VALUE
            ? `R$ ${rule.fine.toFixed(2).toString().replace('.', ',')}`
            : `${rule.fine.toString().replace('.', ',')} %`}
        </td>
        <td className="right">
          {rule.interestType === InterestFineDiscountType.VALUE
            ? `R$ ${rule.interest.toFixed(2).toString().replace('.', ',')}`
            : `${rule.interest.toString().replace('.', ',')} %`}
        </td>
        <td className="right">
          {rule.discountType === InterestFineDiscountType.VALUE
            ? `R$ ${rule.discount.toFixed(2).toString().replace('.', ',')}`
            : `${rule.discount.toString().replace('.', ',')} %`}
        </td>
        <td>
          <FaEllipsisV style={{cursor: 'pointer'}} onClick={handleClick} />
          <Overlay
            show={show}
            target={target}
            placement="bottom"
            container={ref.current}
            containerPadding={20}
            onHide={handleClosepop}
            rootClose={true}
            transition={false}
          >
            <Popover id="popover-contained">
              <Popover.Title as="h3">{'Ações'}</Popover.Title>
              <Popover.Content>
                <span className="icones-popover">
                  <span
                    className="icones-popover__text"
                    onClick={() => deleteRuleMethod(rule.id)}
                  >
                    <FaTrashAlt className="icones-popover__icone" size="18" />

                    <span className="icones-popover__text">Excluir</span>
                  </span>

                  <span onClick={() => showModalMethod(rule.id, true)}>
                    <FaPen className="icones-popover__icone" size="18" />
                    <span className="icones-popover__text">Editar</span>
                  </span>

                  <span onClick={() => showModalMethod(rule.id, false)}>
                    <FaRegEye className="icones-popover__icone" size="18" />
                    <span className="icones-popover__text">Ver</span>
                  </span>
                </span>
              </Popover.Content>
            </Popover>
          </Overlay>
        </td>
      </tr>
    </>
  );
};

export default TableRowBillingRule;
