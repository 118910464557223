import React, {InputHTMLAttributes, useState} from 'react';
import {IconBaseProps} from 'react-icons';
import {FaSearch} from 'react-icons/fa';
import {components} from 'react-select';
import CreatableSelect from 'react-select/async-creatable';

import './styles.scss';
import {autocompleteClientes} from '../../api';
import {IInputValue} from '../../models/forms-model';

interface IModalSearchName extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  find: string;
  sizeColMdInput?: number;
  promiseOptions?: (inputValue) => Promise<ReadonlyArray<unknown>> | void;
  setFieldValue?: (inputValue) => void;
  handleCreate?: (inputValue) => void;
  isCreatable?: boolean;
  createMessage?: (inputValue) => React.ReactNode;
  id: string;
  disabled?: boolean;
  onClose: void;
  getClientId: (inputValue) => string;
}

const ModalSearchName = ({
  id = 'modal-search-name',
  onClose = () => {},
  placeholder = 'Digite Nome ou CPF/CNPJ',
  sizeColMdInput = 12,
  getClientId = (value: string) => {},
  setUpdateTable = (b: boolean) => {},
}) => {
  const handleOutsideClick = event => {
    if (event.target.id === id) onClose();
  };

  const PlaceHolderMerged: React.FC = () => (
    <div>
      {placeholder}
      <FaSearch className="icone-input" />
    </div>
  );

  function noOptionsMessage(inputValue: IInputValue) {
    if (inputValue.inputValue) {
      return 'Nenhum cliente encontrado';
    }
    return 'Nenhum carácter digitado';
  }

  function loadingMessage() {
    return 'Carregando...';
  }

  function defaultCreateMessage(inputValue: string) {
    return `Criar "${inputValue}"`;
  }

  function loadClientData(clientSelected: IInputValue) {
    clientSelected ? getClientId(clientSelected.value) : getClientId('');
    setUpdateTable(true);
    onClose();
  }

  return (
    <>
      <div id={id} className="modal-search-client" onClick={handleOutsideClick}>
        <div className="container-modal-search-client">
          <div className="header-modal-search-client">
            <button
              className="header-modal-search-client-close"
              onClick={onClose}
            />
            <h3>Busca por Cliente</h3>
          </div>

          <div className="content-modal-search-client">
            <div className={`col-md-${sizeColMdInput}`}>
              <CreatableSelect
                cacheOptions
                className="input-modal-name-search-creatable"
                classNamePrefix="input-modal-name-search-creatable"
                loadOptions={autocompleteClientes}
                placeholder={<PlaceHolderMerged />}
                isClearable
                allowCreateWhileLoading={false}
                onChange={inputValue => loadClientData(inputValue)}
                noOptionsMessage={noOptionsMessage}
                //onCreateOption={handleCreate}
                loadingMessage={loadingMessage}
                id={id}
                isValidNewOption={() => false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalSearchName;
