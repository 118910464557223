import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {Controller, useForm} from 'react-hook-form';
import {FaArrowLeft, FaRegEye, FaRegSave} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

import {editarUsuario, exibirUsuario} from '../../api/usuario-api';
import InputDefault from '../../components/Inputs/InputDefault';
import InputPassword from '../../components/Inputs/InputPassword';
import InputSelect from '../../components/Inputs/InputSelect';
import SpinnerLoader from '../../components/Spinner';
import Titulo from '../../components/Titulo';
import UIContainer from '../../components/UI/Container';
import {useAuth} from '../../context';
import {INovoUsuario, IUsuarios} from '../../models/usuario-model';
import {statusOptions} from '../../shared/constantes';
import cpfMask from '../../utils/masks/cpfMask';
import noEmptyMask from '../../utils/masks/noEmptyMask';
import phoneMask from '../../utils/masks/phoneMask';
import cpfValidator, {
  cnpjValidator,
} from '../../utils/validations/cpfCnpjValidator';
import {senhaValidator} from '../../utils/validations/senhaValidator';
import {isMaster} from '../../utils/validations/userIsAdminstrator';
import './styles.scss';
import {cnpjMask} from '../../utils/masks/cpfcnpjMask';

const DadosUsuario: React.FC = () => {
  const {register, errors, handleSubmit, control} = useForm<INovoUsuario>();

  const {usuarioLogado} = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [usuario, setUsuario] = useState<IUsuarios>();
  const [isRequired, setRequired] = useState(false);

  const history = useHistory();

  const handleEditar = async data => {
    const senhasValidas = validaSenhas(data.password, data.confirmarSenha);
    if (!senhasValidas) {
      toast.error('Senha não compatível. ', {autoClose: 2500});
      return;
    }
    const editUsuario = {
      cpf: data.CPF.replace(/[^\d]+/g, ''),
      cnpj: data.CNPJ.replace(/[^\d]+/g, ''),
      email: data.email,
      nome: data.nome,
      telefone: data.telefone.replace(/\D+/g, ''),
      status: data.status,
      password: data.password ? data.password : undefined,
    };
    try {
      setIsLoading(true);
      await editarUsuario(
        usuarioLogado.id,
        editUsuario.cpf,
        editUsuario.cnpj,
        editUsuario.email,
        editUsuario.nome,
        editUsuario.status,
        editUsuario.telefone,
        editUsuario.password
      );
      toast.success('Usuário editado com sucesso!', {autoClose: 2500});
    } catch (e) {
      console.error(e);
    }
    carregarUsuario(usuarioLogado.id);
  };

  async function carregarUsuario(id: string) {
    setIsLoading(true);
    setUsuario(await exibirUsuario(id));
    setIsLoading(false);
  }

  function validaSenhas(senha: string, confirmacao: string) {
    if (senha === confirmacao || (!senha && !confirmacao)) {
      return true;
    }
    return false;
  }

  const handleRedirect = () => {
    if (isMaster(usuarioLogado)) {
      history.push('/termos-uso');
    } else {
      history.push('/dashboard');
    }
  };
  useEffect(() => {
    if (usuarioLogado) {
      carregarUsuario(usuarioLogado.id);
    }
  }, [usuarioLogado]);

  return (
    <UIContainer>
      <Titulo> Dados Usuário </Titulo>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <form
          className="form-cadastro col-md-12 "
          onSubmit={handleSubmit(handleEditar)}
        >
          <div className="titulo-sessao">Informações do usuário</div>
          <div className="form-row">
            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="input-nome">
                Nome completo
              </label>
              <InputDefault
                name="nome"
                type="text"
                placeholder=""
                id="input-nome"
                inputMaxLength={'100'}
                erros={errors}
                mask={noEmptyMask}
                defaultValue={usuario.nome}
                inputRef={register({
                  required: true,
                })}
              />
            </div>
            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="input-cpf">
                CPF
              </label>
              <InputDefault
                name="CPF"
                type="text"
                id="input-cpf"
                inputRef={register({
                  required: true,
                  minLength: 14,
                  maxLength: 14,
                  validate: {
                    cpf: value => {
                      return cpfValidator(value);
                    },
                  },
                })}
                defaultValue={cpfMask(usuario.cpf)}
                mask={cpfMask}
                erros={errors}
                inputMaxLength="14"
              />
            </div>
            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="input-cnpj">
                CNPJ
              </label>
              <InputDefault
                name="CNPJ"
                type="text"
                id="input-cnpj"
                inputRef={register({
                  required: false,
                  minLength: 18,
                  maxLength: 18,
                  validate: {
                    cnpj: value => {
                      return cnpjValidator(value);
                    },
                  },
                })}
                defaultValue={cnpjMask(usuario.cnpj)}
                mask={cnpjMask}
                erros={errors}
                inputMaxLength="18"
              />
            </div>
            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="input-email">
                Email{' '}
              </label>
              <InputDefault
                name="email"
                type="text"
                id="input-email"
                inputMaxLength={'100'}
                inputRef={register({
                  required: true,
                  maxLength: 100,
                  minLength: 0,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Entre com um e-mail válido.',
                  },
                })}
                defaultValue={usuario.email}
                erros={errors}
                mask={noEmptyMask}
              />
            </div>
            <div className="form-group col-md-3">
              <label className="form-label" htmlFor="input-status">
                Status
              </label>
              <Controller
                control={control}
                defaultValue={usuario.status}
                name={'status'}
                inputRef={register({
                  required: true,
                })}
                render={({onChange, value, ref}) => (
                  <InputSelect
                    id="input-status"
                    inputRef={ref}
                    classNamePrefix="addl-class"
                    options={statusOptions}
                    erros={errors}
                    value={statusOptions.find(c => c.value === value)}
                    isDisabled={true}
                    onChange={(val: {value}) => {
                      onChange(val.value);
                    }}
                  />
                )}
              />
            </div>
            <div className="form-group col-md-3">
              <label className="form-label" htmlFor="input-telefone">
                Telefone
              </label>
              <InputDefault
                name="telefone"
                type="text"
                id="input-telefone"
                erros={errors}
                inputMaxLength="14"
                mask={phoneMask}
                defaultValue={phoneMask(usuario.telefone)}
                inputRef={register({
                  required: true,
                  maxLength: 14,
                  minLength: 13,
                })}
              />
            </div>
            <div className="form-group col-md-3">
              <label className="form-label" htmlFor="input-password">
                Senha
              </label>
              <InputPassword
                className="input-default"
                name="password"
                type="password"
                id="input-password"
                erros={errors}
                icon={FaRegEye}
                autocomplete="off"
                inputRef={register({
                  validate: {
                    password: value => {
                      if (value) {
                        setRequired(true);
                        return senhaValidator(value);
                      } else {
                        setRequired(false);
                      }
                    },
                  },
                })}
              />
            </div>
            <div className="form-group col-md-3">
              <label className="form-label" htmlFor="input-confirmarSenha">
                Confirmar Senha
              </label>
              <InputPassword
                className="input-default"
                name="confirmarSenha"
                id="input-confirmarSenha"
                icon={FaRegEye}
                type="password"
                erros={errors}
                autocomplete="off"
                inputRef={register({
                  required: isRequired,
                  validate: {
                    password: value => {
                      if (isRequired) {
                        return senhaValidator(value);
                      }
                    },
                  },
                })}
              />
            </div>
          </div>

          <div className="buttons-group justify-content-end">
            <Button
              className="btn-custom btn-cancel"
              variant="custom"
              onClick={handleRedirect}
            >
              <FaArrowLeft size={13} className="mr-1" />
              Voltar
            </Button>
            <Button
              type="submit"
              variant="custom"
              className="btn-custom btn-save"
            >
              <FaRegSave size={13} className="mr-1" />
              Salvar
            </Button>{' '}
          </div>
        </form>
      )}
    </UIContainer>
  );
};

export default DadosUsuario;
