import React, {useEffect, useState} from 'react';
import {InputGroup, Table} from 'react-bootstrap';
import {Controller, useForm} from 'react-hook-form';
import {
  FaSearch,
  FaCalendar,
  FaBroom,
  FaGripVertical,
  FaSortAlphaDown,
  FaSortAlphaUpAlt,
  FaSortAmountDown,
  FaSortAmountUpAlt,
  FaSortNumericDown,
  FaSortNumericUpAlt,
} from 'react-icons/fa';
import {toast} from 'react-toastify';

import {autocompleteClientes} from '../../api';
import {listarCategorias} from '../../api/categoria-api';
import {listarCobrancas} from '../../api/cobranca-api';
import DatePickerBr from '../../components/DatePickerBr';
import InputSelect from '../../components/Inputs/InputSelect';
import useKeypress from '../../hooks/UseKeyPress';
import {ICategoria} from '../../models/categoria-model';
import {ICliente, INovoCliente} from '../../models/cliente-model';
import {ICobranca, IFiltroBoleto, IListaCobranca} from '../../models/cobranca';
import {IInputValue} from '../../models/forms-model';
import {PaginationModel} from '../../models/pagination-model';
import {BilletOrderByEnum} from '../../pages/BillingBatch/enum/index';
import {statusCobrancaEnum, shippingOptions} from '../../shared/constantes';
import {statusCobranca} from '../../shared/enumMap';
import noEmptyMask from '../../utils/masks/noEmptyMask';
import numberMask from '../../utils/masks/numberMask';
import Accordion from '../Accordion/Accordion';
import BtnDefault from '../Buttons/BtnDefault';
import InputDefault from '../Inputs/InputDefault';
import InputNameSearch from '../Inputs/InputNameSearch';
import InputSearchCreatable from '../Inputs/InputSearchCreatable';
import ModalDefaultCancelar from '../ModalDefault/ModalDefaultCancelar/index';
import ModalDefaultEditarCobranca from '../ModalDefault/ModalDefaultEditarCobranca';
import ModalDefaultEnviar from '../ModalDefault/ModalDefaultEnviar';
import PaginationDefault from '../PaginationDefault';
import SpinnerLoader from '../Spinner';
import TableRow from '../TableRows';
import Titulo from '../Titulo';
import UIContent from '../UI/Content';

import './styles.scss';

const MainBuscaBoleto: React.FC = () => {
  const [cobrancas, setCobrancas] = useState<IListaCobranca[] | [] | undefined>(
    []
  );

  const [categorias, setCategorias] = useState([]);

  const categoryOptions = [];

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(30);
  const [isLoading, setIsLoading] = useState(false);
  const [orderSort, setOrderSort] = useState('DESC');
  const [orderBySort, setOrderBySort] = useState(BilletOrderByEnum.ISSUE_DATE);

  const {control, register, setValue} = useForm<ICobranca>();

  async function listasTodasCobrancas(pageNumber: number) {
    const categoria_id = category;

    const params: IFiltroBoleto = {
      numeroBoleto,
      nomeCliente,
      dtEmissaoStart,
      dtEmissaoEnd,
      dtVencimentoStart,
      dtVencimentoEnd,
      statusEnvio,
      categoria_id,
      statusPagamento,
      tipoCobranca,
    };

    await listar(pageNumber, params, orderSort, orderBySort);
  }

  async function listar(
    pageNumber: number,
    params?: IFiltroBoleto,
    order?: string,
    orderBy?: BilletOrderByEnum
  ) {
    setIsLoading(true);
    const todasCobrancas: PaginationModel = await listarCobrancas(
      pageNumber - 1 > 0 ? pageNumber - 1 : 0,
      limit,
      params,
      order,
      orderBy
    );

    setCobrancas(todasCobrancas.data);
    setTotalCount(todasCobrancas.totalCount);
    setCurrentPage(pageNumber);
    setIsLoading(false);
  }

  const handleSort = (order: string, orderBy: BilletOrderByEnum) => {
    order = orderSort === 'ASC' ? 'DESC' : 'ASC';
    setOrderSort(order);
    setOrderBySort(orderBy);
    listar(currentPage, undefined, order, orderBy);
  };

  const {reset} = useForm();

  const [numeroBoleto, setNumeroBoleto] = useState<string>('');
  const [nomeCliente, setNomeCliente] = useState<string>('');

  const [categoryIsNull, setCategoryAsNull] = useState(false);
  const [category, setCategory] = useState(undefined);

  const [statusEnvioIsNull, setStatusEnvioAsNull] = useState(false);
  const [statusEnvio, setStatusEnvio] = useState(undefined);

  const [statusPagamentoIsNull, setStatusPagamentoAsNull] = useState(false);
  const [statusPagamento, setStatusPagamento] = useState(undefined);

  const [dtEmissaoStart, setDtEmissaoStart] = useState<Date | undefined>();
  const [dtEmissaoEnd, setDtEmissaoEnd] = useState<Date | undefined>();
  const [dtVencimentoStart, setDtVencimentoStart] = useState<Date>();
  const [dtVencimentoEnd, setDtVencimentoEnd] = useState<Date>();

  const [idCobrancaModal, setIdCobrancaModal] = useState<string | undefined>();
  const [idCobrancaRecorrenciaModal, setIdCobrancaRecorrenciaModal] = useState<
    string | undefined
  >();
  const [tipoCobrancaIsNull, setTipoCobrancaAsNull] = useState<boolean>(false);
  const [tipoCobranca, setTipocobranca] = useState(undefined);
  const [expand, setExpand] = useState(false);

  const [nameSearchValue, setNameSearchValue] = useState<IInputValue>(null);

  const resetForm = () => {
    reset();

    setDtVencimentoStart(undefined);
    setDtVencimentoEnd(undefined);
    setDtEmissaoStart(undefined);
    setDtEmissaoEnd(undefined);
    setNomeCliente(undefined);
    advancedFilterClean();
    listar(1);
    setNameSearchValue(null);
  };
  const validateDates = () => {
    if (new Date(dtVencimentoStart) < new Date(dtEmissaoStart)) {
      toast.warning(
        'Por favor, Insira uma data de vencimento superior ou igual a data de emissão.',
        {
          autoClose: 2500,
        }
      );
      return false;
    }
    return true;
  };

  useKeypress('Enter', async () => {
    document.getElementsByName('btn-buscar')[0].click();
  });

  const filtrar = async () => {
    validateDates();
    await listasTodasCobrancas(1);
  };

  async function listarTodasCategorias() {
    setIsLoading(true);
    const todasCategorias: PaginationModel = await listarCategorias(0, 100);

    todasCategorias.data.map((cat: ICategoria) =>
      categoryOptions.push({value: cat.id, label: cat.nome})
    );
    categoryOptions.push({value: '', label: 'Sem categoria'});
    setCategorias(categoryOptions);
    setIsLoading(false);
  }

  useEffect(() => {
    listarTodasCategorias();
    listasTodasCobrancas(currentPage);
  }, []);

  const [showModalCancelar, setShowModalCancelar] = useState(false);
  const [showModalEnviar, setShowModalEnviar] = useState(false);
  const [showModaEditar, setShowModalEditar] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const [idCobranca, setIdCobranca] = useState<string | undefined>();

  const exibirModal = (cobranca: IListaCobranca) => {
    setIdCobrancaModal(cobranca.idcobranca);
    setIdCobrancaRecorrenciaModal(cobranca.idcontrolecobranca);
    setShowModalCancelar(true);
  };

  const exibirModalEnviar = (cobranca: IListaCobranca) => {
    setIdCobrancaModal(cobranca.idcobranca);
    setIdCobrancaRecorrenciaModal(cobranca.idcontrolecobranca);
    setShowModalEnviar(true);
  };

  const cancelarBoleto = (boleto: IListaCobranca) => {
    if (
      boleto.statuscobranca === 'PAGA' ||
      boleto.statuscobranca === 'EM_PROCESSAMENTO'
    ) {
      toast.warning(
        `Cobrança com status ${statusCobranca(
          boleto.statuscobranca
        )} não pode ser cancelada!`,
        {autoClose: 2500}
      );
      return;
    }
    if (boleto.statuscobranca === 'CANCELADA') {
      toast.warning('Cobrança já se encontra cancelada!', {autoClose: 2500});
      return;
    }
    exibirModal(boleto);
  };

  const enviarBoletoCliente = async (cobranca: IListaCobranca) => {
    exibirModal(cobranca);
  };

  const handleCloseCancelar = () => {
    setShowModalCancelar(false);
    listasTodasCobrancas(currentPage);
  };
  const handleCloseEnviar = () => {
    setShowModalEnviar(false);
    listasTodasCobrancas(currentPage);
  };
  const handleCloseEditar = () => {
    setShowModalEditar(false);
  };
  const advancedFilterClean = () => {
    setTipoCobrancaAsNull(true);
    setCategoryAsNull(true);
    setStatusEnvioAsNull(true);
    setStatusPagamentoAsNull(true);

    setStatusEnvio(undefined);
    setStatusPagamento(undefined);
    setCategory(undefined);
    setNumeroBoleto(null);
    setTipocobranca(undefined);
    setValue('numero-boleto', '');
  };
  const handlerExpandAccordion = () => {
    setExpand(!expand);
    advancedFilterClean();
  };
  if (updateTable) {
    listasTodasCobrancas(currentPage);
    setUpdateTable(!updateTable);
  }

  const exibirModalEditar = (id: string) => {
    setIdCobranca(id);
    setShowModalEditar(true);
  };

  const [clienteId, setClienteId] = useState('');
  const [isNewClient, setIsNewClient] = useState(true);
  const [tabelaVisivel, setTabelaVisivel] = useState(false);
  const [clienteInfosLoaded, setClienteInfosLoaded] = useState<ICliente>(
    undefined
  );
  const [estado, setEstado] = useState<boolean>(false);
  const [isAvailable, setAvailable] = useState<boolean>(false);

  function loadClienteData(clienteSelected: IInputValue) {
    clienteSelected && setClienteId(clienteSelected.value);
    clienteSelected && setNomeCliente(clienteSelected.label.split('| ')[1]);
  }

  function handleResetForm() {
    setIsLoading(true);
    setClienteId('');
    setIsNewClient(true);
    setTabelaVisivel(true);
    setClienteInfosLoaded(undefined);
    setIsLoading(false);
  }

  function clearValueOfInputs() {
    const cpfCliente = document.querySelector('#input-cpf') as HTMLInputElement;
    const nome = document.querySelector('#input-nome') as HTMLInputElement;
    const email = document.querySelector('#input-email') as HTMLInputElement;
    const telefone = document.querySelector(
      '#input-telefone'
    ) as HTMLInputElement;
    const cep = document.querySelector('#input-cep') as HTMLInputElement;
    const enderecoCompleto = document.querySelector(
      '#input-endereco-completo'
    ) as HTMLInputElement;
    const enderecoNumero = document.querySelector(
      '#input-endereco-numero'
    ) as HTMLInputElement;
    const enderecoComplemento = document.querySelector(
      '#input-endereco-complemento'
    ) as HTMLInputElement;
    const enderecoBairro = document.querySelector(
      '#input-endereco-bairro'
    ) as HTMLInputElement;
    const enderecoCidade = document.querySelector(
      '#input-endereco-cidade'
    ) as HTMLInputElement;
    const enderecoEstado = document.querySelector(
      '#enderecoEstado .react-select__input input'
    ) as HTMLInputElement;

    const inputSelect = document.querySelector('.react-select__single-value');
    if (inputSelect) {
      inputSelect.textContent = '';
    }

    if (cpfCliente) {
      cpfCliente.value = '';
    }
    if (nome) {
      nome.value = '';
    }
    if (email) {
      email.value = '';
    }
    if (telefone) {
      telefone.value = '';
    }
    if (cep) {
      cep.value = '';
    }
    if (enderecoNumero) {
      enderecoNumero.value = '';
    }
    if (enderecoCompleto) {
      enderecoCompleto.value = '';
    }
    if (enderecoComplemento) {
      enderecoComplemento.value = '';
    }
    if (enderecoBairro) {
      enderecoBairro.value = '';
    }
    if (enderecoCidade) {
      enderecoCidade.value = '';
    }
    if (enderecoEstado) {
      enderecoEstado.value = '';
    }
  }

  function clearForm() {
    let cpfClient = '';
    const cpf = document.querySelector('#input-cpf') as HTMLInputElement;
    if (cpf) {
      cpfClient = cpf.value;
    }

    if (clienteId && cpfClient) {
      handleResetForm();
    }
    setEstado(true);
    clearValueOfInputs();
    handleResetForm();
  }

  function handleCreate(value) {
    clearForm();
    setTabelaVisivel(value);
  }

  const updateLimitValue = async event => {
    const value = parseInt(event.target.value);
    await setLimit(value);
  };

  useEffect(() => {
    setIsLoading(true);
    filtrar().then(() => setIsLoading(false));
  }, [limit]);

  return (
    <UIContent>
      <div className="d-flex bd-highlight titleAndButton">
        <div className="p-2 flex-grow-1 bd-highlight">
          <Titulo> Cobranças - Busca </Titulo>
        </div>
      </div>

      <form>
        <div className="form-row ml-2">
          <div className="form-group col-md-12">
            <InputNameSearch
              find="clientes"
              name="cliente-boleto"
              id="input-buscar-cliente"
              placeholder="Nome"
              inputMaxLength="100"
              promiseOptions={autocompleteClientes}
              setFieldValue={inputValue =>
                setNameSearchValue(loadClienteData(inputValue))
              }
              sizeColMdInput={16}
              value={nameSearchValue}
            />
          </div>
          <label className="col-md-4">
            Emissão
            <div className="form-group col-md-14 d-flex boleto-datapicker">
              <InputGroup.Text className="input-default input-custom-left">
                <FaCalendar />
              </InputGroup.Text>
              <DatePickerBr
                className="input-custom-middle"
                placeholder="Quando?"
                selected={dtEmissaoStart}
                onChange={(date: Date) => setDtEmissaoStart(date)}
                classIcon="calendar-position "
              />
              <DatePickerBr
                className="input-custom-right"
                placeholder="Até"
                selected={dtEmissaoEnd}
                onChange={(date: Date) => setDtEmissaoEnd(date)}
                classIcon="calendar-position "
              />
            </div>
          </label>
          <label className="col-md-4">
            Vencimento
            <div className="form-group col-md-14 boleto-datapicker">
              <InputGroup.Text className="input-default input-custom-left">
                <FaCalendar />
              </InputGroup.Text>
              <DatePickerBr
                className="input-custom-middle"
                placeholder="Quando?"
                selected={dtVencimentoStart}
                onChange={date => setDtVencimentoStart(date)}
                classIcon="calendar-position "
              />
              <DatePickerBr
                className="input-custom-right"
                placeholder="Até"
                selected={dtVencimentoEnd}
                onChange={date => setDtVencimentoEnd(date)}
                classIcon="calendar-position "
              />
            </div>
          </label>
        </div>
        <div className="form-group col-md-12">
          <Accordion
            isExpand={expand}
            title={!expand ? 'Mais filtros' : 'Menos filtros'}
            actionAccordion={handlerExpandAccordion}
          >
            <div className="form-row">
              <div className="form-group col-md-4">
                <Controller
                  control={control}
                  name="status-envio"
                  defaultValue={''}
                  render={({onChange, value, ref}) => (
                    <InputSelect
                      placeholder="Status Envio"
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={shippingOptions}
                      value={
                        !statusEnvioIsNull
                          ? shippingOptions.find(c => c.value === value)
                          : statusEnvioIsNull
                      }
                      onChange={val => {
                        onChange(val.value);
                        setStatusEnvio(val.value);
                        setStatusEnvioAsNull(false);
                      }}
                    />
                  )}
                />
              </div>

              <div className="form-group col-md-4">
                <Controller
                  control={control}
                  defaultValue={null}
                  name="categoria"
                  render={({onChange, value, ref}) => (
                    <InputSelect
                      placeholder="Categoria"
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={categorias}
                      value={
                        !categoryIsNull
                          ? categoryOptions.find(c => c.value === value)
                          : categoryIsNull
                      }
                      onChange={val => {
                        onChange(val.value);
                        setCategory(val.value);
                        setCategoryAsNull(false);
                      }}
                    />
                  )}
                />
              </div>

              <div className="form-group col-md-4">
                <Controller
                  control={control}
                  name="status-pagamento"
                  defaultValue={null}
                  render={({onChange, value, ref}) => (
                    <InputSelect
                      placeholder="Status Cobrança"
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={statusCobrancaEnum}
                      value={
                        statusPagamentoIsNull
                          ? statusPagamentoIsNull
                          : statusCobrancaEnum.find(c => c.value === value)
                      }
                      onChange={val => {
                        onChange(val.value);
                        setStatusPagamento(val.value);
                        setStatusPagamentoAsNull(false);
                      }}
                    />
                  )}
                />
              </div>
              <div className="form-group col-md-4">
                <InputDefault
                  name="numero-boleto"
                  type="text"
                  placeholder=" N° Cobrança"
                  id="input-numero"
                  mask={numberMask}
                  inputRef={register({})}
                  value={numeroBoleto}
                  inputMaxLength="4"
                  onChange={event => {
                    setNumeroBoleto(event.target.value);
                  }}
                />
              </div>
            </div>
          </Accordion>
        </div>

        <div className="d-flex justify-content-start ml-2">
          <BtnDefault
            name="btn-buscar"
            className="btn-custom btn-save"
            id="btn-buscar"
            type="button"
            action={filtrar}
            variantClass="custom"
          >
            <FaSearch size={12} className="mr-1" />
            Buscar
          </BtnDefault>

          <BtnDefault
            action={resetForm}
            type="reset"
            className="btn-custom btn-save"
            variantClass="custom"
          >
            <FaBroom size={12} className="mr-1" />
            Limpar Filtros
          </BtnDefault>
        </div>
      </form>
      {isLoading && !cobrancas ? (
        <SpinnerLoader />
      ) : (
        <Table responsive hover>
          <thead className="table-header">
            <tr>
              <th>
                <div className="th">N° Cobrança</div>
              </th>
              <th>
                <div className="th">Tipo</div>
              </th>
              <th>
                <div
                  className={
                    orderBySort === BilletOrderByEnum.CLIENT
                      ? 'thContainerActive'
                      : 'thContainer'
                  }
                  onClick={() => handleSort('ASC', BilletOrderByEnum.CLIENT)}
                >
                  {orderSort === 'ASC' &&
                  orderBySort === BilletOrderByEnum.CLIENT ? (
                    <FaSortAlphaDown size={20} />
                  ) : orderBySort !== BilletOrderByEnum.CLIENT ? (
                    <FaSortAlphaDown size={20} />
                  ) : (
                    <FaSortAlphaUpAlt size={20} />
                  )}
                  Cliente
                </div>
              </th>
              <th>
                <div
                  className={
                    orderBySort === BilletOrderByEnum.ISSUE_DATE
                      ? 'thContainerActive'
                      : 'thContainer'
                  }
                  onClick={() =>
                    handleSort('ASC', BilletOrderByEnum.ISSUE_DATE)
                  }
                >
                  {orderSort === 'ASC' &&
                  orderBySort === BilletOrderByEnum.ISSUE_DATE ? (
                    <FaSortAmountDown size={20} />
                  ) : orderBySort !== BilletOrderByEnum.ISSUE_DATE ? (
                    <FaSortAmountDown size={20} />
                  ) : (
                    <FaSortAmountUpAlt size={20} />
                  )}
                  Emissão
                </div>
              </th>
              <th>
                <div
                  className={
                    orderBySort === BilletOrderByEnum.DUE_DATE
                      ? 'thContainerActive'
                      : 'thContainer'
                  }
                  onClick={() => handleSort('ASC', BilletOrderByEnum.DUE_DATE)}
                >
                  {orderSort === 'ASC' &&
                  orderBySort === BilletOrderByEnum.DUE_DATE ? (
                    <FaSortAmountDown size={20} />
                  ) : orderBySort !== BilletOrderByEnum.DUE_DATE ? (
                    <FaSortAmountDown size={20} />
                  ) : (
                    <FaSortAmountUpAlt size={20} />
                  )}
                  Vencimento
                </div>
              </th>
              <th>
                <div className="th">Categorias</div>
              </th>
              <th>
                <div className="th">Status Envio</div>
              </th>
              <th>
                <div
                  className={
                    orderBySort === BilletOrderByEnum.VALUE
                      ? 'thContainerActive'
                      : 'thContainer'
                  }
                  onClick={() => handleSort('ASC', BilletOrderByEnum.VALUE)}
                >
                  {orderSort === 'ASC' &&
                  orderBySort === BilletOrderByEnum.VALUE ? (
                    <FaSortNumericDown size={20} />
                  ) : orderBySort !== BilletOrderByEnum.VALUE ? (
                    <FaSortNumericDown size={20} />
                  ) : (
                    <FaSortNumericUpAlt size={20} />
                  )}
                  Valor
                </div>
              </th>
              <th>
                <div className="th">Status Cobrança</div>
              </th>
              <th>
                <div
                  className={
                    orderBySort === BilletOrderByEnum.PAYED_VALUE
                      ? 'thContainerActive'
                      : 'thContainer'
                  }
                  onClick={() =>
                    handleSort('ASC', BilletOrderByEnum.PAYED_VALUE)
                  }
                >
                  {orderSort === 'ASC' &&
                  orderBySort === BilletOrderByEnum.PAYED_VALUE ? (
                    <FaSortNumericDown size={20} />
                  ) : orderBySort !== BilletOrderByEnum.PAYED_VALUE ? (
                    <FaSortNumericDown size={20} />
                  ) : (
                    <FaSortNumericUpAlt size={20} />
                  )}
                  Valor Pago
                </div>
              </th>
              <th>
                <div
                  className={
                    orderBySort === BilletOrderByEnum.PAYED_AT
                      ? 'thContainerActive'
                      : 'thContainer'
                  }
                  onClick={() => handleSort('ASC', BilletOrderByEnum.PAYED_AT)}
                >
                  {orderSort === 'ASC' &&
                  orderBySort === BilletOrderByEnum.PAYED_AT ? (
                    <FaSortAmountDown size={20} />
                  ) : orderBySort !== BilletOrderByEnum.PAYED_AT ? (
                    <FaSortAmountDown size={20} />
                  ) : (
                    <FaSortAmountUpAlt size={20} />
                  )}
                  Pago Em
                </div>
              </th>
              <th>
                <div className="th">Ações</div>
              </th>
            </tr>
          </thead>

          <tbody>
            {cobrancas.map((cobranca: IListaCobranca, index) => {
              return (
                <TableRow
                  cobranca={cobranca}
                  exibirModal={exibirModal}
                  exibirModalEnviar={exibirModalEnviar}
                  cancelarBoleto={cancelarBoleto}
                  enviarBoleto={enviarBoletoCliente}
                  exibirModalEditar={exibirModalEditar}
                  updateTableFunction={() => setUpdateTable(true)}
                  key={index}
                />
              );
            })}
          </tbody>
        </Table>
      )}

      {!isLoading && cobrancas !== undefined && cobrancas.length === 0 && (
        <div className="msg-table ">
          <p>Nenhum dado para ser exibido com esses parâmetros.</p>
        </div>
      )}
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <div className="pagination justify-content-center">
          <PaginationDefault
            totalNaPagina={cobrancas.length}
            currentPage={currentPage}
            totalCount={totalCount}
            limit={limit}
            action={(value: number) => listasTodasCobrancas(value)}
          />
          <select
            name="amount"
            id="amount"
            className="pagination-box"
            onChange={updateLimitValue}
            value={limit}
          >
            <option value="30">30</option>
            <option value="45">45</option>
            <option value="60">60</option>
            <option value="75">75</option>
            <option value="90">90</option>
          </select>
        </div>
      )}

      {showModalCancelar ? (
        <ModalDefaultCancelar
          variantClass="btn-purple btn-purple--round"
          nome="clientes"
          idRecorrencia={idCobrancaRecorrenciaModal}
          idCobranca={idCobrancaModal}
          showModalCancelar={showModalCancelar}
          handleCloseCancelar={handleCloseCancelar}
        />
      ) : (
        ''
      )}
      {showModalEnviar ? (
        <ModalDefaultEnviar
          variantClass="btn-purple btn-purple--round"
          nome="clientes"
          idCobranca={idCobrancaModal}
          showModalEnviar={showModalEnviar}
          handleCloseEnviar={handleCloseEnviar}
        />
      ) : (
        ''
      )}

      {showModaEditar ? (
        <ModalDefaultEditarCobranca
          icon={FaGripVertical}
          variantClass="btn-purple btn-purple--round"
          setUpdateTable={setUpdateTable}
          id={idCobranca}
          showModalCobranca={showModaEditar}
          handleCloseCobranca={handleCloseEditar}
        />
      ) : (
        ''
      )}
    </UIContent>
  );
};

export default MainBuscaBoleto;
