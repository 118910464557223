import React from 'react';

import './styles.scss';
import MainCategoria from '../../components/MainCategoria/index';
import UIContainer from '../../components/UI/Container';

const Categorias: React.FC = () => (
  <UIContainer>
    <MainCategoria />
  </UIContainer>
);
export default Categorias;
