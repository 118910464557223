import React from 'react';

const AppStore = require('../../assets/btnsApp/app-store-badge.svg');
const GooglePlay = require('../../assets/btnsApp/google-play.svg');
import './styles.scss';

const DownloadButtons: React.FC = () => {
  return (
    <div className="download-container ">
      <div className="download-text">
        <span>Não possui conta na {process.env.REACT_APP_NOME_PARCEIRO}?</span>
        <span>Faça download do nosso app e crie sua conta em minutos.</span>
      </div>
      <div className="btn-content">
        <a href={process.env.REACT_APP_LINK_GOOGLE_PLAY} target="_blank">
          <img
            className="btn-googleplay"
            src={GooglePlay.default}
            alt="Imagem botão Google Play"
          />
        </a>
        <a
          href={process.env.REACT_APP_LINK_APP_STORE}
          target="_blank"
          className="ml-2"
        >
          <img
            className="btn-applestore"
            src={AppStore.default}
            alt="Imagem botão App Store"
          />
        </a>
      </div>
    </div>
  );
};

export default DownloadButtons;
