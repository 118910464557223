import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {FaCalendar} from 'react-icons/fa';
import {FaSearch} from 'react-icons/fa';
import {toast} from 'react-toastify';

import './styles.scss';
import {getDadosPlanoContaFinanceira} from '../../api';
import DatePickerBr from '../../components/DatePickerBr';
import InputSelect from '../../components/Inputs/InputSelect';
import SpinnerLoader from '../../components/Spinner';
import Subtitulo from '../../components/Subtitulo';
import UIContainer from '../../components/UI/Container';
import {useAuth} from '../../context/usuario-context';
import {
  IPixAvailable,
  IPlanoContaFinanceira,
} from '../../models/conta-financeiro';
import {IDashboardFilters} from '../../models/dashboard-model';
import {formataDinheiro} from '../../utils/formatMonetary';
import {showModalWarning} from '../../utils/modalWarning';
import DashboardBoleto from './DashboardBoleto';
import DashboardCategoria from './DashboardCategoria/DashboardCategoria';

import {useHistory} from 'react-router-dom';

const Dashboard: React.FC = () => {
  const {usuarioLogado} = useAuth();
  const history = useHistory();
  const date = new Date();
  const [startDate, setStartDate] = useState<Date | undefined>(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );
  const [tipoData, setTipoData] = useState<string | undefined>('Envio');
  const [filter, setFilter] = useState<IDashboardFilters | undefined>();

  const redirect = () => {
    history.push('/dashboard');
  };

  const [items] = useState([
    {
      label: 'Envio',
      value: 'Envio',
    },
    {
      label: 'Vencimento',
      value: 'Vencimento',
    },
    {
      label: 'Pagamento',
      value: 'Pagamento',
    },
  ]);

  const validateDates = () => {
    setFilter(undefined);
    if (new Date(endDate) < new Date(startDate)) {
      toast.warning('A data fim deve ser igual ou posterior a data atual.', {
        autoClose: 2500,
      });
      return false;
    }

    if (endDate && startDate) {
      setFilter({
        tipoData: tipoData,
        startDate: startDate,
        finishDate: endDate,
      });
    }
    return true;
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateDates();
  };

  useEffect(() => {
    if (endDate && startDate) {
      setFilter({
        tipoData: tipoData ? tipoData : 'Envio',
        startDate: startDate,
        finishDate: endDate,
      });
    }
  }, []);

  const getPossuiPix = async () => {
    const documentsLocation = history.location.state as IPixAvailable;
    let html = '';
    if (documentsLocation && !documentsLocation.pixAvailable) {
      html += `<li> Para emissão de cobranças você deve ter uma chave PIX cadastrada no seu aplicativo <strong>${process.env.REACT_APP_NOME_PARCEIRO}</strong>
      </li>`;
    }
    if (documentsLocation && !documentsLocation.documentAvailable) {
      html += `<li> Para emissão de cobranças você deve ter a documentação aprovada no seu aplicativo ${process.env.REACT_APP_NOME_PARCEIRO}
      </li>`;
    }

    if (documentsLocation && !documentsLocation.infoPix) {
      html += `<li> Você atingiu o limite de operações da sua conta ${process.env.REACT_APP_NOME_PARCEIRO}. Para solicitar o aumento do limite acesse o aplicativo e clique no menu opções - meus limites.
      </li>`;
    }
    if (html) {
      showModalWarning(
        'Atenção! Você possui algumas pendências',
        html,
        redirect
      );
    }
  };

  useEffect(() => {
    getPossuiPix();
    return () => {};
  }, []);

  const [
    planoFinanceira,
    setPlanoFinanceira,
  ] = useState<IPlanoContaFinanceira>();

  const [isLoading, setIsLoading] = useState(false);

  const limitePlanoConta = async () => {
    setIsLoading(true);
    const planos = await getDadosPlanoContaFinanceira();
    setPlanoFinanceira(planos);

    setIsLoading(false);
  };

  useEffect(() => {
    limitePlanoConta();
  }, []);

  return (
    <UIContainer>
      <br />
      <Subtitulo>Seja bem vindo(a) {usuarioLogado?.nome}!</Subtitulo>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <div className="col-md-12 subtitulo-tela">
          {planoFinanceira ? (
            <p>
              {' '}
              Você poderá emitir{' '}
              {planoFinanceira ? (
                planoFinanceira.dailyTransactionLimit -
                  planoFinanceira.dailyTransactionLimitRequested >
                9999999 ? (
                  <span className="text-home">{' ilimitadas'}</span>
                ) : (
                  <span className="text-home">
                    {' '}
                    {planoFinanceira.dailyTransactionLimit -
                      planoFinanceira.dailyTransactionLimitRequested}
                  </span>
                )
              ) : (
                <span className="text-home">0</span>
              )}{' '}
              cobranças, no valor máximo de{' '}
              {planoFinanceira ? (
                planoFinanceira.dailyMaximumValue > 9999999 ? (
                  <span className="text-home">{' ilimitado'}</span>
                ) : (
                  <span className="text-home">
                    {' '}
                    {formataDinheiro(
                      planoFinanceira.dailyMaximumValue -
                        planoFinanceira.dailyMaximumValueRequested
                    )}
                  </span>
                )
              ) : (
                <span className="text-home">{formataDinheiro(0)}</span>
              )}
              {''} por cobrança.
            </p>
          ) : (
            `Para emissão de cobranças você deve ter uma chave PIX cadastrada no seu aplicativo ${process.env.REACT_APP_NOME_PARCEIRO}`
          )}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="d-flex bd-highlight mb-5 ml-3">
          <div className="container-filter__group">
            <label className="dashboard-boleto__label" htmlFor="envio">
              Data
            </label>
            <InputSelect
              name="envio"
              id="envio"
              classNamePrefix="addl-class"
              options={items}
              defaultValue={items[0]}
              className="custom-select-dashboard"
              onChange={val => {
                setTipoData(val.value);
              }}
            />
          </div>
          <div className="container-filter__group">
            <label htmlFor="Data-inicio" className="dashboard-boleto__label">
              Data de Início
            </label>
            <div className="container-filter__date">
              <DatePickerBr
                icon={FaCalendar}
                classIcon="container-filter__calendar "
                id="Data-inicio"
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
              />
            </div>
          </div>
          <div className="container-filter__group">
            <label htmlFor="Data-fim" className="dashboard-boleto__label">
              Data de fim
            </label>
            <div className="container-filter__date">
              <DatePickerBr
                icon={FaCalendar}
                classIcon="container-filter__calendar "
                id="Data-fim"
                selected={endDate}
                onChange={(date: Date) => setEndDate(date)}
              />
            </div>
          </div>
          <div className="container-filter__group d-flex mt-auto">
            <Button
              className="btn-custom btn-save "
              type="submit"
              variant="custom"
            >
              <FaSearch size={12} className="mr-1" />
              Buscar
            </Button>
          </div>
        </div>
      </form>
      <DashboardBoleto filter={filter} />
      <DashboardCategoria filter={filter} />
    </UIContainer>
  );
};

export default Dashboard;
