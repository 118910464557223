import React from 'react';

import {IDashboardFilters} from '../../../models/dashboard-model';
import FaturamentoCategoria from './FaturamentoCategoria';
import StatusCobranca from './StatusCobranca';
import './styles.scss';
import VolumeEnvioCategoria from './VolumeEnvioCategoria';

interface Filtros {
  filter: IDashboardFilters;
}

const DashboardCategoria: React.FC<Filtros> = ({filter}) => {
  return (
    <div className="dashboard-categoria">
      <VolumeEnvioCategoria filter={filter} />
      <FaturamentoCategoria filter={filter} />
      <StatusCobranca filter={filter} />
    </div>
  );
};

export default DashboardCategoria;
