import React, {useEffect, useState} from 'react';
import './styles.scss';
import {FaQuestion} from 'react-icons/fa';
import UIModal from '../UI/Modal';
import UIModalHeader from '../UI/Modal/ModalHeader';
import UIModalBody from '../UI/Modal/ModalBody';
import FormTicket from './FormTicket';
import UIModalFooter from '../UI/Modal/ModalFooter';
import Draggable from 'react-draggable';
import useWindowDimensions from '../../hooks/WindowsDimensions';
import {calculaPorcentagem} from '../../utils/porcentagem/calcPorcentagem';
import {useLocation} from 'react-router-dom';
import {useAuth} from '../../context/usuario-context';
import {isMaster} from '../../utils/validations/userIsAdminstrator';

const Ticket: React.FC = () => {
  const {width} = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);
  const [activeDrags, setActiveDrags] = useState(0);
  const location = useLocation();
  const [controlledPosition, setControlledPosition] = useState({
    x: 0,
    y: 0,
  });
  const [controlledPositionModal, setControlledPositionModal] = useState({
    x: 0,
    y: 0,
  });

  const {usuarioLogado} = useAuth();
  const onStart = () => {
    let active = activeDrags;
    setActiveDrags(++active);
  };

  const onStop = () => {
    let active = activeDrags;
    setActiveDrags(--active);
  };

  const onControlledDragIcon = (e, position) => {
    const {x, y} = position;
    const total = width - x * -1;
    const maxDistancia = calculaPorcentagem(24, width);
    if (total > maxDistancia) {
      setControlledPosition({x, y: 0});
      setControlledPositionModal({x, y: 0});
    } else {
      setControlledPosition({x, y: 0});
      setControlledPositionModal({x: x + width * 0.2, y: 0});
    }
  };

  const onControlledDragModal = (e, position) => {
    const {x, y} = position;
    const total = width - x * -1;
    if (total > width) {
      setControlledPositionModal({x, y: 0});
      setControlledPosition({x: x - width * 0.2, y: 0});
    } else {
      setControlledPosition({x, y: 0});
      setControlledPositionModal({x, y: 0});
    }
  };

  const dragHandlers = {onStart: onStart, onStop: onStop};
  const nodeRef = React.useRef(null);
  const nodeRefModal = React.useRef(null);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    closeModal();
  }, [location.pathname]);

  return (
    <>
      {!isMaster(usuarioLogado) &&
        !location?.pathname.startsWith('/acesso-u4crypto') && (
          <>
            <Draggable
              bounds="parent"
              {...dragHandlers}
              nodeRef={nodeRef}
              axis="x"
              handle="strong"
              onDrag={onControlledDragIcon}
              position={controlledPosition}
            >
              <div
                className="ticket-content box cursor-x"
                ref={nodeRef}
                onClick={() => setIsOpen(!isOpen)}
              >
                <strong>
                  <FaQuestion
                    className="input-content__icone-ticket"
                    size={26}
                  />
                </strong>
              </div>
            </Draggable>

            <Draggable
              bounds="parent"
              {...dragHandlers}
              nodeRef={nodeRefModal}
              axis="x"
              handle="strong"
              position={controlledPositionModal}
              onDrag={onControlledDragModal}
            >
              <div className="ticket-modal box cursor-x" ref={nodeRefModal}>
                <UIModal isOpen={isOpen} onClickClose={() => closeModal()}>
                  <strong>
                    <UIModalHeader onClickClose={closeModal}>
                      Atendimento
                    </UIModalHeader>
                  </strong>
                  <UIModalBody>
                    <FormTicket onClickClose={closeModal}></FormTicket>
                  </UIModalBody>
                  <UIModalFooter></UIModalFooter>
                </UIModal>
              </div>
            </Draggable>
          </>
        )}
    </>
  );
};

export default Ticket;
