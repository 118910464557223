import React, {useState, useEffect} from 'react';
import {FaArrowLeft, FaPen, FaGripVertical} from 'react-icons/fa';
import {useParams, useHistory} from 'react-router-dom';

import {verCobranca} from '../../api/cobranca-api';
import BtnDefault from '../../components/Buttons/BtnDefault';
import {IVerCobrancaBoleto} from '../../models/cobranca';
import CardSection from '../CardSection';
import SpinnerLoader from '../Spinner';
import Titulo from '../Titulo';
import VerBoletosPix from './VerBoletosPix';
import VerCliente from './VerCliente';
import VerCobranca from './VerCobranca';
import VerFinanceiro from './VerFinanceiro';

import './styles.scss';
import ModalDefaultEditarCobranca from '../ModalDefault/ModalDefaultEditarCobranca';

interface VerCobrancaProps {
  id: string;
}
const MainVerBoleto: React.FC = () => {
  const [cobrancas, setCobrancas] = useState<IVerCobrancaBoleto>();
  const [isLoading, setIsLoading] = useState(false);
  const [showModalEditar, setShowModalEditar] = useState(false);
  const [updateCobranca, setUpdatecobranca] = useState(false);
  const [idCobranca, setIdCobranca] = useState<string | undefined>();

  const {id} = useParams<VerCobrancaProps>();

  const history = useHistory();
  const handleRedirect = () => {
    history.goBack();
  };

  const handlerCobrancas = async id => {
    setIsLoading(true);
    const todasCobrancas = await verCobranca(id);

    setCobrancas(todasCobrancas);
    setIsLoading(false);
  };

  const handleCloseEditar = () => {
    setShowModalEditar(false);
  };
  if (updateCobranca) {
    handlerCobrancas(id);
    setUpdatecobranca(!updateCobranca);
  }
  const handlerEditarCobrancas = id => {
    setIdCobranca(id);
    setShowModalEditar(true);
  };
  useEffect(() => {
    handlerCobrancas(id);
  }, []);

  return (
    <>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <>
          <div className="d-flex bd-highlight">
            <div className="p-2 flex-grow-1 bd-highlight">
              <Titulo>Cobrança Pix</Titulo>
            </div>

            <BtnDefault
              action={handleRedirect}
              className="btn-custom btn-save p-2 flex-grow-1 bd-highlight"
              variantClass="custom"
            >
              <FaArrowLeft size={13} className="mr-1" />
              Voltar
            </BtnDefault>
            <BtnDefault
              action={() => handlerEditarCobrancas(id)}
              className="btn-custom btn-save p-2 flex-grow-1 bd-highlight"
              variantClass="custom"
            >
              <FaPen size={13} className="mr-1" />
              Editar
            </BtnDefault>
          </div>
          <CardSection title="Cobrança/Esta cobrança">
            <VerCobranca {...cobrancas}></VerCobranca>
          </CardSection>
          <CardSection title="Cliente">
            <VerCliente {...cobrancas?.cliente}></VerCliente>
          </CardSection>
          <CardSection title="Financeiro">
            <VerFinanceiro {...cobrancas}></VerFinanceiro>
          </CardSection>
          <CardSection title="Cobrança Pix">
            {cobrancas?.id && <VerBoletosPix id={cobrancas.id} />}
          </CardSection>
        </>
      )}
      {showModalEditar ? (
        <ModalDefaultEditarCobranca
          icon={FaGripVertical}
          variantClass="btn-purple btn-purple--round"
          id={idCobranca}
          showModalCobranca={showModalEditar}
          handleCloseCobranca={handleCloseEditar}
          setUpdateTable={setUpdatecobranca}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default MainVerBoleto;
