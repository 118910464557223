import React, {useState} from 'react';
import './styles.scss';
import {Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {FaRegSave, FaBan} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

import {cadastrarUsuario} from '../../../api/usuario-api';
import {IModalEventProps} from '../../../models/modal-events-model';
import {IUsuario} from '../../../models/usuario-model';
import phoneMask from '../../../utils/masks/phoneMask';
import {
  cpfValidator,
  cnpjValidator,
  limpaCpf,
  limpaCnpj,
} from '../../../utils/validations/cpfCnpjValidator';
import InputDefault from '../../Inputs/InputDefault';
import SpinnerLoader from '../../Spinner';
import noEmptyMask from '../../../utils/masks/noEmptyMask';
import cpfMask from '../../../utils/masks/cpfMask';
import cnpjMask from '../../../utils/masks/cpfcnpjMask';

const FormCadastroUsuario: React.FC<IModalEventProps> = params => {
  const {register, handleSubmit, errors, setValue} = useForm<IUsuario>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async data => {
    const newUsuario = {
      nome: data.nome,
      cpf: limpaCpf(data.cpf),
      cnpj: limpaCnpj(data.cnpj),
      email: data.email,
      password: data.password,
      status: (data.status = 'ATIVO'),
      telefone: data.telefone.replace(/[^\d]+/g, ''),
    };

    criarUsuario(newUsuario);
  };

  const criarUsuario = async (newUsuario: IUsuario) => {
    try {
      await cadastrarUsuario(newUsuario);
      toast.success('Usuario cadastrado com sucesso!', {autoClose: 2500});
      history.push('/usuarios');
      closeModal();
      setIsLoading(true);
    } catch (error) {
      toast.error(error, {autoClose: 2500});
    }
    setIsLoading(false);
  };

  const cancelForm = () => {
    closeModal();
  };

  const closeModal = () => {
    params.handleUpdateTable(true);
    params.handleCloseModal(false);
  };

  return (
    <form className="form-modal" onSubmit={handleSubmit(onSubmit)}>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <>
          <h6 className="titulo-sessao">Informações do usuário</h6>
          <div className="form-row">
            <div className="form-group col-md-4">
              <label className="label-item-tabela" htmlFor="input-nome">
                Nome completo
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="nome"
                type="text"
                placeholder=""
                id="input-nome"
                inputMaxLength={'100'}
                inputRef={register({
                  required: true,
                })}
                erros={errors}
                mask={noEmptyMask}
              />
            </div>

            <div className="form-group col-md-4">
              <label className="label-item-tabela" htmlFor="input-cpf">
                CPF
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="cpf"
                type="text"
                placeholder=""
                id="input-cpf"
                inputMaxLength={'14'}
                inputRef={register({
                  required: true,
                  maxLength: 14,
                  validate: {
                    cpf: value => {
                      return cpfValidator(value);
                    },
                  },
                })}
                erros={errors}
                mask={cpfMask}
                onBlur={e =>
                  setValue('cpf', cpfMask(e.target.value.slice(0, 14)))
                }
              />
            </div>

            <div className="form-group col-md-4">
              <label className="label-item-tabela" htmlFor="input-cnpj">
                CNPJ
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="cnpj"
                type="text"
                placeholder=""
                id="input-cnpj"
                inputMaxLength={'18'}
                inputRef={register({
                  required: true,
                  maxLength: 18,
                  validate: {
                    cnpj: value => {
                      return cnpjValidator(value);
                    },
                  },
                })}
                erros={errors}
                mask={cnpjMask}
                onBlur={e =>
                  setValue('cnpj', cnpjMask(e.target.value.slice(0, 18)))
                }
              />
            </div>

            <div className="form-group col-md-6">
              <label className="label-item-tabela" htmlFor="input-email">
                Email
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="email"
                type="text"
                inputMaxLength={'100'}
                placeholder=""
                id="input-email"
                inputRef={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Entre com um e-mail válido. ',
                  },
                })}
                erros={errors}
                mask={noEmptyMask}
              />
            </div>

            <div className="form-group col-md-6">
              <label className="label-item-tabela" htmlFor="telefone-usuario">
                Telefone
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="telefone"
                type="text"
                placeholder=""
                id="telefone-usuario"
                inputRef={register({
                  required: true,
                  maxLength: 14,
                  minLength: 13,
                })}
                erros={errors}
                inputMaxLength="14"
                mask={phoneMask}
              />
            </div>
          </div>

          <div className="buttons-group">
            <Button
              className="btn-custom btn-cancel"
              variant="custom"
              onClick={() => cancelForm()}
            >
              <FaBan size={13} className="mr-1" />
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="custom"
              className="btn-custom btn-save"
            >
              <FaRegSave size={13} className="mr-1" />
              Salvar
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export default FormCadastroUsuario;
