import React from 'react';

import './styles.scss';
const UIContent: React.FC = ({children}) => {
  return (
    <React.Fragment>
      <div className="content">{children}</div>
    </React.Fragment>
  );
};

export default UIContent;
