import {ITermo} from 'models/termo-model';

import api from './api';

export const atualizarTermo = async (id: string) => {
  return (await api.post(`/usuarios/atualizar-termo/${id}`))?.data;
};

export const ultimoTermo = async () => {
  const params = {partnerID: process.env.REACT_APP_PARTNER_ID};
  return (await api.post<ITermo>('termos-de-uso/ultimo-termo', params))?.data;
};
