import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {AiOutlineEye} from 'react-icons/ai';
import {FaArrowLeft, FaArrowRight} from 'react-icons/fa';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import Swal from 'sweetalert2';

import {
  abortBatchApi,
  duplicateBatchApi,
  getBatchDetailsApi,
  resendDefaulters,
  sendNowAPI,
} from '../../api';
import BtnDefault from '../../components/Buttons/BtnDefault';
import CardSection from '../../components/CardSection';
import ModalEditClientGroup from '../../components/MainClientGroup/ModalClientGroupEdit';
import SpinnerLoader from '../../components/Spinner';
import Titulo from '../../components/Titulo';
import UIContainer from '../../components/UI/Container';
import {
  IBillingBatch,
  IBillingBatchSecondStage,
} from '../../models/billing-batch.model';
import {formataDinheiro} from '../../utils/formatMonetary';
import BatchSummary from '../BatchSummary';

interface IBatchDetailsParam {
  id: string;
}

const BatchDetails: React.FC<string> = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loadedBatch, setLoadedBatch] = useState<IBillingBatch>();
  const [numberOfMembers, setNumberOfMembers] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [duplicateBatch, setDuplicateBatch] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [summary, setSummary] = useState<IBillingBatchSecondStage>();

  const {id} = useParams<IBatchDetailsParam>();

  const calcDayToBeSend = () => {
    const IN_MILLISECONDS_1_DAY = 86400000;
    const daysToRemove = loadedBatch.daysBeforeSend * IN_MILLISECONDS_1_DAY;
    const sd = loadedBatch.expirationDay.split('/');
    const dateToSend =
      new Date(`${sd[2]}/${sd[1]}/${sd[0]}`).getTime() - daysToRemove;
    return new Date(dateToSend).toLocaleDateString('pt-br');
  };

  const loadBatch = async () => {
    setIsLoading(true);
    const {batch, groupMembersNumber} = await getBatchDetailsApi(id);
    setLoadedBatch(batch);
    setNumberOfMembers(groupMembersNumber);
    setIsLoading(false);
  };

  const resendClick = async () => {
    try {
      await Swal.fire({
        title: `Deseja realmente reenviar o Lote ${loadedBatch.number} ?`,
        text:
          'Ao confirmar, todos os clientes do lote que estão inadimplentes receberão novamente a cobrança!',
        showCancelButton: true,
        confirmButtonColor: ' #d33',
        cancelButtonColor: `${process.env.REACT_APP_COLOR_PRIMARY}`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText:
          '<i class="fas fa-arrow-right fa-sm" aria-hidden="true"></i> Confirmar',
        cancelButtonText:
          '<i class="fas fa-ban" aria-hidden="true"></i> Voltar',
      }).then(async result => {
        if (result.isConfirmed) {
          await resendAction();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const resendAction = async () => {
    setDisableButton(true);
    setIsLoading(true);
    try {
      await resendDefaulters(id);
      toast.success('Reenvio solicitado com sucesso', {autoClose: 2500});
      await loadBatch();
    } catch (e) {
      toast.error('Erro ao tentar reenvio', {autoClose: 2500});
    }
    setIsLoading(false);
  };

  const duplicate = async () => {
    setIsLoading(true);
    const batchInfo = await duplicateBatchApi(id);
    setSummary(batchInfo);
    setDuplicateBatch(true);
    setIsLoading(false);
  };

  const abortClick = async () => {
    try {
      await Swal.fire({
        title: `Tem certeza que deseja Cancelar o envio do Lote ${loadedBatch.number} ?`,
        text:
          'Ao confirmar, você estará cancelando o envio para todos os clientes deste lote!',
        showCancelButton: true,
        confirmButtonColor: ' #d33',
        cancelButtonColor: `${process.env.REACT_APP_COLOR_PRIMARY}`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText:
          '<i class="fas fa-trash fa-sm" aria-hidden="true"></i> Confirmar',
        cancelButtonText:
          '<i class="fas fa-ban" aria-hidden="true"></i> Voltar',
      }).then(async result => {
        if (result.isConfirmed) {
          await abortAction();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const abortAction = async () => {
    setIsLoading(true);
    try {
      await abortBatchApi(id);
      toast.success('Lote cancelado com sucesso', {autoClose: 2500});
    } catch (e) {
      toast.error('Erro ao cancelar lote', {autoClose: 2500});
    }
    await loadBatch();
    setIsLoading(false);
  };

  const isCancelButtonAvailable = () => {
    if (
      loadedBatch.sendImmediately &&
      loadedBatch.status !== 'AGUARDANDO_PROCESSAMENTO'
    )
      return false;

    if (loadedBatch.status === 'AGUARDANDO_PROCESSAMENTO') return true;

    return !(
      new Date(
        new Date(`${loadedBatch.expirationDay} 00:00:00 GMT-0300`).getTime() -
          loadedBatch.daysBeforeSend * 86400000
      ) <= new Date(`${new Date().toDateString()} 00:00:00 GMT-0300`)
    );
  };

  const sendNowClick = async () => {
    try {
      await Swal.fire({
        title: `Tem certeza que deseja Enviar Agora o Lote ${loadedBatch.number} ?`,
        text:
          'Ao confirmar, você estará enviando para todos clientes deste lote esta cobrança!',
        showCancelButton: true,
        confirmButtonColor: ' #d33',
        cancelButtonColor: `${process.env.REACT_APP_COLOR_PRIMARY}`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText:
          '<i class="fas fa-arrow-right fa-sm" aria-hidden="true"></i> Confirmar',
        cancelButtonText:
          '<i class="fas fa-ban" aria-hidden="true"></i> Voltar',
      }).then(async result => {
        if (result.isConfirmed) {
          await sendNowAction();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const sendNowAction = async () => {
    setIsLoading(true);
    try {
      await sendNowAPI(id);
      toast.success('Cobranças enviadas com sucesso', {autoClose: 2500});
    } catch (e) {
      toast.error('Erro ao enviar cobranças', {autoClose: 2500});
    }
    await loadBatch();
    setIsLoading(false);
  };

  useEffect(() => {
    loadBatch();
  }, []);

  return (
    <>
      <UIContainer>
        {isLoading || !loadedBatch ? (
          <SpinnerLoader />
        ) : !duplicateBatch ? (
          <>
            <div className="d-flex bd-highlight titleAndButton">
              <div className="p-2 flex-grow-1 bd-highlight">
                <Titulo>{`Visulizar Lote - ${loadedBatch.number}`}</Titulo>
              </div>
              <div className="align-content-end">
                <BtnDefault
                  action={() => {
                    window.history.back();
                  }}
                  className="btn-custom btn-save p-2 flex-grow-1 bd-highlight"
                  variantClass="custom"
                >
                  <FaArrowLeft size={13} className="mr-1" />
                  Voltar
                </BtnDefault>
              </div>
            </div>
            <CardSection title={''}>
              <div className="d-flex bd-highlight">
                <div className="p-2 flex-grow-1 bd-highlight">
                  <Titulo>Cobrança</Titulo>
                </div>
              </div>

              <div className="row mx-auto">
                <div className="col-sm-4">
                  <label htmlFor="Categoria" className="form-label">
                    Regra de Cobrança:
                  </label>
                  <span className="text__content">
                    {loadedBatch.billingRule.name}
                  </span>
                </div>
                <div className="col-sm-4">
                  <label htmlFor="Descrição" className="form-label">
                    Descrição:
                  </label>
                  <span className="text__content">
                    {loadedBatch.description}
                  </span>
                </div>
                <div className="col-sm-4">
                  <label htmlFor="Tipo" className="form-label">
                    Vencimento:
                  </label>
                  <span className="text__content">
                    {new Date(
                      `${loadedBatch.expirationDay} 00:00:00`
                    ).toLocaleDateString('pt-br')}
                  </span>
                </div>
              </div>
              <div className="row mx-auto">
                <div className="col-sm-4">
                  <label htmlFor="Multa" className="form-label">
                    Multa:
                  </label>
                  <span className="text__content">
                    {String(loadedBatch.fine) !== '0'
                      ? formataDinheiro(loadedBatch.fine / 100)
                      : '-'}
                  </span>
                </div>
                <div className="col-sm-4">
                  <label htmlFor="Juros ao mês" className="form-label">
                    Juros ao mês:
                  </label>
                  <span className="text__content">
                    {String(loadedBatch.interest) !== '0'
                      ? formataDinheiro(loadedBatch.interest / 100)
                      : '-'}
                  </span>
                </div>
                <div className="col-sm-4">
                  <label htmlFor="Instruções" className="form-label">
                    Desconto:
                  </label>
                  <span className="text__content">
                    {String(loadedBatch.discount) !== '0'
                      ? formataDinheiro(loadedBatch.discount / 100)
                      : '-'}
                  </span>
                </div>
              </div>
              <div className="row mx-auto">
                <div className="col-sm-4">
                  <label htmlFor="Juros ao mês" className="form-label">
                    Valor da Cobrança:
                  </label>
                  <span className="text__content">
                    {String(loadedBatch.value) !== '0'
                      ? formataDinheiro(loadedBatch.value / 100)
                      : '-'}
                  </span>
                </div>
                <div className="col-sm-4">
                  <label htmlFor="Juros ao mês" className="form-label">
                    Nº Lote:
                  </label>
                  <span className="text__content">{loadedBatch.number}</span>
                </div>
                <div className="col-sm-4">
                  <label htmlFor="Juros ao mês" className="form-label">
                    Valor do Lote:
                  </label>
                  <span className="text__content">
                    {String(loadedBatch.value) !== '0'
                      ? formataDinheiro(
                          (loadedBatch.value / 100) * numberOfMembers
                        )
                      : '-'}
                  </span>
                </div>
              </div>
              <br />
              <br />
              <div className="row mx-auto">
                <div className="col-sm-4">
                  <div className="row">
                    <span className="group__title">
                      {`${loadedBatch.clientGroup.name} (${numberOfMembers} clientes)`}
                    </span>
                  </div>
                  <div className="row">
                    <label htmlFor="Categoria" className="group__description">
                      {loadedBatch.clientGroup.description}
                    </label>
                  </div>
                </div>
                <Button
                  name="btnSalvar"
                  className="btn-custom btn-save"
                  type="button"
                  onClick={() => setShowModal(true)}
                  variant="custom"
                >
                  <AiOutlineEye size={20} className="mr-1" />
                  Visualizar Grupo
                </Button>
              </div>
              <div className="row mx-auto">
                <div className="col-sm-4">
                  <label htmlFor="Juros ao mês" className="form-label">
                    Envio:
                  </label>
                  <span className="text__content">
                    {loadedBatch.sendImmediately
                      ? 'Imediatamente'
                      : `Agendado - ${calcDayToBeSend()}`}
                  </span>
                </div>
              </div>

              {showModal ? (
                <ModalEditClientGroup
                  icon={AiOutlineEye}
                  variantClass="btn-purple btn-purple--round"
                  showModalCliente={true}
                  id={loadedBatch.clientGroup.id}
                  setUpdateTable={() => setShowModal(false)}
                  showModalViewOrEdit={true}
                  secondStageParam={true}
                />
              ) : (
                ''
              )}
            </CardSection>

            <div className="container-bottoes col-md-14">
              <div className="button-salvar-cobranca">
                <Button
                  name="btnSalvar"
                  className="btn-custom btn-cancel"
                  type="submit"
                  onClick={duplicate}
                  variant="custom"
                >
                  Duplicar Lote
                </Button>
              </div>

              <div className="button-salvar-cobranca">
                {loadedBatch.canBeResend ? (
                  <Button
                    name="btnSalvar"
                    className="btn-custom btn-cancel"
                    type="submit"
                    onClick={resendClick}
                    variant="custom"
                  >
                    Reenviar Inadimplentes
                  </Button>
                ) : (
                  ''
                )}
              </div>

              <div className="button-salvar-cobranca">
                {loadedBatch && isCancelButtonAvailable() ? (
                  <Button
                    name="btnSalvar"
                    className="btn-custom btn-cancel"
                    type="submit"
                    onClick={abortClick}
                    variant="custom"
                  >
                    Cancelar Envio
                  </Button>
                ) : (
                  ''
                )}
              </div>

              <div className="button-salvar-cobranca">
                {!loadedBatch.sendImmediately &&
                !loadedBatch.forcedSent &&
                loadedBatch.status === 'PROCESSADO' ? (
                  <Button
                    name="btnSalvar"
                    className="btn-custom btn-save"
                    type="submit"
                    onClick={sendNowClick}
                    variant="custom"
                    disabled={disableButton}
                  >
                    <FaArrowRight size={16} className="mr-1" />
                    Enviar Agora
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex bd-highlight titleAndButton">
              <div className="p-2 flex-grow-1 bd-highlight">
                <Titulo>{`Duplicar Lote - ${loadedBatch.number}`}</Titulo>
              </div>
              <div className="align-content-end">
                <BtnDefault
                  action={() => {
                    setDuplicateBatch(false);
                  }}
                  className="btn-custom btn-save p-2 flex-grow-1 bd-highlight"
                  variantClass="custom"
                >
                  <FaArrowLeft size={13} className="mr-1" />
                  Voltar
                </BtnDefault>
              </div>
            </div>
            <CardSection title={''}>
              <BatchSummary
                batch={summary.batch}
                billingRuleName={summary.billingRuleName}
                groupInfo={summary.groupInfo}
              />
            </CardSection>
          </>
        )}
      </UIContainer>
    </>
  );
};

export default BatchDetails;
