import React from 'react';

import {ResponsiveLine} from '@nivo/line';

import colors from '../../styles/base/_colors.module.scss';
import data from './data.json';

const LineChart = () => {
  return (
    <>
      <ResponsiveLine
        data={data}
        enableGridX={false}
        enableGridY={true}
        margin={{top: 30, right: 50, bottom: 50, left: 50}}
        xScale={{type: 'point'}}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          reverse: false,
        }}
        curve="monotoneX"
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 18,
          tickRotation: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 0,
          legendPosition: 'middle',
        }}
        pointSize={4}
        pointColor={{theme: 'background'}}
        pointBorderWidth={2}
        pointBorderColor={{from: 'serieColor'}}
        pointLabelYOffset={-12}
        useMesh={true}
        enableCrosshair={true}
        colors={colors.colorPrimary}
        onClick={event =>
          console.log(event.data.xFormatted, event.data.yFormatted)
        }
      />
    </>
  );
};

export default LineChart;
