import React from 'react';

import './styles.scss';

const LinkPDFNotFound: React.FC = () => {
  return (
    <>
      <div className="container main mt-5">
        <h1 className="container__error">404</h1>
        <h1 className="container__text">
          Oops! Link PDF da cobrança não encontrada
        </h1>
        <p className="container__content mt-4 text-center">
          Esse erro pode ocorrer caso a cobrança que você esteja tentando
          acessar não exista ou não possua o link para download.
        </p>
      </div>
    </>
  );
};

export default LinkPDFNotFound;
