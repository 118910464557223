import React, {createContext, useContext, useEffect, useState} from 'react';

import {efetuarLogoffApi, removeCookiesUser} from 'api';
import SpinnerLoader from 'components/Spinner';
import Cookies from 'js-cookie';
import {IUsuario} from 'models/usuario-model';

type ContextType = {
  usuarioLogado: IUsuario | null;
  setUsuarioLogado: (usuarioLogado: IUsuario | null) => void;
  Login(user: object, token: string): Promise<void>;
  Logout(): void;
  loading: boolean;
  isLogged(): boolean;
  hasRole(role: string): boolean;
};

export const UsuarioLogadoContext = createContext<ContextType>(
  {} as ContextType
);

export const AuthProvider: React.FC = ({children}) => {
  const [usuarioLogado, setUsuarioLogado] = useState<IUsuario | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const cookiesAuthentication = Cookies.get('access_token');
    const cookiesUser = Cookies.get('user');
    if (cookiesAuthentication && cookiesUser) {
      const usuario = JSON.parse(cookiesUser);
      setUsuarioLogado(usuario);
      setLoading(false);
    }
    setLoading(false);
  }, []);

  async function Login(userData: object, token: string) {
    Cookies.set('userActive', 'allowed');
    Cookies.set('user', userData);
    Cookies.set('access_token', token);
    setUsuarioLogado(userData);
  }

  async function Logout() {
    await efetuarLogoffApi();
    removeCookiesUser();
    setUsuarioLogado(null);
  }

  const hasRole = (role: string) => {
    if (!usuarioLogado?.perfil?.permissoes?.find(x => x === role)) {
      return false;
    }
    return true;
  };

  const isLogged = () => !!usuarioLogado;

  if (loading) {
    return <SpinnerLoader />;
  }

  return (
    <UsuarioLogadoContext.Provider
      value={{
        usuarioLogado,
        setUsuarioLogado,
        isLogged,
        hasRole,
        Login,
        loading,
        Logout,
      }}
    >
      {children}
    </UsuarioLogadoContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(UsuarioLogadoContext);

  return context;
}
