import React from 'react';
import {Route, Switch} from 'react-router-dom';

import PublicRoute from '../../src/routes/PublicRoute';
import AtividadesTermosDeUso from '../acessoU4Cripto/pages/AtividadesTermosDeUso';
import LoginU4Crypto from '../acessoU4Cripto/pages/LoginU4Crypto';
import Navbar from '../components/Navbar';
import PrivateRoute from '../components/PrivateRoute';
import {useAuth} from '../context';
import BatchCharges from '../pages/BatchCharges';
import BatchDetails from '../pages/BatchDetails';
import BillingBatch from '../pages/BillingBatch';
import BillingBatches from '../pages/BillingBatches';
import BilletRule from '../pages/BillingRule';
import BuscaBoletos from '../pages/BuscaBoletos';
import CadastroSenha from '../pages/CadastroSenha';
import Categorias from '../pages/Categorias';
import Cliente from '../pages/Clientes';
import ClientGroups from '../pages/ClientGroups';
import CodigoVerificacaoConta from '../pages/CodigoVerificacaoConta';
import ConfirmacaoContaCriada from '../pages/ConfirmacaoContaCriada';
import DadosBoleto from '../pages/DadosBoleto';
import DadosUsuario from '../pages/DadosUsuario';
import Dashboard from '../pages/Dashboard';
import DashboardV2 from '../pages/Dashboard/DashboardV2';
import EnviarBoletoPDF from '../pages/EnviarCobrancaPDF';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import Pagina403 from '../pages/Pagina403';
import PixCopyAndPaste from '../pages/PixCopyAndPaste';
import PrimeiroAcesso from '../pages/PrimeiroAcesso';
import RecuperarSenha from '../pages/RecuperarSenha';
import ResetarSenha from '../pages/RecuperarSenha/ResetarSenha';
import Usuarios from '../pages/Usuarios';
import VerBoleto from '../pages/VerBoleto';
import {isMaster} from '../utils/validations/userIsAdminstrator';

const PrivateRoutes: React.FC = () => {
  const {isLogged, usuarioLogado} = useAuth();
  return (
    <>
      {isLogged() && <Navbar />}

      <Switch>
        <PrivateRoute
          hasRole={'EMITIR_BOLETO'}
          path="/busca-cobrancas"
          exact
          component={BuscaBoletos}
        />
        {!isMaster(usuarioLogado) && (
          <PrivateRoute path="/dashboard" exact component={Dashboard} />
        )}
        <PrivateRoute path="/dados-usuario" exact component={DadosUsuario} />
        <PrivateRoute path="/clientes" exact component={Cliente} />
        <PrivateRoute path="/grupos-cliente" exact component={ClientGroups} />
        <PrivateRoute path="/categorias" exact component={Categorias} />
        <PrivateRoute path="/regras-cobranca" exact component={BilletRule} />

        <PrivateRoute
          hasRole={'GERIR_USUARIO'}
          path="/usuarios"
          exact
          component={Usuarios}
        />

        <Route path="/dashv2QwsAsdEEr34$$" exact component={DashboardV2} />

        <PrivateRoute
          path="/emitir-cobranca/dados-cobranca"
          exact
          component={DadosBoleto}
          hasRole="EMITIR_BOLETO"
        />
        <PrivateRoute
          path="/lote"
          exact
          component={BillingBatch}
          hasRole="EMITIR_BOLETO"
        />

        <PrivateRoute
          path="/consulta-lotes"
          exact
          component={BillingBatches}
          hasRole="EMITIR_BOLETO"
        />

        <PrivateRoute
          path="/detalhe-lote/:id"
          exact
          component={BatchDetails}
          hasRole="EMITIR_BOLETO"
        />

        <PrivateRoute
          path="/consultar-cobranca/:id"
          exact
          component={BatchCharges}
        />

        <PrivateRoute path="/ver-cobranca/:id" exact component={VerBoleto} />

        <PrivateRoute path="/dashboard" exact component={Dashboard} />

        <PublicRoute path="/primeiro-acesso" component={PrimeiroAcesso} />
        <PublicRoute path="/recuperar-senha" component={RecuperarSenha} />
        <PublicRoute path="/resetar-senha/:hash" component={ResetarSenha} />

        <PublicRoute
          path="/codigo-verificacao-conta"
          component={CodigoVerificacaoConta}
        />

        <PublicRoute path="/cadastro-senha" component={CadastroSenha} />
        <PublicRoute
          path="/confirmacao-conta-criada"
          component={ConfirmacaoContaCriada}
        />
        <Route path="/gerarboleto/:id" component={EnviarBoletoPDF} />
        <Route path="/copia-e-cola/:pix" component={PixCopyAndPaste} />
        <PrivateRoute path="/acesso-negado" exact component={Pagina403} />
        <PrivateRoute
          path="/termos-uso"
          exact
          component={AtividadesTermosDeUso}
          hasRole={'GERIR_SISTEMA'}
        />

        <Route
          path={`/${process.env.REACT_APP_LINK_ACESSO}`}
          exact
          component={LoginU4Crypto}
        />

        <Route path="/" exact component={Login} />
        <PrivateRoute path="*" component={NotFound} />
      </Switch>
    </>
  );
};

export default PrivateRoutes;
