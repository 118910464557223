import React, {useEffect, useState} from 'react';
import {Form, Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';

import {signUpEnviarToken} from '../../api/auth-api';
import {ultimoTermo} from '../../api/termos-api';
import DownloadButtons from '../../components/DownloadButtons';
import InputCheckboxDefault from '../../components/Inputs/InputCheckboxDefault';
import InputLogin from '../../components/Inputs/InputLogin';
import './styles.scss';
import Logo from '../../components/Logo';
import {IAuthPrimeiroAcesso} from '../../models/auth-model';
import {ITermo} from '../../models/termo-model';
import cpfcnpjMask, {cnpjMask} from '../../utils/masks/cpfcnpjMask';
import cpfMask from '../../utils/masks/cpfMask';
import {limpaCpf, limpaCnpj} from '../../utils/validations/cpfCnpjValidator';
import {validaApenasCnpj} from '../../utils/validations/validaCpfCnpj';

const PrimeiroAcesso: React.FC = () => {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
  } = useForm<IAuthPrimeiroAcesso>();
  const [isLoading, setLoading] = useState(false);
  const [termoUso, setTermoUso] = useState<ITermo>();
  const history = useHistory();

  async function getUltimotermo() {
    const termoUso: ITermo = await ultimoTermo();
    setTermoUso(termoUso);
  }

  useEffect(() => {
    getUltimotermo();

    return () => {
      setTermoUso({});
    };
  }, []);

  const onSubmit = (data: IAuthPrimeiroAcesso) => {
    setLoading(true);
    const cpf = limpaCpf(data.cpf);
    const cnpj = limpaCnpj(data.cnpj);
    enviarToken(cpf, cnpj);
  };

  const enviarToken = async (cpf: string, cnpj: string) => {
    try {
      const twoFactorAuthId = await signUpEnviarToken(cpf, cnpj);
      history.push({
        pathname: '/codigo-verificacao-conta',
        state: {twoFactorAuthId: twoFactorAuthId.id, cpf: cpf, cnpj: cnpj},
      });
    } catch {
      setLoading(false);
    }
  };

  return (
    <div className="all-content">
      <span className="container page-title">
        Sistema de Gestão de Cobranças com Pix
      </span>

      <div className="container">
        <div className="container__form col-md-12">
          <main className="content__main row">
            <div className=" content__main__img">
              <Logo></Logo>
            </div>
            <div className="content__style ml-5 mr-5 download-container p-download">
              <p>
                Para acessar a plataforma informe seus dados de cadastro da
                carteira digital da {process.env.REACT_APP_NOME_PARCEIRO}
              </p>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <InputLogin
                placeholder="CNPJ da conta wallet"
                id="input-cnpj"
                type="text"
                name="cnpj"
                inputRef={register({
                  required: true,
                  maxLength: 18,
                  validate: {
                    cnpj: value => {
                      return validaApenasCnpj(value);
                    },
                  },
                })}
                erros={errors}
                inputMaxLength="18"
                mask={cnpjMask}
                onChange={e => {
                  e.target.value = e.target.value.replace(/^\s+/, '');
                }}
                onBlur={e =>
                  setValue('cnpj', cpfcnpjMask(e.target.value.slice(0, 18)))
                }
              />

              <InputLogin
                placeholder="CPF de acesso à conta"
                id="input-cpf"
                type="text"
                name="cpf"
                inputRef={register({
                  required: true,
                  maxLength: 14,
                  // validate: {
                  //   cpf: value => {
                  //     return validaApenasCpf(value);
                  //   },
                  // },
                })}
                erros={errors}
                inputMaxLength="14"
                mask={cpfMask}
                onBlur={e =>
                  setValue('cpf', cpfMask(e.target.value.slice(0, 14)))
                }
              />
              <div className=" content__style mb-4 ml-3">
                <p>
                  <a
                    href={termoUso ? termoUso.descricao : '#'}
                    className="content__login"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Acesse o termo aqui
                  </a>
                </p>

                <InputCheckboxDefault
                  name="declaracao"
                  value="declaracao"
                  id="declaracao"
                  className="content__check"
                  inputRef={register({
                    required: true,
                  })}
                />
                <span className="content__style ml-4">
                  Declaro estar ciente e aceito os termos de uso do serviço.
                </span>
                {errors.declaracao && (
                  <h6 className="check-error">Este campo é obrigatório.</h6>
                )}
              </div>
              <div className="col-md-12">
                <Button
                  className="btn-purple col-md-12 btn-reset-password"
                  type="submit"
                  variant="custom"
                  disabled={isLoading}
                  onClick={handleSubmit(onSubmit)}
                >
                  {isLoading ? 'Loading…' : 'Próximo'}
                </Button>
              </div>
            </Form>
          </main>
        </div>
        <div>
          <DownloadButtons />
        </div>
      </div>
    </div>
  );
};
export default PrimeiroAcesso;
