import React, {useRef, useState} from 'react';
import {Overlay, Popover} from 'react-bootstrap';
import {FaCog, FaRegUserCircle, FaSignOutAlt} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

import Swal from 'sweetalert2';

import {useAuth} from '../../../src/context/usuario-context';
import {isMaster} from '../../utils/validations/userIsAdminstrator';
import './styles.scss';

function NavbarTop() {
  const history = useHistory();

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const {Logout, usuarioLogado} = useAuth();

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleClosepop = () => setShow(false);

  async function logoff() {
    try {
      Swal.fire({
        title: 'Tem certeza que deseja sair?',
        text: 'Ao confirmar, você será deslogado(a) do sistema!',
        showCancelButton: true,
        confirmButtonColor: '#6CC551',
        cancelButtonColor: '#d33',
        confirmButtonText:
          '<i class="fa fa-arrow-left fa-sm" aria-hidden="true"></i> Sair',
        cancelButtonText:
          '<i class="fas fa-ban fa-sm" aria-hidden="true"></i> Cancelar',
        customClass: {
          confirmButton: 'confirmButtonModalClass',
          cancelButton: 'cancelButtonModalClass',
        },
      }).then(async result => {
        if (result.value) {
          if (isMaster(usuarioLogado)) {
            Logout();
            toast.success('Logoff feito com sucesso! ', {autoClose: 2500});
            history.replace('acesso-u4crypto');
          } else {
            Logout();
            toast.success('Logoff feito com sucesso! ', {autoClose: 2500});
            history.replace('/');
          }
        }
      });
    } catch (error) {
      toast.error(error);
    }
  }

  return (
    <div className="navbar navbar-top">
      <div className="navbar-top__text ">
        <text>
          {usuarioLogado?.clientName} - {usuarioLogado?.nome}
        </text>
      </div>
      <div className="navbar-top__icones ">
        {/* <FaBell size={25} /> */}
        <FaRegUserCircle size={30} onClick={handleClick}></FaRegUserCircle>
        <Overlay
          show={show}
          target={target}
          placement="bottom"
          container={ref.current}
          containerPadding={20}
          onHide={handleClosepop}
          rootClose={true}
          transition={false}
        >
          <Popover id="popover-contained">
            <Popover.Content>
              <div className="icon-pop">
                <div>
                  <FaCog
                    className="icon-pop__icon"
                    size="18"
                    onClick={() => history.replace('/dados-usuario')}
                  />
                  <span
                    className="icon-pop__text"
                    onClick={() => history.replace('/dados-usuario')}
                  >
                    Meus dados
                  </span>
                </div>
                <div>
                  <FaSignOutAlt
                    className="icon-pop__icon"
                    size="18"
                    onClick={() => logoff()}
                  />
                  <span className="icon-pop__text" onClick={() => logoff()}>
                    Sair
                  </span>
                </div>
              </div>
            </Popover.Content>
          </Popover>
        </Overlay>
      </div>
    </div>
  );
}

export default NavbarTop;
