import React, {useEffect, useState} from 'react';

import {boletosRecorrentesByDate} from '../../../../api/dashboard-api';
import UILoad from '../../../../components/UI/Load';
import {IDashboardFilters} from '../../../../models/dashboard-model';
interface Filtros {
  filter: IDashboardFilters;
}
const BoletosRecorrentes: React.FC<Filtros> = ({filter}) => {
  const [totalBoletosRecorrente, setTotalBoletosRecorrente] = useState(0);
  const [loading, setLoading] = useState(false);

  async function getCobrancasRecorrentes(filter) {
    setLoading(true);
    const totalRecorrentes: number = await boletosRecorrentesByDate(filter);
    setTotalBoletosRecorrente(totalRecorrentes);
    setLoading(false);
  }

  useEffect(() => {
    if (filter) {
      getCobrancasRecorrentes(filter);
    }
  }, [filter]);

  return (
    <div className="dashboard-boleto__content dashboard-boleto__border">
      {loading ? (
        <UILoad />
      ) : (
        <>
          <label className="dashboard-boleto__label" htmlFor="recorrente">
            Cobranças recorrentes
          </label>
          {totalBoletosRecorrente > 0 ? (
            <span className="dashboard-boleto__span dashboard-boleto__purple   ">
              {totalBoletosRecorrente}
            </span>
          ) : (
            <span className="dashboard-boleto__span dashboard-boleto__zero   ">
              Não há dados disponíveis para o período selecionado.{' '}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default BoletosRecorrentes;
