import React from 'react';

import './styles.scss';
import MainUsuarios from '../../components/MainUsuarios/index';
import UIContainer from '../../components/UI/Container';

const Usuarios: React.FC = () => (
  <UIContainer>
    <MainUsuarios />
  </UIContainer>
);
export default Usuarios;
