import React from 'react';

import './styles.scss';

interface lastUpdatedProps {
  tempo: string;
}

const LabelLastUpdated: React.FC<lastUpdatedProps> = ({tempo}) => {
  return (
    <>
      <label className="label-last-updated">
        Ultima atualização: {tempo} atrás
      </label>
    </>
  );
};

export default LabelLastUpdated;
