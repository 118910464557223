import React, {useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import './styles.scss';
import {
  FaArrowRight,
  FaBan,
  FaEdit,
  FaRegSave,
  FaQuestionCircle,
} from 'react-icons/fa';
import {toast} from 'react-toastify';

import Swal from 'sweetalert2';

import {createBillingRuleApi, editRuleApi} from '../../../api';
import {
  BillingRuleModel,
  BillingRuleType,
  InterestFineDiscountType,
} from '../../../models/billing-rule-model';
import CardJurosMulta from '../../../pages/DadosBoleto/CardJurosMulta';
import {newMoneyMask} from '../../../utils/masks/moneyMask';
import noEmptyMask from '../../../utils/masks/noEmptyMask';
import CharacterCount from '../../CharacterCount';
import Errors from '../../Errors';
import InputDefault from '../../Inputs/InputDefault';
import SpinnerLoader from '../../Spinner';
import Titulo from '../../Titulo';

interface BilletRuleModal {
  handleCloseModal?: Function;
  handleUpdateTable?: Function;
  afterSave?: Function;
  booleanUtil?: boolean;
  objectUtil?: Record<string, object>;
  rule?: BillingRuleModel;
  showEditOrView?: boolean;
}

const FormBilletRule: React.FC<BilletRuleModal> = ({
  handleCloseModal,
  handleUpdateTable,
  rule,
  showEditOrView = true,
}) => {
  const {register, handleSubmit, errors} = useForm<BillingRuleModel>();

  const [editing, setEditing] = useState(showEditOrView);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [radioValue, setRadioValue] = useState<BillingRuleType>(
    BillingRuleType.FIXED
  );
  const [name, setName] = useState<string>('');
  const [value, setValue] = useState<number>(0);
  const [description, setDescription] = useState<string>('');
  const [information, setInformation] = useState<string>('');
  const [informationCounter, setInformationCounter] = useState<Number>(300);
  const [secondStage, setSecondStage] = useState<boolean>(false);
  const [thereEmptyFields, setThereEmptyFields] = useState<boolean>(true);
  const [diasAmaisParaDesconto, setDiasAmaisParaDesconto] = useState<number>(0);
  const [isPorcentagemDesconto, setIsPorcentagemDesconto] = useState<boolean>(
    false
  );
  const [discount, setDiscount] = useState<number>(0);
  const [isPorcentagemJuros, setIsPorcentagemJuros] = useState<boolean>(false);
  const [billingDay, setBillingDay] = useState<number>(1);
  const [expirationDay, setExpirationDay] = useState<number>(1);
  const [
    daysToPayAfterExpiration,
    setDaysToPayAfterExpiration,
  ] = useState<number>(0);
  const [putRuleValuesInState, setPutRuleValuesInState] = useState<boolean>(
    false
  );
  const [isEditStage, setIsEditStage] = useState<boolean>(false);

  const onSubmit = (data: {}) => {
    if (!isEditStage && !rule) createRule(data);
    if (isEditStage && rule) editRule(data);
  };

  const editRule = async (data: {}) => {
    try {
      const editedRule: BillingRuleModel = {
        id: rule.id,
        billingDay: billingDay,
        daysAfterExpiration: parseInt(String(daysToPayAfterExpiration)),
        discount: parseFloat(data.desconto.replace(',', '.')),
        expirationDay: expirationDay,
        fine: parseFloat(data.multa.replace(',', '.')),
        fineType: isPorcentagemJuros
          ? InterestFineDiscountType.PERCENT
          : InterestFineDiscountType.VALUE,
        interest: parseFloat(data.juros.replace(',', '.')),
        interestType: isPorcentagemJuros
          ? InterestFineDiscountType.PERCENT
          : InterestFineDiscountType.VALUE,
        type: radioValue,
        name: name,
        value: value,
        description: description,
        information: information,
        discountAdvanceDays: diasAmaisParaDesconto,
        discountType: isPorcentagemDesconto
          ? InterestFineDiscountType.PERCENT
          : InterestFineDiscountType.VALUE,
      };
      const showWarning = areValuesHigherThanAllowed(editedRule);
      if (showWarning) {
        showValueWarning(editedRule, editRuleApi);
      } else {
        await editRuleApi(editedRule);
        toast.success('Regra editada com sucesso!', {autoClose: 2500});
        closeModal();
      }
      setIsLoading(true);
    } catch (error) {
      toast.error(error, {autoClose: 2500});
    }
    setIsLoading(false);
  };

  const createRule = async (data: any) => {
    try {
      if (!data.desconto) data.desconto = '0';
      if (!data.juros) data.juros = '0';
      if (!data.multa) data.multa = '0';
      const rule: BillingRuleModel = {
        billingDay: billingDay,
        daysAfterExpiration: parseInt(String(daysToPayAfterExpiration)),
        discount: parseFloat(data.desconto.replace(',', '.')),
        expirationDay: expirationDay,
        fine: parseFloat(data.multa.replace(',', '.')),
        fineType: isPorcentagemJuros
          ? InterestFineDiscountType.PERCENT
          : InterestFineDiscountType.VALUE,
        interest: parseFloat(data.juros.replace(',', '.')),
        interestType: isPorcentagemJuros
          ? InterestFineDiscountType.PERCENT
          : InterestFineDiscountType.VALUE,
        type: radioValue,
        name: name,
        value: value,
        description: description,
        information: information,
        discountAdvanceDays: diasAmaisParaDesconto,
        discountType: isPorcentagemDesconto
          ? InterestFineDiscountType.PERCENT
          : InterestFineDiscountType.VALUE,
      };
      const showWarning = areValuesHigherThanAllowed(rule);
      if (showWarning) {
        showValueWarning(rule, createBillingRuleApi);
      } else {
        await createBillingRuleApi(rule);
        toast.success('Regra cadastrada com sucesso!', {autoClose: 2500});
        closeModal();
      }
      setIsLoading(true);
    } catch (error) {
      toast.error(
        'Erro ao salvar regra de cobrança. Favor validar os campos.',
        {autoClose: 2500}
      );
    }
    setIsLoading(false);
  };

  const showValueWarning = (rule: BillingRuleModel, callback: Function) => {
    try {
      Swal.fire({
        title:
          'O valor do juros e/ou da multa ultrapassaram o limite permitido por lei!',
        text:
          'Pode-se apenas cobrar 0,033% de juros ao dia e 2% de multa ao mês conforme art. 52 do Código de Defesa do Consumidor!',
        showCancelButton: true,
        confirmButtonText:
          '<i class="fas fa-check fa-sm" aria-hidden="true"></i> Estou ciente, continuar',
        cancelButtonText:
          '<i class="fas fa-ban" aria-hidden="true"></i> Voltar e editar',
        customClass: {
          confirmButton: 'confirmButtonModalClass',
          cancelButton: 'cancelButtonModalClass',
        },
      }).then(async result => {
        if (result.isConfirmed) {
          await callback(rule);
          if (rule.id) {
            toast.success('Regra editada com sucesso!', {autoClose: 2500});
            closeModal();
          }
          toast.success('Regra cadastrada com sucesso!', {autoClose: 2500});
          closeModal();
        }
      });
    } catch (error) {
      toast.error(error, {autoClose: 2500});
    }
  };

  const areValuesHigherThanAllowed = (rule: BillingRuleModel) => {
    if (
      rule.interestType === InterestFineDiscountType.PERCENT &&
      rule.interest > 0.033
    )
      return true;
    if (rule.fineType === InterestFineDiscountType.PERCENT && rule.fine > 2)
      return true;
    if (rule.interestType === InterestFineDiscountType.VALUE) {
      const interestValueAllowed = rule.value * 0.0033;
      if (rule.interest > interestValueAllowed) return true;
    }
    if (rule.fineType === InterestFineDiscountType.VALUE) {
      const fineValueAllowed = rule.value * 0.02;
      if (rule.fine > fineValueAllowed) return true;
    }
    return false;
  };

  const closeModal = () => {
    handleUpdateTable(true);
    handleCloseModal(false);
  };

  const characterCounter = e => {
    e.target.value = e.target.value.replace(/^\s+/, '');
    setInformationCounter(300 - e.target.value.length);
  };

  useEffect(() => {
    if (name !== '' && description !== '' && information !== '' && value !== 0)
      setThereEmptyFields(false);
  }, [radioValue, name, description, information, value]);

  useEffect(() => {
    if (rule && !putRuleValuesInState) {
      setName(rule.name);
      setDescription(rule.description);
      setBillingDay(rule.billingDay);
      setDaysToPayAfterExpiration(rule.daysAfterExpiration);
      setDiscount(rule.discount);
      setDiasAmaisParaDesconto(rule.discountAdvanceDays);
      setExpirationDay(rule.expirationDay);
      setInformation(rule.information);
      setValue(rule.value);
      setPutRuleValuesInState(true);
      setIsEditStage(true);
    }
  });

  return (
    <form className="form-modal">
      {isLoading ? (
        <SpinnerLoader />
      ) : !secondStage ? (
        <div>
          <Titulo>Passo 1/2 - Detalhes da Regra</Titulo>
          <label className="label-item-tabela" htmlFor="input-nome">
            Tipo de Regra:
          </label>
          <div className="form-row">
            <div
              key="billet-rule-option-fixed"
              className="form-group col-md-6 label-item-tabela form-row"
            >
              <Form.Check
                inline
                type="radio"
                name="billet-rule-option"
                id="billet-rule-option-fixed"
                label="Fixa"
                defaultChecked={
                  !rule ? true : rule.type === BillingRuleType.FIXED
                }
                onChange={() => setRadioValue(BillingRuleType.FIXED)}
                disabled={!editing}
              />
              <div
                data-tooltip="Seus parâmetros não poderão ser editados ao utilizar esta regra no cadastro de um lote."
                className="--tooltip"
              >
                <FaQuestionCircle className="form-nova-cobranca__icon" />
              </div>
            </div>
            <div
              key="billet-rule-option-dynamic"
              className="form-group col-md-6 label-item-tabela form-row"
            >
              <Form.Check
                inline
                type="radio"
                name="billet-rule-option"
                id="billet-rule-option-dynamic"
                label="Dinâmica"
                defaultChecked={
                  !rule ? false : rule.type === BillingRuleType.DYNAMIC
                }
                onChange={() => setRadioValue(BillingRuleType.DYNAMIC)}
                disabled={!editing}
              />
              <div
                data-tooltip="Permite edição dos seus parâmetros no momento do cadastro de um lote."
                className="--tooltip"
              >
                <FaQuestionCircle className="form-nova-cobranca__icon" />
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="col-md-4" key="name">
              <label className="label-item-tabela" htmlFor="input-name">
                Nome da Regra
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="rule-name"
                type="text"
                readOnly={false}
                id="input-name"
                defaultValue={name}
                inputRef={register({
                  required: true,
                  maxLength: 30,
                  minLength: 1,
                })}
                onChange={event => {
                  setName(event.target.value);
                }}
                erros={errors}
                inputMaxLength="30"
                mask={noEmptyMask}
                disabledInput={!editing}
              />
            </div>
            <div className="form-group col-md-4 input-valor-cobranca">
              <label className="label-item-tabela" htmlFor="input-value">
                Valor
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="value"
                type="text"
                defaultValue={value !== 0 ? newMoneyMask(value.toString()) : ''}
                placeholder=""
                id="input-value"
                onChange={event => {
                  let test = newMoneyMask(event.target.value);
                  test = test.replace(',', '.');
                  setValue(parseFloat(test) * 100);
                }}
                inputRef={register({
                  required: true,
                  min: 1,
                  maxLength: 14,
                })}
                erros={errors}
                mask={newMoneyMask}
                inputMaxLength="14"
                inputGroupText="R$"
                disabledInput={!editing}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-sm-12" key="name">
              <label className="label-item-tabela" htmlFor="input-description">
                Descrição da Regra
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="description"
                type="text"
                readOnly={false}
                defaultValue={description}
                id="input-description"
                onChange={event => setDescription(event.target.value)}
                inputRef={register({
                  required: true,
                  maxLength: 100,
                  minLength: 1,
                })}
                erros={errors}
                inputMaxLength="100"
                mask={noEmptyMask}
                disabledInput={!editing}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-sm-12  mt-3" key="information">
              <label className="label-item-tabela" htmlFor="information">
                Informações
              </label>
              <span className="asterisk ">*</span>
              <textarea
                name="information"
                defaultValue={information}
                maxLength={300}
                id="information"
                className="text-area-default"
                aria-label="With textarea"
                ref={register({required: true})}
                onChange={event => {
                  setInformation(event.target.value);
                  characterCounter(event);
                }}
                disabled={!editing}
              />
              <div className="d-flex justify-content-between">
                <Errors name="descricao" erros={errors} />
                <CharacterCount count={informationCounter} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Titulo>Passo 2/2 - Complete os dados de cobrança</Titulo>
          <CardJurosMulta
            register={register}
            errors={errors}
            setValorDesconto={setDiscount}
            setDiasAmaisParaDesconto={setDiasAmaisParaDesconto}
            setIsPorcentagemDesconto={setIsPorcentagemDesconto}
            setIsPorcentagemJuros={setIsPorcentagemJuros}
            juros={
              rule
                ? newMoneyMask(rule.interest.toFixed(2).toString()).replace(
                    '.',
                    ','
                  )
                : ''
            }
            multa={
              rule
                ? newMoneyMask(rule.fine.toFixed(2).toString()).replace(
                    '.',
                    ','
                  )
                : ''
            }
            desconto={newMoneyMask(discount.toFixed(2).toString()).replace(
              '.',
              ','
            )}
            diasAMaisParaDesconto={diasAmaisParaDesconto.toString()}
            isFinePercent={
              !rule ? false : rule.fineType === InterestFineDiscountType.PERCENT
            }
            isDiscountPercent={
              !rule
                ? false
                : rule.discountType === InterestFineDiscountType.PERCENT
            }
          />

          <br />
          <br />

          <div className="form-row">
            <div className="col-sm-3" key="billing-day">
              <label className="label-item-tabela" htmlFor="input-billing-day">
                Dia de Cobrança
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="billing-day"
                type="number"
                readOnly={false}
                id="input-billing-day"
                onChange={event => {
                  if (parseInt(event.target.value) > 31)
                    event.target.value = '31';
                  setBillingDay(parseInt(event.target.value));
                }}
                inputRef={register({
                  required: true,
                  maxLength: 2,
                  minLength: 1,
                })}
                erros={errors}
                inputMaxLength="2"
                mask={noEmptyMask}
                defaultValue={rule ? rule.billingDay.toString() : ''}
              />
            </div>
            <div className="col-sm-3 align-self-end" />
            <div className="col-sm-3 align-content-end" key="expiration-day">
              <label
                className="label-item-tabela"
                htmlFor="input-expiration-day"
              >
                Dia de Vencimento
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="expiration-day"
                type="number"
                readOnly={false}
                id="input-expiration-day"
                onChange={event => {
                  if (parseInt(event.target.value) > 31)
                    event.target.value = '31';
                  setExpirationDay(parseInt(event.target.value));
                }}
                inputRef={register({
                  required: true,
                  maxLength: 2,
                  minLength: 1,
                })}
                erros={errors}
                inputMaxLength="2"
                mask={noEmptyMask}
                defaultValue={expirationDay.toString()}
              />
            </div>
          </div>

          <br />
          <br />

          <div className="form-row">
            <div className="col-sm-6" key="payment-limit">
              <label
                className="label-item-tabela"
                htmlFor="input-payment-limit"
              >
                Nº de dias limite para pagamento (após vencimento)
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="payment-limit"
                type="number"
                readOnly={false}
                id="input-payment-limit"
                onChange={event => {
                  if (parseInt(event.target.value) > 15)
                    event.target.value = '15';
                  setDaysToPayAfterExpiration(parseInt(event.target.value));
                }}
                inputRef={register({
                  required: true,
                  maxLength: 2,
                  minLength: 1,
                })}
                erros={errors}
                inputMaxLength="2"
                mask={noEmptyMask}
                defaultValue={daysToPayAfterExpiration.toString()}
              />
            </div>
          </div>
        </div>
      )}
      <div className="buttons-group">
        <Button
          className="btn-custom btn-cancel"
          variant="custom"
          onClick={() => closeModal()}
        >
          <FaBan size={13} className="mr-1" />
          Cancelar
        </Button>
        {rule && !editing ? (
          <Button
            type="button"
            onClick={() => setEditing(true)}
            variant="custom"
            className="btn-custom btn-save"
            disabled={thereEmptyFields}
          >
            <FaEdit size={13} className="mr-1" />
            Editar
          </Button>
        ) : !secondStage ? (
          <Button
            type="button"
            onClick={() => setSecondStage(true)}
            variant="custom"
            className="btn-custom btn-save"
            disabled={thereEmptyFields}
          >
            <FaArrowRight size={13} className="mr-1" />
            Próximo
          </Button>
        ) : (
          <Button
            type="button"
            onClick={handleSubmit(onSubmit)}
            variant="custom"
            className="btn-custom btn-save"
          >
            <FaRegSave size={13} className="mr-1" />
            Salvar
          </Button>
        )}
      </div>
    </form>
  );
};

export default FormBilletRule;
