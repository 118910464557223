import Cookies from 'js-cookie';
import {IValidarToken} from 'models/auth-model';
import {IUsuario} from 'models/usuario-model';

import api from './api';

export const efetuarLogin = async (
  cpf: string,
  cnpj: string,
  password: string
) => {
  const partnerID = process.env.REACT_APP_PARTNER_ID;
  const params = {cpf, cnpj, password, partnerID};
  const token = (await api.post('auth/login', params))?.data;
  const usuario = token.usuario;
  const clientName = token.clientName;
  const pixAvailable = token.pixAvailable;
  const documentAvailable = token.documentAvailable;
  const infoPix = token.infoPix;
  let verifyInfoPix = false;
  if (
    infoPix &&
    infoPix.dailyMaximumValueRequested < infoPix.dailyMaximumValue
  ) {
    verifyInfoPix = true;
  }

  usuario.clientName = clientName;

  Cookies.set('access_token', token.access_token);
  Cookies.set('refresh_token', token.refresh_token);
  try {
    await verificarTermoAtualizado(usuario.id);
    return {
      usuario,
      accept: true,
      token: token.access_token,
      pixAvailable: pixAvailable,
      documentAvailable: documentAvailable,
      infoPix: verifyInfoPix,
    };
  } catch (e) {
    return {
      usuario,
      accept: false,
      token: token.access_token,
      pixAvailable: pixAvailable,
      documentAvailable: documentAvailable,
      infoPix: verifyInfoPix,
    };
  }
};

export const verificarTermoAtualizado = async (id: string | undefined) => {
  (await api.get(`/usuarios/ultimo-termo-atualizado/${id}`))?.data;
};

export const signUpEnviarToken = async (cpf: string, cnpj: string) => {
  const params = {cpf, cnpj, partnerID: process.env.REACT_APP_PARTNER_ID};
  return (await api.post<IUsuario>('sign-up/2fa/recurrence-sign-up', params))
    ?.data;
};

export const signUpValidarToken = async (
  cpf: string,
  cnpj: string,
  twoFactorAuthId: string,
  token: string
) => {
  const params = {
    cpf,
    cnpj,
    twoFactorAuthId,
    token,
    partnerID: process.env.REACT_APP_PARTNER_ID,
  };
  return (
    await api.post<IValidarToken>('sign-up/customer/recurrence-sign-up', params)
  )?.data;
};

export const efetuarLoginMaster = async (email: string, password: string) => {
  const partnerID = process.env.REACT_APP_PARTNER_ID;
  const params = {email, password, partnerID};
  const token = (await api.post('auth/login-master', params))?.data;
  if (token) {
    Cookies.set('access_token', token.access_token);
    Cookies.set('refresh_token', token.refresh_token);
  }
  return {usuarioLogado: token.usuario, token: token.access_token};
};

export const efetuarLogoffApi = async () => {
  await api.delete('auth/logout');
};

export const getRefreshToken = async () => {
  return (await api.put('auth/refresh-token'))?.data;
};
