import {
  IDashboardFilters,
  IFaturamento,
  IStatusCobranca,
  IStatusEnvio,
  IVolumeEnvio,
} from '../models/dashboard-model';
import api from './api';

export const boletosRecorrentesByDate = async (filter: IDashboardFilters) => {
  return (
    await api.get<number>(
      `/dashboard/total-recorrentes?tipoData=${filter.tipoData}&startDate=${filter.startDate}&finishDate=${filter.finishDate}`
    )
  )?.data;
};

export const boletosEmAbertoByDate = async (filter: IDashboardFilters) => {
  return (
    await api.get<number>(
      `/dashboard/boletos-em-aberto/?tipoData=${filter.tipoData}&startDate=${filter.startDate}&finishDate=${filter.finishDate}`
    )
  )?.data;
};

export const boletosPagosByDate = async (filter: IDashboardFilters) => {
  return (
    await api.get<number>(
      `/dashboard/boletos-pagos/?tipoData=${filter.tipoData}&startDate=${filter.startDate}&finishDate=${filter.finishDate}`
    )
  )?.data;
};

export const previsaoRecebimentoByDate = async (filter: IDashboardFilters) => {
  return (
    await api.get<number>(
      `/dashboard/previsao-recebimento/?tipoData=${filter.tipoData}&startDate=${filter.startDate}&finishDate=${filter.finishDate}`
    )
  )?.data;
};

export const volumeInadimplenciaByDate = async (filter: IDashboardFilters) => {
  return (
    await api.get<number>(
      `/dashboard/volume-inadimplencia/?tipoData=${filter.tipoData}&startDate=${filter.startDate}&finishDate=${filter.finishDate}`
    )
  )?.data;
};

export const statusEnvioByDate = async (filter: IDashboardFilters) => {
  return (
    await api.get<IStatusEnvio>(
      `/dashboard/status-envio/?tipoData=${filter.tipoData}&startDate=${filter.startDate}&finishDate=${filter.finishDate}`
    )
  )?.data;
};

export const statusCobrancasByDate = async (filter: IDashboardFilters) => {
  return (
    await api.get<IStatusCobranca>(
      `/dashboard/status-cobrancas/?tipoData=${filter.tipoData}&startDate=${filter.startDate}&finishDate=${filter.finishDate}`
    )
  )?.data;
};

export const volumeEnvioByCategoriaByDate = async (
  filter: IDashboardFilters
) => {
  return (
    await api.get<IVolumeEnvio[]>(
      `/dashboard/volume-envio-por-categoria/?tipoData=${filter.tipoData}&startDate=${filter.startDate}&finishDate=${filter.finishDate}`
    )
  )?.data;
};

export const volumeEnvioTotal = async () => {
  return (await api.get<number>('/dashboard/boletos-enviados'))?.data;
};

export const volumeEnvioTotalByDate = async (filter: IDashboardFilters) => {
  return (
    await api.get<number>(
      `dashboard/boletos-enviados/?tipoData=${filter.tipoData}&startDate=${filter.startDate}&finishDate=${filter.finishDate}`
    )
  )?.data;
};

export const faturamentoByCategoria = async () => {
  return (await api.get<IFaturamento>('/dashboard/faturamento-por-categoria'))
    ?.data;
};

export const faturamentoByCategoriaByDate = async (
  filter: IDashboardFilters
) => {
  return (
    await api.get<IFaturamento>(
      `/dashboard/faturamento-por-categoria/?tipoData=${filter.tipoData}&startDate=${filter.startDate}&finishDate=${filter.finishDate}`
    )
  )?.data;
};
