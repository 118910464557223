import React, {useEffect, useState} from 'react';

import InputCheckboxDefault from '../../Inputs/InputCheckboxDefault';
import {ICliente} from '../../../models';
import cpfcnpjMask from '../../../utils/masks/cpfcnpjMask';

interface TableRowProps {
  checked: boolean;
  client: ICliente;
  clientsIds: Set<string | undefined>;
  setClientUpdated: Function;
}

const TableRowClientGroupModal: React.FC<TableRowProps> = ({
  checked,
  client,
  clientsIds,
  setClientUpdated,
}) => {
  const [isChecked, setIsChecked] = useState(clientsIds.has(client.id));
  useEffect(() => {}, [clientsIds]);

  const handleChange = () => {
    if (clientsIds.has(client.id)) {
      clientsIds.delete(client.id);
      setIsChecked(false);
    } else {
      clientsIds.add(client.id);
      setIsChecked(true);
    }
    setClientUpdated();
  };

  return (
    <>
      <tr key={client.id}>
        <td className="align-middle check">
          <InputCheckboxDefault
            name={''}
            onClick={handleChange}
            //onChange={handleChange}
            checked={isChecked}
          />
        </td>
        <td className="align-middle left">{client.nome}</td>
        <td className="align-middle left">{client.email}</td>
        <td className="align-middle left">{cpfcnpjMask(client.cpfCnpj)}</td>
      </tr>
    </>
  );
};

export default TableRowClientGroupModal;
