import React from 'react';

import './styles.scss';
import {ICliente} from '../../../models/cobranca';
import cpfcnpjMask from '../../../utils/masks/cpfcnpjMask';
import phoneMask from '../../../utils/masks/phoneMask';
import CardSection from '../../CardSection';

const VerCliente: React.FC<ICliente> = (props: ICliente) => {
  return (
    <>
      {/* <div className="d-flex justify-content-end ">
        <BtnDefault className="btn-custom btn-save">Editar</BtnDefault>
      </div> */}
      <div className="row ml-2">
        <div className="col-sm-4">
          <label htmlFor="Nome" className="form-label">
            Nome:
          </label>
          <span className="text__content">{props.nome}</span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="CPF" className="form-label">
            Documento:
          </label>
          <span className="text__content">{cpfcnpjMask(props.cpfCnpj)}</span>
        </div>
      </div>
      <div className="row ml-2">
        <div className="col-sm-8">
          <label htmlFor="Endreço" className="form-label">
            Endereço:
          </label>
          <span className="text__content">
            {props.endereco?.logradouro}, {props.endereco?.numero}{' '}
            {props.endereco?.bairro} - {props.endereco?.cidade},{' '}
            {props.endereco?.siglaEstado} - {props.endereco?.cep}
          </span>
        </div>
      </div>
      <div className="w-75">
        <CardSection title="Contato">
          <div className="row">
            <div className="col-sm-4">
              <label htmlFor="Telefone" className="form-label">
                Telefone:
              </label>

              <span className="text__content">{phoneMask(props.telefone)}</span>
            </div>
            <div className="col-sm-6">
              <label htmlFor="E-mail" className="form-label">
                E-mail:
              </label>
              <span className="text__content">{props.email}</span>
            </div>
          </div>
        </CardSection>
      </div>
    </>
  );
};

export default VerCliente;
