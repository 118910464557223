import {ITicket} from 'models/ticket-model';
import {IupdateUser, IUsuario} from 'models/usuario-model';

import api from './api';

export const cadastrarUsuario = async (usuario: IUsuario) => {
  return (await api.post<IUsuario>('/usuarios', usuario))?.data;
};

export const listarUsuarios = async (
  page = 0,
  limit = 10,
  nome?: string,
  email?: string,
  cpf?: string,
  order?: string
) => {
  return (
    await api.get<IUsuario>(
      `/usuarios?page=${page}&limit=${limit}&nome=${nome}&email=${email}&cpf=${cpf}&order=${order}`
    )
  )?.data;
};

export const exibirUsuario = async (id: string) => {
  return (await api.get<IUsuario>(`/usuarios/${id}`))?.data;
};

export const deletarUsuario = async (id: string) => {
  return await api.delete<IUsuario>(`/usuarios/${id}`);
};

export const editarUsuario = async (id: string, data: IupdateUser) => {
  return (await api.put<IUsuario>(`/usuarios/${id}`, data))?.data;
};

export const getDadosByHash = async (hash?: string) => {
  return (await api.get(`/usuarios/recuperar-senha/${hash}`))?.data;
};

export const alterarSenha = async (id: string, password: string) => {
  const params = {id, password};
  return (await api.put('/usuarios/alterar-senha', params))?.data;
};

export const enviarEmailEsqueciMinhaSenha = async (
  cpf: string,
  cnpj: string
) => {
  const partnerID = process.env.REACT_APP_PARTNER_ID;
  const params = {cpf, cnpj, partnerID};
  return (await api.post('/usuarios/esqueci-minha-senha', params))?.data;
};

export const enviarEmailTicket = async (ticket: ITicket) => {
  return (await api.post('/usuarios/ticket', ticket))?.data;
};

export const enviarEmailTicketLogado = async (
  idUsuario: string,
  ticket: ITicket
) => {
  return (await api.post(`/usuarios/ticket/${idUsuario}`, ticket))?.data;
};
