import React from 'react';

import './styles.scss';
import {IDashboardFilters} from '../../../models/dashboard-model';
import BoletosAberto from './BoletosAberto';
import BoletosPagos from './BoletosPagos';
import BoletosRecorrentes from './BoletosRecorrentes';
import PrevisaoRecebimento from './PrevisaoRecebimento';
import StatusEnvio from './StatusEnvio';
import VolumeEnvio from './VolumeEnvio';
import VolumeInadimplencia from './VolumeInadimplencia';
interface Filtros {
  filter: IDashboardFilters;
}

const DashboardBoleto: React.FC<Filtros> = ({filter}) => {
  return (
    <div className="dashboard-boleto ">
      <div className="box-dashboard col-md-6">
        <VolumeEnvio filter={filter} />
        <PrevisaoRecebimento filter={filter} />
        <BoletosPagos filter={filter} />
        <BoletosAberto filter={filter} />
      </div>
      <div className="box-dashboard col-md-6">
        <BoletosRecorrentes filter={filter} />
        <VolumeInadimplencia filter={filter} />
        <StatusEnvio filter={filter} />
      </div>
    </div>
  );
};

export default DashboardBoleto;
