import React, {useState, useEffect} from 'react';
import './styles.scss';
import {Table} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {
  FaSearch,
  FaUserPlus,
  FaBroom,
  FaSortAlphaDown,
  FaSortAlphaUpAlt,
} from 'react-icons/fa';

import {listarUsuarios} from '../../api/usuario-api';
import {PaginationModel} from '../../models/pagination-model';
import {IUsuarios} from '../../models/usuario-model';
import cpfMask from '../../utils/masks/cpfMask';
import noEmptyMask from '../../utils/masks/noEmptyMask';
import BtnDefault from '../Buttons/BtnDefault';
import InputDefault from '../Inputs/InputDefault';
import ModalDefault from '../ModalDefault';
import PaginationDefault from '../PaginationDefault';
import SpinnerLoader from '../Spinner';
import TableRowUsuario from '../TableRows/TableRowsUsuario';
import Titulo from '../Titulo';
import UIContent from '../UI/Content';
import ModalEditarUsuario from './ModalUsuarioEditar';

const MainUsuarios: React.FC = () => {
  const {handleSubmit, reset, register, setValue} = useForm();
  const [updateTable, setUpdateTable] = useState(false);
  const [usuarios, setUsuarios] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const [nome, setNome] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [cpf, setCPF] = useState<string>('');
  const [showModalUsuario, setShowModalUsuario] = useState(false);
  const [idUsuario, setIdUsuario] = useState<string | undefined>();
  const [showModalViewOrEdit, setShowModalViewOrEdit] = useState(false);
  const [orderSort, setOrderSort] = useState(true);

  if (updateTable) {
    listarTodosUsuarios(currentPage, nome, email, cpf);
    setUpdateTable(!updateTable);
  }

  async function listarTodosUsuarios(
    pageNumber: number,
    nome?: string,
    email?: string,
    cpf?: string,
    order?: string
  ) {
    setIsLoading(true);
    const cpfUsuario = cpf?.replace(/[^\d]+/g, '');
    const todosUsers: PaginationModel = await listarUsuarios(
      pageNumber - 1,
      limit,
      nome,
      email,
      cpfUsuario,
      order
    );

    setUsuarios(todosUsers.data);
    setTotalCount(todosUsers.totalCount);
    setCurrentPage(pageNumber);
    setIsLoading(false);
  }

  useEffect(() => {
    const order = orderSort ? 'ASC' : 'DESC';
    listarTodosUsuarios(currentPage, nome, email, cpf, order);
  }, [orderSort]);

  const filtrar = () => {
    listarTodosUsuarios(1, nome, email, cpf, orderSort ? 'ASC' : 'DESC');
  };

  const clearFilter = () => {
    if (nome || email || cpf) {
      reset();
      setNome('');
      setEmail('');
      setCPF('');
      return;
    }
    listarTodosUsuarios(1, orderSort ? 'ASC' : 'DESC');
  };

  const exibirModal = (id: string, showMenu: boolean) => {
    setIdUsuario(id);
    setShowModalUsuario(true);
    setShowModalViewOrEdit(showMenu);
  };

  const handleCloseUsuario = () => {
    setShowModalUsuario(false);
  };

  return (
    <UIContent>
      <div className="d-flex bd-highlight titleAndButton">
        <div className="p-2 flex-grow-1 bd-highlight">
          <Titulo> Usuários - Listagem</Titulo>
        </div>
      </div>

      <form onSubmit={handleSubmit(filtrar)} className="mb-5">
        <div className="form-row ">
          <div className="form-group col-md-4">
            <InputDefault
              name="nomeUsuario"
              type="text"
              placeholder="Nome do Usuário"
              id="input-nome-usuario"
              onChange={e => setNome(e.target.value)}
              value={nome}
              inputMaxLength="300"
              inputRef={register({})}
              mask={noEmptyMask}
            />
          </div>

          <div className="form-group col-md-4">
            <InputDefault
              name="emailUsuario"
              type="text"
              placeholder="E-mail do Usuário"
              id="input-email-usuario"
              onChange={e => setEmail(e.target.value)}
              value={email}
              inputMaxLength="255"
              inputRef={register({})}
              mask={noEmptyMask}
            />
          </div>

          <div className="form-group col-md-4">
            <InputDefault
              name="cpfUsuario"
              type="text"
              placeholder="CPF do Usuário"
              id="input-cpf-usuario"
              onChange={e => setCPF(e.target.value)}
              value={cpf}
              mask={cpfMask}
              inputMaxLength="14"
              inputRef={register({})}
              onBlur={e =>
                setValue('cpfUsuario', cpfMask(e.target.value.slice(0, 14)))
              }
            />
          </div>
        </div>
        <div className="d-flex justify-content-start">
          <BtnDefault
            name="btn-buscar"
            className="btn-custom btn-save"
            id="btn-buscar"
            type="submit"
            variantClass="custom"
          >
            <FaSearch size={12} className="mr-1" />
            Buscar
          </BtnDefault>
          <BtnDefault
            action={clearFilter}
            name="btn-limpar"
            id="btn-limpar"
            className="btn-custom btn-save p-2 flex-grow-1 bd-highlight"
            variantClass="custom"
          >
            <FaBroom size={12} className="mr-1" />
            Limpar filtros
          </BtnDefault>
        </div>
      </form>

      <ModalDefault
        nome="usuarios"
        icon={FaUserPlus}
        setUpdateTable={setUpdateTable}
        variantClass="btn-custom btn-purple btn-purple--round"
      >
        Novo usuário
      </ModalDefault>

      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <Table responsive borderless hover>
          <thead className="table-header">
            <tr>
              <th>
                <div
                  className="thContainerActive"
                  onClick={() => setOrderSort(!orderSort)}
                >
                  {orderSort ? (
                    <FaSortAlphaDown size={20} />
                  ) : (
                    <FaSortAlphaUpAlt size={20} />
                  )}
                  Nome
                </div>
              </th>
              <th>
                <div className="th">E-mail</div>
              </th>
              <th>
                <div className="th">CPF</div>
              </th>
              <th>
                <div className="th">Perfil</div>
              </th>
              <th>
                <div className="th">Status</div>
              </th>
              <th>
                <div className="th">Ações</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {usuarios &&
              usuarios.map((usuario: IUsuarios, index) => {
                return (
                  <TableRowUsuario
                    usuario={usuario}
                    exibirModal={exibirModal}
                    key={index}
                  />
                );
              })}
          </tbody>
        </Table>
      )}

      {!isLoading && usuarios !== undefined && usuarios.length === 0 && (
        <div className="msg-table ">
          <p>Nenhum usuário cadastrado.</p>
        </div>
      )}

      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <PaginationDefault
          currentPage={currentPage}
          totalCount={totalCount}
          limit={limit}
          action={(value: number) =>
            listarTodosUsuarios(
              value,
              nome,
              email,
              cpf,
              orderSort ? 'ASC' : 'DESC'
            )
          }
          totalNaPagina={usuarios.length}
        />
      )}

      {showModalUsuario ? (
        <ModalEditarUsuario
          icon={FaUserPlus}
          variantClass="btn-purple btn-purple--round"
          setUpdateTable={setUpdateTable}
          id={idUsuario}
          showModalUsuario={showModalUsuario}
          handleCloseUsuario={handleCloseUsuario}
          showMenuModal={showModalViewOrEdit}
        />
      ) : (
        ''
      )}
    </UIContent>
  );
};

export default MainUsuarios;
