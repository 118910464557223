import React from 'react';

import {ResponsiveBar} from '@nivo/bar';

import colors from '../../styles/base/_colors.module.scss';
import data from './data.json';

const BarChart = () => {
  return (
    <>
      <ResponsiveBar
        data={data}
        groupMode="grouped"
        keys={['Pagos', 'Inadimplentes', 'A Vencer']}
        indexBy="month"
        margin={{top: 40, right: 130, bottom: 50, left: 50}}
        padding={0.4}
        innerPadding={3}
        colors={[
          colors.colorPrimary,
          colors.colorCompany,
          colors.colorPrimaryLight,
        ]}
        borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
        borderRadius={5}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 18,
          tickRotation: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 18,
          tickRotation: 0,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{from: 'color', modifiers: [['darker', 1.6]]}}
        enableLabel={false}
        enableGridY={true}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
          },
        ]}
        animate={true}
        onClick={event => console.log(event.id, event.indexValue, event.value)}
      />
    </>
  );
};

export default BarChart;
