import React, {ButtonHTMLAttributes, MouseEventHandler} from 'react';
import {Button} from 'react-bootstrap';
import Subtitulo from '../../../Subtitulo';
import './styles.scss';
import _ from 'lodash/fp';
import BtnDefault from '../../../Buttons/BtnDefault';

interface FormTicketEnviadoProps extends ButtonHTMLAttributes<Button> {
  onClickClose?: Function;
}

const FormTicketEnviado: React.FC<FormTicketEnviadoProps> = ({
  onClickClose,
}) => {
  const closeModal = () => {
    onClickClose();
  };
  return (
    <div className="form-ticket-enviado">
      <Subtitulo>
        Mesangem enviada com sucesso, aguarde retorno por email.
      </Subtitulo>
      <BtnDefault
        className="btn-purple btn btn-custom col-12"
        action={() => closeModal()}
      >
        Fechar
      </BtnDefault>
    </div>
  );
};

export default FormTicketEnviado;
