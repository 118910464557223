import './styles.scss';

import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {FaRegSave, FaBan} from 'react-icons/fa';

import {toast} from 'react-toastify';

import {cadastrarCategoria} from '../../../api';
import {ICategoria} from '../../../models/categoria-model';
import {IModalEventProps} from '../../../models/modal-events-model';
import noEmptyMask from '../../../utils/masks/noEmptyMask';
import CharacterCount from '../../CharacterCount';
import Errors from '../../Errors';
import InputDefault from '../../Inputs/InputDefault';
import SpinnerLoader from '../../Spinner';

const FormCategoria: React.FC<IModalEventProps> = params => {
  const {register, handleSubmit, errors} = useForm<ICategoria>();

  const [isLoading, setIsLoading] = useState(false);
  const [contadorDescricao, setContadorDescricao] = useState<Number>(100);

  const onSubmit = (data: ICategoria) => {
    criarCategoria(data.nome, data.descricao);
  };

  async function criarCategoria(nome: string, descricao: string) {
    try {
      await cadastrarCategoria(nome.trim(), descricao.trim());
      toast.success('Categoria cadastrada com sucesso!', {autoClose: 2500});

      closeModal();
      setIsLoading(true);
    } catch (error) {
      toast.error(error, {autoClose: 2500});
    }
    setIsLoading(false);
  }

  const cancelForm = () => {
    closeModal();
  };

  const closeModal = () => {
    params.handleUpdateTable(true);
    params.handleCloseModal(false);
  };

  const characterCounter = e => {
    e.target.value = e.target.value.replace(/^\s+/, '');
    setContadorDescricao(100 - e.target.value.length);
  };

  return (
    <form className="form-modal">
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <div>
          <h6 className="titulo-sessao">Informações da Categoria</h6>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label className="label-item-tabela" htmlFor="input-cat">
                Nome da categoria/setor
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="nome"
                type="text"
                placeholder=""
                id="input-cat"
                inputRef={register({
                  required: true,
                })}
                inputMaxLength="100"
                erros={errors}
                mask={noEmptyMask}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-12">
              <label className="label-item-tabela" htmlFor="descricao">
                Descrição
              </label>
              <span className="asterisk ">*</span>
              <textarea
                maxLength={100}
                id="descricao"
                className="col-md-12 text-area-default"
                onChange={characterCounter}
                name="descricao"
                ref={register({
                  required: true,
                  maxLength: 100,
                })}
              />
              <div className="d-flex justify-content-between">
                <Errors name="descricao" erros={errors} />
                <CharacterCount count={contadorDescricao}></CharacterCount>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="buttons-group">
        <Button
          className="btn-custom btn-cancel"
          variant="custom"
          onClick={() => cancelForm()}
        >
          <FaBan size={13} className="mr-1" />
          Cancelar
        </Button>
        <Button
          type="button"
          onClick={handleSubmit(onSubmit)}
          variant="custom"
          className="btn-custom btn-save"
        >
          <FaRegSave size={13} className="mr-1" />
          Salvar
        </Button>
      </div>
    </form>
  );
};

export default FormCategoria;
