import React from 'react';

import './styles.scss';
import MainBuscaBoleto from '../../components/MainBuscaBoleto/index';
import UIContainer from '../../components/UI/Container';

const BuscaBoletos: React.FC = () => (
  <UIContainer>
    <MainBuscaBoleto />
  </UIContainer>
);
export default BuscaBoletos;
