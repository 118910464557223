import React, {MouseEventHandler, useEffect, useState} from 'react';
import './styles.scss';
import {Button} from 'react-bootstrap';
import {Controller, useForm} from 'react-hook-form';
import {FaPen, FaBan, FaRegWindowClose, FaRegSave} from 'react-icons/fa';
import {toast} from 'react-toastify';

import {editarUsuario} from '../../../api/usuario-api';
import {IupdateUser, IUsuario} from '../../../models/usuario-model';
import {statusOptions} from '../../../shared/constantes';
import cpfcnpjMask from '../../../utils/masks/cpfcnpjMask';
import phoneMask from '../../../utils/masks/phoneMask';
import cpfCnpjValidator from '../../../utils/validations/cpfCnpjValidator';
import BtnDefault from '../../Buttons/BtnDefault';
import InputDefault from '../../Inputs/InputDefault';
import InputSelect from '../../Inputs/InputSelect';
import noEmptyMask from '../../../utils/masks/noEmptyMask';
import cnpjMask from '../../../utils/masks/cpfcnpjMask';
interface IModalEditarUsuarioProps {
  usuario: IUsuario;
  handleCloseModal?: Function;
  handleUpdateTable?: Function;
  action?: MouseEventHandler;
  showEditOrView?: boolean;
}

const FormEditUsuario: React.FC<IModalEditarUsuarioProps> = ({
  usuario,
  handleCloseModal,
  handleUpdateTable,
  showEditOrView,
}: IModalEditarUsuarioProps) => {
  const {register, handleSubmit, errors, control} = useForm();
  const [editing, setEditing] = useState(showEditOrView);

  const handleEditar = async data => {
    const user: IupdateUser = {
      cpf: data.CPF.replace(/[^\d]+/g, ''),
      email: data.email,
      nome: data.nome,
      status: data.status,
      telefone: data.telefone.replace(/\D+/g, ''),
    };
    try {
      await editarUsuario(usuario.id, user);
      toast.success('Usuário editado com sucesso!', {autoClose: 2500});
      closeModal();
      handleCloseModal();
    } catch (e) {
      console.error(e);
    }
  };

  const cancelForm = () => {
    handleCloseModal(false);
  };

  const closeModal = () => {
    handleUpdateTable(true);
  };

  function focusForMask() {
    document.getElementsByName('telefone')[0].focus();
    document.getElementsByName('CPF')[0].focus();
    document.getElementsByName('CPF')[0].blur();
    document.getElementsByName('cnpj')[0].focus();
    document.getElementsByName('cnpj')[0].blur();
  }

  const toggleEditing = () => {
    setEditing(true);
  };

  useEffect(() => {
    focusForMask();
  }, []);
  return (
    <>
      <form onSubmit={handleSubmit(handleEditar)} className="form-modal">
        <h6 className="titulo-sessao">Informações do usuário</h6>
        <div className="row">
          <div className="col-md-4" key={'nome'}>
            <label className="label-item-tabela" htmlFor="input-nome">
              Nome Completo
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="nome"
              type="text"
              disabledInput={!editing}
              readOnly={false}
              id="input-nome"
              inputRef={register({
                required: true,
                maxLength: 100,
                minLength: 0,
              })}
              defaultValue={usuario.nome}
              erros={errors}
              inputMaxLength="100"
              mask={noEmptyMask}
            ></InputDefault>
          </div>
          <div className="col-md-4" key={'CPF'}>
            <label className="label-item-tabela" htmlFor="input-cpf">
              CPF
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="CPF"
              type="text"
              id="input-cpf"
              disabledInput={!editing}
              inputRef={register({
                required: true,
                maxLength: 14,
                minLength: 0,
                validate: {
                  cpfCnpj: value => {
                    return cpfCnpjValidator(value);
                  },
                },
              })}
              defaultValue={usuario.cpf}
              mask={cpfcnpjMask}
              erros={errors}
              inputMaxLength="14"
            ></InputDefault>
          </div>
          <div className="form-group col-md-4">
            <label className="label-item-tabela" htmlFor="input-cnpj">
              CNPJ
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="cnpj"
              type="text"
              id="input-cnpj"
              disabledInput={!editing}
              inputRef={register({
                required: true,
                maxLength: 18,
                validate: {
                  cnpj: value => {
                    return cpfCnpjValidator(value);
                  },
                },
              })}
              defaultValue={usuario.cnpj}
              mask={cnpjMask}
              erros={errors}
              inputMaxLength="18"
            />
          </div>
          <div className="col-md-4 mt-3" key="email">
            <label className="label-item-tabela" htmlFor="input-email">
              Email
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="email"
              type="text"
              id="input-email"
              disabledInput={!editing}
              inputRef={register({
                required: true,
                maxLength: 100,
                minLength: 0,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Entre com um e-mail válido. ',
                },
              })}
              defaultValue={usuario.email}
              erros={errors}
              inputMaxLength="100"
              mask={noEmptyMask}
            ></InputDefault>
          </div>
          <div className="col-md-4 mt-3" key="status">
            <label className="label-item-tabela" htmlFor="status">
              Status
            </label>
            <Controller
              control={control}
              defaultValue={usuario.status}
              name="status"
              id="status"
              render={({onChange, value, ref}) => (
                <InputSelect
                  placeholder="Status"
                  id="status"
                  inputRef={ref}
                  classNamePrefix="addl-class"
                  isDisabled={!editing}
                  options={statusOptions}
                  erros={errors}
                  value={statusOptions.find(c => c.value === value)}
                  onChange={(val: {value}) => {
                    onChange(val.value);
                  }}
                />
              )}
            />
          </div>
          <div className="col-md-4 mt-3" key="perfil">
            <label className="label-item-tabela" htmlFor="input-perfil">
              Perfil
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="Perfil"
              type="text"
              id="input-perfil"
              disabledInput={!editing}
              disabled={true}
              readOnly={true}
              inputRef={register({
                required: true,
                maxLength: 1000,
                minLength: 0,
              })}
              defaultValue={usuario.perfil.nome}
              erros={errors}
              inputMaxLength="1000"
            ></InputDefault>
          </div>
          <div className="col-md-4 mt-3" key="telefone">
            <label className="label-item-tabela" htmlFor="input-telefone">
              Telefone
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="telefone"
              type="text"
              id="input-telefone"
              disabledInput={!editing}
              inputRef={register({
                required: true,
                maxLength: 14,
                minLength: 13,
              })}
              defaultValue={usuario.telefone}
              erros={errors}
              inputMaxLength="14"
              mask={phoneMask}
            ></InputDefault>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            className="btn-custom btn-cancel"
            variant="custom"
            onClick={() => cancelForm()}
          >
            {showEditOrView ? (
              <span>
                <FaBan size={13} className="mr-1" /> Cancelar
              </span>
            ) : (
              <span>
                <FaRegWindowClose size={13} className="mr-1" /> Fechar
              </span>
            )}
          </Button>

          {!editing && !showEditOrView ? (
            <Button
              type="button"
              className="btn-custom btn-save"
              variant="custom"
              onClick={toggleEditing}
            >
              <FaPen size={13} className="mr-1" />
              Editar
            </Button>
          ) : (
            <BtnDefault
              type="submit"
              variantClass="custom"
              className="btn-custom btn-save"
            >
              {showEditOrView ? (
                <span>
                  <FaPen size={13} className="mr-1" />
                  Editar
                </span>
              ) : (
                <span>
                  {' '}
                  <FaRegSave size={13} className="mr-1" />
                  Salvar
                </span>
              )}
            </BtnDefault>
          )}
        </div>
      </form>
    </>
  );
};
export default FormEditUsuario;
