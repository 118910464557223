import React from 'react';
import {DeepMap, FieldError} from 'react-hook-form';

import {ICobranca} from '../../../models/cobranca';
import CardDesconto from '../CardDesconto';
import CardMulta from '../CardMulta';
import './styles.scss';

interface CardJurosMultaProps {
  register;
  errors: DeepMap<ICobranca, FieldError>;
  setValorDesconto: React.Dispatch<React.SetStateAction<number>>;
  setDiasAmaisParaDesconto: React.Dispatch<React.SetStateAction<number>>;
  setIsPorcentagemDesconto: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPorcentagemJuros: React.Dispatch<React.SetStateAction<boolean>>;
  juros?: string;
  multa?: string;
  desconto?: string;
  diasAMaisParaDesconto?: string;
  isDiscountPercent?: boolean;
  isFinePercent?: boolean;
  isDisabled?: boolean;
  radioFineNameOption?: string;
  radioDiscountNameOption?: string;
}

const CardJurosMulta: React.FC<CardJurosMultaProps> = ({
  register,
  errors,
  setValorDesconto,
  setDiasAmaisParaDesconto,
  setIsPorcentagemDesconto,
  setIsPorcentagemJuros,
  multa,
  juros,
  desconto,
  diasAMaisParaDesconto,
  isDiscountPercent,
  isFinePercent,
  isDisabled,
  radioFineNameOption,
  radioDiscountNameOption,
}) => {
  return (
    <div className="d-flex justify-content-center">
      <div className="cards-valores">
        <div className="col-md-6">
          <CardMulta
            register={register}
            errors={errors}
            setIsPorcentagemJuros={setIsPorcentagemJuros}
            multa={multa}
            juros={juros}
            isPercent={isFinePercent}
            isDisable={isDisabled}
            radioNameOption={radioFineNameOption}
          />
        </div>

        <div className="col-md-6">
          <CardDesconto
            register={register}
            errors={errors}
            setValorDesconto={setValorDesconto}
            setDiasAmaisParaDesconto={setDiasAmaisParaDesconto}
            setIsPorcentagemDesconto={setIsPorcentagemDesconto}
            desconto={desconto}
            diasAMaisParaDesconto={diasAMaisParaDesconto}
            isPercent={isDiscountPercent}
            isDisabled={isDisabled}
            radioNameOption={radioDiscountNameOption}
          />
        </div>
      </div>
    </div>
  );
};

export default CardJurosMulta;
