export enum InterestFineDiscountType {
  VALUE = 'VALOR',
  PERCENT = 'PERCENTUAL',
}

export enum BillingRuleType {
  DYNAMIC = 'DINAMICO',
  FIXED = 'FIXO',
}

export interface BillingRuleModel {
  id?: string;
  type: BillingRuleType;
  name: string;
  value: number;
  description: string;
  information: string;
  fine: number;
  fineType: InterestFineDiscountType;
  interest: number;
  interestType: InterestFineDiscountType;
  discount: number;
  discountType: InterestFineDiscountType;
  discountAdvanceDays: number;
  billingDay: number;
  expirationDay: number;
  daysAfterExpiration: number;
}
