import React, {
  ChangeEventHandler,
  LegacyRef,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import './styles.scss';

interface InputCheckboxDefaultProps {
  name: string;
  value?: string;
  onClick?: MouseEventHandler;
  id?: string;
  className?: string;
  checked?: boolean;
  defaulChecked?: boolean;
  disabled?: boolean;
  inputRef?: LegacyRef<HTMLInputElement>;
  onChange?: ChangeEventHandler;
}

const InputCheckboxDefault: React.FC<InputCheckboxDefaultProps> = ({
  onClick,
  name,
  id,
  className,
  value,
  checked,
  defaulChecked,
  disabled,
  inputRef,
  onChange,
}) => {
  useEffect(() => {});

  return (
    <label className={'checkbox-container'}>
      <input
        type="checkbox"
        name={name}
        value={value}
        id={id}
        onClick={onClick}
        className={className + ' custom-checkbox-button'}
        checked={checked}
        defaultChecked={defaulChecked}
        disabled={disabled}
        ref={inputRef}
        onChange={onChange}
      />
      <span
        className={`custom-checkbox-button-checkmark ${
          disabled ? 'disabled' : ''
        }`}
      ></span>
    </label>
  );
};

export default InputCheckboxDefault;
