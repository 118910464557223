import React, {useState, useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import {IconBaseProps} from 'react-icons';
import {verCobranca} from '../../../api/cobranca-api';
import {IVerCobrancaBoleto} from '../../../models/cobranca';
import {IFormContent} from '../../../models/forms-model';
import FormEditCobranca from '../../MainBuscaBoleto/FormEditCobranca';

import './styles.scss';

interface ModalEditarProps {
  id?: string;
  icon?: React.ComponentType<IconBaseProps>;
  variantClass?: string;
  formContent?: IFormContent;
  setUpdateTable?: Function;
  handleCloseCobranca?: Function;
  showModalCobranca?: boolean;
}
const ModalDefaultEditarCobranca: React.FC<ModalEditarProps> = ({
  id,
  icon: Icon,
  setUpdateTable,
  handleCloseCobranca,
  showModalCobranca,
}: ModalEditarProps) => {
  const [cobranca, setCobranca] = useState<IVerCobrancaBoleto>();

  const updateTable = () => {
    setCobranca(undefined);
    setUpdateTable(true);
  };

  async function carregarCobranca(idControleCobranca: string) {
    const cobranças = await verCobranca(idControleCobranca);
    setCobranca(cobranças);
  }

  const showPopoverEditarCobrancaa = () => {
    const showPopover = document.querySelectorAll<HTMLElement>('.popover ')[0];

    if (showPopover) {
      if (showModalCobranca) {
        showPopover.style.display = 'none';
      }
    }
  };

  useEffect(() => {
    carregarCobranca(id);
    showPopoverEditarCobrancaa();
  }, []);

  return (
    <>
      {cobranca && (
        <Modal
          animation={false}
          show={showModalCobranca}
          onHide={handleCloseCobranca}
          backdrop="static"
          keyboard={true}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {Icon && <Icon size={20} className="modal-icone" />}
              Editar cobrança
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <FormEditCobranca
              cobranca={cobranca}
              handleCloseModal={handleCloseCobranca}
              handleUpdateTable={updateTable}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ModalDefaultEditarCobranca;
