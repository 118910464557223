import {createGlobalStyle} from 'styled-components';

import colors from '../styles/base/_colors.module.scss';

export const GlobalStyles = createGlobalStyle`

.btn-purple, .btn-custom.btn.btn-save  {
  background-color: ${process.env.REACT_APP_COLOR_PRIMARY};
  
  &:focus,
    &:active,&:hover {
      background-color: ${process.env.REACT_APP_COLOR_SECONDARY};
      border-color: ${process.env.REACT_APP_COLOR_PRIMARY};
    }
}

.input-form {
  border: 1px solid ${process.env.REACT_APP_COLOR_PRIMARY};
}

a{
  color: ${process.env.REACT_APP_COLOR_PRIMARY};
  &:hover {
    color: ${process.env.REACT_APP_COLOR_SECONDARY};
  }
}

.input-content__icone {
  color: ${process.env.REACT_APP_COLOR_PRIMARY};
}

.ticket-content {
  border: 2px solid ${process.env.REACT_APP_COLOR_SECONDARY};
}
.ticket-content .input-content__icone-ticket {
  color: ${process.env.REACT_APP_COLOR_PRIMARY};
}

#root .nav,#root .nav__logo,.nav__footer {
  background-color: ${process.env.REACT_APP_COLOR_PRIMARY};
}

.nav__footer {
  background-color: ${process.env.REACT_APP_COLOR_SECONDARY};
}

.dashboard-boleto__border,.dashboard-categoria__border {
  border: 0.0625rem solid ${process.env.REACT_APP_COLOR_PRIMARY};
}

.react-datepicker__close-icon::after {
  background-color:  ${process.env.REACT_APP_COLOR_PRIMARY};
}
.react-select__dropdown-indicator {
  color:  ${process.env.REACT_APP_COLOR_PRIMARY} !important;
}
.nav__text--selected {
  background-color: ${process.env.REACT_APP_COLOR_SECONDARY};
}

#root .nav__text a:hover, #root .nav__text__subgrupo:hover {
  
  background-color: ${process.env.REACT_APP_COLOR_SECONDARY};
}

.titulo-tela {
  color:  ${process.env.REACT_APP_COLOR_PRIMARY} !important;
}

.page-item.active .page-link {
  background-color: ${process.env.REACT_APP_COLOR_PRIMARY} !important;
  border-color:  ${process.env.REACT_APP_COLOR_PRIMARY} !important;
}

#root .navbar-top__icones svg, .icon-pop__icon {
color: ${process.env.REACT_APP_COLOR_PRIMARY};
}

.content table thead {
  color: ${colors.colorHeaderTableContrastColor};
}


.input-default {
  &::placeholder {
    color: ${process.env.REACT_APP_COLOR_SECONDARY};
  }
}

`;
