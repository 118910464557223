import React from 'react';

import './styles.scss';
import MainCliente from '../../components/MainCliente/index';
import UIContainer from '../../components/UI/Container';

const Clientes: React.FC = () => (
  <UIContainer>
    <MainCliente>MainCliente</MainCliente>
  </UIContainer>
);
export default Clientes;
