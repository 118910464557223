import {IEnvioSegundaViaEmail} from 'models/emailModel';

import {
  ICobranca,
  IFiltroBoleto,
  IListVerCobranca,
  IVerCobrancaBoleto,
} from '../models/cobranca';
import {PaginationModel} from '../models/pagination-model';
import {BilletOrderByEnum} from '../pages/BillingBatch/enum/index';
import {BatchChargesOrderByEnum} from './../pages/BatchCharges/enum/index';
import api from './api';

export const listarCobrancas = async (
  page = 0,
  limit = 10,
  params?: IFiltroBoleto,
  order?: string,
  orderBy?: BilletOrderByEnum
) => {
  return (
    await api.get<PaginationModel>(
      `/cobrancas?page=${page}&limit=${limit}&order=${order}&orderBy=${orderBy}`,
      {
        params: params,
      }
    )
  )?.data;
};

export const verCobranca = async (id?: string) => {
  return (await api.get<IVerCobrancaBoleto>(`/cobrancas/${id}/exibir`))?.data;
};

export const listarCobrancasDoLote = async (
  page = 0,
  limit = 10,
  lote_id: string,
  order = 'ASC',
  orderBy = BatchChargesOrderByEnum.CLIENT,
  nomeCliente = '',
  statusEnvio = '',
  statusPagamento = '',
  dtEmissao = '',
  dtVencimento = ''
) => {
  return await api.get(
    `/cobrancas?page=${page}&limit=${limit}&lote_id=${lote_id}&order=${order}&orderBy=${orderBy}&nomeCliente=${nomeCliente}&statusEnvio=${statusEnvio}&statusPagamento=${statusPagamento}&dtEmissao=${dtEmissao}&dtVencimento=${dtVencimento}`
  );
};

export const cancelarCobranca = async (id?: string, justificativa?: string) => {
  return (
    await api.put(`/cobrancas/${id}/cancelar`, {
      motivoCancelamento: justificativa,
    })
  )?.data;
};

export const cadastrarCobranca = async (cobranca: ICobranca) => {
  return (await api.post<ICobranca>('/controle-cobrancas', cobranca))?.data;
};

export const listarVerCobrancas = async (id: string, page = 0, limit = 10) => {
  return (
    await api.get<IListVerCobranca>(
      `/controle-cobrancas/${id}/cobrancas?page=${page}&limit=${limit}`
    )
  )?.data;
};

export const exibirUltimasCobrancas = async (
  id?: string,
  qtdeCobrancas?: number
) => {
  return (
    await api.get<IVerCobrancaBoleto>(
      `/controle-cobrancas/${id}/quantidade-cobrancas/${qtdeCobrancas}`
    )
  )?.data;
};

export const updateStatusEnvioCobrancaRecebido = async (id: string) => {
  return (await api.put(`/cobrancas/${id}/gerar-boleto`))?.data;
};

export const verBoletoPDF = async (idCobranca: string) => {
  return (await api.get(`/cobrancas/${idCobranca}/ver-boleto`))?.data;
};

export const enviarBoleto = async (idCobranca: string) => {
  return (await api.get(`/cobrancas/${idCobranca}/enviar-boleto`))?.data;
};

export const enviaSegundaViaBoleto = async (dto: IEnvioSegundaViaEmail) => {
  return await api.post('/cobrancas/enviar-segunda-via-boleto', dto);
};

export const enviaSegundaViaBoletoSemEmail = async (id: string) => {
  return await api.post('/cobrancas/enviar-segunda-via-boleto', {
    idCobranca: id,
  });
};

export const editarCobranca = async (
  idControleCobranca: string,
  categoriaId: string
) => {
  const idCategoria = {categoriaId};
  return (
    await api.patch(`/controle-cobrancas/${idControleCobranca}`, idCategoria)
  )?.data;
};

export const deleteBilletApi = async (billetID: string) => {
  return (await api.delete(`/cobrancas/${billetID}/delete`))?.data;
};

export const cancelAllBillets = async (idControleCobranca: string) => {
  return (
    await api.delete(`/cobrancas/${idControleCobranca}/cancelar-cobrancas`)
  )?.data;
};
