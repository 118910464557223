import React, {useEffect, useState} from 'react';
import {FiCopy} from 'react-icons/fi';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import QRCode from 'qrcode';

import BtnDefault from '../../components/Buttons/BtnDefault';

import './styles.scss';

interface ParamTypes {
  pix: string;
}

const PixCopyAndPaste = () => {
  const [pixValue, setPixValue] = useState('');

  const generateQR = (pix: string) => {
    try {
      QRCode.toCanvas(pix, {}, (err, canvas) => {
        if (err) toast.error('Erro ao gerar QR Code!', {autoClose: 1500});

        const element = document.getElementById('canvas');
        element.appendChild(canvas);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const parseString = (pixText: string) => {
    let parsedText = pixText;
    let run = true;
    do {
      parsedText = parsedText.replace('%2f', '/');
      parsedText = parsedText.replace('%20', ' ');
      run = parsedText.includes('%2f') || parsedText.includes('%20');
    } while (run);
    return parsedText;
  };

  const modifyBodyStyle = () => {
    document.body.style.height = '100vh';
    document.body.style.width = '100vw';
    document.body.style.display = 'flex';
    document.body.style['flex-direction'] = 'row';
    document.body.style['justify-content'] = 'center';
    document.body.style['align-items'] = 'center';
  };

  useEffect(async () => {
    const newPix = parseString(pix);
    setPixValue(newPix);
    await generateQR(newPix);
    modifyBodyStyle();
  }, []);

  const {pix} = useParams<ParamTypes>();

  const copyPixStringToClipboard = () => {
    navigator.clipboard.writeText(pixValue);
    toast.success('Pix copiado!', {autoClose: 1500});
  };

  return (
    <div className="container align-content-center">
      <div className="row justify-content-center">
        <div className="col-sm header__pix">
          Use o QR Code abaixo para pagar o boleto
        </div>
      </div>
      <br />
      <div className="info__text">
        <p>Para fazer o pagamento, abra seu aplicativo de pagamentos,</p>
        <p>escaneie a imagem ou cole o código do QR Code</p>
      </div>
      <div id="canvas" />
      <div className="row copy__paste__row">
        <BtnDefault
          className="copy__button"
          type="button"
          action={copyPixStringToClipboard}
        >
          Copiar código do QR Code <FiCopy size={16} className="mr-1" />
        </BtnDefault>
      </div>
    </div>
  );
};

export default PixCopyAndPaste;
