import React from 'react';

import './styles.scss';
import MainVerBoleto from '../../components/MainVerBoleto';
import UIContainer from '../../components/UI/Container';

const VerBoleto: React.FC = () => {
  return (
    <UIContainer>
      <MainVerBoleto></MainVerBoleto>
    </UIContainer>
  );
};

export default VerBoleto;
