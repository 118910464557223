import {useEffect, useState} from 'react';
import _ from 'lodash';
import {getLocalStorageValue, saveLocalStorage} from '../utils/storage';

export const useTheme = () => {
  const themes = getLocalStorageValue('all-themes');
  const [theme, setTheme] = useState(themes.data.purple);
  const [themeLoaded, setThemeLoaded] = useState(false);

  const setMode = mode => {
    saveLocalStorage('theme', mode);
    setTheme(mode);
  };

  useEffect(() => {
    const localTheme = getLocalStorageValue('all-themes').default;
    localTheme ? setTheme(localTheme) : setTheme(themes.data.light);
    setThemeLoaded(true);
  }, []);

  return {theme, themeLoaded, setMode};
};
