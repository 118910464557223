import React, {useEffect, useState} from 'react';

import {volumeEnvioByCategoriaByDate} from '../../../../api/dashboard-api';
import UILoad from '../../../../components/UI/Load';
import {
  IDashboardFilters,
  IVolumeEnvio,
} from '../../../../models/dashboard-model';
interface Filtros {
  filter: IDashboardFilters;
}
const VolumeEnvioCategoria: React.FC<Filtros> = ({filter}) => {
  const [valores, setValores] = useState<IVolumeEnvio[]>([]);
  const [loading, setLoading] = useState(false);

  async function getVolumeEnvioByCategoriaByDate(filter) {
    setLoading(true);
    const listaValores: IVolumeEnvio[] = await volumeEnvioByCategoriaByDate(
      filter
    );
    setValores(listaValores);
    setLoading(false);
  }

  useEffect(() => {
    if (filter) {
      getVolumeEnvioByCategoriaByDate(filter);
    }
  }, [filter]);
  return (
    <div
      className={
        loading ? 'dashboard-categoria__border' : 'dashboard-categoria__border'
      }
    >
      <h6 className="dashboard-categoria__title">Volume Envio por categoria</h6>
      <div className="dashboard-categoria__content">
        {loading ? (
          <UILoad />
        ) : (
          <>
            {valores.length > 0 ? (
              <>
                <label
                  className="dashboard-categoria__label"
                  htmlFor="volume-categoria"
                >
                  Categoria/setor
                </label>

                <ul className="dashboard-categoria__list">
                  {valores.map((val, index) => {
                    return (
                      <li key={index}>
                        {val.porcentagem} % - {val.nome}
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              <span className="dashboard-boleto__span dashboard-boleto__zero   ">
                Não há dados disponíveis para o período selecionado.{' '}
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VolumeEnvioCategoria;
