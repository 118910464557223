import React, {useRef, useState} from 'react';
import {Overlay, Popover} from 'react-bootstrap';
import {FaEllipsisV, FaPen, FaRegEye} from 'react-icons/fa';

import {IUsuarios} from '../../../models/usuario-model';
import {statusUsuario} from '../../../shared/enumMap';
import cpfMask from '../../../utils/masks/cpfMask';

interface TableRowProps {
  usuario: IUsuarios;
  exibirModal: Function;
}

const TableRowUsuario: React.FC<TableRowProps> = ({usuario, exibirModal}) => {
  const ref = useRef(null);

  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleClosepop = () => setShow(false);

  return (
    <>
      <tr key={usuario.id}>
        <td className="left">{usuario.nome}</td>
        <td className="left">{usuario.email}</td>
        <td className="left">{cpfMask(usuario.cpf)}</td>
        <td className="left">{usuario.perfil?.nome}</td>
        <td className="left">{statusUsuario(usuario.status)}</td>
        <td>
          <FaEllipsisV style={{cursor: 'pointer'}} onClick={handleClick} />
          <Overlay
            show={show}
            target={target}
            placement="bottom"
            container={ref.current}
            containerPadding={20}
            onHide={handleClosepop}
            rootClose={true}
            transition={false}
          >
            <Popover id="popover-contained">
              <Popover.Title as="h3">{'Ações'}</Popover.Title>
              <Popover.Content>
                <span className="icones-popover">
                  <span onClick={() => exibirModal(usuario.id, true)}>
                    <FaPen className="icones-popover__icone" size="18" />
                    <span className="icones-popover__text">Editar</span>
                  </span>

                  <span onClick={() => exibirModal(usuario.id, false)}>
                    <FaRegEye className="icones-popover__icone" size="18" />
                    <span className="icones-popover__text">Ver</span>
                  </span>
                </span>
              </Popover.Content>
            </Popover>
          </Overlay>
        </td>
      </tr>
    </>
  );
};

export default TableRowUsuario;
