import React, {MouseEventHandler, useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {Button} from 'react-bootstrap';
import './styles.scss';
import {FaPen, FaBan} from 'react-icons/fa';
import {editarCobranca} from '../../../api/cobranca-api';
import {ICategoria, IVerCobrancaBoleto} from '../../../models/cobranca';

import {
  statusEnvioMsgCobranca,
  tipoCobrancaEnums,
} from '../../../shared/enumMap';
import {formataData} from '../../../utils/formatDate';
import {formataDinheiro} from '../../../utils/formatMonetary';
import cpfcnpjMask from '../../../utils/masks/cpfcnpjMask';
import InputDefault from '../../Inputs/InputDefault';
import InputSelect from '../../Inputs/InputSelect';
import {listarCategorias} from '../../../api/categoria-api';

interface IModalCobrancaProps {
  cobranca?: IVerCobrancaBoleto;
  handleCloseModal?: Function;
  handleUpdateTable?: Function;
  action?: MouseEventHandler;
}
const FormEditCobranca: React.FC<IModalCobrancaProps> = ({
  cobranca,
  handleCloseModal,
  handleUpdateTable,
}: IModalCobrancaProps) => {
  const {register, handleSubmit, errors} = useForm();
  const [categorias, setCategorias] = useState([]);

  const [selectCategorias, setSelectCategorias] = useState({
    value: cobranca?.categoria?.id,
    label: cobranca?.categoria?.nome,
  });

  const CobrancasCategorias = [];
  const handleListarCategoria = async () => {
    const todasCategorias = await listarCategorias();
    if (todasCategorias) {
      todasCategorias.data.map((categoria: ICategoria) =>
        CobrancasCategorias.push({value: categoria.id, label: categoria.nome})
      );
      CobrancasCategorias.push({value: undefined, label: 'Sem categoria'});
      setCategorias(CobrancasCategorias);
    }
  };

  const handleEditar = async () => {
    try {
      await editarCobranca(cobranca.id, selectCategorias?.value);
      toast.success('Categoria cobrança editada com sucesso!', {
        autoClose: 2500,
      });
      closeModal();
      handleCloseModal();
    } catch (e) {
      toast.error(e, {autoClose: 2500});
    }
  };

  const cancelForm = () => {
    handleCloseModal(false);
  };

  const closeModal = () => {
    handleUpdateTable(true);
  };
  const onChangeSelect = item => {
    setSelectCategorias(item);
  };

  useEffect(() => {
    handleListarCategoria();
  }, []);
  return (
    <>
      <form onSubmit={handleSubmit(handleEditar)} className="form-modal">
        <h6 className="titulo-sessao">Informações da cobrança</h6>
        <div className="form-row">
          <div className="col-sm-4">
            <label className="label-item-tabela" htmlFor="input-nome">
              Categoria
            </label>
            <InputSelect
              defaultValue={selectCategorias}
              id="categoria-select-edit"
              classNamePrefix="addl-class"
              name="categoria"
              options={categorias}
              inputRef={register({
                required: false,
              })}
              onChange={onChangeSelect}
            />
          </div>

          <div className="col-sm-4 ">
            <label className="label-item-tabela" htmlFor="input-cpf">
              Número
            </label>

            <InputDefault
              name="numero"
              type="text"
              id="input-numero"
              disabledInput={true}
              erros={errors}
              inputMaxLength="10"
              defaultValue={String(cobranca?.cobrancaAtual?.numero)}
            ></InputDefault>
          </div>
          <div className="col-sm-4">
            <label className="label-item-tabela" htmlFor="input-cpf">
              Tipo
            </label>

            <InputDefault
              name="tipo"
              type="text"
              id="input-tipo"
              disabledInput={true}
              erros={errors}
              inputMaxLength="10"
              defaultValue={tipoCobrancaEnums(cobranca.tipo)}
            ></InputDefault>
          </div>
          <div className="col-sm-4 mt-2">
            <label className="label-item-tabela" htmlFor="input-cpf">
              Status
            </label>

            <InputDefault
              name="status"
              type="text"
              id="input-status"
              disabledInput={true}
              erros={errors}
              inputMaxLength="10"
              defaultValue={statusEnvioMsgCobranca(
                cobranca?.cobrancaAtual?.statusEnvioMensagemCobranca
              )}
            ></InputDefault>
          </div>

          <div className="col-sm-4 mt-2">
            <label className="label-item-tabela" htmlFor="input-cpf">
              Vencimento
            </label>

            <InputDefault
              name="vencimento"
              type="text"
              id="input-vencimento"
              disabledInput={true}
              erros={errors}
              inputMaxLength="10"
              defaultValue={String(
                formataData(cobranca?.cobrancaAtual?.vencimentoEm)
              )}
            ></InputDefault>
          </div>
          <div className="col-sm-4 mt-2">
            <label className="label-item-tabela" htmlFor="input-cpf">
              Valor
            </label>
            <InputDefault
              name="valor"
              type="text"
              id="input-valor"
              disabledInput={true}
              erros={errors}
              inputMaxLength="10"
              defaultValue={String(
                formataDinheiro(cobranca?.cobrancaAtual.valor)
              )}
            ></InputDefault>
          </div>
        </div>
        <h6 className="titulo-sessao mt-4">Informações do cliente</h6>
        <div className="form-row">
          <div className="col-sm-4">
            <label className="label-item-tabela" htmlFor="input-nome">
              Nome cliente
            </label>
            <InputDefault
              name="nome"
              type="text"
              disabledInput={true}
              readOnly={false}
              id="input-nome"
              inputRef={register({
                required: true,
                maxLength: 100,
                minLength: 1,
              })}
              defaultValue={cobranca?.cliente.nome}
              erros={errors}
              inputMaxLength="100"
            ></InputDefault>
          </div>
          <div className="col-sm-4">
            <label className="label-item-tabela" htmlFor="input-cpf">
              CPF/CNPJ
            </label>

            <InputDefault
              name="CPF"
              type="text"
              id="input-cpf"
              disabledInput={true}
              erros={errors}
              inputMaxLength="18"
              defaultValue={cpfcnpjMask(cobranca?.cliente.cpfCnpj)}
            ></InputDefault>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-4">
          <Button
            className="btn-custom btn-cancel"
            variant="custom"
            onClick={() => cancelForm()}
          >
            <FaBan size={13} className="mr-1" /> Cancelar
          </Button>
          <Button
            type="submit"
            className="btn-custom btn-save"
            variant="custom"
          >
            <FaPen size={13} className="mr-1" />
            Editar
          </Button>
        </div>
      </form>
    </>
  );
};

export default FormEditCobranca;
