import React, {useState, useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import {IconBaseProps} from 'react-icons';

import {exibirCategoria} from '../../../api/categoria-api';
import {ICategoria} from '../../../models/categoria-model';
import {IFormContent} from '../../../models/forms-model';
import FormEditCategoria from '../FormEditCategoria';

import './styles.scss';
interface ModalEditarProps {
  id?: string;
  icon?: React.ComponentType<IconBaseProps>;
  variantClass?: string;
  formContent?: IFormContent;
  setUpdateTable?: Function;
  handleCloseCategoria?: Function;
  showModalCategoria?: boolean;
  showMenuModal?: boolean;
}

const ModalEditarCategoria: React.FC<ModalEditarProps> = ({
  id,
  icon: Icon,
  setUpdateTable,
  handleCloseCategoria,
  showModalCategoria,
  showMenuModal,
}) => {
  const [categoria, setCategoria] = useState<ICategoria>();

  async function carregarCategoria(id: string) {
    setCategoria(await exibirCategoria(id));
  }

  const updateTable = () => {
    setCategoria(undefined);
    setUpdateTable(true);
  };

  const showPopoverCategoria = () => {
    const showPopover = document.querySelectorAll<HTMLElement>('.popover ')[0];

    if (showPopover) {
      if (showModalCategoria) {
        showPopover.style.display = 'none';
      }
    }
  };

  useEffect(() => {
    carregarCategoria(id);
    showPopoverCategoria();
  }, []);
  return (
    <>
      {categoria && (
        <Modal
          animation={false}
          show={showModalCategoria}
          onHide={handleCloseCategoria}
          backdrop="static"
          keyboard={true}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {Icon && <Icon size={20} className="modal-icone" />}
              {showMenuModal ? 'Editar categoria' : 'Ver categoria'}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <FormEditCategoria
              categoria={categoria}
              handleCloseModal={handleCloseCategoria}
              handleUpdateTable={updateTable}
              showEditOrView={showMenuModal}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default ModalEditarCategoria;
