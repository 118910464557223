import React, {useEffect, useState} from 'react';
import './styles.scss';
import {Table} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {
  FaGripVertical,
  FaSearch,
  FaSortAlphaDown,
  FaSortAlphaUpAlt,
  FaSortAmountDown,
  FaSortAmountUpAlt,
  FaSortNumericDown,
  FaSortNumericUpAlt,
} from 'react-icons/fa';
import {toast} from 'react-toastify';

import Swal from 'sweetalert2';

import {deleteRuleApi, listRulesApi} from '../../api/billing-rule';
import {PaginationModel} from '../../models/pagination-model';
import noEmptyMask from '../../utils/masks/noEmptyMask';
import BtnDefault from '../Buttons/BtnDefault';
import InputDefault from '../Inputs/InputDefault';
import ModalDefault from '../ModalDefault';
import PaginationDefault from '../PaginationDefault';
import SpinnerLoader from '../Spinner';
import TableRowBillingRule from '../TableRows/TableRowsBillingRule';
import Titulo from '../Titulo';
import UIContent from '../UI/Content';
import {BillingRuleOrderByEnum} from './enum/index';
import ModalEditBillingRule from './ModalEditBillingRule';

const MainBilletRule: React.FC = () => {
  const {handleSubmit} = useForm();
  const [rules, setRules] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(10);

  const [updateTable, setUpdateTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ruleName, setRuleName] = useState<string>('');
  const [idRule, setIdRule] = useState<string | undefined>();
  const [showModalRule, setShowModalRule] = useState(false);
  const [showModalViewOrEdit, setShowModalViewOrEdit] = useState(false);
  const [charWarning, setCharWarning] = useState<boolean>(false);
  const [orderSort, setOrderSort] = useState(true);
  const [orderBySort, setOrderBySort] = useState(BillingRuleOrderByEnum.NAME);

  async function listRules(
    pageNumber: number,
    ruleName?: string,
    order?: string,
    orderBySort?: string
  ) {
    setIsLoading(true);
    let paginaAtual = 0;
    if (pageNumber > 0) {
      paginaAtual = pageNumber - 1;
    }

    const allRules: PaginationModel = await listRulesApi(
      limit,
      paginaAtual,
      ruleName,
      order,
      orderBySort
    );

    setRules(allRules.data);
    setTotalCount(allRules.totalCount);
    setCurrentPage(pageNumber);
    setIsLoading(false);
  }

  useEffect(() => {
    const order = orderSort ? 'ASC' : 'DESC';
    listRules(currentPage, ruleName, order, orderBySort);
  }, [orderSort, orderBySort, limit, ruleName, currentPage]);

  if (updateTable) {
    listRules(currentPage);
    setUpdateTable(!updateTable);
  }

  const filter = async () => {
    if (ruleName === '') listRules(1);
    if (ruleName.length < 3 && ruleName !== '') {
      setCharWarning(true);
    } else {
      setCharWarning(false);
      listRules(1);
    }
  };

  const showModal = (id: string, showMenu: boolean) => {
    setIdRule(id);
    setShowModalRule(true);
    setShowModalViewOrEdit(showMenu);
  };
  const handleCloseCategoria = () => {
    setShowModalRule(false);
  };
  function deleteRule(id: string) {
    try {
      Swal.fire({
        title: 'Tem certeza que deseja excluir?',
        text: 'Ao confirmar, a regra de lote será excluída!',
        showCancelButton: true,
        confirmButtonText:
          '<i class="fas fa-trash fa-sm" aria-hidden="true"></i> Excluir',
        cancelButtonText:
          '<i class="fas fa-ban" aria-hidden="true"></i> Cancelar',
        customClass: {
          confirmButton: 'confirmButtonModalClass',
          cancelButton: 'cancelButtonModalClass',
        },
      }).then(async result => {
        if (result.value) {
          try {
            setIsLoading(true);
            await deleteRuleApi(id);
            toast.success('Regra de Cobrança excluída com sucesso!', {
              autoClose: 2500,
            });
            setIsLoading(false);
            if (rules.length - 1 > 0) {
              listRules(currentPage);
            } else {
              listRules(currentPage - 1);
              setCurrentPage(currentPage - 1);
            }
          } catch (e) {
            setIsLoading(false);
          }
        }
      });
    } catch (error) {
      toast.error(error, {autoClose: 2500});
    }
  }

  return (
    <UIContent>
      <div className="d-flex bd-highlight titleAndButton">
        <div className="p-2 flex-grow-1 bd-highlight">
          <Titulo> Regras de Lote </Titulo>
        </div>
      </div>
      <form onSubmit={handleSubmit(filter)}>
        <div className="form-row mb-5">
          <div className="form-group col-md-4">
            <InputDefault
              name="categoria"
              type="text"
              placeholder="Nome da Regra"
              id="input-rule-name"
              onChange={e => setRuleName(e.target.value)}
              value={ruleName}
              inputMaxLength="100"
              mask={noEmptyMask}
            />
            {charWarning && (
              <p className="input-error">
                Digite ao menos 3 caracteres para realizar a busca!
              </p>
            )}
          </div>
          <div className="d-flex justify-content-start">
            <BtnDefault
              name="btn-buscar"
              className="btn-custom btn-save"
              id="btn-buscar"
              type="submit"
              variantClass="custom"
            >
              <FaSearch size={12} className="mr-1" />
              Buscar
            </BtnDefault>
          </div>
        </div>
      </form>

      <ModalDefault
        nome="create-billet-rules"
        icon={FaGripVertical}
        variantClass="btn-purple btn-purple--round"
        setUpdateTable={setUpdateTable}
      >
        Nova Regra
      </ModalDefault>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <Table responsive hover>
          <thead className="table-header">
            <tr>
              <th>Tipo de Regra</th>
              <th>
                <div
                  className={
                    orderBySort === BillingRuleOrderByEnum.NAME
                      ? 'thContainerActive'
                      : 'thContainer'
                  }
                  onClick={() => {
                    setOrderSort(!orderSort);
                    setOrderBySort(BillingRuleOrderByEnum.NAME);
                  }}
                >
                  {orderSort && orderBySort === BillingRuleOrderByEnum.NAME ? (
                    <FaSortAlphaDown size={20} />
                  ) : orderBySort !== BillingRuleOrderByEnum.NAME ? (
                    <FaSortAlphaDown size={20} />
                  ) : (
                    <FaSortAlphaUpAlt size={20} />
                  )}
                  Nome da Regra
                </div>
              </th>
              <th>
                <div
                  className={
                    orderBySort === BillingRuleOrderByEnum.VALUE
                      ? 'thContainerActive'
                      : 'thContainer'
                  }
                  onClick={() => {
                    setOrderSort(!orderSort);
                    setOrderBySort(BillingRuleOrderByEnum.VALUE);
                  }}
                >
                  {orderSort && orderBySort === BillingRuleOrderByEnum.VALUE ? (
                    <FaSortNumericDown size={20} />
                  ) : orderBySort !== BillingRuleOrderByEnum.VALUE ? (
                    <FaSortNumericDown size={20} />
                  ) : (
                    <FaSortNumericUpAlt size={20} />
                  )}
                  Valor
                </div>
              </th>
              <th>Descrição</th>
              <th>
                <div
                  className={
                    orderBySort === BillingRuleOrderByEnum.DUE_DATE
                      ? 'thContainerActive'
                      : 'thContainer'
                  }
                  onClick={() => {
                    setOrderSort(!orderSort);
                    setOrderBySort(BillingRuleOrderByEnum.DUE_DATE);
                  }}
                >
                  {orderSort &&
                  orderBySort === BillingRuleOrderByEnum.DUE_DATE ? (
                    <FaSortAmountDown size={20} />
                  ) : orderBySort !== BillingRuleOrderByEnum.DUE_DATE ? (
                    <FaSortAmountDown size={20} />
                  ) : (
                    <FaSortAmountUpAlt size={20} />
                  )}
                  Vencimento
                </div>
              </th>
              <th>Limite PGTO</th>
              <th>Multa</th>
              <th>Juros</th>
              <th>Desconto</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {rules &&
              rules.map((rule, index) => {
                return (
                  <TableRowBillingRule
                    rule={rule}
                    showModalMethod={showModal}
                    deleteRuleMethod={deleteRule}
                    key={index}
                  />
                );
              })}
          </tbody>
        </Table>
      )}
      {!isLoading && rules !== undefined && rules.length === 0 && (
        <div className="msg-table ">
          {ruleName === '' ? (
            <p>Não há Regras de cobranças cadastradas!</p>
          ) : (
            <p>Não há Regras de cobranças cadastradas com esse filtro!</p>
          )}
        </div>
      )}
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <PaginationDefault
          currentPage={currentPage}
          totalCount={totalCount}
          limit={limit}
          action={(value: number) => setCurrentPage(value)}
          totalNaPagina={rules.length}
        />
      )}
      {showModalRule ? (
        <ModalEditBillingRule
          icon={FaGripVertical}
          variantClass="btn-purple btn-purple--round"
          setUpdateTable={setUpdateTable}
          id={idRule}
          showModal={showModalRule}
          handleClose={handleCloseCategoria}
          showMenuModal={showModalViewOrEdit}
        />
      ) : (
        ''
      )}
    </UIContent>
  );
};

export default MainBilletRule;
