import React, {useState} from 'react';
import {Control, Controller, DeepMap, FieldError} from 'react-hook-form';
import {FaCalendar} from 'react-icons/fa';

import {getDadosPlanoContaFinanceira} from '../../../api';
import CardSection from '../../../components/CardSection';
import CharacterCount from '../../../components/CharacterCount';
import DatePickerBr from '../../../components/DatePickerBr';
import InputCheckboxDefault from '../../../components/Inputs/InputCheckboxDefault';
import InputDefault from '../../../components/Inputs/InputDefault';
import InputRadioDefault from '../../../components/Inputs/InputRadioDefault';
import SelectQtdDias from '../../../components/SelectQtdDias';
import {ICobranca} from '../../../models/cobranca';
import {IPlanoContaFinanceira} from '../../../models/conta-financeiro';
import {formataDinheiro} from '../../../utils/formatMonetary';
import moneyMask from '../../../utils/masks/moneyMask';
import {showModalWarning} from '../../../utils/modalWarning';
import './styles.scss';

interface CardClienteParceladaProps {
  register;
  getValues;
  control: Control;
  errors: DeepMap<ICobranca, FieldError>;
  vlTotalCalculado: number;
  isEnvioImediato: boolean;
  qtdDiasAntesDoVencimento: number;
  setVlParcelas: React.Dispatch<React.SetStateAction<number>>;
  setNumParcelas: React.Dispatch<React.SetStateAction<number>>;
  setValorCobranca: React.Dispatch<React.SetStateAction<number>>;
  planoFinanceira: IPlanoContaFinanceira;
  setHasLimit: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingLimit: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEnvioImediato: React.Dispatch<React.SetStateAction<boolean>>;
  setQtdDiasAntesDoVencimento: React.Dispatch<React.SetStateAction<number>>;
  setCobrarPorEmail: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardClienteParcelada: React.FC<CardClienteParceladaProps> = ({
  register,
  control,
  errors,
  vlTotalCalculado,
  setValorCobranca,
  setVlParcelas,
  setNumParcelas,
  setHasLimit,
  getValues,
  setIsLoadingLimit,
  setIsEnvioImediato,
  isEnvioImediato,
  setQtdDiasAntesDoVencimento,
  qtdDiasAntesDoVencimento,
  setCobrarPorEmail,
}) => {
  const [characterCount, setCharacterCount] = useState(150);
  const [cobrancaEmail, setCobrancaEmail] = useState<boolean>(false);
  const trocarParcelaChecada = (parcelaChecada: boolean) => {};

  const characterCounter = e => {
    e.target.value = e.target.value.replace(/^\s+/, '');
    setCharacterCount(150 - e.target.value.length);
  };

  const verificarLimite = async () => {
    setIsLoadingLimit(true);
    const data: IPlanoContaFinanceira = await getDadosPlanoContaFinanceira();
    const value = Number(
      getValues('valor').replace(/\./g, '').replace(',', '.')
    );
    const parcelas = Number(getValues('numeroParcelas'));

    const parsedValue = value * parcelas;

    if (
      !(
        data.maxValueMonthlyValueRequested + parsedValue >
        data.maxValueMonthly
      ) &&
      !(data.dailyMaximumValueRequested + parsedValue > data.dailyMaximumValue)
    ) {
      setIsLoadingLimit(false);
      setHasLimit(true);
      return;
    }

    setHasLimit(false);
    showModalWarning(
      'Limite do plano ultrapassado!',
      '<p>O limite do seu plano diário ou mensal não comporta a emissão desta cobrança, aumente seu limite através do atendimento!</p>'
    );
  };

  return (
    <CardSection title="Informações Valor/ Data">
      <div className="form-row">
        <div className="form-group col-md-3">
          <label className="label-item-tabela" htmlFor="input-valor">
            Valor da parcela
          </label>
          <span className="asterisk ">*</span>

          <InputDefault
            name="valor"
            type="text"
            placeholder=""
            id="input-valor"
            inputRef={register({
              required: true,
              min: 1,
              maxLength: 10,
            })}
            erros={errors}
            onBlur={event => {
              setVlParcelas(
                Number(event.target.value.replace('.', '').replace(',', '.'))
              );
              setValorCobranca(
                Number(event.target.value.replace('.', '').replace(',', '.'))
              );
              verificarLimite();
            }}
            mask={moneyMask}
            onChange={() => {
              setIsLoadingLimit(true);
            }}
            inputGroupText="R$"
            inputMaxLength="10"
          />
          {/* <span className="info-limit-day">
            {`Você pode emitir mais ${
              planoFinanceira.dailyTransactionLimit
            } cobranças, com limite total de ${formataDinheiro(
              planoFinanceira.dailyTransactionLimit
            )}.`}
          </span> */}
        </div>
        <div className="form-group col-md-3">
          <label className="label-item-tabela" htmlFor="input-num-parcelas">
            Número de parcelas
          </label>
          <span className="asterisk ">*</span>
          <div className="num-parcelas">
            <SelectQtdDias
              id="input-num-parcelas"
              qtde={12}
              className="input-default"
              name="numeroParcelas"
              inputRef={register({required: true})}
              onChange={event => {
                setNumParcelas(Number(event.target.value));
                verificarLimite();
              }}
              erros={errors}
            />
          </div>
        </div>
        <div className="form-group col-md-3">
          <div className="total-calculado">
            <label className="label-item-tabela" htmlFor="input-total">
              Total:
            </label>
            <span className="total-calculado__total-parcelado">
              {vlTotalCalculado ? formataDinheiro(vlTotalCalculado) : 'R$ 0.00'}
            </span>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-3 cliente-datapicker ">
          <label className="label-item-tabela" htmlFor="input-vencimento">
            Vencimento primeira parcela
            <span className="asterisk ">*</span>
          </label>
          <Controller
            className="input-default"
            name="vencimento"
            defaultValue={null}
            control={control}
            rules={{required: true}}
            render={({onChange, value, ref}) => (
              <DatePickerBr
                id="input-vencimento"
                icon={FaCalendar}
                classIcon="icon-calendar"
                selected={value}
                onChange={onChange}
                dataMinima={new Date()}
                inputRef={ref}
                name="vencimento"
                erros={errors}
              />
            )}
          />
        </div>
        <div className="form-group col-md-3">
          <label className="label-item-tabela" htmlFor="input-diaMesVencimento">
            Dia do vencimento
            <span className="asterisk ">*</span>
          </label>
          <SelectQtdDias
            id="input-diaMesVencimento"
            qtde={31}
            className="input-default"
            name="diaMesVencimento"
            formName="form-nova-cobranca"
            inputRef={register({required: true})}
            erros={errors}
          />
        </div>
        <div className="form-group col-md-3">
          <label
            className="label-item-tabela"
            htmlFor="input-diasAmaisParaPagamento"
          >
            Limite de pagamento - Dias a mais
          </label>
          <SelectQtdDias
            id="input-diasAmaisParaPagamento"
            qtde={10}
            hasZero={true}
            className="input-default"
            name="diasAmaisParaPagamento"
            formName="form-nova-cobranca"
            inputRef={register}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-12 pl-0">
          <InputRadioDefault
            className="form-check-input-porc"
            name={'primeiraParcelaImediata'}
            id={'1'}
            onClick={() => {
              setIsEnvioImediato(true);
              setQtdDiasAntesDoVencimento(0);
            }}
            classLabel="label-item-tabela ml-2"
            defaultChecked={isEnvioImediato}
          >
            Primeira parcela imediatamente. Próximas agendadas para{' '}
            <SelectQtdDias
              id="input-diasEnvioAntesDoVencimento"
              qtde={31}
              hasZero={true}
              name="diasEnvioAntesDoVencimento"
              formName="form-nova-cobranca"
              inputRef={register}
              disabled={!isEnvioImediato}
              defaultValue={!isEnvioImediato ? 0 : qtdDiasAntesDoVencimento}
              onChange={e => setQtdDiasAntesDoVencimento(e.target.value)}
            />{' '}
            dia(s) antes do vencimento
          </InputRadioDefault>
        </div>
        <div className="form-group col-md-12 pl-0">
          <InputRadioDefault
            className="form-check-input-porc"
            name={'agendamentoTodasAsParcelas'}
            id={'2'}
            onClick={() => {
              setIsEnvioImediato(false);
              setQtdDiasAntesDoVencimento(0);
            }}
            classLabel="label-item-tabela ml-2"
            defaultChecked={!isEnvioImediato}
          >
            Todas as parcelas agendadas para{' '}
            <SelectQtdDias
              id="input-diasEnvioAntesDoVencimento"
              qtde={31}
              hasZero={true}
              name="diasEnvioAntesDoVencimento"
              formName="form-nova-cobranca"
              inputRef={register}
              disabled={isEnvioImediato}
              defaultValue={isEnvioImediato ? 0 : qtdDiasAntesDoVencimento}
              onChange={e => setQtdDiasAntesDoVencimento(e.target.value)}
            />{' '}
            antes do vencimento
          </InputRadioDefault>
        </div>
        <div className="col-md-9">
          <label className="d-flex mt-3 mb-4 checkbox-cobranca">
            <InputCheckboxDefault
              name="cobrancaEmail"
              checked={cobrancaEmail}
              onChange={e => {
                setCobrancaEmail(e.target.checked);
                setCobrarPorEmail(e.target.checked);
              }}
            />
            Desejo que seja cobrada inadimplência diariamente por e-mail
          </label>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-12">
          <label className="label-item-tabela" htmlFor="input-instrucoes">
            Informações
          </label>
          <span className="asterisk ">*</span>
          <textarea
            maxLength={150}
            className="col-md-12 text-area-default"
            name="instrucoesCliente"
            id="input-instrucoes"
            placeholder="Insira aqui as informações que irão ser exibidas na cobrança"
            onChange={characterCounter}
            ref={register({
              required: true,
            })}
          />
          <CharacterCount count={characterCount}></CharacterCount>
          {errors.instrucoesCliente && (
            <span className="input-error error-textarea ">
              Este campo é obrigatório.
            </span>
          )}
        </div>
      </div>
    </CardSection>
  );
};

export default CardClienteParcelada;
