import React, {useState, useEffect} from 'react';
import {Button, Table} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {FaRegSave} from 'react-icons/fa';
import {toast} from 'react-toastify';

import Swal from 'sweetalert2';

import {
  cadastrarTermoUso,
  deletarTermoDeUso,
  listarTermosUso,
} from '../../../api/u4cripto-api';
import InputDefault from '../../../components/Inputs/InputDefault';
import PaginationDefault from '../../../components/PaginationDefault';
import SpinnerLoader from '../../../components/Spinner';
import Titulo from '../../../components/Titulo';
import UIContainer from '../../../components/UI/Container';
import UIContent from '../../../components/UI/Content';
import {INovoTermoUso} from '../../../models/u4Cripto-model';
import noEmptyMask from '../../../utils/masks/noEmptyMask';
import TableRowAtividadesTermosDeUso from '../../components/TableRows/TableRowsTermosUso';

const AtividadesTermosDeUso: React.FC = () => {
  const {handleSubmit, register, errors} = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(10);
  const [termosUso, setTermosUso] = useState([]);

  async function listarTermosDeUso(pageNumber: number) {
    setIsLoading(true);
    let paginaAtual = 0;
    if (pageNumber > 0) {
      paginaAtual = pageNumber - 1;
    }

    const todosTermosUso = await listarTermosUso(paginaAtual, limit);

    setTermosUso(todosTermosUso.data);
    setTotalCount(todosTermosUso.totalCount);
    setCurrentPage(pageNumber);
    setIsLoading(false);
  }

  function onSubmit(data: INovoTermoUso) {
    const newTermo: INovoTermoUso = {
      versao: data.versao,
      descricao: data.descricao,
      dataCriacao: data.dataCriacao,
      ativo: true,
    };
    criarTermoUso(newTermo);
  }
  async function criarTermoUso(termo: INovoTermoUso) {
    try {
      await cadastrarTermoUso(termo);
      toast.success('Termo de uso cadastrado com sucesso!', {autoClose: 2500});
      listarTermosDeUso(currentPage);
    } catch (error) {
      toast.error(error, {autoClose: 2500});
    }
  }

  function excluirTermoUso(id: string) {
    try {
      Swal.fire({
        title: 'Tem certeza que deseja excluir?',
        text: 'Ao confirmar, o termo de uso será excluída!',
        showCancelButton: true,
        confirmButtonColor: ' #d33',
        cancelButtonColor: `${process.env.REACT_APP_COLOR_PRIMARY}`,
        confirmButtonText:
          '<i class="fas fa-trash fa-sm" aria-hidden="true"></i> Excluir',
        cancelButtonText:
          '<i class="fas fa-ban" aria-hidden="true"></i> Cancelar',
      }).then(async result => {
        if (result.value) {
          try {
            setIsLoading(true);
            await deletarTermoDeUso(id);
            toast.success('Termo excluído com sucesso!', {autoClose: 2500});
            setIsLoading(false);
            if (termosUso.length - 1 > 0) {
              listarTermosDeUso(currentPage);
            } else {
              listarTermosDeUso(currentPage - 1);
              setCurrentPage(currentPage - 1);
            }
          } catch (e) {
            setIsLoading(false);
          }
        }
      });
    } catch (error) {
      toast.error(error, {autoClose: 2500});
    }
  }
  useEffect(() => {
    listarTermosDeUso(currentPage);
  }, []);
  return (
    <UIContainer>
      <UIContent>
        <Titulo> Gerenciamento de termos de uso </Titulo>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row mb-5">
            <div className="form-group col-md-4">
              <InputDefault
                name="descricao"
                type="text"
                placeholder="Insira a url do termo de uso"
                id="descricao-termo"
                inputMaxLength="300"
                inputRef={register({
                  required: true,
                })}
                erros={errors}
                mask={noEmptyMask}
              />
            </div>
            <div className="form-group col-md-4">
              <InputDefault
                name="versao"
                type="text"
                placeholder="Versão  do termo de uso"
                id="versao-termo"
                inputMaxLength="10"
                inputRef={register({
                  required: true,
                })}
                erros={errors}
                mask={noEmptyMask}
              />
            </div>
            <div className="form-group col-md-4">
              <Button
                type="submit"
                variant="custom"
                className="btn-custom btn-save"
              >
                <FaRegSave size={13} className="mr-1" />
                Salvar
              </Button>
            </div>
          </div>
        </form>

        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <Table responsive borderless hover>
            <thead>
              <tr>
                <th>Versão do termo</th>
                <th>Link do termo</th>
                <th>Data de criação</th>
                <th>Ativo</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {termosUso &&
                termosUso.map((termo, index) => (
                  <TableRowAtividadesTermosDeUso
                    termosUso={termo}
                    excluirTermoUso={excluirTermoUso}
                    key={index}
                  />
                ))}
            </tbody>
          </Table>
        )}
        {!isLoading && termosUso !== undefined && termosUso.length === 0 && (
          <div className="msg-table ">
            <p>Nenhum termo de uso cadastrado.</p>
          </div>
        )}
        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <PaginationDefault
            currentPage={currentPage}
            totalCount={totalCount}
            limit={limit}
            action={(value: number) => listarTermosDeUso(value)}
            totalNaPagina={termosUso.length}
          />
        )}
      </UIContent>
    </UIContainer>
  );
};

export default AtividadesTermosDeUso;
