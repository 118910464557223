import './styles.scss';
import React, {InputHTMLAttributes} from 'react';
import {FaSearch} from 'react-icons/fa';
import {components} from 'react-select';
import Select from 'react-select/async-creatable';

import {IInputValue} from '../../../models/forms-model';

interface InputNameSearchProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  find: string;
  sizeColMdInput?: number;
  promiseOptions?: (inputValue) => Promise<ReadonlyArray<unknown>> | void;
  setFieldValue?: (inputValue) => void;
  handleCreate?: (inputValue) => void;
  isCreatable?: boolean;
  createMessage?: (inputValue) => React.ReactNode;
  id: string;
  disabled?: boolean;
  value: IInputValue;
}

const InputNameSearch: React.FC<InputNameSearchProps> = ({
  placeholder,
  sizeColMdInput = 12,
  promiseOptions,
  setFieldValue,
  handleCreate,
  createMessage,
  id,
  disabled,
  value,
}) => {
  const PlaceHolderMerged: React.FC = () => (
    <div>
      {placeholder}
      <FaSearch className="icone-input" />
    </div>
  );

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage
        {...props}
        className="show-cliente"
        onClick={handleCreate}
      >
        {/*<button className="show-cliente" onClick={handleCreate}>
          Criar novo cliente
        </button>*/}
      </components.NoOptionsMessage>
    );
  };

  return (
    <div className={`col-md-${sizeColMdInput}`}>
      <Select
        cacheOptions
        className="input-search-creatable-container"
        classNamePrefix="input-name-search-creatable"
        loadOptions={promiseOptions}
        placeholder={<PlaceHolderMerged />}
        isClearable
        allowCreateWhileLoading={false}
        isValidNewOption={() => false}
        onChange={setFieldValue}
        noOptionsMessage={noOptionsMessage}
        onCreateOption={handleCreate}
        loadingMessage={loadingMessage}
        id={id}
        components={{NoOptionsMessage}}
        isDisabled={disabled}
        value={value}
      />
    </div>
  );
};

function noOptionsMessage() {
  return 'Nenhum cliente encontrado';
}

function loadingMessage() {
  return 'Carregando..';
}

function defaultCreateMessage(inputValue) {
  return `Criar "${inputValue}"`;
}

export default InputNameSearch;
