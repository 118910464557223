import React, {useEffect, useState} from 'react';

import {previsaoRecebimentoByDate} from '../../../../api/dashboard-api';
import UILoad from '../../../../components/UI/Load';
import {IDashboardFilters} from '../../../../models/dashboard-model';
import {formataDinheiro} from '../../../../utils/formatMonetary';
interface Filtros {
  filter: IDashboardFilters;
}
const PrevisaoRecebimento: React.FC<Filtros> = ({filter}) => {
  const [totalPrevisaoRecebimento, setTotalPrevisaoRecebimento] = useState(0);
  const [loading, setLoading] = useState(false);

  async function getPrevisaoRecebimentoByDate(filter) {
    setLoading(true);
    const totalPagos: number = await previsaoRecebimentoByDate(filter);
    setTotalPrevisaoRecebimento(totalPagos);
    setLoading(false);
  }

  useEffect(() => {
    if (filter) {
      getPrevisaoRecebimentoByDate(filter);
    }
  }, [filter]);
  return (
    <div className="dashboard-boleto__content dashboard-boleto__border">
      {loading ? (
        <UILoad />
      ) : (
        <>
          <label className="dashboard-boleto__label" htmlFor="recebimento">
            Previsão de Recebimento
          </label>
          {totalPrevisaoRecebimento > 0 ? (
            <span className="dashboard-boleto__span dashboard-boleto__green   ">
              {formataDinheiro(totalPrevisaoRecebimento)}
            </span>
          ) : (
            <span className="dashboard-boleto__span dashboard-boleto__zero   ">
              Não há dados disponíveis para o período selecionado.{' '}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default PrevisaoRecebimento;
