import axios from 'axios';

import {ICliente, INovoCliente} from '../models';
import {IClientGroup} from '../models/client-group.model';
import api from './api';

const PATH_API = '/clientes';
const PATH_GROUP_API = '/clientes/grupo';
const CancelToken = axios.CancelToken;

interface IFiltroCliente {
  cpfCnpj?: undefined | string;
  email?: undefined | string;
  nome?: undefined | string;
}

export const autocompleteClientes = (input: string) => {
  const cleanInput = input ? input.replace(/[.-]/g, '').trim() : input;
  const rgxStartsWithNum = /^(\d{3,11})$/;
  const isMin3Num = rgxStartsWithNum.test(cleanInput);
  const queryParm: IFiltroCliente = {};

  queryParm.nome = cleanInput;
  if (isMin3Num) {
    queryParm.cpfCnpj = cleanInput;
  }

  return api
    .get<ICliente[]>(`${PATH_API}/autocomplete`, {
      params: queryParm,
      cancelToken: new CancelToken(cancel => {
        if (!input) {
          cancel();
        }
      }),
    })
    .then(({data}) =>
      data
        ? data.map(c => ({label: `${c.cpfCnpj} | ${c.nome}`, value: c.id}))
        : data
    );
};

export const cadastrarCliente = async (cliente: INovoCliente) => {
  const params = {...cliente};
  return (await api.post<ICliente>(PATH_API, params))?.data;
};

export const exibirCliente = async (id: string) => {
  if (id) {
    return (await api.get<ICliente>(`${PATH_API}/${id}`))?.data;
  }
  return false;
};

export const listarClientes = async (
  page = 0,
  limit = 10,
  nome?: string,
  cpfCnpj?: string,
  email?: string,
  order?: string,
  listarInativos = false
) => {
  return (
    await api.get(
      `${PATH_API}?page=${page}&limit=${limit}&nome=${nome}&cpfCnpj=${cpfCnpj}&email=${email}&order=${order}&listarInativos=${listarInativos}`
    )
  )?.data;
};

export const deletarCliente = async (id: string) => {
  return await api.delete<ICliente>(`${PATH_API}/${id}`);
};

export const atualizarCliente = async (id: string, cliente: INovoCliente) => {
  const params = {...cliente};
  return (await api.put<ICliente>(`${PATH_API}/${id}`, params))?.data;
};

export const createGroup = async (group: IClientGroup) => {
  const params = {...group};
  return (await api.post<any>(PATH_GROUP_API, params))?.data;
};

export const getGroupDetailsApi = async (groupId: string) => {
  return (await api.get<IClientGroup>(`${PATH_GROUP_API}/${groupId}/detalhes`))
    ?.data;
};

export const listGroups = async (
  page = 0,
  limit = 10,
  name?: string,
  order?: string
) => {
  if (name) {
    return (
      await api.get(
        `${PATH_GROUP_API}/lista?page=${page}&limit=${limit}&name=${name}&order=${order}`
      )
    )?.data;
  }
  return (
    await api.get(
      `${PATH_GROUP_API}/lista?page=${page}&limit=${limit}&order=${order}`
    )
  )?.data;
};

export const deleteGroupApi = async (id: string) => {
  return api.delete(`${PATH_GROUP_API}/${id}/exclui`);
};

export const updateGroupApi = async (group: IClientGroup) => {
  const params = {
    name: group.name,
    description: group.description,
  };
  return api.put(`${PATH_GROUP_API}/${group.id}/edita`, params);
};

export const putClientsInGroup = async (
  groupId: string,
  clients: Set<string>
) => {
  const params = {clients: [...clients]};
  return (await api.put<any>(`${PATH_GROUP_API}/${groupId}/adiciona`, params))
    ?.data;
};

export const getClientsOutOfGroup = async (
  groupId: string,
  page = 0,
  limit = 10,
  order = 'ASC'
) => {
  if (page > 0) page = page - 1;

  return (
    await api.get(
      `${PATH_GROUP_API}/clientes-fora-do-grupo?limit=${limit}&page=${page}&groupId=${groupId}&order=${order}`
    )
  ).data;
};

export const getClientsInGroup = async (
  groupId: string,
  page = 0,
  limit = 10,
  order = 'ASC'
) => {
  if (page > 0) page = page - 1;

  return (
    await api.get(
      `${PATH_GROUP_API}/clientes-no-grupo?limit=${limit}&page=${page}&groupId=${groupId}&order=${order}`
    )
  ).data;
};

export const removeClientFromGroup = async (
  groupId: string,
  clientId: string
) => {
  return (
    await api.patch<any>(`${PATH_GROUP_API}/${groupId}/exclui/${clientId}`)
  )?.data;
};

export const checkIfThereIsClientsRegistered = async (): Promise<boolean> => {
  return (await api.get<boolean>(`${PATH_API}/existe/clientes`))?.data;
};

export const sendImportedClients = async (file: FormData) => {
  return await api.post<any>(`${PATH_API}/lote`, file);
};

export const getExceptionFile = async () => {
  return await api.get<any>(`${PATH_API}/lote/erro`, {
    responseType: 'arraybuffer',
    headers: {'Content-Type': 'blob'},
  });
};

export const getExampleFileDir = async () => {
  return (await api.get(`${PATH_API}/lote/exemplo`))?.data;
};

export const toggleClientActive = async (clientId: string) => {
  return (await api.put(`${PATH_API}/ativo/${clientId}`))?.data;
};
