import {BillingRuleOrderByEnum} from '../components/MainBillingRule/enum/index';
import {BillingRuleModel} from '../models/billing-rule-model';
import {PaginationModel} from '../models/pagination-model';
import api from './api';

const PATH_API = '/billing-rule';

export const createBillingRuleApi = async (rule: BillingRuleModel) => {
  return api.post<BillingRuleModel>(`${PATH_API}`, rule);
};

export const listRulesApi = async (
  limit: number,
  page: number,
  name = '',
  order = 'ASC',
  orderBy = BillingRuleOrderByEnum.NAME
) => {
  return (
    await api.get<PaginationModel>(
      `${PATH_API}?limit=${limit}&page=${page}&name=${name}&order=${order}&orderBy=${orderBy}`
    )
  )?.data;
};

export const deleteRuleApi = async (id: string) => {
  return api.delete(`${PATH_API}/${id}/delete`);
};

export const getDetailsRuleApi = async (id: string) => {
  return (await api.get<BillingRuleModel>(`${PATH_API}/${id}/get`))?.data;
};

export const editRuleApi = async (rule: BillingRuleModel) => {
  return api.patch<BillingRuleModel>(`${PATH_API}/${rule.id}/edit`, rule);
};
