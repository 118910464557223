import React, {useEffect, useState} from 'react';

import {statusCobrancasByDate} from '../../../../api/dashboard-api';
import UILoad from '../../../../components/UI/Load';
import {
  IDashboardFilters,
  IStatusCobranca,
} from '../../../../models/dashboard-model';
import {formataDinheiro} from '../../../../utils/formatMonetary';
interface Filtros {
  filter: IDashboardFilters;
}
const StatusCobranca: React.FC<Filtros> = ({filter}) => {
  const [cobrancas, setCobrancas] = useState<IStatusCobranca>({
    em_aberto: 0,
    pago: 0,
    vencidos: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(false);

  async function getStatusCobrancasByDate(filter) {
    setLoading(true);
    const valoresCobrancas: IStatusCobranca = await statusCobrancasByDate(
      filter
    );
    setCobrancas(valoresCobrancas);
    setLoading(false);
  }
  useEffect(() => {
    if (filter) {
      getStatusCobrancasByDate(filter);
    }
  }, [filter]);

  return (
    <div
      className={
        loading ? 'dashboard-categoria__border' : 'dashboard-categoria__border'
      }
    >
      <h6 className="dashboard-categoria__title">Status Cobrança</h6>

      {loading ? (
        <UILoad />
      ) : (
        <>
          {cobrancas && cobrancas.total > 0 ? (
            <>
              <div className="dashboard-categoria__content">
                <table className="categoria-table">
                  <thead className="dashboard-categoria__table">
                    <tr>
                      <th>Cobranças</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="dashboard-categoria__yellow">Em aberto</td>
                      <td>{formataDinheiro(cobrancas.em_aberto)}</td>
                    </tr>
                    <tr>
                      <td className="dashboard-categoria__green">Pago</td>
                      <td>{formataDinheiro(cobrancas.pago)}</td>
                    </tr>
                    <tr>
                      <td className="dashboard-categoria__red">Vencidos</td>
                      <td>{formataDinheiro(cobrancas.vencidos)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className={
                  loading
                    ? 'dashboard-categoria__show '
                    : 'dashboard-categoria__tot'
                }
              >
                <span className="dashboard-categoria__total">
                  {' '}
                  Total - {formataDinheiro(cobrancas.total)}
                </span>
              </div>
            </>
          ) : (
            <span className="dashboard-boleto__span dashboard-boleto__zero   ">
              Não há dados disponíveis para o período selecionado.{' '}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default StatusCobranca;
