import React, {useState} from 'react';
import {Form, Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {FaRegEye} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

import {casdastrarContaFinanceira} from '../../api/contaFinanceira-api';
import InputLogin from '../../components/Inputs/InputLogin';
import Logo from '../../components/Logo';
import {
  IAuthPrimeiroAcesso,
  ICreateContaFinanceira,
} from '../../models/auth-model';
import cpfcnpjMask, {cnpjMask} from '../../utils/masks/cpfcnpjMask';
import {senhaValidator} from '../../utils/validations/senhaValidator';
import './styles.scss';

const CadastroSenha: React.FC<IAuthPrimeiroAcesso> = () => {
  const {register, handleSubmit, errors} = useForm<IAuthPrimeiroAcesso>();

  const [isLoading, setLoading] = useState(false);

  const history = useHistory();
  const contaFinanceiraLocation = history.location
    .state as ICreateContaFinanceira;

  const criarContafinanceira = async (
    contafinanceira: ICreateContaFinanceira
  ) => {
    {
      setLoading(true);
    }
    try {
      await casdastrarContaFinanceira(contafinanceira);
      toast.success('Usuario cadastrado com sucesso!', {autoClose: 2500});
      history.push('/confirmacao-conta-criada');
    } catch (error) {
      console.error(error);
    }
    {
      setLoading(false);
    }
  };

  const onSubmit = (data: {password: string; confirmarSenha: string}) => {
    if (data.password === data.confirmarSenha) {
      contaFinanceiraLocation.usuario.password = data.password;
      contaFinanceiraLocation.partnerID = process.env.REACT_APP_PARTNER_ID;
      criarContafinanceira(contaFinanceiraLocation);
    } else {
      toast.error('Senha não compatível. ', {autoClose: 2500});
    }
  };

  return (
    <div className="all-content">
      <span className="container page-title">
        Sistema de Gestão de Cobranças com Pix
      </span>

      <div className="container">
        <div className="div-form_senha col-md-12">
          <main className="content_senha row">
            <div className="content_senha__logo">
              <Logo></Logo>
            </div>
            <div className="content__text content__style ml-5 mr-5">
              <p>
                Defina sua senha de acesso exclusiva para o sistema de
                recorrência.
              </p>
            </div>
            <div>
              <div className="row text-center mb-4">
                <div className="col-sm-12">
                  <label className="form-label ml-5" htmlFor="Cnpj">
                    CNPJ:{' '}
                  </label>
                  <span className="text__content">
                    {cnpjMask(contaFinanceiraLocation.cpfCnpj)}
                  </span>
                </div>
                <div className="col-sm-12 ">
                  <label className="form-label ml-3" htmlFor="Cpf">
                    CPF:{' '}
                  </label>
                  <span className="text__content">
                    {cpfcnpjMask(contaFinanceiraLocation.usuario.cpf)}
                  </span>
                </div>
              </div>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <InputLogin
                  name="password"
                  placeholder="Senha"
                  id="input-password"
                  icon={FaRegEye}
                  type="password"
                  inputRef={register({
                    required: true,
                    validate: {
                      password: value => {
                        return senhaValidator(value);
                      },
                    },
                  })}
                  erros={errors}
                />

                <InputLogin
                  name="confirmarSenha"
                  placeholder="Confirmar senha"
                  id="input-confirmarSenha"
                  icon={FaRegEye}
                  type="password"
                  inputRef={register({
                    required: true,
                  })}
                  erros={errors}
                />
                <div className="col-md-12">
                  <Button
                    className="btn-purple col-md-12 btn-reset-password"
                    type="submit"
                    variant="custom"
                    disabled={isLoading}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {isLoading ? 'Loading…' : 'Cadastrar senha'}
                  </Button>
                </div>
              </Form>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
export default CadastroSenha;
