export function senhaValidator(senha: string) {
  let retorno = false;
  const letrasMaiusculas = /[A-Z]/;
  const letrasMinusculas = /[a-z]/;
  const numeros = /[0-9]/;
  const caracteresEspeciais = /[!|@|#|$|%|^|&|*|(|)|-|_]/;

  if (senha.length < 8) {
    return retorno;
  }

  let auxMaiuscula = 0;
  let auxMinuscula = 0;
  let auxNumero = 0;
  let auxEspecial = 0;
  for (let i = 0; i < senha.length; i++) {
    if (letrasMaiusculas.test(senha[i])) auxMaiuscula++;
    else if (letrasMinusculas.test(senha[i])) auxMinuscula++;
    else if (numeros.test(senha[i])) auxNumero++;
    else if (caracteresEspeciais.test(senha[i])) auxEspecial++;
  }
  if (auxMaiuscula > 0) {
    if (auxMinuscula > 0) {
      if (auxNumero > 0) {
        if (auxEspecial) {
          retorno = true;
        }
      }
    }
  }
  return retorno;
}

export function limpaSenha(senha: string): string {
  return senha.replace(/[^\d]+/g, '');
}

export function validarSenhas(senha: string, confirmacao: string) {
  return senha === confirmacao;
}
