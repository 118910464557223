import {
  statusCobrancaEnum,
  StatusEnvioMensagemEnum,
  statusOptions,
  TipoCobrancaEnum,
  TipoOptionsDeslogado,
  TipoOptionsLogado,
} from 'shared/constantes';

export function statusEnvioMsgCobranca(nome: string): string {
  const statusEnvMsg = StatusEnvioMensagemEnum.find(
    item => item.value === nome
  );
  if (statusEnvMsg) {
    return statusEnvMsg.label;
  }

  return '';
}

export function tipoCobrancaEnums(nome: string): string {
  const tipocobran = TipoCobrancaEnum.find(item => item.value === nome);
  if (tipocobran) {
    return tipocobran.label;
  }
  return '';
}
export function statusUsuario(nome: string): string {
  const statusUser = statusOptions.find(item => item.value === nome);
  if (statusUser) {
    return statusUser.label;
  }
  return '';
}

export function statusCobranca(nome: string): string {
  const statusCobranca = statusCobrancaEnum.find(item => item.value === nome);
  if (statusCobranca) {
    return statusCobranca.label;
  }

  return '';
}

// Enums de controle
export enum TipoCobranca {
  AVULSA = 'AVULSA',
  PARCELADA = 'PARCELADA',
  RECORRENTE = 'RECORRENTE',
}

export function tipoOptionsDeslogadoEnums(nome: string): string {
  const tipoOptions = TipoOptionsDeslogado.find(item => item.value === nome);
  if (tipoOptions) {
    return tipoOptions.label;
  }
  return '';
}

export function tipoOptionsLogadoEnums(nome: string): string {
  const tipoOptions = TipoOptionsLogado.find(item => item.value === nome);
  if (tipoOptions) {
    return tipoOptions.label;
  }
  return '';
}
