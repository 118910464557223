import React, {
  LegacyRef,
  ChangeEventHandler,
  MouseEventHandler,
  FocusEventHandler,
  useEffect,
  useState,
} from 'react';
import {FieldErrors} from 'react-hook-form';

import './styles.scss';
import _ from 'lodash/fp';

interface SelectQtdDiasProps {
  qtde: number;
  id?: string;
  className?: string;
  name?: string;
  formName?: string;
  inputRef?: LegacyRef<HTMLSelectElement>;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  erros?: FieldErrors;
  hasZero?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  onBlur?: FocusEventHandler<HTMLSelectElement>;
  defaultValue?: string;
}

const SelectQtdDias: React.FC<SelectQtdDiasProps> = ({
  qtde,
  className = 'select-dias',
  name,
  inputRef,
  formName,
  id,
  hasZero = false,
  onChange,
  onClick,
  disabled,
  erros,
  onBlur,
  defaultValue,
}) => {
  const opcoes = [];
  let start;

  hasZero ? (start = 0) : (start = 1);

  opcoes.push(
    <option key={0} value={null}>
      {}
    </option>
  );

  for (let i = start; i <= qtde; i++) {
    opcoes.push(
      <option key={i + 1} value={i}>
        {i}
      </option>
    );
  }

  const [valueDefault, setValueDefault] = useState('0');

  useEffect(() => {
    setValueDefault(defaultValue);
  }, [defaultValue, valueDefault]);

  return (
    <>
      <select
        form={formName}
        name={name}
        id={id}
        className={className}
        ref={inputRef}
        onChange={onChange}
        disabled={disabled}
        onClick={onClick}
        onBlur={onBlur}
        defaultValue={valueDefault}
        value={valueDefault}
      >
        {opcoes}
      </select>
      {_.get(`${name}['type']`, erros) === 'required' && (
        <p className="input-error">Este campo é obrigatório.</p>
      )}
    </>
  );
};

export default SelectQtdDias;
