import React, {useEffect, useState} from 'react';
import {Modal, Table, Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {IconBaseProps} from 'react-icons';
import {FaArrowLeft, FaTelegramPlane} from 'react-icons/fa';
import {toast} from 'react-toastify';

import Swal from 'sweetalert2';

import {enviaSegundaViaBoleto, verCobranca} from '../../../api';
import InputRadioDefault from '../../../components/Inputs/InputRadioDefault';
import {IVerCobrancaBoleto} from '../../../models/cobranca';
import {IEnvioSegundaViaEmail} from '../../../models/emailModel';
import {statusCobranca, statusEnvioMsgCobranca} from '../../../shared/enumMap';
import {formataData} from '../../../utils/formatDate';
import {formataDinheiro} from '../../../utils/formatMonetary';
import noEmptyMask from '../../../utils/masks/noEmptyMask';
import InputDefault from '../../Inputs/InputDefault';
import SpinnerLoader from '../../Spinner';
import './styles.scss';

interface ModalDefaultProps {
  nome: string;
  icon?: React.ComponentType<IconBaseProps>;
  variantClass?: string;
  idCobranca?: string;
  handleCloseEnviar?: Function;
  showModalEnviar?: boolean;
}

const ModalDefaultEnviar: React.FC<ModalDefaultProps> = ({
  icon: Icon,
  idCobranca,
  handleCloseEnviar,
  showModalEnviar,
}) => {
  const EMAIL_SELECT_RADIO_INPUT = 'EMAIL_SELECT_RADIO_INPUT';

  const [cobranca, setCobranca] = useState<IVerCobrancaBoleto>();
  const [isLoading, setIsLoading] = useState(true);
  const [emailAtual, setEmailAtual] = useState<boolean>(true);

  const {handleSubmit, register, errors} = useForm();

  const showValueWarning = () => {
    return Swal.fire({
      title: 'Esse cliente está inativo no momento',
      text: 'Não é possível reenviar cobrança para um usuário inativo',
      confirmButtonText: 'Continuar',
      customClass: {
        confirmButton: 'confirmButtonModalClass',
      },
    });
  };

  async function carregarCobranca(idCobranca: string) {
    setIsLoading(true);
    const retornoCobranca = await verCobranca(idCobranca);
    setCobranca(retornoCobranca);
    setIsLoading(false);
    return cobranca;
  }

  const showPopoverBoletos = () => {
    const showPopover = document.querySelectorAll<HTMLElement>('.popover ')[0];
    if (showModalEnviar) {
      showPopover.style.display = 'none';
    } else {
      showPopover.style.display = 'block';
    }
  };

  useEffect(() => {
    carregarCobranca(idCobranca);
    showPopoverBoletos();
  }, []);

  const closeModal = () => {
    handleCloseEnviar();
  };

  const enviarBoletoAlternativo = async data => {
    let enderecoEmail = cobranca.cliente.email;
    if (data.emailAlternativo) {
      enderecoEmail = data.emailAlternativo;
    }
    try {
      const templateEnvioEmail: IEnvioSegundaViaEmail = {
        idCobranca: cobranca.cobrancaAtual.id,
        email: enderecoEmail,
      };

      if (cobranca?.cliente.isActive) {
        await enviaSegundaViaBoleto(templateEnvioEmail);
        toast.success('Cobrança enviada com sucesso!', {autoClose: 2500});
        closeModal();
        handleCloseEnviar();
      } else {
        closeModal();
        showValueWarning();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <Modal
          animation={false}
          show={showModalEnviar}
          onHide={handleCloseEnviar}
          backdrop="static"
          keyboard={true}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {Icon && <Icon size={20} className="modal-icone" />}
              Enviar Cobrança
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-modal">
              <Table responsive borderless hover>
                <thead>
                  <tr>
                    <th>N° Cobrança</th>
                    <th>Vencimento</th>
                    <th>Status envio</th>
                    <th>Valor</th>
                    <th>Status Pgto.</th>
                  </tr>
                </thead>

                <tbody>
                  <tr key={cobranca.id}>
                    <td>{cobranca.cobrancaAtual.numero}</td>
                    <td>{formataData(cobranca.cobrancaAtual.vencimentoEm)}</td>
                    <td>
                      {statusEnvioMsgCobranca(
                        cobranca.cobrancaAtual.statusEnvioMensagemCobranca
                      )}
                    </td>

                    <td>{formataDinheiro(cobranca.cobrancaAtual.valor)}</td>
                    <td>{statusCobranca(cobranca.cobrancaAtual.status)}</td>
                  </tr>
                </tbody>
              </Table>

              <form
                onSubmit={handleSubmit(enviarBoletoAlternativo)}
                className=""
              >
                <div className="form-check">
                  <InputRadioDefault
                    className="form-check-input"
                    name={EMAIL_SELECT_RADIO_INPUT}
                    id={EMAIL_SELECT_RADIO_INPUT + 1}
                    onClick={() => setEmailAtual(true)}
                    inputRef={register({
                      required: true,
                    })}
                    erros={errors}
                    classLabel="label-item-quando"
                    defaultChecked={emailAtual === true}
                  >
                    {cobranca.cliente.email}{' '}
                  </InputRadioDefault>
                </div>
                <div className="form-check opcoes-agendado mt-2">
                  <InputRadioDefault
                    className="form-check-input"
                    name={EMAIL_SELECT_RADIO_INPUT}
                    id={EMAIL_SELECT_RADIO_INPUT + 2}
                    onClick={() => setEmailAtual(false)}
                    inputRef={register({
                      required: true,
                    })}
                    erros={errors}
                    classLabel="label-item-quando"
                    defaultChecked={emailAtual === false}
                  >
                    Enviar para outro email
                  </InputRadioDefault>
                  {!emailAtual ? (
                    <>
                      <div className="form-check card-quando__card-opcao mt-2">
                        <label
                          className="label-item-quando"
                          htmlFor="input-emailAlternativo"
                        >
                          Email Alternativo
                        </label>
                        <InputDefault
                          name="emailAlternativo"
                          className="col-md-4"
                          type="text"
                          placeholder=""
                          id="input-emailAlternativo"
                          inputMaxLength={'50'}
                          inputRef={register({
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: 'Entre com um e-mail válido.',
                            },
                          })}
                          erros={errors}
                          mask={noEmptyMask}
                        />
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>

                <div className="buttons-group">
                  <Button
                    className="btn-custom btn-cancel"
                    variant="custom"
                    onClick={() => handleCloseEnviar()}
                  >
                    <FaArrowLeft size={13} className="mr-1" />
                    Voltar
                  </Button>

                  <Button
                    type="submit"
                    variant="custom"
                    className="btn-custom btn-save"
                  >
                    <FaTelegramPlane size={13} className="mr-1" />
                    Enviar cobrança
                  </Button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ModalDefaultEnviar;
