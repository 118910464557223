import React from 'react';
import {Pagination} from 'react-bootstrap';
import './styles.scss';

interface PaginationDefaultProps {
  currentPage: number;
  totalCount: number;
  limit: number;
  action?: Function;
  totalNaPagina: number;
}

const PaginationDefault: React.FC<PaginationDefaultProps> = ({
  action,
  currentPage,
  totalCount,
  limit,
  totalNaPagina,
}) => {
  const itemPage = [];
  const totalPages = Math.ceil(totalCount / limit);
  for (let i = 1; i <= totalPages; i++) {
    itemPage.push(
      <Pagination.Item
        key={i}
        active={i === currentPage}
        onClick={() => paginate(i)}
      >
        {i}
      </Pagination.Item>
    );
  }

  function prevPage() {
    if (currentPage !== 1) {
      action(currentPage);
    }
  }

  function nextPage() {
    if (currentPage + 1 <= totalPages) {
      action(currentPage + 1);
    }
  }

  function paginate(pageSelect: number) {
    if (currentPage !== pageSelect) {
      action(pageSelect);
    }
  }

  {
    const vlToRender = totalCount ? (
      <>
        <Pagination className="pagination justify-content-center">
          <Pagination.Prev onClick={() => prevPage()} />
          {itemPage}
          <Pagination.Next onClick={() => nextPage()} />
        </Pagination>
        {/*<p className="msg-pagination">
          Exibindo {1 + (currentPage - 1) * limit} a{' '}
          {totalNaPagina + (currentPage - 1) * limit} de {totalCount}
        </p>*/}
      </>
    ) : null;

    return vlToRender;
  }
};

export default PaginationDefault;
