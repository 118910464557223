import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {Controller, useForm} from 'react-hook-form';
import {FaRegSave, FaBan, FaPlus, FaUserPlus} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

import {cadastrarCliente, listarClientes} from '../../../api';
import {getEndereco} from '../../../api';
import {listGroups} from '../../../api/cliente-api';
import {IClientGroupSelectModel} from '../../../models/client-group-select.model';
import {INovoCliente} from '../../../models/cliente-model';
import {IModalEventProps} from '../../../models/modal-events-model';
import {IViaCep} from '../../../models/viaCep-model';
import cepMask from '../../../utils/masks/cepMask';
import cpfcnpjMask from '../../../utils/masks/cpfcnpjMask';
import noEmptyMask from '../../../utils/masks/noEmptyMask';
import phoneMask from '../../../utils/masks/phoneMask';
import cepValidator from '../../../utils/validations/cepValidator';
import cpfCnpjValidator from '../../../utils/validations/cpfCnpjValidator';
import InputDefault from '../../Inputs/InputDefault';
import InputEstado from '../../Inputs/InputEstado';
import InputSelectGroups from '../../Inputs/InputSelectGroups';
import ModalDefault from '../../ModalDefault';
import ModalDefaultCadastrarGrupo from '../../ModalDefault/ModalDefaultCadastrarGrupo';

const FormCadastroCliente: React.FC<IModalEventProps> = params => {
  const [grupos, setGrupos] = useState<IClientGroupSelectModel[]>();
  const [selected, setSelected] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    errors,
    control,
    setValue,
  } = useForm<INovoCliente>();
  const history = useHistory();

  const onSubmit = (data: INovoCliente) => {
    const newClient: INovoCliente = {
      nome: data.nome,
      cpfCnpj: data.cpfCnpj.replace(/[^\d]+/g, ''),
      telefone: data.telefone.replace(/[^\d]+/g, ''),
      email: data.email,
      endereco: {
        cep: data.endereco.cep.replace(/[^\d]+/g, ''),
        logradouro: data.endereco.logradouro,
        numero: data.endereco.numero,
        complemento: data.endereco.complemento,
        bairro: data.endereco.bairro,
        cidade: data.endereco.cidade,
        siglaEstado: data.endereco.siglaEstado['value'],
      },
      groups: selected.map((grupo: IClientGroupSelectModel) => {
        return grupo.value;
      }),
    };
    criarCliente(newClient);
  };

  const cancelForm = () => {
    closeModal();
  };

  const onChangeState = val => {
    setValue('endereco.siglaEstado', {
      label: val.uf,
      value: val.uf,
    });
  };

  async function getAddress(event) {
    if (cepValidator(event.target.value)) {
      const endereco: IViaCep = await getEndereco(event.target.value);
      if (!endereco.erro) {
        setValue('endereco.logradouro', endereco.logradouro);
        setValue('endereco.bairro', endereco.bairro);
        setValue('endereco.cidade', endereco.localidade);
        setValue('endereco.complemento', endereco.complemento);
        setValue('endereco.siglaEstado', {
          label: endereco.uf,
          value: endereco.uf,
        });
      }
    }
  }
  const closeModal = () => {
    params.handleUpdateTable(true);
    params.handleCloseModal(false);
  };

  function validaConfirmacaoEmail(emailConfirmacao: string): boolean {
    const emailValue = getValues('email');
    return emailValue === emailConfirmacao;
  }

  async function criarCliente(cliente: INovoCliente) {
    try {
      const clienteResp = await cadastrarCliente(cliente);

      toast.success('Cliente cadastrado com sucesso!', {autoClose: 2500});

      const auxClient = await listarClientes(
        0,
        1,
        clienteResp.nome,
        clienteResp.cpfCnpj,
        clienteResp.email
      );
      if (params.afterSave) {
        params.afterSave(auxClient.data[0].id);
      } else {
        history.push('/clientes');
        closeModal();
      }
    } catch (error) {
      toast.error(error, {autoClose: 2500});
    }
  }

  const listarGrupos = async () => {
    const grupos = await listGroups(0, 100, '', 'ASC');
    setGrupos(
      grupos.data.map(grupo => {
        return {
          value: grupo.id,
          label: grupo.name,
        };
      })
    );
  };
  useEffect(() => {
    listarGrupos();
  }, [isModalOpen]);

  const handleChange = selectedOptions => {
    setSelected(selectedOptions);
  };

  return (
    <>
      <form
        className="form-modal"
        onSubmit={handleSubmit(onSubmit)}
        style={{display: `${isModalOpen ? 'none' : 'block'}`}}
      >
        <div className="titulo-sessao">Dados pessoais</div>

        <div className="row">
          <div className="col-sm-4">
            <label className="form-label" htmlFor="input-cpf">
              CPF/CNPJ
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="cpfCnpj"
              type="text"
              placeholder=""
              id="input-cpf"
              inputRef={register({
                required: true,
                maxLength: 18,
                minLength: 14,
                validate: {
                  cpfCnpj: value => {
                    return cpfCnpjValidator(value);
                  },
                },
              })}
              erros={errors}
              inputMaxLength="18"
              mask={cpfcnpjMask}
            />
          </div>

          <div className="col-sm-4">
            <label className="form-label" htmlFor="input-nome">
              Nome ou Razão Social
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="nome"
              type="text"
              placeholder=""
              id="input-nome"
              inputRef={register({
                required: true,
              })}
              erros={errors}
              inputMaxLength={'100'}
              mask={noEmptyMask}
            />
          </div>

          <div className="col-sm-4">
            <label className="form-label" htmlFor="input-telefone">
              Telefone
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="telefone"
              type="text"
              id="input-telefone"
              inputRef={register({
                required: true,
                maxLength: 14,
                minLength: 13,
              })}
              erros={errors}
              inputMaxLength="14"
              mask={phoneMask}
            />
          </div>

          <div className="col-sm-6 mt-3">
            <label className="form-label" htmlFor="input-email">
              Email
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="email"
              type="text"
              placeholder=""
              id="input-email"
              inputMaxLength={'100'}
              inputRef={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Entre com um e-mail válido.',
                },
              })}
              erros={errors}
              mask={noEmptyMask}
            />
          </div>

          <div className="col-sm-6 mt-3">
            <label className="form-label" htmlFor="input-confirmacao-email">
              Confirme seu Email
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="confirmacaoEmail"
              type="text"
              placeholder=""
              inputMaxLength={'100'}
              id="input-confirmacao-email"
              inputRef={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Entre com um e-mail válido.',
                },
                validate: {
                  confirmacaoEmail: value => {
                    return validaConfirmacaoEmail(value);
                  },
                },
              })}
              erros={errors}
              mask={noEmptyMask}
            />
          </div>
        </div>

        <div className="col-12 mt-3 pl-0 row">
          <div className="form-group col-md-12 row d-flex align-items-center">
            <div className="col-12">
              <label className="form-label" htmlFor="grupos-clientes-select">
                Grupos de Clientes
              </label>
            </div>
            <div className="col-9">
              <Controller
                control={control}
                defaultValue={null}
                rules={{required: false}}
                id="grupos-clientes-select"
                name="grupos-clientes"
                render={({ref}) => (
                  <InputSelectGroups
                    isMulti={true}
                    placeholder=""
                    inputRef={ref}
                    id="grupos-clientes-select"
                    classNamePrefix="addl-class"
                    name="categoria"
                    options={grupos}
                    onChange={handleChange}
                  />
                )}
              />
            </div>
            <div>
              <Button
                onClick={() => {
                  setIsModalOpen(true);
                }}
                variant="custom"
                className="btn-custom btn-save"
              >
                <FaPlus size={18} className="mr-1" />
                Adicionar Grupo
              </Button>
            </div>
          </div>
        </div>

        <div className="titulo-sessao">Dados cobrança </div>

        <div className="row">
          <div className="col-sm-4 mt-3">
            <label className="form-label" htmlFor="input-cep">
              CEP
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="endereco.cep"
              type="text"
              id="input-cep"
              inputRef={register({
                required: true,
                maxLength: 9,
                minLength: 9,
              })}
              erros={errors}
              inputMaxLength="9"
              mask={cepMask}
              onBlur={e => getAddress(e)}
            />
          </div>

          <div className="col-sm-4 mt-3">
            <label className="form-label" htmlFor="input-endereco-completo">
              Endereço completo
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="endereco.logradouro"
              type="text"
              placeholder=""
              id="input-endereco-completo"
              inputRef={register({
                required: true,
              })}
              inputMaxLength={'100'}
              erros={errors}
              mask={noEmptyMask}
            />
          </div>

          <div className="col-sm-4 mt-3">
            <label className="form-label" htmlFor="input-endereco-numero">
              Nº
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="endereco.numero"
              type="text"
              placeholder=""
              id="input-endereco-numero"
              inputRef={register({
                required: true,
                maxLength: 5,
                minLength: 1,
              })}
              erros={errors}
              inputMaxLength="5"
              mask={noEmptyMask}
            />
          </div>

          <div className="col-sm-3 mt-3">
            <label className="form-label" htmlFor="input-endereco-complemento">
              Complemento <span className="label-opcional">(opcional)</span>
            </label>
            <InputDefault
              name="endereco.complemento"
              type="text"
              placeholder=""
              id="input-endereco-complemento"
              inputRef={register({
                required: false,
              })}
              inputMaxLength={'100'}
              erros={errors}
              mask={noEmptyMask}
            />
          </div>

          <div className="col-sm-3 mt-3">
            <label className="form-label" htmlFor="input-endereco-bairro">
              Bairro
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="endereco.bairro"
              type="text"
              placeholder=""
              id="input-endereco-bairro"
              inputRef={register({
                required: true,
              })}
              inputMaxLength={'100'}
              erros={errors}
              mask={noEmptyMask}
            />
          </div>

          <div className="col-sm-3 mt-3">
            <label className="form-label" htmlFor="input-endereco-cidade">
              Cidade
            </label>
            <span className="asterisk ">*</span>
            <InputDefault
              name="endereco.cidade"
              type="text"
              placeholder=""
              id="input-endereco-cidade"
              inputRef={register({
                required: true,
              })}
              inputMaxLength={'60'}
              erros={errors}
              mask={noEmptyMask}
            />
          </div>

          <div className="col-sm-2 mt-3">
            <InputEstado
              control={control}
              name="endereco.siglaEstado"
              inputClassName="form-select"
              isRequired={true}
              erros={errors}
              onChange={onChangeState}
              inputRef={register({
                required: true,
              })}
            />
          </div>
        </div>

        <div className="d-flex justify-content-center mt-4">
          <Button
            className="btn-custom btn-cancel"
            variant="custom"
            onClick={() => cancelForm()}
          >
            <FaBan size={13} className="mr-1" />
            Cancelar
          </Button>

          <Button
            type="submit"
            variant="custom"
            className="btn-custom btn-save"
          >
            <FaRegSave size={13} className="mr-1" />
            Salvar
          </Button>
        </div>
      </form>
      <div>
        <ModalDefaultCadastrarGrupo
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      </div>
    </>
  );
};

export default FormCadastroCliente;
