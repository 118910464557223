import React, {useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {FaBan, FaRegSave, FaUserPlus} from 'react-icons/fa';
import {toast} from 'react-toastify';

import {createGroup} from '../../../api';
import {IClientGroup} from '../../../models/client-group.model';
import noEmptyMask from '../../../utils/masks/noEmptyMask';
import InputDefault from '../../Inputs/InputDefault';
import Titulo from '../../Titulo';

interface ModalDefaultCadastrarGrupoProps {
  setIsModalOpen: Function;
  isModalOpen: boolean;
}

const ModalDefaultCadastrarGrupo = ({
  setIsModalOpen,
  isModalOpen,
}: ModalDefaultCadastrarGrupoProps) => {
  const {register, handleSubmit, errors} = useForm<IClientGroup>();
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseClientGroup = () => {
    setIsModalOpen(false);
  };

  const onSubmit = async (data: IClientGroup) => {
    const newGroup: IClientGroup = {
      name: data.name,
      description: data.description,
    };

    await saveGroup(newGroup);
  };

  async function saveGroup(group: IClientGroup) {
    try {
      setIsLoading(true);
      await createGroup(group);

      toast.success('Grupo cadastrado com sucesso!', {autoClose: 2500});
    } catch (error) {
      toast.error(error, {autoClose: 2500});
    }
    setIsLoading(false);
  }

  return (
    <>
      <Modal
        animation={false}
        show={isModalOpen}
        onHide={handleCloseClientGroup}
        backdrop={false}
        keyboard={true}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {<FaUserPlus size={20} className="modal-icone" />}
            Adicionar Grupo de Clientes
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form className="form-modal" onSubmit={handleSubmit(onSubmit)}>
            <Titulo>Detalhes do Grupo</Titulo>
            <div className="row">
              <div className="col-sm-4">
                <label className="form-label" htmlFor="input-group-name">
                  Nome do Grupo
                </label>
                <span className="asterisk ">*</span>
                <InputDefault
                  name="name"
                  type="text"
                  placeholder=""
                  id="input-nome"
                  inputRef={register({
                    required: true,
                  })}
                  erros={errors}
                  inputMaxLength={'25'}
                  mask={noEmptyMask}
                />
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-12">
                <label className="form-label" htmlFor="input-confirmacao-email">
                  Descrição
                </label>
                <span className="asterisk ">*</span>
                <InputDefault
                  name="description"
                  type="text"
                  placeholder=""
                  inputMaxLength={'150'}
                  id="input-descricao"
                  inputRef={register({
                    required: true,
                  })}
                  erros={errors}
                  mask={noEmptyMask}
                />
              </div>
            </div>

            <div className="d-flex justify-content-center mt-4">
              <Button
                className="btn-custom btn-cancel"
                variant="custom"
                onClick={handleCloseClientGroup}
              >
                <FaBan size={13} className="mr-1" />
                Cancelar
              </Button>

              <Button
                type="submit"
                variant="custom"
                className="btn-custom btn-save"
                disabled={isLoading}
              >
                <FaRegSave size={13} className="mr-1" />
                Salvar
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalDefaultCadastrarGrupo;
