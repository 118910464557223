import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import {toggleClientActive} from '../../../api';
import './styles.scss';

interface BtnSwitchProps {
  clientId: string;
  checked: boolean;
}

const handleChange = async (
  key: string,
  isDisabled: boolean,
  setDisabled: {
    (value: React.SetStateAction<boolean>): void;
    (arg0: boolean): void;
  }
) => {
  if (!isDisabled) {
    setDisabled(true);
    await toggleClientActive(key);
    setDisabled(false);
  }
};

const BtnSwitch: React.FC<BtnSwitchProps> = ({clientId, checked}) => {
  const [isDisabled, setDisabled] = useState<boolean>(false);
  return (
    <>
      <Form.Check
        type="switch"
        id={clientId}
        key={clientId}
        label=""
        disabled={isDisabled}
        defaultChecked={checked}
        onChange={() => handleChange(clientId, isDisabled, setDisabled)}
      />{' '}
    </>
  );
};

export default BtnSwitch;
