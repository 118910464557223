import React, {
  InputHTMLAttributes,
  LegacyRef,
  ChangeEventHandler,
  FocusEventHandler,
} from 'react';
import {FieldErrors} from 'react-hook-form';

import './styles.scss';
import Errors from '../../Errors';

interface InputDefaultProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type: string;
  placeholder?: string;
  id?: string;
  className?: string;
  inputRef?: LegacyRef<HTMLInputElement>;
  erros?: FieldErrors;
  inputMaxLength?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  mask?: (value: string) => string;
  defaultValue?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  inputGroupText?: string;
  inputMinLengthNumber?: string;
  disabledInput?: boolean;
  readOnly?: boolean;
  inputMinLength?: string;
}

const InputDefault: React.FC<InputDefaultProps> = ({
  onBlur,
  name,
  type,
  placeholder,
  className = '',
  id,
  inputRef,
  defaultValue,
  erros,
  onChange,
  inputMaxLength = '1000',
  mask = (value: string) => value,
  inputGroupText,
  inputMinLengthNumber = '0',
  readOnly,
  inputMinLength = '',
  disabledInput,
}) => {
  const aplicarMask = e => (e.target.value = `${mask(e.target.value)}`);

  const onChangeTrigger = e => {
    if (onChange) {
      onChange(e);
    }
    aplicarMask(e);
  };

  const onBlurTrigger = e => {
    if (onBlur) {
      onBlur(e);
    }
    aplicarMask(e);
  };

  return (
    <div className="input-group input-group--stylized">
      {inputGroupText && (
        <div className="input-group-text">{inputGroupText}</div>
      )}
      <input
        defaultValue={defaultValue}
        name={name}
        className={`input-default ${className}`}
        type={type}
        placeholder={placeholder}
        id={id}
        ref={inputRef}
        readOnly={readOnly}
        maxLength={Number(inputMaxLength)}
        minLength={Number(inputMinLength)}
        min={Number(inputMinLengthNumber)}
        disabled={disabledInput}
        onChange={e => {
          onChangeTrigger(e);
        }}
        onBlur={e => {
          onBlurTrigger(e);
        }}
      />

      <Errors
        erros={erros}
        name={name}
        inputMaxLength={inputMaxLength}
        inputMinLengthNumber={inputMinLengthNumber}
        inputMinLength={inputMinLength}
      />
    </div>
  );
};

export default InputDefault;
