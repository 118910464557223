import React, {MouseEventHandler, useEffect, useState} from 'react';
import './styles.scss';
import {Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {FaBan, FaArrowRight, FaPen} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

import {putClientsInGroup, updateGroupApi} from '../../../api';
import {IClientGroup} from '../../../models/client-group.model';
import noEmptyMask from '../../../utils/masks/noEmptyMask';
import BtnDefault from '../../Buttons/BtnDefault';
import InputDefault from '../../Inputs/InputDefault';
import Titulo from '../../Titulo';
import FormSecondStepGroupRegister from '../FormRegisterGroup/FormSecondStepGroupRegister';

interface IModalEditGroupProps {
  group: IClientGroup;
  handleUpdateTable?: Function;
  action?: MouseEventHandler;
  showEditOrView?: boolean;
  secondStageParam?: boolean;
  avoidRedirect?: boolean;
}

const FormEditClientGroup: React.FC<IModalEditGroupProps> = ({
  group,
  handleUpdateTable,
  showEditOrView,
  secondStageParam = false,
  avoidRedirect = false,
}: IModalEditGroupProps) => {
  const {register, handleSubmit, errors} = useForm();
  const [canEdit, setCanEdit] = useState(showEditOrView);
  const [secondStage, setSecondStage] = useState<boolean>(secondStageParam);
  const [disabledNextButton, setDisabledNextButton] = useState<boolean>(false);
  const [savedClientsIds] = useState<Set<string>>(new Set<string>());
  const history = useHistory();

  const handleUpdateGroup = async (data: IClientGroup) => {
    const editedGroup = {
      id: group.id,
      name: data.name,
      description: data.description,
      clients: [],
    };

    try {
      setDisabledNextButton(true);
      await updateGroupApi(editedGroup);
      setCanEdit(true);
      toast.success('Grupo editado com sucesso! ', {autoClose: 2500});
      setSecondStage(true);
    } catch (e) {
      toast.error(e, {autoClose: 2500});
    }
  };

  const closeModal = () => {
    handleUpdateTable(true);
  };

  const updateClients = async (clients: Set<string>) => {
    try {
      if (group.id) {
        await putClientsInGroup(group.id, clients);

        toast.success('Clientes salvos com sucesso!', {autoClose: 2500});

        if (!avoidRedirect) history.push('/grupos-cliente');
        closeModal();
      }
    } catch (e) {
      toast.error(e, {autoClose: 2500});
    }
  };

  const toggleEditing = () => {
    setCanEdit(true);
  };

  useEffect(() => {
    for (const client of group.clients) {
      savedClientsIds.add(client.id);
    }
  }, []);

  return (
    <div>
      {!secondStage ? (
        <form className="form-modal" onSubmit={handleSubmit(handleUpdateGroup)}>
          {canEdit ? (
            <Titulo>Passo 1/2 - Detalhes do Grupo</Titulo>
          ) : (
            <Titulo>Detalhes do Grupo</Titulo>
          )}

          <div className="row">
            <div className="col-sm-4">
              <label className="form-label" htmlFor="input-group-name">
                Nome do Grupo
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="name"
                type="text"
                placeholder=""
                id="input-nome"
                inputRef={register({
                  required: true,
                })}
                erros={errors}
                inputMaxLength={'25'}
                mask={noEmptyMask}
                disabledInput={!canEdit}
                defaultValue={group.name}
              />
            </div>
          </div>

          <br />

          <div className="row">
            <div className="col-12">
              <label className="form-label" htmlFor="input-confirmacao-email">
                Descrição
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="description"
                type="text"
                placeholder=""
                inputMaxLength={'150'}
                id="input-descricao"
                inputRef={register({
                  required: true,
                })}
                erros={errors}
                mask={noEmptyMask}
                disabledInput={!canEdit}
                defaultValue={group.description}
              />
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <Button
              className="btn-custom btn-cancel"
              variant="custom"
              onClick={() => closeModal()}
            >
              <FaBan size={13} className="mr-1" />
              Cancelar
            </Button>

            {canEdit ? (
              <BtnDefault
                type="submit"
                variantClass="custom"
                className="btn-custom btn-save"
                disabled={disabledNextButton}
              >
                <FaArrowRight size={13} className="mr-1" />
                Próximo
              </BtnDefault>
            ) : (
              <Button
                type="button"
                variant="custom"
                className="btn-custom btn-save"
                onClick={toggleEditing}
              >
                <FaPen size={13} className="mr-1" />
                Editar
              </Button>
            )}
          </div>
        </form>
      ) : (
        <FormSecondStepGroupRegister
          handleCloseModal={closeModal}
          handleUpdateGroup={updateClients}
          clientsIdsAlreadyInserted={savedClientsIds}
          groupId={group.id}
        />
      )}
    </div>
  );
};

export default FormEditClientGroup;
