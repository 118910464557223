import React, {useState} from 'react';
import {FaEye, FaEyeSlash, FaRegQuestionCircle} from 'react-icons/fa';
import './styles.scss';

import Tooltip from '../../Tooltip';

type SaldoCardProps = {
  value: string;
};

const SaldoCard = ({value}: SaldoCardProps) => {
  const [saldo, setSaldo] = useState<number>(1250);
  const [hidden, setHidden] = useState<boolean>(true);

  const updateSaldo = () => {
    //TODO get saldo from API
    const novoSaldo = 1000;
    setSaldo(novoSaldo);
  };

  return (
    <>
      <div className="card-saldo__body col-md-12">
        <div className="card-saldo__header">
          <label className="card-saldo__label">Saldo</label>
          <Tooltip data="Saldo referente ao valor contido na Carteira Digital">
            <FaRegQuestionCircle size={20} className="card-saldo__help" />
          </Tooltip>
        </div>
        <div className="input-hide" onClick={() => setHidden(!hidden)}>
          {hidden ? (
            <>
              <label className="card-saldo__valor mb-0">
                <FaEyeSlash size={30} className="card-saldo__input" />
                R$ {value}
              </label>
            </>
          ) : (
            <>
              <div className="card-saldo__valor mb-0">
                <FaEye size={30} className="card-saldo__input" />
                ***
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SaldoCard;
