import React from 'react';
import {useHistory} from 'react-router-dom';

import './styles.scss';
import BtnDefault from '../../components/Buttons/BtnDefault';
import UIContainer from '../../components/UI/Container';
import {useAuth} from '../../context/usuario-context';
import {isMaster} from '../../utils/validations/userIsAdminstrator';

const NotFound: React.FC = () => {
  const {usuarioLogado} = useAuth();

  const history = useHistory();
  const redirectHome = () => {
    if (!isMaster(usuarioLogado)) {
      history.push('/dashboard');
    } else {
      history.push('/termos-uso');
    }
  };
  return (
    <UIContainer>
      <h1 className="container__error">404</h1>
      <h2 className="container__text mt-3">Oops! Página não encontrada</h2>
      <div className="container_404">
        <div className="container__content mt-5">
          <p>
            A página anterior está tentando te redirecionar para uma
            inexistente!
          </p>

          <p className="container__content">
            Se você não quiser visitar essa página, poderá voltar à anterior ou
            clicar em Home.
          </p>
        </div>
      </div>
      <div className="container__btn">
        <BtnDefault
          action={redirectHome}
          className="btn-custom btn-save"
          variantClass="custom"
        >
          {!isMaster(usuarioLogado) ? '  Home' : '  Termos de uso'}
        </BtnDefault>
      </div>
    </UIContainer>
  );
};

export default NotFound;
