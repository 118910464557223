import React, {useRef, useState} from 'react';
import {Overlay, Popover} from 'react-bootstrap';
import {FaEllipsisV, FaPen, FaTrashAlt, FaRegEye} from 'react-icons/fa';

import {IClientGroup} from '../../../models/client-group.model';
import {ModalEnumClientGroup} from '../../MainClientGroup';

interface TableRowProps {
  clientGroup: IClientGroup;
  showModal: Function;
  deleteCall: Function;
}

const TableRowClienteGroup: React.FC<TableRowProps> = ({
  clientGroup,
  showModal,
  deleteCall,
}) => {
  const ref = useRef(null);

  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleClosepop = () => setShow(false);

  return (
    <>
      <tr key={clientGroup.id}>
        <td className="left">{clientGroup.name}</td>
        <td className="left">{clientGroup.description}</td>
        <td>{clientGroup.clients}</td>
        <td>
          <FaEllipsisV style={{cursor: 'pointer'}} onClick={handleClick} />
          <Overlay
            show={show}
            target={target}
            placement="bottom"
            container={ref.current}
            containerPadding={20}
            onHide={handleClosepop}
            rootClose={true}
            transition={false}
          >
            <Popover id="popover-contained">
              <Popover.Title as="h3">{'Ações'}</Popover.Title>
              <Popover.Content>
                <span className="icones-popover">
                  <span
                    className="icones-popover__text"
                    onClick={() => deleteCall(clientGroup.id)}
                  >
                    <FaTrashAlt className="icones-popover__icone" size="18" />

                    <span className="icones-popover__text">Excluir</span>
                  </span>

                  <span
                    onClick={() => {
                      showModal(
                        clientGroup.id,
                        true,
                        ModalEnumClientGroup.EDIT_GROUP
                      );
                    }}
                  >
                    <FaPen className="icones-popover__icone" size="18" />
                    <span className="icones-popover__text">Editar</span>
                  </span>

                  <span
                    onClick={() => {
                      showModal(
                        clientGroup.id,
                        false,
                        ModalEnumClientGroup.EDIT_GROUP
                      );
                    }}
                  >
                    <FaRegEye className="icones-popover__icone" size="18" />
                    <span className="icones-popover__text">Ver</span>
                  </span>
                </span>
              </Popover.Content>
            </Popover>
          </Overlay>
        </td>
      </tr>
    </>
  );
};

export default TableRowClienteGroup;
