import {toast} from 'react-toastify';

export function formataDinheiro(valor: Number | String) {
  try {
    if (valor === undefined) {
      return valor;
    }
    valor = Number(valor);
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  } catch {
    return toast.error('Erro na formatação da data.', {autoClose: 2500});
  }
}
