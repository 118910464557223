import {IPlanoContaFinanceira} from 'models/conta-financeiro';

import {ICreateContaFinanceira} from '../models/auth-model';
import api from './api';

export const casdastrarContaFinanceira = async (
  contafinanceira: ICreateContaFinanceira
) => {
  contafinanceira.partnerID = process.env.REACT_APP_PARTNER_ID;
  return (
    await api.post<ICreateContaFinanceira>(
      '/contas-financeiras',
      contafinanceira
    )
  )?.data;
};

export const getDadosPlanoContaFinanceira = async () => {
  return (
    await api.get<IPlanoContaFinanceira>(
      '/contas-financeiras/informacoes-plano'
    )
  )?.data;
};

export const getVerificacaoDocumento = async () => {
  return (await api.get<boolean>('/contas-financeiras/verificacao-documento'))
    ?.data;
};

export const verificarChavePix = async () => {
  return (await api.get<boolean>('/contas-financeiras/verificar-chave-pix'))
    ?.data;
};
