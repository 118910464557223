export default function moneyMask(rawNum: string) {
  rawNum = rawNum.replace(',', '');
  rawNum = rawNum.replace('.', '');
  return formatarMoeda(rawNum);
}

export const newMoneyMask = (rawNum: string) => {
  rawNum = rawNum.replace(',', '');
  rawNum = rawNum.replace('.', '');
  return newFormatMoney(rawNum);
};

const newFormatMoney = (rawNum: string) => {
  rawNum = rawNum.replace(/\D/g, '');

  let value = rawNum;

  if (rawNum.length > 2) {
    const firstDigits = rawNum.slice(0, -2);
    const lastDigits = rawNum.slice(-2);

    value = firstDigits + ',' + lastDigits;
  }

  return value;
};

function formatarMoeda(rawNum: string) {
  rawNum = rawNum.replace(/\D/g, '');

  let valor = rawNum;

  if (rawNum.length > 2) {
    let firstDigits = rawNum.slice(0, -2);
    const lastDigits = rawNum.slice(-2);

    firstDigits =
      firstDigits.length <= 6
        ? firstDigits.replace(/^(\d{1,3})(\d{3})/g, '$1.$2')
        : firstDigits.replace(/^(\d{1,3})(\d{3})(\d{3})/, '$1.$2.$3');

    const firstDigitsArray = firstDigits.split('');
    if (+firstDigitsArray[0] === 0) {
      const zero = firstDigitsArray[0];
      firstDigitsArray.shift();
      valor = zero + ',' + firstDigitsArray.join('') + lastDigits;
    } else {
      valor = firstDigits + ',' + lastDigits;
    }
  }

  return valor;
}
