import {PaginationModel} from 'models/pagination-model';
import {ITermosUso, INovoTermoUso} from 'models/u4Cripto-model';
import api from './api';

export const listarTermosUso = async (page = 0, limit = 10) => {
  return (
    await api.get<PaginationModel>(`/termos-de-uso?page=${page}&limit=${limit}`)
  )?.data;
};

export const deletarTermoDeUso = async (id: string) => {
  return await api.delete<ITermosUso>(`/termos-de-uso/${id}`);
};

export const cadastrarTermoUso = async (termoUso: INovoTermoUso) => {
  const params = {...termoUso};
  return (await api.post<INovoTermoUso>('/termos-de-uso', params))?.data;
};
