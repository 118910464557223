import React, {useEffect, useState} from 'react';
import {
  FaChevronDown,
  FaChevronRight,
  FaCog,
  FaRegChartBar,
  FaRegListAlt,
  FaRegStickyNote,
  FaUserFriends,
  FaRegFileAlt,
} from 'react-icons/fa';
import {NavLink} from 'react-router-dom';

import {ultimoTermo} from '../../api';
import Logo from '../../components/Logo';
import {useAuth} from '../../context';
import {ITermo} from '../../models/termo-model';
import {
  isAdministrador,
  isMaster,
} from '../../utils/validations/userIsAdminstrator';

import './styles.scss';

function Navbar() {
  const {usuarioLogado} = useAuth();

  const [mostrarMenuCobranca, setMenuCobranca] = useState(false);
  const showMenuCobranca = () => setMenuCobranca(!mostrarMenuCobranca);

  const [mostrarMenuCobrancasAvulsas, setMenuCobrancasAvulsas] = useState(
    false
  );
  const showMenuCobrancasAvulsas = () =>
    setMenuCobrancasAvulsas(!mostrarMenuCobrancasAvulsas);

  const [mostrarMenuCobrancasEmLote, setMenuCobrancasEmLote] = useState(false);
  const showMenuCobrancasEmLote = () =>
    setMenuCobrancasEmLote(!mostrarMenuCobrancasEmLote);

  const [mostrarMenuClientes, setMenuClientes] = useState(false);
  const showMenuClientes = () => setMenuClientes(!mostrarMenuClientes);

  const [termoUso, setTermoUso] = useState<ITermo>();

  const [mostrarMenuConfiguracoes, setMenuConfiguracoes] = useState(false);
  const showMenuConfiguracoes = () => {
    setMenuConfiguracoes(!mostrarMenuConfiguracoes);
  };

  const getUltimotermo = async () => {
    const termoUso = await ultimoTermo();
    setTermoUso(termoUso);
  };

  useEffect(() => {
    getUltimotermo();
    return () => {
      setTermoUso({});
    };
  }, []);

  return (
    <>
      <nav className="nav nav--active">
        <ul className="nav__menu-items">
          <li className="nav__logo">
            <NavLink
              to={isMaster(usuarioLogado) ? '/termos-uso' : '/dashboard'}
              isActive={(match, location) => {
                if (match) {
                  return location?.pathname.startsWith('/dashboard');
                }
              }}
              className="nav-link-logo"
            >
              <Logo src={process.env.REACT_APP_LOGO_ADDRESS} />
            </NavLink>
          </li>
          {usuarioLogado && isMaster(usuarioLogado) && (
            <li className="nav__text">
              <NavLink
                to="/termos-uso"
                isActive={(match, location) => {
                  if (match) {
                    return location?.pathname.startsWith('/termos-uso');
                  }
                }}
                activeClassName="nav__text--selected"
              >
                <FaRegStickyNote size={20} />
                <span>Termos de uso</span>
              </NavLink>
            </li>
          )}
          {!isMaster(usuarioLogado) && (
            <>
              <li className="nav__text">
                <NavLink
                  to="/dashboard"
                  isActive={(match, location) => {
                    if (match) {
                      return location?.pathname.startsWith('/dashboard');
                    }
                  }}
                  activeClassName="nav__text--selected"
                >
                  <FaRegChartBar size={20} />
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li className="nav__text">
                <NavLink
                  to="#"
                  onClick={showMenuCobrancasAvulsas}
                  isActive={(match, location) => {
                    if (
                      match &&
                      location?.pathname.startsWith('/busca-cobrancas')
                    ) {
                      return location?.pathname.startsWith('/busca-cobrancas');
                    } else if (
                      match &&
                      location?.pathname.startsWith(
                        '/emitir-cobranca/dados-cobranca'
                      )
                    ) {
                      return location?.pathname.startsWith(
                        '/emitir-cobranca/dados-cobranca'
                      );
                    } else if (
                      match &&
                      location?.pathname.startsWith('/categorias')
                    ) {
                      return location?.pathname.startsWith('/categorias');
                    }
                  }}
                  activeClassName="nav__text--selected"
                >
                  <FaRegListAlt size={20} />
                  <span>Cobranças Avulsas</span>
                  {mostrarMenuCobrancasAvulsas ? (
                    <FaChevronDown size={15} className="ml-2" />
                  ) : (
                    <FaChevronRight size={15} className="ml-2" />
                  )}
                </NavLink>
              </li>
              {mostrarMenuCobrancasAvulsas ? (
                <>
                  <li className="nav__text__subgrupo">
                    <NavLink
                      to="/emitir-cobranca/dados-cobranca"
                      isActive={(match, location) => {
                        if (match) {
                          return location?.pathname.startsWith(
                            '/emitir-cobranca/dados-cobranca'
                          );
                        }
                      }}
                      activeClassName="nav__text--selected"
                    >
                      <span>Emitir Cobranças</span>
                    </NavLink>
                  </li>

                  <li className="nav__text__subgrupo">
                    <NavLink
                      to="/busca-cobrancas"
                      isActive={(match, location) => {
                        if (match) {
                          return location?.pathname.startsWith(
                            '/busca-cobrancas'
                          );
                        }
                      }}
                      activeClassName="nav__text--selected"
                    >
                      <span>Cobranças Emitidas</span>
                    </NavLink>
                  </li>

                  <li className="nav__text__subgrupo">
                    <NavLink
                      to="/categorias"
                      isActive={(match, location) => {
                        if (match) {
                          return location?.pathname.startsWith('/categorias');
                        }
                      }}
                      activeClassName="nav__text--selected"
                    >
                      <span>Categorias de Cobranças</span>
                    </NavLink>
                  </li>
                </>
              ) : (
                false
              )}
              <li className="nav__text">
                <NavLink
                  to="#"
                  onClick={showMenuCobrancasEmLote}
                  isActive={(match, location) => {
                    if (match && location?.pathname.startsWith('/lote')) {
                      return location?.pathname.startsWith('/lote');
                    } else if (
                      match &&
                      location?.pathname.startsWith('/consulta-lotes')
                    ) {
                      return location?.pathname.startsWith('/consulta-lotes');
                    } else if (
                      match &&
                      location?.pathname.startsWith('/regras-cobranca')
                    ) {
                      return location?.pathname.startsWith('/regras-cobranca');
                    }
                  }}
                  activeClassName="nav__text--selected"
                >
                  <FaRegListAlt size={20} />
                  <span>Cobranças em Lote</span>
                  {mostrarMenuCobrancasEmLote ? (
                    <FaChevronDown size={15} className="ml-2" />
                  ) : (
                    <FaChevronRight size={15} className="ml-2" />
                  )}
                </NavLink>
              </li>
              {mostrarMenuCobrancasEmLote ? (
                <>
                  <li className="nav__text__subgrupo">
                    <NavLink
                      to="/lote"
                      isActive={(match, location) => {
                        if (match) {
                          return location?.pathname.startsWith('/lote');
                        }
                      }}
                      activeClassName="nav__text--selected"
                    >
                      <span>Emitir Cobranças</span>
                    </NavLink>
                  </li>

                  <li className="nav__text__subgrupo">
                    <NavLink
                      to="/consulta-lotes"
                      isActive={(match, location) => {
                        if (match) {
                          return location?.pathname.startsWith(
                            '/consulta-lotes'
                          );
                        }
                      }}
                      activeClassName="nav__text--selected"
                    >
                      <span>Consultar Lotes</span>
                    </NavLink>
                  </li>

                  <li className="nav__text__subgrupo">
                    <NavLink
                      to="/regras-cobranca"
                      isActive={(match, location) => {
                        if (match) {
                          return location?.pathname.startsWith(
                            '/regras-cobranca'
                          );
                        }
                      }}
                      activeClassName="nav__text--selected"
                    >
                      <span>Regras de Lote</span>
                    </NavLink>
                  </li>
                </>
              ) : (
                false
              )}
            </>
          )}
          {!isMaster(usuarioLogado) && (
            <li className="nav__text ">
              <NavLink
                to="#"
                onClick={showMenuClientes}
                isActive={(match, location) => {
                  if (match && location?.pathname.startsWith('/clientes')) {
                    return location?.pathname.startsWith('/clientes');
                  }
                  if (
                    match &&
                    location?.pathname.startsWith('/grupos-cliente')
                  ) {
                    return location?.pathname.startsWith('/grupos-cliente');
                  }
                }}
                activeClassName="nav__text--selected"
              >
                <FaUserFriends size={20} />
                <span>Clientes</span>
                {mostrarMenuClientes ? (
                  <FaChevronDown size={15} className="ml-2" />
                ) : (
                  <FaChevronRight size={15} className="ml-2" />
                )}
              </NavLink>
            </li>
          )}
          {mostrarMenuClientes ? (
            <>
              <li className="nav__text__subgrupo">
                <NavLink
                  to="/clientes"
                  isActive={(match, location) => {
                    if (match) {
                      return location?.pathname.startsWith('/clientes');
                    }
                  }}
                  activeClassName="nav__text--selected"
                >
                  <span>Meus Clientes</span>
                </NavLink>
              </li>
              <li className="nav__text__subgrupo">
                <NavLink
                  to="/grupos-cliente"
                  isActive={(match, location) => {
                    if (match) {
                      return location?.pathname.startsWith('/grupos-cliente');
                    }
                  }}
                  activeClassName="nav__text--selected"
                >
                  <span>Grupos de Clientes</span>
                </NavLink>
              </li>
            </>
          ) : (
            false
          )}
          {!isMaster(usuarioLogado) && usuarioLogado && (
            <li className="nav__text">
              <NavLink
                to="#"
                onClick={showMenuConfiguracoes}
                isActive={(match, location) => {
                  if (match && location?.pathname.startsWith('/usuarios')) {
                    return location?.pathname.startsWith('/usuarios');
                  }
                }}
                activeClassName="nav__text--selected"
              >
                <FaCog size={20} />
                <span>Configurações</span>
                {mostrarMenuConfiguracoes ? (
                  <FaChevronDown size={15} className="ml-2" />
                ) : (
                  <FaChevronRight size={15} className="ml-2" />
                )}
              </NavLink>
            </li>
          )}
          {usuarioLogado &&
            isAdministrador(usuarioLogado) &&
            mostrarMenuConfiguracoes && (
              <li className="nav__text__subgrupo">
                <NavLink
                  to="/usuarios"
                  isActive={(match, location) => {
                    if (match) {
                      return location?.pathname.startsWith('/usuarios');
                    }
                  }}
                  activeClassName="nav__text--selected"
                >
                  <span>Gestão de Usuários</span>
                </NavLink>
              </li>
            )}
        </ul>
      </nav>
      {!isMaster(usuarioLogado) && (
        <div className="nav__footer">
          <div className="nav__footer__content">
            <li>
              <a
                href={termoUso?.descricao}
                rel="noopener noreferrer"
                target="__blank"
              >
                <FaRegFileAlt className="mr-2" />
                Termos e Privacidade
              </a>
            </li>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
