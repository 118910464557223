import React, {useEffect, useState} from 'react';
import {InputGroup, Table} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {
  FaBroom,
  FaCalendar,
  FaSearch,
  FaSortAlphaDown,
  FaSortAlphaUpAlt,
  FaSortAmountDown,
  FaSortAmountUpAlt,
  FaSortNumericDown,
  FaSortNumericUpAlt,
} from 'react-icons/fa';

import {listBatchesApi} from '../../api';
import BtnDefault from '../../components/Buttons/BtnDefault';
import DatePickerBr from '../../components/DatePickerBr';
import InputDefault from '../../components/Inputs/InputDefault';
import PaginationDefault from '../../components/PaginationDefault';
import SpinnerLoader from '../../components/Spinner';
import TableRowsBillingBatch from '../../components/TableRows/TableRowsBillingBatch';
import Titulo from '../../components/Titulo';
import UIContainer from '../../components/UI/Container';
import UIContent from '../../components/UI/Content';
import {IBatchParams, IBillingBatch} from '../../models/billing-batch.model';
import noEmptyMask from '../../utils/masks/noEmptyMask';
import {BilletBatchOrderByEnum} from '../BillingBatch/enum';

const BillingBatches: React.FC = () => {
  const {handleSubmit, register, reset} = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(30);
  const [batches, setBatches] = useState<IBillingBatch[]>([]);
  const [batchNumber, setBatchNumber] = useState<string>('');
  const [ruleName, setRuleName] = useState<string>('');
  const [dueDateStart, setDueDateStart] = useState<string>();
  const [dueDateEnd, setDueDateEnd] = useState();
  const [orderSort, setOrderSort] = useState(false);
  const [orderBySort, setOrderBySort] = useState(BilletBatchOrderByEnum.NUMBER);
  const [updateTable, setUpdateTable] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const listBatches = async (
    page?: number,
    limit?: number,
    batch?: string,
    dueDateStart?: string,
    dueDateEnd?: string,
    clientGroupName?: string,
    order?: string,
    orderBy?: BilletBatchOrderByEnum
  ) => {
    setIsLoading(true);
    const params: IBatchParams = {
      page: page && page > 0 ? page - 1 : 0,
      limit: limit ? limit : 30,
      batch: batch ? batch : '',
      dueDateStart: dueDateStart ? dueDateStart : '',
      dueDateEnd: dueDateEnd ? dueDateEnd : '',
      clientGroupName: clientGroupName ? clientGroupName : '',
      order,
      orderBy,
    };

    const response = await listBatchesApi(params);

    setBatches(response.data);
    setTotalCount(response.totalCount);

    setIsLoading(false);
  };

  useEffect(() => {
    const order = orderSort ? 'ASC' : 'DESC';
    listBatches(
      pageNumber,
      limit,
      batchNumber,
      dueDateStart ? dueDateStart.toISOString() : '',
      dueDateEnd ? dueDateEnd.toISOString() : '',
      ruleName,
      order,
      orderBySort
    );
  }, [orderSort, orderBySort, limit, pageNumber, updateTable]);

  const filter = async () => {
    const order = orderSort ? 'ASC' : 'DESC';
    await listBatches(
      pageNumber,
      limit,
      batchNumber,
      dueDateStart ? dueDateStart.toISOString() : '',
      dueDateEnd ? dueDateEnd.toISOString() : '',
      ruleName,
      order,
      orderBySort
    );
  };

  const clearFilters = async () => {
    reset();
    setDueDateStart('');
    setDueDateEnd('');
    setRuleName('');
    setBatchNumber('');

    await filter();
  };

  return (
    <UIContainer>
      <UIContent>
        <div className="d-flex bd-highlight titleAndButton">
          <div className="p-2 flex-grow-1 bd-highlight">
            <Titulo> Consultar Lotes </Titulo>
          </div>
        </div>

        <form onSubmit={handleSubmit(filter)}>
          <div className="form-row ml-2">
            <div className="form-group col-md-4">
              <InputDefault
                name="batch-number"
                type="text"
                placeholder="Número do Lote"
                id="input-batch-number"
                onChange={e => setBatchNumber(e.target.value)}
                value={batchNumber}
                inputMaxLength="15"
                inputRef={register({})}
                mask={noEmptyMask}
              />
            </div>

            <div className="form-group col-md-4">
              <InputDefault
                name="rule-name"
                type="text"
                placeholder="Grupo de Clientes"
                id="input-rule-name"
                onChange={e => setRuleName(e.target.value)}
                value={ruleName}
                inputMaxLength="50"
                inputRef={register({})}
                mask={noEmptyMask}
              />
            </div>

            <label className="col-md-6">
              Vencimento
              <div className="form-group col-md-14 d-flex boleto-datapicker">
                <InputGroup.Text className="input-default input-custom-left">
                  <FaCalendar />
                </InputGroup.Text>
                <DatePickerBr
                  className="input-custom-middle"
                  placeholder="Quando?"
                  selected={dueDateStart}
                  onChange={date => setDueDateStart(date)}
                  classIcon="calendar-position "
                />
                <DatePickerBr
                  className="input-custom-right"
                  placeholder="Até"
                  selected={dueDateEnd}
                  onChange={date => setDueDateEnd(date)}
                  classIcon="calendar-position "
                />
              </div>
            </label>
          </div>

          <div className="d-flex justify-content-start">
            <BtnDefault
              name="btn-buscar"
              className="btn-custom btn-save"
              id="btn-buscar"
              type="submit"
              variantClass="custom"
            >
              <FaSearch size={12} className="mr-1" />
              Buscar
            </BtnDefault>
            <BtnDefault
              action={clearFilters}
              name="btn-limpar"
              id="btn-limpar"
              className="btn-custom btn-save p-2 flex-grow-1 bd-highlight"
              variantClass="custom"
            >
              <FaBroom size={12} className="mr-1" />
              Limpar filtros
            </BtnDefault>
          </div>
        </form>
        {isLoading && !batches ? (
          <SpinnerLoader />
        ) : (
          <Table responsive hover>
            <thead className="table-header">
              <tr>
                <th>
                  <div
                    className={
                      orderBySort === BilletBatchOrderByEnum.NUMBER
                        ? 'thContainerActive'
                        : 'thContainer'
                    }
                    onClick={() => {
                      setOrderSort(!orderSort);
                      setOrderBySort(BilletBatchOrderByEnum.NUMBER);
                      setSelectedValue('');
                    }}
                  >
                    {orderSort &&
                    orderBySort === BilletBatchOrderByEnum.NUMBER ? (
                      <FaSortAmountDown size={20} />
                    ) : orderBySort !== BilletBatchOrderByEnum.NUMBER ? (
                      <FaSortAmountDown size={20} />
                    ) : (
                      <FaSortAmountUpAlt size={20} />
                    )}
                    N° e data de Geração do Lote
                  </div>
                </th>
                <th>
                  <div className="th">Nº de cobranças no lote</div>
                </th>
                <th>
                  <div
                    className={
                      orderBySort === BilletBatchOrderByEnum.GROUP
                        ? 'thContainerActive'
                        : 'thContainer'
                    }
                    onClick={() => {
                      setOrderSort(!orderSort);
                      setOrderBySort(BilletBatchOrderByEnum.GROUP);
                      setSelectedValue('');
                    }}
                  >
                    {orderSort &&
                    orderBySort === BilletBatchOrderByEnum.GROUP ? (
                      <FaSortAlphaDown size={20} />
                    ) : orderBySort !== BilletBatchOrderByEnum.GROUP ? (
                      <FaSortAlphaDown size={20} />
                    ) : (
                      <FaSortAlphaUpAlt size={20} />
                    )}
                    Grupos de Clientes
                  </div>
                </th>
                <th>
                  <div
                    className={
                      orderBySort === BilletBatchOrderByEnum.VALUE &&
                      selectedValue === 'valorTotal'
                        ? 'thContainerActive'
                        : 'thContainer'
                    }
                    onClick={() => {
                      setOrderSort(!orderSort);
                      setOrderBySort(BilletBatchOrderByEnum.VALUE);
                      setSelectedValue('valorTotal');
                    }}
                  >
                    {selectedValue === 'valorTotal' ? (
                      orderSort ? (
                        <FaSortNumericDown size={20} />
                      ) : (
                        <FaSortNumericUpAlt size={20} />
                      )
                    ) : (
                      <FaSortNumericDown size={20} />
                    )}
                    Valor Total
                  </div>
                </th>
                <th>
                  <div
                    className={
                      orderBySort === BilletBatchOrderByEnum.VALUE &&
                      selectedValue === 'valorRecebido'
                        ? 'thContainerActive'
                        : 'thContainer'
                    }
                    onClick={() => {
                      setOrderSort(!orderSort);
                      setOrderBySort(BilletBatchOrderByEnum.VALUE);
                      setSelectedValue('valorRecebido');
                    }}
                  >
                    {selectedValue === 'valorRecebido' ? (
                      orderSort ? (
                        <FaSortNumericDown size={20} />
                      ) : (
                        <FaSortNumericUpAlt size={20} />
                      )
                    ) : (
                      <FaSortNumericDown size={20} />
                    )}
                    Valor Recebido
                  </div>
                </th>
                <th>
                  <div
                    className={
                      orderBySort === BilletBatchOrderByEnum.VALUE &&
                      selectedValue === 'aReceber'
                        ? 'thContainerActive'
                        : 'thContainer'
                    }
                    onClick={() => {
                      setOrderSort(!orderSort);
                      setOrderBySort(BilletBatchOrderByEnum.VALUE);
                      setSelectedValue('aReceber');
                    }}
                  >
                    {selectedValue === 'aReceber' ? (
                      orderSort ? (
                        <FaSortNumericDown size={20} />
                      ) : (
                        <FaSortNumericUpAlt size={20} />
                      )
                    ) : (
                      <FaSortNumericDown size={20} />
                    )}
                    À Receber
                  </div>
                </th>
                <th>
                  <div
                    className={
                      orderBySort === BilletBatchOrderByEnum.DUE_DATE
                        ? 'thContainerActive'
                        : 'thContainer'
                    }
                    onClick={() => {
                      setOrderSort(!orderSort);
                      setOrderBySort(BilletBatchOrderByEnum.DUE_DATE);
                      setSelectedValue('');
                    }}
                  >
                    {orderSort &&
                    orderBySort === BilletBatchOrderByEnum.DUE_DATE ? (
                      <FaSortAmountDown size={20} />
                    ) : orderBySort !== BilletBatchOrderByEnum.DUE_DATE ? (
                      <FaSortAmountDown size={20} />
                    ) : (
                      <FaSortAmountUpAlt size={20} />
                    )}
                    Vencimento
                  </div>
                </th>
                <th>
                  <div className="th">Status</div>
                </th>
                <th>
                  <div className="th">Ações</div>
                </th>
              </tr>
            </thead>

            <tbody>
              {batches.map((batch, index) => {
                return (
                  <TableRowsBillingBatch
                    setUpdateTable={() => setUpdateTable(!updateTable)}
                    batch={batch}
                    key={index}
                  />
                );
              })}
            </tbody>
          </Table>
        )}

        {!isLoading && batches !== undefined && batches.length === 0 && (
          <div className="msg-table ">
            <p>Não há cobranças em lote cadastradas com esse filtro!</p>
          </div>
        )}
        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <div className="pagination justify-content-center">
            <PaginationDefault
              totalNaPagina={batches.length}
              currentPage={pageNumber}
              totalCount={totalCount}
              limit={limit}
              action={(value: number) => setPageNumber(value)}
            />
            <select
              name="amount"
              id="amount"
              className="pagination-box"
              onChange={event => {
                setLimit(parseInt(event.target.value));
              }}
              value={limit}
            >
              <option value="30">30</option>
              <option value="45">45</option>
              <option value="60">60</option>
              <option value="75">75</option>
              <option value="90">90</option>
            </select>
          </div>
        )}
      </UIContent>
    </UIContainer>
  );
};
export default BillingBatches;
