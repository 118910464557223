export default function subtrDate(date: Date, dataToCompare: Date): number {
  if (date && dataToCompare) {
    const dateActual = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    const dateCompare = Date.UTC(
      dataToCompare.getFullYear(),
      dataToCompare.getMonth(),
      dataToCompare.getDate()
    );
    const day = 1000 * 60 * 60 * 24;

    return (dateActual - dateCompare) / day;
  } else {
    return 10;
  }
}
