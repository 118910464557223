import React, {useEffect, useState} from 'react';
import {DeepMap, FieldError} from 'react-hook-form';
import {FaQuestionCircle} from 'react-icons/fa';

import CardSection from '../../../components/CardSection';
import InputDefault from '../../../components/Inputs/InputDefault';
import InputRadioDefault from '../../../components/Inputs/InputRadioDefault';
import {ICobranca} from '../../../models/cobranca';
import moneyMask from '../../../utils/masks/moneyMask';
import './styles.scss';

interface CardMultaProps {
  register;
  errors: DeepMap<ICobranca, FieldError>;
  setIsPorcentagemJuros: React.Dispatch<React.SetStateAction<boolean>>;
  multa?: string;
  juros?: string;
  isPercent?: boolean;
  isDisable?: boolean;
  radioNameOption?: string;
}

const CardMulta: React.FC<CardMultaProps> = ({
  register,
  errors,
  setIsPorcentagemJuros,
  multa,
  juros,
  isPercent = false,
  isDisable = false,
  radioNameOption = 'isPorcentagemMulta',
}) => {
  const [isPorcentagem, setIsPorcentagem] = useState<boolean>(false);
  const [isDisabled, setDisabled] = useState<boolean>();
  const [valorJuros, setValorJuros] = useState<string>('');
  const [valorMulta, setValorMulta] = useState<string>('');

  const trocarTipo = (tipo: boolean) => {
    if (!isDisabled) {
      setIsPorcentagem(tipo);
      setIsPorcentagemJuros(tipo);
    }
    if (isPercent === undefined) {
      setValorMulta('0');
      setValorJuros('0');
      setDisabled(false);
      const multa = document.querySelector('#input-multa') as HTMLInputElement;
      if (multa) {
        multa.value = '';
      }
      const juros = document.querySelector('#input-juros') as HTMLInputElement;
      if (juros) {
        juros.value = '';
      }
    }
  };

  useEffect(() => {
    setValorJuros(juros);
    setValorMulta(multa);
    setDisabled(isDisable);
    setIsPorcentagem(isPercent);
  }, [juros, multa, isDisable, isPercent, isDisabled]);

  return (
    <CardSection title="Encargos">
      <div className="form-row justify-content-between">
        <div className="form-group col-md-2 mt-3">
          <div className="cards-valores__card-desconto__container-radio">
            <InputRadioDefault
              className="form-check-input-porc"
              name={radioNameOption}
              id={radioNameOption + 1}
              onClick={() => {
                trocarTipo(false);
              }}
              classLabel="label-item-tabela ml-2"
              defaultChecked={!isPorcentagem}
            >
              $
            </InputRadioDefault>
          </div>
          <div className="cards-valores__card-desconto__container-radio">
            <InputRadioDefault
              className="form-check-input-porc"
              name={radioNameOption}
              id={radioNameOption + 2}
              onClick={() => {
                trocarTipo(true);
              }}
              classLabel="label-item-tabela ml-2 mt-3"
              defaultChecked={isPorcentagem}
            >
              %
            </InputRadioDefault>
          </div>
        </div>
        <div className="form-group col-md-5">
          <label
            className="label-item-tabela label-tooltip"
            htmlFor="input-multa"
          >
            Multa ao mês
            <div
              data-tooltip="Pode-se apenas cobrar 2% de multa ao mês."
              className="--tooltip"
            >
              <FaQuestionCircle className="form-nova-cobranca__icon" />
            </div>
          </label>

          <div className="cards-valores__container-card__multa">
            <InputDefault
              name="multa"
              type="text"
              placeholder=""
              id="input-multa"
              inputMinLengthNumber={'0'}
              inputRef={register({
                maxLength: isPorcentagem ? 6 : 10,
              })}
              inputMaxLength={isPorcentagem ? '6' : '10'}
              mask={moneyMask}
              erros={errors}
              value={valorMulta}
              disabledInput={isDisabled}
              defaultValue={multa}
            />
            {<span>{isPorcentagem ? '%' : '$'}</span>}
          </div>
        </div>

        <div className="form-group col-md-5">
          <label
            className="label-item-tabela label-tooltip"
            htmlFor="input-juros"
          >
            Juros ao dia
            <div
              data-tooltip="Pode-se apenas cobrar 0,033% de juros ao dia."
              className="--tooltip"
            >
              <FaQuestionCircle className="form-nova-cobranca__icon" />
            </div>
          </label>
          <div className="cards-valores__container-card__juros">
            <InputDefault
              name="juros"
              type="text"
              placeholder=""
              id="input-juros"
              inputMinLengthNumber={'0'}
              inputRef={register({
                maxLength: isPorcentagem ? 6 : 10,
              })}
              inputMaxLength={isPorcentagem ? '6' : '10'}
              mask={moneyMask}
              erros={errors}
              value={valorJuros}
              disabledInput={isDisabled}
              defaultValue={valorJuros}
            />
            {<span>{isPorcentagem ? '%' : '$'}</span>}
          </div>
        </div>
      </div>
    </CardSection>
  );
};

export default CardMulta;
