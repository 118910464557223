import React, {useEffect, useState, ButtonHTMLAttributes} from 'react';
import {useAuth} from '../../../context/usuario-context';
import './styles.scss';
import {Controller, useForm} from 'react-hook-form';
import InputDefault from '../../Inputs/InputDefault';
import noEmptyMask from '../../../utils/masks/noEmptyMask';
import {cpfValidator} from '../../../utils/validations/cpfCnpjValidator';
import cpfMask from '../../../utils/masks/cpfMask';
import {Button} from 'react-bootstrap';
import InputSelect from '../../Inputs/InputSelect';
import {
  TipoOptionsDeslogado,
  TipoOptionsLogado,
} from '../../../shared/constantes';
import {ItemSelectDto} from '../../../models/item-select';
import {ITicket} from '../../../models/ticket-model';
import CharacterCount from '../../CharacterCount';
import Errors from '../../Errors';
import _ from 'lodash/fp';
import FormTicketEnviado from './FormTicketEnviado';
import {
  tipoOptionsDeslogadoEnums,
  tipoOptionsLogadoEnums,
} from '../../../shared/enumMap';
import {
  enviarEmailTicket,
  enviarEmailTicketLogado,
} from '../../../api/usuario-api';

interface FormTicketProps extends ButtonHTMLAttributes<Button> {
  onClickClose?: Function;
}

const FormTicket: React.FC<FormTicketProps> = ({onClickClose}) => {
  const {usuarioLogado} = useAuth();
  const [options, setOptions] = useState<ItemSelectDto[]>([]);
  const [stage, setStage] = useState<number>(1);
  const [name, setName] = useState<string>('');
  const [cpf, setCpf] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [contadorJustificar, setContadorJustificar] = useState<Number>(1024);

  const {register, handleSubmit, errors, control, setValue} = useForm();
  const characterCounter = e => {
    e.target.value = e.target.value.replace(/^\s+/, '');
    setContadorJustificar(1024 - e.target.value.length);
  };

  const avancar = data => {
    setName(data.nome);
    setCpf(data.cpf);
    setEmail(data.email);
    setStage(2);
  };

  const onSubmit = async data => {
    try {
      if (usuarioLogado) {
        const ticket: ITicket = {
          questionDescription: data.descricao,
          questionType: tipoOptionsLogadoEnums(data.tipo),
          partnerId: process.env.REACT_APP_PARTNER_ID,
        };
        await enviarEmailTicketLogado(usuarioLogado.id, ticket);
      } else {
        const ticket: ITicket = {
          name: name,
          email: email,
          document: cpf.replace(/[^\d]+/g, ''),
          questionDescription: data.descricao,
          questionType: tipoOptionsDeslogadoEnums(data.tipo),
          partnerId: process.env.REACT_APP_PARTNER_ID,
        };
        await enviarEmailTicket(ticket);
      }
      setStage(3);
    } catch (e) {
      onClickClose();
    }
  };

  useEffect(() => {
    setOptions(TipoOptionsDeslogado);
    setStage(1);
    if (usuarioLogado) {
      setStage(2);
      setOptions(TipoOptionsLogado);
    }
  }, [usuarioLogado]);
  return (
    <>
      {!usuarioLogado && stage === 1 && (
        <form
          className="form-modal form-ticket"
          onSubmit={handleSubmit(avancar)}
        >
          <p>
            Caro usuário, envie uma mensagem ou utilize uma das opções de
            atendimento
          </p>
          <div className="form-row">
            <div className="form-group col-12">
              <label className="label-item-tabela" htmlFor="input-nome-ticket">
                Nome completo
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="nome"
                type="text"
                placeholder=""
                id="input-nome-ticket"
                inputMaxLength={'100'}
                inputRef={register({
                  required: true,
                })}
                erros={errors}
                mask={noEmptyMask}
              />
            </div>

            <div className="form-group col-12">
              <label className="label-item-tabela" htmlFor="input-cpf-ticket">
                CPF
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="cpf"
                type="text"
                placeholder=""
                id="input-cpf-ticket"
                inputMaxLength={'14'}
                inputRef={register({
                  required: true,
                  maxLength: 14,
                  validate: {
                    cpf: value => {
                      return cpfValidator(value);
                    },
                  },
                })}
                onBlur={e =>
                  setValue('cpf', cpfMask(e.target.value.slice(0, 14)))
                }
                erros={errors}
                mask={cpfMask}
              />
            </div>

            <div className="form-group col-12">
              <label className="label-item-tabela" htmlFor="input-email-ticket">
                Email
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="email"
                type="text"
                inputMaxLength={'100'}
                placeholder=""
                id="input-email-ticket"
                inputRef={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Entre com um e-mail válido. ',
                  },
                })}
                erros={errors}
                mask={noEmptyMask}
              />
            </div>
          </div>
          <div className="buttons-group">
            <Button
              className="btn-purple btn btn-custom col-12"
              variant="custom"
              type="submit"
            >
              Avançar
            </Button>
          </div>
        </form>
      )}
      {stage === 2 && (
        <form
          className="form-modal form-ticket"
          onSubmit={handleSubmit(onSubmit)}
        >
          <p>
            {usuarioLogado ? usuarioLogado.nome + ',' : 'Caro usuário,'} envie
            uma mensagem ou utilize uma das opções de atendimento
          </p>
          <div className="form-row">
            <div className="form-group col-12" key="input-tipo-ticket">
              <label className="label-item-tabela" htmlFor="tipo">
                Tipo
              </label>
              <span className="asterisk ">*</span>
              <Controller
                control={control}
                defaultValue={null}
                rules={{required: true}}
                name="tipo"
                id="input-tipo-ticket"
                render={({onChange, value, ref}) => (
                  <InputSelect
                    placeholder="Tipo"
                    inputRef={ref}
                    erros={errors}
                    id="tipo"
                    classNamePrefix="addl-class"
                    name="tipo"
                    options={options}
                    value={options.find(c => c.value === value)}
                    onChange={(val: {value}) => {
                      onChange(val.value);
                    }}
                  />
                )}
              />
              {_.get(`${'tipo'}['type']`, errors.tipo) === 'required' && (
                <p className="input-error">Este campo é obrigatório.</p>
              )}
            </div>

            <div className="form-group col-12" key="descricao">
              <label className="label-item-tabela" htmlFor="descricao-ticket">
                Descrição
              </label>
              <span className="asterisk ">*</span>
              <textarea
                maxLength={1024}
                className="col-md-12 text-area-default"
                name="descricao"
                id="descricao-ticket"
                ref={register({
                  required: true,
                })}
                onChange={characterCounter}
              />
              <div className="d-flex justify-content-between">
                <Errors name="descricao" erros={errors} />
                <CharacterCount count={contadorJustificar}></CharacterCount>
              </div>
            </div>
          </div>
          <div className="buttons-group">
            <Button
              className="btn-purple btn btn-custom col-12"
              variant="custom"
              type="submit"
            >
              Enviar
            </Button>
          </div>
        </form>
      )}

      {stage === 3 && (
        <FormTicketEnviado onClickClose={onClickClose}></FormTicketEnviado>
      )}
    </>
  );
};

export default FormTicket;
