import {ClientRequest} from 'http';
import React, {useEffect, useRef, useState} from 'react';
import {Overlay, Popover} from 'react-bootstrap';
import {FaEllipsisV, FaPen, FaTrashAlt, FaRegEye} from 'react-icons/fa';
import BtnSwitch from '../../Buttons/BtnSwitch/index';
import {ICliente} from '../../../models';
import cpfcnpjMask from '../../../utils/masks/cpfcnpjMask';
import phoneMask from '../../../utils/masks/phoneMask';
import './styles.scss';

interface TableRowProps {
  cliente: ICliente;
  exibirModal: Function;
  excluirCliente: Function;
}

interface ITooltipProps {
  children: React.ReactNode;
}

const Tooltip = ({children}: ITooltipProps) => {
  return <div className="tooltip__father">{children}</div>;
};

const TableRowCliente: React.FC<TableRowProps> = ({
  cliente,
  exibirModal,
  excluirCliente,
}) => {
  const ref = useRef(null);

  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);
  const [tooltip, showTooltip] = useState(false);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleClosepop = () => setShow(false);

  return (
    <>
      <tr key={cliente.id}>
        <td className="left">{cliente.nome}</td>
        <td className="left">{cliente.email}</td>
        <td className="left">{phoneMask(cliente.telefone)}</td>
        <td className="left">{cpfcnpjMask(cliente.cpfCnpj)}</td>
        <td
          onMouseEnter={() => showTooltip(true)}
          onMouseLeave={() => showTooltip(false)}
        >
          {cliente.groups.length > 0 ? (
            <div className="row">
              <div className="col-12">{cliente.groups[0].name}</div>
              {cliente.groups?.length > 1 ? (
                <>
                  <div className="col-12 text-secondary font-weight-bold">
                    e +{cliente.groups.length - 1} grupos
                  </div>
                  {tooltip ? (
                    <Tooltip>
                      {cliente.groups?.map(group => {
                        return (
                          <ul key={group.id} className="group__list">
                            <li>{group.name}</li>
                          </ul>
                        );
                      })}
                    </Tooltip>
                  ) : null}
                </>
              ) : null}
            </div>
          ) : (
            <div className="no__groups">Nenhum grupo cadastrado</div>
          )}
        </td>
        <td>
          <BtnSwitch clientId={cliente.id} checked={cliente.isActive} />
        </td>
        <td>
          <FaEllipsisV style={{cursor: 'pointer'}} onClick={handleClick} />
          <Overlay
            show={show}
            target={target}
            placement="bottom"
            container={ref.current}
            containerPadding={20}
            onHide={handleClosepop}
            rootClose={true}
            transition={false}
          >
            <Popover id="popover-contained">
              <Popover.Title as="h3">{'Ações'}</Popover.Title>
              <Popover.Content>
                <span className="icones-popover">
                  <span
                    className="icones-popover__text"
                    onClick={() => excluirCliente(cliente.id)}
                  >
                    <FaTrashAlt
                      className="icones-popover__icone"
                      size="18"
                    ></FaTrashAlt>

                    <span className="icones-popover__text">Excluir</span>
                  </span>

                  <span onClick={() => exibirModal(cliente.id, true)}>
                    <FaPen className="icones-popover__icone" size="18" />
                    <span className="icones-popover__text">Editar</span>
                  </span>

                  <span onClick={() => exibirModal(cliente.id, false)}>
                    <FaRegEye className="icones-popover__icone" size="18" />
                    <span className="icones-popover__text">Ver</span>
                  </span>
                </span>
              </Popover.Content>
            </Popover>
          </Overlay>
        </td>
      </tr>
    </>
  );
};

export default TableRowCliente;
