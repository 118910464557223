import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {FaRegSave, FaBan, FaArrowRight} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

import Swal from 'sweetalert2';

import {createGroup, putClientsInGroup} from '../../../api';
import {IClientGroup} from '../../../models/client-group.model';
import {IModalEventProps} from '../../../models/modal-events-model';
import noEmptyMask from '../../../utils/masks/noEmptyMask';
import InputDefault from '../../Inputs/InputDefault';
import Titulo from '../../Titulo';
import FormSecondStepGroupRegister from './FormSecondStepGroupRegister';

const FormCadastroCliente: React.FC<IModalEventProps> = params => {
  const {register, handleSubmit, errors} = useForm<IClientGroup>();
  const [thereIsClient, setThereIsClient] = useState<boolean | undefined>(true);
  const [secondStage, setSecondStage] = useState<boolean | undefined>(false);
  const [disabledNextButton, setDisabledNextButton] = useState<boolean>(false);
  const [groupId, setGroupId] = useState<string>('');
  const history = useHistory();

  const onSubmit = async (data: IClientGroup) => {
    const newGroup: IClientGroup = {
      name: data.name,
      description: data.description,
    };

    setDisabledNextButton(true);

    if (!thereIsClient) {
      await noClientWarning(newGroup);
    } else {
      await saveGroup(newGroup);
    }

    if (thereIsClient && !secondStage) {
      setSecondStage(true);
    }
  };

  const noClientWarning = async (group: IClientGroup) => {
    try {
      Swal.fire({
        title: 'Você ainda não cadastrou nenhum Cliente!',
        text: 'Deseja cadastrar seus clientes agora?',
        showCancelButton: true,
        confirmButtonColor: ' #d33',
        cancelButtonColor: `${process.env.REACT_APP_COLOR_PRIMARY}`,
        confirmButtonText:
          '<i class="fas fa-users fa-sm" aria-hidden="true"></i> Cadastrar',
        cancelButtonText:
          '<i class="fas fa-save" aria-hidden="true"></i> Salvar Grupo',
      }).then(async result => {
        if (result.value) {
          await saveGroup(group);
          history.push('/clientes');
        } else {
          await saveGroup(group);
          closeModal();
        }
      });
    } catch (error) {
      toast.error(error, {autoClose: 2500});
    }
  };

  const updateClients = async (clients: Set<string>) => {
    try {
      await putClientsInGroup(groupId, clients);

      toast.success('Clientes salvos com sucesso!', {autoClose: 2500});

      closeModal();
    } catch (e) {
      toast.error(e, {autoClose: 2500});
    }
  };

  async function saveGroup(group: IClientGroup) {
    try {
      const savedClientGroup = await createGroup(group);

      setGroupId(await savedClientGroup.id);

      toast.success('Grupo cadastrado com sucesso!', {autoClose: 2500});
    } catch (error) {
      toast.error(error, {autoClose: 2500});
    }
  }

  const closeModal = () => {
    params.handleUpdateTable(true);
    params.handleCloseModal(false);
  };

  useEffect(() => {
    setThereIsClient(params.booleanUtil);
  }, []);

  return (
    <div>
      {!secondStage ? (
        <form className="form-modal" onSubmit={handleSubmit(onSubmit)}>
          {thereIsClient ? (
            <Titulo>Passo 1/2 - Detalhes do Grupo</Titulo>
          ) : (
            <Titulo>Detalhes do Grupo</Titulo>
          )}

          <div className="row">
            <div className="col-sm-4">
              <label className="form-label" htmlFor="input-group-name">
                Nome do Grupo
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="name"
                type="text"
                placeholder=""
                id="input-nome"
                inputRef={register({
                  required: true,
                })}
                erros={errors}
                inputMaxLength={'25'}
                mask={noEmptyMask}
              />
            </div>
          </div>

          <br />

          <div className="row">
            <div className="col-12">
              <label className="form-label" htmlFor="input-confirmacao-email">
                Descrição
              </label>
              <span className="asterisk ">*</span>
              <InputDefault
                name="description"
                type="text"
                placeholder=""
                inputMaxLength={'150'}
                id="input-descricao"
                inputRef={register({
                  required: true,
                })}
                erros={errors}
                mask={noEmptyMask}
              />
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <Button
              className="btn-custom btn-cancel"
              variant="custom"
              onClick={() => closeModal()}
            >
              <FaBan size={13} className="mr-1" />
              Cancelar
            </Button>

            {thereIsClient ? (
              <Button
                type="submit"
                variant="custom"
                className="btn-custom btn-save"
                disabled={disabledNextButton}
              >
                <FaArrowRight size={13} className="mr-1" />
                Próximo
              </Button>
            ) : (
              <Button
                type="submit"
                variant="custom"
                className="btn-custom btn-save"
              >
                <FaRegSave size={13} className="mr-1" />
                Salvar
              </Button>
            )}
          </div>
        </form>
      ) : (
        <FormSecondStepGroupRegister
          handleCloseModal={closeModal}
          handleUpdateGroup={updateClients}
          clientsIdsAlreadyInserted={new Set<string>()}
        />
      )}
    </div>
  );
};

export default FormCadastroCliente;
