import React, {useRef, useState} from 'react';
import {Overlay, Popover} from 'react-bootstrap';
import {
  FaBan,
  FaEllipsisV,
  FaRegFileAlt,
  FaTelegramPlane,
} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

import Swal from 'sweetalert2';

import {enviaSegundaViaBoletoSemEmail} from '../../../api/cobranca-api';
import {deleteBilletApi} from '../../../api/cobranca-api';
import {BatchChargesModel} from '../../../models/batch-charges-model';
import {statusEnvioMsgCobranca, statusCobranca} from '../../../shared/enumMap';
import {formataData} from '../../../utils/formatDate';
import {formataDinheiro} from '../../../utils/formatMonetary';
import SpinnerLoader from '../../Spinner';

interface TableRowProps {
  cobranca: BatchChargesModel;
  updateTableFunction?: Function;
}

const TableRowsBatchCharges = ({
  cobranca,
  updateTableFunction,
}: TableRowProps) => {
  const ref = useRef(null);

  const history = useHistory();

  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleClosepop = () => setShow(false);

  const enviarSegundaVia = async (id: string) => {
    try {
      setLoading(true);
      await enviaSegundaViaBoletoSemEmail(id);
      setLoading(false);
      toast.success('Cobrança reenviada com sucesso!', {autoClose: 2500});
    } catch (e) {
      setLoading(false);
      toast.error(`Erro ao reenviar cobranca!, Detalhes: ${e.details}`, {
        autoClose: 2500,
      });
    }
  };

  const deleteBilletAction = async (cobranca: BatchChargesModel) => {
    try {
      await deleteBilletApi(cobranca.idcobranca);
      toast.success('Cobrança excluida com sucesso!', {autoClose: 2500});
      updateTableFunction();
    } catch (error) {
      toast.error(`Erro ao excluir cobrança!: Detalhes: ${error}`, {
        autoClose: 2500,
      });
    }
  };

  const deleteBilletClick = () => {
    if (
      cobranca.statuscobranca === 'PAGA' ||
      cobranca.statuscobranca === 'FALHA_GERAR_COBRANCA'
    ) {
      toast.warning(
        `Cobrança com status ${statusCobranca(
          cobranca.statuscobranca
        )} não pode ser cancelada!`,
        {autoClose: 2500}
      );
      return;
    }
    if (cobranca.statuscobranca === 'CANCELADA') {
      toast.warning('Cobrança já se encontra cancelada!', {autoClose: 2500});
      return;
    }
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      text: 'Ao confirmar, a cobrança será excluída!',
      showCancelButton: true,
      confirmButtonText:
        '<i class="fa fa-check fa-sm" aria-hidden="true"></i> Excluir',
      cancelButtonText:
        '<i class="fas fa-ban fa-sm" aria-hidden="true"></i> Cancelar',
      customClass: {
        confirmButton: 'confirmButtonModalClass',
        cancelButton: 'cancelButtonModalClass',
      },
    }).then(async result => {
      if (result.isConfirmed) {
        await deleteBilletAction(cobranca);
        updateTableFunction();
      }
    });
  };

  return (
    <>
      {loading && <SpinnerLoader />}
      <tr key={cobranca.idcobranca}>
        <td className="left">{cobranca.nomecliente}</td>
        <td className="center">
          {new Date(cobranca.dataemissao).toLocaleDateString('pt-br')}
        </td>
        <td className="center">{formataData(cobranca.vencimento)}</td>
        <td className="left">
          {statusEnvioMsgCobranca(cobranca.statusenviomsg)}
        </td>
        <td className="right">{formataDinheiro(cobranca.valor)}</td>
        <td className="left">{statusCobranca(cobranca.statuscobranca)}</td>
        <td className="right">{formataDinheiro(cobranca.valorpago)}</td>
        <td className="center">
          {cobranca.pagoem !== null ? formataData(cobranca.pagoem) : '-'}
        </td>
        <td>
          <FaEllipsisV style={{cursor: 'pointer'}} onClick={handleClick} />
          <Overlay
            show={show}
            target={target}
            placement="bottom"
            container={ref.current}
            containerPadding={20}
            onHide={handleClosepop}
            rootClose={true}
            transition={false}
          >
            <Popover id="popover-contained">
              <Popover.Title as="h3">{'Ações'}</Popover.Title>
              <Popover.Content>
                <div className="icones-popover">
                  <div
                    onClick={() =>
                      history.push(`/ver-cobranca/${cobranca.idcobranca}`)
                    }
                  >
                    <FaRegFileAlt className="icones-popover__icone" size="18" />
                    <span className="icones-popover__text">Detalhe</span>
                  </div>
                  {cobranca.statuscobranca === 'EM_ABERTO' ? (
                    <>
                      <div
                        onClick={() => enviarSegundaVia(cobranca.idcobranca)}
                      >
                        <FaTelegramPlane
                          className="icones-popover__icone"
                          size="18"
                        />
                        <span className="icones-popover__text">Enviar</span>
                      </div>
                      <div onClick={deleteBilletClick}>
                        <FaBan className="icones-popover__icone" size="18" />
                        <span className="icones-popover__text">Excluir</span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </Popover.Content>
            </Popover>
          </Overlay>
        </td>
      </tr>
    </>
  );
};

export default TableRowsBatchCharges;
