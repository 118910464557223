import React from 'react';

import './styles.scss';
import NavbarTop from '../../NavbarTop';

const UIContainer: React.FC = ({children}) => {
  return (
    <React.Fragment>
      <div className="page-container">
        <NavbarTop />
        <div className="container-fluid main">{children}</div>
      </div>
    </React.Fragment>
  );
};

export default UIContainer;
