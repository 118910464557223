import {toast} from 'react-toastify';
import './styles.scss';

type IAlertType =
  | 'alert-danger'
  | 'alert-warning'
  | 'alert-success'
  | 'alert-info';

const alert = (type: IAlertType) => (
  message: string,
  callbackOnOpen?: () => void,
  callbackOnClose?: () => void
) =>
  toast(message, {
    className: `notification ${type}`,
    onOpen: callbackOnOpen,
    onClose: callbackOnClose,
  });

export const Alert = {
  error: alert('alert-danger'),
  info: alert('alert-info'),
  success: alert('alert-success'),
  warning: alert('alert-warning'),
};
