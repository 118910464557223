import React from 'react';

import {IVerCobrancaBoleto} from '../../../models/cobranca';
import {
  statusCobranca,
  tipoCobrancaEnums,
  statusEnvioMsgCobranca,
} from '../../../shared/enumMap';
import {formataData} from '../../../utils/formatDate';
import {formataDinheiro} from '../../../utils/formatMonetary';
import Titulo from '../../Titulo';
const VerCobranca: React.FC<IVerCobrancaBoleto> = (
  props: IVerCobrancaBoleto
) => {
  return (
    <>
      <div className="d-flex bd-highlight">
        <div className="p-2 flex-grow-1 bd-highlight">
          <Titulo>Cobrança</Titulo>
        </div>
      </div>

      <div className="row mx-auto">
        <div className="col-sm-4">
          <label htmlFor="Categoria" className="form-label">
            Categoria:
          </label>
          <span className="text__content">{props.categoria?.nome}</span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Descrição" className="form-label">
            Descrição da Categoria:
          </label>
          <span className="text__content">{props.categoria?.descricao}</span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Tipo" className="form-label">
            Tipo:
          </label>
          <span className="text__content">{tipoCobrancaEnums(props.tipo)}</span>
        </div>
      </div>
      <div className="row mx-auto">
        <div className="col-sm-4">
          <label htmlFor="Multa" className="form-label">
            Multa:
          </label>
          <span className="text__content">
            {String(props.multaPorcentagem) !== '0'
              ? props.multaPorcentagem + ' % '
              : String(props.multa) !== '0'
              ? formataDinheiro(props.multa)
              : '-'}
          </span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Juros ao mês" className="form-label">
            Juros ao mês:
          </label>
          <span className="text__content">
            {String(props.juros) !== '0' ? formataDinheiro(props.juros) : '-'}
          </span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Instruções" className="form-label">
            Desconto:
          </label>
          <span className="text__content">
            {String(props.descontoPorcentagem) !== '0'
              ? props.descontoPorcentagem + ' % '
              : String(props.desconto) !== '0'
              ? formataDinheiro(props.desconto)
              : '-'}
          </span>
        </div>
      </div>
      <div className="row mx-auto">
        <div className="col-sm-12">
          <label htmlFor="Justificativa" className="form-label">
            Descrição da Cobrança:
          </label>
          <span className="text__content">
            {props.descricao ? props.descricao : '-'}
          </span>
        </div>
      </div>
      <div className="d-flex bd-highlight">
        <div className="p-2 flex-grow-1 bd-highlight">
          <Titulo>Esta cobrança</Titulo>
        </div>
      </div>

      <div className="row ml-2">
        <div className="col-sm-4">
          <label htmlFor="Número" className="form-label">
            Número:
          </label>
          <span className="text__content">{props.cobrancaAtual?.numero}</span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Emissão" className="form-label">
            Emissão:
          </label>
          <span className="text__content">
            {props.cobrancaAtual?.dataEnvioParaWallet
              ? formataData(props.cobrancaAtual?.dataEnvioParaWallet)
              : '-'}
          </span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Vencimento" className="form-label">
            Vencimento:
          </label>
          <span className="text__content">
            {formataData(props.cobrancaAtual?.vencimentoEm)}
          </span>
        </div>
      </div>
      <div className="row ml-2">
        <div className="col-sm-4">
          <label htmlFor="Status" className="form-label">
            Status:
          </label>

          <span className="text__content">
            {statusCobranca(props.cobrancaAtual?.status)}
          </span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Status envio" className="form-label">
            Status envio:
          </label>
          <span className="text__content">
            {statusEnvioMsgCobranca(
              props.cobrancaAtual?.statusEnvioMensagemCobranca
            )}
          </span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Valor pago" className="form-label">
            Valor:
          </label>
          <span className="text__content">
            {props.cobrancaAtual?.valor
              ? formataDinheiro(props.cobrancaAtual.valor)
              : ''}
          </span>
        </div>
      </div>
      <div className="row ml-2">
        <div className="col-sm-4">
          <label htmlFor="Enviado em:" className="form-label">
            Enviado em:
          </label>
          <span className="text__content">
            {props.cobrancaAtual?.enviadoEm
              ? formataData(props.cobrancaAtual?.enviadoEm)
              : '-'}
          </span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Limite para pgto" className="form-label">
            Limite para pgto:
          </label>
          <span className="text__content">
            {props.cobrancaAtual?.dataLimitePagamento
              ? formataData(props.cobrancaAtual?.dataLimitePagamento)
              : '-'}
          </span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Valor pago" className="form-label">
            Valor pago:
          </label>
          <span className="text__content">
            {props.cobrancaAtual?.valorPago
              ? formataDinheiro(props.cobrancaAtual.valorPago)
              : '-'}
          </span>
        </div>
      </div>
      <div className="row ml-2">
        <div className="col-sm-4">
          <label htmlFor="Instruções" className="form-label">
            Instruções:
          </label>
          <span className="text__content">{props.instrucoesCliente}</span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Pago Em" className="form-label">
            Pago em:
          </label>
          <span className="text__content">
            {props.cobrancaAtual?.pagoEm
              ? formataData(props.cobrancaAtual?.pagoEm)
              : '-'}
          </span>
        </div>
        <div className="col-sm-4">
          <label htmlFor="Mensagem de erro" className="form-label">
            Mensagem de erro:
          </label>
          <span className="text__content">
            {props.cobrancaAtual?.mensagemCallback
              ? props.cobrancaAtual?.mensagemCallback
              : '-'}
          </span>
        </div>
      </div>
    </>
  );
};

export default VerCobranca;
