import React, {useState, useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import {IconBaseProps} from 'react-icons';

import './styles.scss';

import {getDetailsRuleApi} from '../../../api/billing-rule';
import {BillingRuleModel} from '../../../models/billing-rule-model';
import {IFormContent} from '../../../models/forms-model';
import FormBillingRule from '../FormBillingRule';

interface ModalEditarProps {
  id?: string;
  icon?: React.ComponentType<IconBaseProps>;
  variantClass?: string;
  formContent?: IFormContent;
  setUpdateTable?: Function;
  handleClose?: Function;
  showModal?: boolean;
  showMenuModal?: boolean;
}

const ModalEditBillingRule: React.FC<ModalEditarProps> = ({
  id,
  icon: Icon,
  setUpdateTable,
  handleClose,
  showModal,
  showMenuModal,
}) => {
  const [rule, setRule] = useState<BillingRuleModel>();

  async function loadRule(id: string) {
    setRule(await getDetailsRuleApi(id));
  }

  const updateTable = () => {
    setRule(undefined);
    setUpdateTable(true);
  };

  const showPopoverCategoria = () => {
    const showPopover = document.querySelectorAll<HTMLElement>('.popover ')[0];

    if (showPopover) {
      if (showModal) {
        showPopover.style.display = 'none';
      }
    }
  };

  useEffect(() => {
    loadRule(id);
    showPopoverCategoria();
  }, []);
  return (
    <>
      {rule && (
        <Modal
          animation={false}
          show={showModal}
          onHide={handleClose}
          backdrop="static"
          keyboard={true}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {Icon && <Icon size={20} className="modal-icone" />}
              {showMenuModal
                ? 'Editar Regra de Cobrança'
                : 'Ver Regra de Cobrança'}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <FormBillingRule
              rule={rule}
              handleCloseModal={handleClose}
              handleUpdateTable={updateTable}
              showEditOrView={showMenuModal}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default ModalEditBillingRule;
